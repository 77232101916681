import React, {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {MyProfileView} from './MyProfileView'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'
import {RateInterpreterView} from './RateInterpreterView'
import {useQuery} from 'react-query'
import {getUserByID} from './core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {UserListEditInProfile} from './UserListEditInProfile'

export function ConfigurationView() {
  let {id} = useParams()
  const {currentUser} = useAuth()
  const [activeTab, setActiveTab] = useState(1)
  const navigate = useNavigate()

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber)
  }

  const {
    isLoading: isLoadingCustomer,
    data: user,
    error,
    refetch: refetchCsutomer,
  } = useQuery(
    [`customer-detail`, id],
    () => {
      return getUserByID(id)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <>
      {/* <div className='mb-3'>
        <h1 className='mb-0 fw-semibold fs-2'>
          {user?.code === currentUser?.result?.code
            ? 'My Profile'
            : user?.userType === 'INTERPRETER'
            ? 'Interpreter'
            : user?.userType === 'CONSUMER'
            ? 'Requester'
            : user?.userType === 'SYSTEM'
            ? 'Admin'
            : 'Profile'}
        </h1>
        <span className='text-gray-500'>Manage Configuration</span>
      </div> */}
      <div className='mb-5'>
        <div className='d-flex align-items-center '>
          {user?.code !== currentUser?.result?.code && (
            <button
              className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-3'
              onClick={() => navigate(-1)}
            >
              <i className='bi bi-arrow-left-circle ' style={{fontSize: '28px'}}></i>
            </button>
          )}
          <div>
            <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
              {user?.code === currentUser?.result?.code
                ? 'My Profile'
                : user?.userType === 'INTERPRETER'
                ? 'Interpreter'
                : user?.userType === 'CONSUMER'
                ? 'Requester'
                : user?.userType === 'SYSTEM'
                ? 'Admin'
                : 'Profile'}
            </h1>
            <p className='text-gray-500 fs-5'>Manage Configuration</p>
          </div>
        </div>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400   ${
                  activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_1'
                onClick={() => handleTabClick(1)}
              >
                Info
              </a>
            </li>

            {user?.userType == 'INTERPRETER' && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400 ${
                    activeTab === 2 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_2'
                  onClick={() => handleTabClick(2)}
                >
                  Rate
                </a>
              </li>
            )}

            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 3 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_3'
                onClick={() => handleTabClick(3)}
              >
                Authentication
              </a>
            </li>
          </ul>
        </div>
        <div className='card-body pt-0'>
          <div className=''>
            <div className='tab-content' id='myTabContent'>
              {activeTab === 1 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                  <UserListEditInProfile customerCode={0} userType={'INTERPRETER'} />
                </div>
              )}

              {user?.userType == 'INTERPRETER' && activeTab === 2 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                  <RateInterpreterView />
                </div>
              )}

              {activeTab === 3 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel'>
                  <br />
                  <div
                    className='card mb-5 mb-xl-5 shadow-sm'
                    style={{borderTop: '3px solid #e3759b'}}
                  >
                    {/* style={{backgroundColor: '#FAF7F5'}} */}
                    <div className='card-body pt-9 pb-4'>
                      <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                        <div className='flex-grow-1'>
                          <div className='d-flex justify-content-between align-items-start flex-wrap'>
                            <div className='d-flex flex-column'>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex'>
                                  <h5 className='text-info fs-3 fw-bold '>
                                    IMPORTANT{' '}
                                    <i
                                      className='bi bi-info-circle-fill text-info me-2 '
                                      style={{fontSize: '1.3rem'}}
                                    >
                                      {' '}
                                    </i>
                                  </h5>
                                </div>
                                {/* <UnderDevelopmentBadge className='mb-2' /> */}
                              </div>

                              <div className='d-flex flex-wrap fs-6'>
                                <p className='text-gray-600' style={{fontSize: '12px'}}>
                                  We will ask you for a verification code upon initial login on any
                                  new device, and <br></br> then once every 30 days after that.
                                </p>
                              </div>
                              <div className='d-flex flex-wrap flex-sm-nowrap'>
                                <div className='pe-7 mb-4 border-2 border-end border-secondary'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-filter'> Email</Tooltip>}
                                  >
                                    <div>
                                      <button
                                        type='button'
                                        className='btn btn-primary btn-sm btn-icon'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_4'
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/communication/com002.svg'
                                          className='svg-icon-muted svg-icon-2'
                                        />
                                      </button>
                                    </div>
                                  </OverlayTrigger>
                                  {/* <button
                                    type='button'
                                    className='btn btn-primary btn-sm'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_4'
                                  >
                                    <i className='fa-solid fa-envelope me-2'></i>
                                    Email
                                  </button> */}
                                </div>

                                <div className='ps-0 ps-sm-7 flex-grow-1'>
                                  <div className='d-flex justify-content-between align-items-start flex-wrap'>
                                    <div className='d-flex flex-column'>
                                      <div className='d-flex align-items-center'>
                                        <span className='fs-6' style={{fontSize: '12px'}}>
                                          Sends a one-time verification code to your email address.{' '}
                                          <br></br> Email:
                                        </span>
                                      </div>

                                      <div className='d-flex flex-wrap fs-4'>
                                        <p className='text-black fw-bold'>OFF</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <img
            className='w-100 card-rounded-bottom'
            alt=''
            src='assetsmedia/svg/illustrations/bg-4.svg'
          />
        </div>
      </div>
    </>
  )
}
