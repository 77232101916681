import axios from 'axios'
import moment from 'moment'
import {useEffect, useRef, useState} from 'react'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {KTSVG} from '../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useDownloadExcel} from 'react-export-table-to-excel'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

export function PermissionsChnage() {
  const [logData, setLogData] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const tableRef = useRef(null)

  useEffect(() => {
    fetchAllLogInData(currentPage)
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    if (searchTerm.trim() === '') {
      handleSearch()
    }
  }, [searchTerm])

  const fetchAllLogInData = async (page: number) => {
    setIsLoading(true)
    try {
      //want to set correct network call
      const response = await axios.get(`${API_URL}/accounts/user-permissions-log`, {
        params: {
          page,
          items_per_page: rowsPerPage,
          search: searchTerm,
        },
      })
      const {payload} = response.data
      setLogData(response.data.data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearch = () => {
    setCurrentPage(1)
    fetchAllLogInData(1)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Premissions_Details',
    sheet: searchTerm ? `Filtered results for "${searchTerm}"` : 'Complete PreCall Data',
  })
  return (
    <>
      <div className='mb-5'>
        <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
          Permissions Details
        </h1>

        <p className='text-gray-500 fs-5'>Permissions Details List</p>
      </div>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title d-flex align-items-center position-relative me-4 '>
            {/* Login Data */}
          </div>

          <div className='card-toolbar'>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                  onClick={handleSearch}
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              {/* <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Download CSV</Tooltip>}
              >
                <button
                  type='button'
                  className='btn btn-primary btn-sm me-3 btn-icon'
                  onClick={onDownload}
                >
                  <KTSVG
                    path='/media/icons/duotune/files/fil017.svg'
                    className='svg-icon-muted'
                  />
                </button>
              </OverlayTrigger> */}
            </div>
          </div>
        </div>

        <div className='mt-1 mb-4 py-2 px-8'>
          <div className='tab-content' id='myTabContent'>
            {/* begin::Body */}
            <div className='py-0'>
              {/* begin::Table container */}
              <div className='table-responsive' style={{overflowY: 'scroll'}}>
                {/* begin::Table */}
                <table className='table table-row-dashed overflow-auto table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
                  {/* begin::Table head */}
                  <thead
                    style={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: '#ffffff',
                      zIndex: '10',
                      boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;',
                      border: '1px solid #dee2e6',
                    }}
                  >
                    <tr className='fw-semibold text-muted text-uppercase text-center'>
                      <th className='min-w-200px ' rowSpan={3} colSpan={1}>
                        Name
                      </th>
                      <th className='min-w-200px ' rowSpan={3} colSpan={1}>
                        Email
                      </th>
                      <th className='min-w-200px ' rowSpan={3} colSpan={1}>
                        Role
                      </th>
                      <th className='min-w-600px ' rowSpan={1} colSpan={7}>
                        Previous Premission
                      </th>
                      <th className='min-w-600px ' rowSpan={1} colSpan={7}>
                        New Premission
                      </th>
                      <th className='min-w-300px ' rowSpan={3} colSpan={1}>
                        Reason
                      </th>
                      <th className='min-w-150px ' rowSpan={3} colSpan={1}>
                        Modified By
                      </th>
                      <th className='min-w-150px' rowSpan={3} colSpan={1}>
                        Timestamp (UTC)
                      </th>
                    </tr>
                    <tr className='fw-semibold text-muted text-uppercase text-center'>
                      <th className='min-w-200px ' rowSpan={1} colSpan={2}>
                        OPI
                      </th>
                      <th className='min-w-120px ' rowSpan={1} colSpan={2}>
                        OSI
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={2}>
                        VRI
                      </th>
                      <th className='min-w-150px ' rowSpan={1} colSpan={1}>
                        OTHER
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={2}>
                        OPI
                      </th>
                      <th className='min-w-120px ' rowSpan={1} colSpan={2}>
                        OSI
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={2}>
                        VRI
                      </th>
                      <th className='min-w-150px ' rowSpan={1} colSpan={1}>
                        OTHER
                      </th>
                    </tr>
                    <tr className='fw-semibold text-muted text-uppercase text-center'>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        On-Demand
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Scheduled
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Consecutive
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Simultaneous
                      </th>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        On-Demand
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Scheduled
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={1}>
                        Third Party Platform
                      </th>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        On-Demand
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Scheduled
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Consecutive
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Simultaneous
                      </th>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        On-Demand
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Scheduled
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={1}>
                        Third Party Platform
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {logData?.length > 0 ? (
                      logData.map((item: any, index: number) => (
                        <tr key={index}>
                          <td style={{borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd'}}>
                            {item.userName || '-'}
                          </td>
                          <td style={{borderRight: '1px solid #ddd'}}>{item.email || '-'}</td>
                          <td style={{borderRight: '1px solid #ddd'}}>{item.role || '-'}</td>

                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.opI_OndemandTelephonic ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.opI_ShdTelephonic ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.osI_OnsiteConsecutive ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.osI_OnsiteSimultaneous ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.vrI_OndemandVideoInteroreting ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.vrI_ShdVideoInteroreting ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.other_3rdPartyPlatform ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>

                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.opI_OndemandTelephonic ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.opI_ShdTelephonic ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.osI_OnsiteConsecutive ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.osI_OnsiteSimultaneous ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.vrI_OndemandVideoInteroreting ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.vrI_ShdVideoInteroreting ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.other_3rdPartyPlatform ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>

                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.reason ? item.reason : '-'}
                          </td>
                          <td
                            style={{
                              borderRight: '1px solid #ddd',
                              textAlign: 'center',
                            }}
                          >
                            {item.modifiedBy || '-'}
                          </td>
                          <td
                            style={{
                              borderRight: '1px solid #ddd',
                              borderLeft: '1px solid #ddd',
                              textAlign: 'center',
                            }}
                          >
                            {moment(item.lastModifiedDateTime).utc().format('YYYY-MM-DD hh:mm A') ||
                              '-'}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={20}>
                          <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                            <div className='text-center'>
                              <div className='symbol symbol-200px '>
                                <img src='/media/other/nodata.png' alt='' />
                              </div>
                            </div>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                              No matching records found
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <CommonPaginationModel
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={(newRowsPerPage: number) => setRowsPerPage(newRowsPerPage)}
          />
        </div>
      </div>
      {isLoading && <CommonLoading />}
    </>
  )
}
