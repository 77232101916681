import React, {useState} from 'react'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Formik} from 'formik'
import toaster from '../../../../Utils/toaster'
import axios from 'axios'
import * as Yup from 'yup'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'

const API_URL = process.env.REACT_APP_API_URL

const HoursOptions: any = Array.from({length: 23}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

const MinutesOptions: any = Array.from({length: 60}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

const createRateSchema = Yup.object().shape({
  fK_LanguageFrom: Yup.string().required('Required').notOneOf(['0'], 'Required'),
  fK_LanguageTo: Yup.array()
    .required('Required')
    .min(1, 'Required')
    .test('not-same', 'Language from and language to must be different', function (value) {
      const {fK_LanguageFrom} = this.parent
      if (value && Array.isArray(value)) {
        return !value.some((lang) => lang.value === fK_LanguageFrom)
      }
      return true
    }),
  fK_ServiceType: Yup.array().required('Required').min(1, 'Required'),
  fK_CommunicationType: Yup.array().required('Required').min(1, 'Required'),
  startTime: Yup.string()
    .test('is-after', 'Start time cannot be after end time', function (value) {
      const {endTime} = this.parent
      if (endTime && value) {
        return timeToMinutes(value) < timeToMinutes(endTime)
      }
      return true
    })
    .required('Required'),
  endTime: Yup.string()
    .test('is-after-start', 'End time cannot be before start time', function (value) {
      const {startTime} = this.parent
      if (startTime && value) {
        return timeToMinutes(value) > timeToMinutes(startTime)
      }
      return true
    })
    .required('Required'),
  minimumBillableHours: Yup.number().required('Required'),
  minimumBillableMinutes: Yup.number().required('Required'),
  incrementsHours: Yup.number().required('Required'),
  incrementsMinutes: Yup.number().required('Required'),
  days: Yup.array().required('Required').min(1, 'Required'),
  regularRate: Yup.number().required('Required').min(0, 'Rate cannot be negative'),
  rushRate: Yup.number().required('Required').min(0, 'Rate cannot be negative'),
  emergencyRate: Yup.number().required('Required').min(0, 'Rate cannot be negative'),
  conferenceCharges: Yup.number().required('Required').min(0, 'Charges cannot be negative'),
})

interface RowData {
  id: number
  fK_LanguageFrom: string
  languageFrom: string
  fK_LanguageTo: string[]
  fK_ServiceType: string[]
  fK_CommunicationType: string[]
  startTime: string
  endTime: string
  minimumBillableHours: number
  minimumBillableMinutes: number
  incrementsHours: number
  incrementsMinutes: number
  days: string[]
  regularRate: string
  rushRate: string
  emergencyRate: string
  conferenceCharges: string
  isActive: boolean
}

const daysOptions: any = [
  {value: '1', label: 'Sunday'},
  {value: '2', label: 'Monday'},
  {value: '3', label: 'Tuesday'},
  {value: '4', label: 'Wednesday'},
  {value: '5', label: 'Thursday'},
  {value: '6', label: 'Friday'},
  {value: '7', label: 'Saturday'},
]

// Define the props for the TableRow component
interface TableRowProps {
  data: RowData
  languages: any
  serviceTypes: any
  communicationTypes: any
  fetchAllCustomerRate: any
  rateCategory: string
}
// Define the TableRow component
const TableRow: React.FC<TableRowProps> = ({
  data,
  languages,
  serviceTypes,
  communicationTypes,
  fetchAllCustomerRate,
  rateCategory,
}) => {
  let {id} = useParams()
  const {currentUser} = useAuth()
  const [isEditing, setIsEditing] = useState(false)

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={createRateSchema}
      onSubmit={async (values, {setSubmitting, resetForm}) => {
        setSubmitting(true)
        try {
          let response = await axios.put(`${API_URL}/rates/rate`, {
            ...values,
            userId: id,
            fK_LanguageTo: values.fK_LanguageTo.map((item: any) => item.value),
            days: values.days.map((item: any) => item.value),
            fK_CommunicationType: values.fK_CommunicationType.map((item: any) => item.value),
            fK_ServiceType: values.fK_ServiceType.map((item: any) => item.value),
          })

          if (response.data.status === 'S') {
            toaster('success', response.data.text ?? 'Successfully Updated')
            setIsEditing(false)
            resetForm()
            fetchAllCustomerRate({
              fK_LanguageFrom: null,
              fK_LanguageTo: [],
              fK_ServiceType: [],
              fK_CommunicationType: [],
            })
          } else if (response.data.status === 'E') {
            toaster('error', response.data.text ?? 'Updated Error')
          }
        } catch (ex) {
          toaster('error', 'Server Error')
          console.error(ex)
        } finally {
          setSubmitting(true)
        }
      }}
    >
      {({
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
        resetForm,
        values,
        errors,
        touched,
        isValid,
        dirty,
        ...formik
      }) => (
        <tr>
          <td>
            {isEditing ? (
              <>
                <Select
                  {...formik.getFieldProps('fK_LanguageFrom')}
                  className='react-select-styled react-select-solid react-select-sm'
                  classNamePrefix='react-select'
                  menuPosition='fixed'
                  menuPlacement='auto'
                  options={languages.map((item: any) => ({
                    value: item.key,
                    label: item.value,
                  }))}
                  placeholder='Select'
                  value={{
                    value: values.fK_LanguageFrom,
                    label:
                      languages.find((x: any) => x.key === values.fK_LanguageFrom)?.value ||
                      'Select From Language',
                  }}
                  onChange={(selectedOption: any) => {
                    setFieldValue('fK_LanguageFrom', selectedOption?.value)
                  }}
                  onBlur={(e) => setFieldTouched('fK_LanguageFrom', true)}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      border: '1px solid #e4e6ef',
                    }),
                  }}
                />
                {errors.fK_LanguageFrom && touched?.fK_LanguageFrom && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{String(errors.fK_LanguageFrom ?? '')}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <span>{data.languageFrom}</span>
            )}
          </td>
          <td>
            {isEditing ? (
              <>
                <Select
                  {...formik.getFieldProps('fK_LanguageTo')}
                  className='react-select-styled react-select-solid react-select-sm'
                  classNamePrefix='react-select'
                  options={languages.map((item: any) => ({
                    value: item.key,
                    label: item.value,
                  }))}
                  placeholder='Select To Languages'
                  value={values.fK_LanguageTo}
                  onChange={(selectedOption: any) => {
                    setFieldValue('fK_LanguageTo', selectedOption)
                  }}
                  onBlur={(e) => setFieldTouched('fK_LanguageTo', true)}
                  isMulti
                  menuPosition='fixed'
                  menuPlacement='auto'
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      border: '1px solid #e4e6ef',
                    }),
                  }}
                />
                {errors.fK_LanguageTo && touched?.fK_LanguageTo && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{errors.fK_LanguageTo}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              data.fK_LanguageTo.map((x: any, index) => (
                <span key={index} className='badge badge-secondary mb-1 me-1'>
                  {x?.label}
                </span>
              ))
              // <span className='badge badge-secondary mb-1 me-1'>{data.toLanguages.join(', ')}</span>
            )}
          </td>

          <td>
            {isEditing ? (
              <>
                <Select
                  {...formik.getFieldProps('fK_CommunicationType')}
                  className='react-select-styled react-select-solid react-select-sm'
                  classNamePrefix='react-select'
                  placeholder='Select Communication Types'
                  isMulti
                  menuPosition='fixed'
                  menuPlacement='auto'
                  options={communicationTypes.map((item: any) => ({
                    value: item.key,
                    label: item.value,
                  }))}
                  value={values.fK_CommunicationType}
                  onChange={(selectedOption: any) => {
                    setFieldValue('fK_CommunicationType', selectedOption)
                  }}
                  onBlur={(e) => setFieldTouched('fK_CommunicationType', true)}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      border: '1px solid #e4e6ef',
                    }),
                  }}
                />
                {errors.fK_CommunicationType && touched?.fK_CommunicationType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{errors.fK_CommunicationType}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              data.fK_CommunicationType.map((x: any, index) => (
                <span key={index} className='badge badge-dark mb-1 me-1'>
                  {x?.label}
                </span>
              ))
            )}
          </td>

          <td>
            {isEditing ? (
              <>
                <Select
                  {...formik.getFieldProps('fK_ServiceType')}
                  className='react-select-styled react-select-solid react-select-sm'
                  classNamePrefix='react-select'
                  options={serviceTypes.map((item: any) => ({
                    value: item.key,
                    label: item.value,
                  }))}
                  placeholder='Select Service Types'
                  value={values.fK_ServiceType}
                  onChange={(selectedOption: any) => {
                    setFieldValue('fK_ServiceType', selectedOption)
                  }}
                  onBlur={(e) => setFieldTouched('fK_ServiceType', true)}
                  isMulti
                  menuPosition='fixed'
                  menuPlacement='auto'
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      border: '1px solid #e4e6ef',
                    }),
                  }}
                />
                {errors.fK_ServiceType && touched?.fK_ServiceType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{errors.fK_ServiceType}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              data.fK_ServiceType.map((x: any, index) => (
                <span key={index} className='badge badge-primary mb-1 me-1'>
                  {x?.label}
                </span>
              ))
            )}
          </td>

          <td>
            {isEditing ? (
              <>
                <input
                  id='startTime'
                  type='time'
                  className='form-control form-control-white form-select-sm custom-input-height'
                  placeholder='Start Time'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.startTime}
                />
                {errors.startTime && touched?.startTime && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{errors.startTime}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <span>{data.startTime}</span>
            )}
          </td>
          <td>
            {isEditing ? (
              <>
                <input
                  id='endTime'
                  name='endTime'
                  type='time'
                  className='form-control form-control-white form-select-sm custom-input-height'
                  placeholder='End Time'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.endTime}
                />
                {errors.endTime && touched?.endTime && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{errors.endTime}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <span>{data.endTime}</span>
            )}
          </td>
          <td>
            {isEditing ? (
              <div className='input-group input-group-sm'>
                <div className='d-flex w-auto' style={{flex: 1, marginRight: 5}}>
                  <Select
                    {...formik.getFieldProps('minimumBillableHours')}
                    className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                    classNamePrefix='react-select'
                    options={HoursOptions}
                    menuPosition='fixed'
                    menuPlacement='auto'
                    placeholder='Select Hours'
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                        border: '1px solid #e4e6ef',
                        borderRadius: '4px 0 0 4px',
                      }),
                    }}
                    value={{
                      value: values.minimumBillableHours,
                      label:
                        HoursOptions.find((x: any) => x.value === values.minimumBillableHours)
                          ?.label || 'Select Hours',
                    }}
                    onChange={(e: any) => {
                      setFieldValue('minimumBillableHours', e?.value)
                    }}
                    onBlur={(e) => setFieldTouched('minimumBillableHours', true)}
                  />
                  {errors.minimumBillableHours && touched?.minimumBillableHours && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-8 fw-semibold'>
                        <span role='alert'>{errors.minimumBillableHours}</span>
                      </div>
                    </div>
                  )}
                  <span
                    className='input-group-text fs-8'
                    style={{flex: 1, borderRadius: '0 4px 4px 0', padding: '0 1rem'}}
                  >
                    Hours
                  </span>
                </div>
                <div className='d-flex w-auto' style={{flex: 1}}>
                  <Select
                    {...formik.getFieldProps('minimumBillableMinutes')}
                    className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                    classNamePrefix='react-select'
                    options={MinutesOptions}
                    menuPosition='fixed'
                    menuPlacement='auto'
                    placeholder='Select Minutes'
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                        border: '1px solid #e4e6ef',
                        borderRadius: '4px 0 0 4px',
                      }),
                    }}
                    value={{
                      value: values.minimumBillableMinutes,
                      label:
                        MinutesOptions.find((x: any) => x.value === values.minimumBillableMinutes)
                          ?.label || 'Select Minutes',
                    }}
                    onChange={(e: any) => {
                      setFieldValue('minimumBillableMinutes', e?.value)
                    }}
                    onBlur={(e) => setFieldTouched('minimumBillableMinutes', true)}
                  />
                  {errors.minimumBillableMinutes && touched?.minimumBillableMinutes && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-8 fw-semibold'>
                        <span role='alert'>{errors.minimumBillableMinutes}</span>
                      </div>
                    </div>
                  )}
                  <span
                    className='input-group-text fs-8'
                    style={{flex: 1, borderRadius: '0 4px 4px 0', padding: '0 1rem'}}
                  >
                    Minutes
                  </span>
                </div>
              </div>
            ) : (
              <span>
                {data.minimumBillableHours} Hours {data.minimumBillableMinutes} Minutes
              </span>
            )}
          </td>

          <td>
            {isEditing ? (
              <div className='input-group input-group-sm'>
                <div className='d-flex w-auto' style={{flex: 1, marginRight: 5}}>
                  <Select
                    className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                    classNamePrefix='react-select'
                    options={HoursOptions}
                    menuPosition='fixed'
                    menuPlacement='auto'
                    placeholder='Select Hours'
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                        border: '1px solid #e4e6ef',
                        borderRadius: '4px 0 0 4px',
                      }),
                    }}
                    value={{
                      value: values.incrementsHours,
                      label:
                        HoursOptions.find((x: any) => x.value === values.incrementsHours)?.label ||
                        'Select Hours',
                    }}
                    onChange={(e: any) => {
                      setFieldValue('incrementsHours', e?.value)
                    }}
                    onBlur={(e) => setFieldTouched('incrementsHours', true)}
                  />
                  {errors.incrementsHours && touched?.incrementsHours && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-8 fw-semibold'>
                        <span role='alert'>{errors.incrementsHours}</span>
                      </div>
                    </div>
                  )}
                  <span
                    className='input-group-text fs-8'
                    style={{flex: 1, borderRadius: '0 4px 4px 0', padding: '0 1rem'}}
                  >
                    Hours
                  </span>
                </div>
                <div className='d-flex w-auto' style={{flex: 1}}>
                  <Select
                    className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                    classNamePrefix='react-select'
                    options={MinutesOptions}
                    menuPosition='fixed'
                    menuPlacement='auto'
                    placeholder='Select Minutes'
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                        border: '1px solid #e4e6ef',
                        borderRadius: '4px 0 0 4px',
                      }),
                    }}
                    value={{
                      value: values.incrementsMinutes,
                      label:
                        MinutesOptions.find((x: any) => x.value === values.incrementsMinutes)
                          ?.label || 'Select Minutes',
                    }}
                    onChange={(e: any) => {
                      setFieldValue('incrementsMinutes', e?.value)
                    }}
                    onBlur={(e) => setFieldTouched('incrementsMinutes', true)}
                  />
                  {errors.incrementsMinutes && touched?.incrementsMinutes && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.incrementsMinutes}</span>
                      </div>
                    </div>
                  )}
                  <span
                    className='input-group-text fs-8'
                    style={{flex: 1, borderRadius: '0 4px 4px 0', padding: '0 1rem'}}
                  >
                    Minutes
                  </span>
                </div>
              </div>
            ) : (
              <span>
                {data.incrementsHours} Hours {data.incrementsMinutes} Minutes
              </span>
            )}
          </td>
          <td>
            {isEditing ? (
              <>
                <Select
                  className='react-select-styled react-select-solid react-select-sm'
                  classNamePrefix='react-select'
                  options={daysOptions}
                  placeholder='Select'
                  isMulti
                  menuPosition='fixed'
                  menuPlacement='auto'
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      border: '1px solid #e4e6ef',
                    }),
                  }}
                  value={values.days}
                  onChange={(selectedOption: any) => {
                    setFieldValue('days', selectedOption)
                  }}
                  onBlur={(e) => setFieldTouched('days', true)}
                />
                {errors.days && touched?.days && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{errors.days}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              data.days.map((x: any, index) => (
                <span key={index} className='badge badge-secondary mb-1 me-1'>
                  {x?.label}
                </span>
              ))
            )}
          </td>

          <td>
            {isEditing ? (
              <>
                <input
                  type='number'
                  className='form-control form-control-white form-select-sm custom-input-height'
                  placeholder='Enter Regular Rate'
                  id='regularRate'
                  name='regularRate'
                  value={values.regularRate}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('regularRate', true)}
                />
                {errors.regularRate && touched?.regularRate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{errors.regularRate}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <span>{data.regularRate}</span>
            )}
          </td>
          <td>
            {isEditing ? (
              <>
                <input
                  type='number'
                  className='form-control form-control-white form-select-sm custom-input-height'
                  placeholder='Enter Rush Rate'
                  id='rushRate'
                  name='rushRate'
                  value={values.rushRate}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('rushRate', true)}
                />
                {errors.rushRate && touched?.rushRate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{errors.rushRate}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <span>{data.rushRate}</span>
            )}
          </td>
          <td>
            {isEditing ? (
              <>
                <input
                  type='number'
                  className='form-control form-control-white form-select-sm custom-input-height'
                  placeholder='Enter Emergency Rate'
                  id='emergencyRate'
                  name='emergencyRate'
                  value={values.emergencyRate}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('emergencyRate', true)}
                />
                {errors.emergencyRate && touched?.emergencyRate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-8 fw-semibold'>
                      <span role='alert'>{errors.emergencyRate}</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <span>{data.emergencyRate}</span>
            )}
          </td>
          {rateCategory === 'C' && (
            <td>
              {isEditing ? (
                <>
                  <input
                    type='number'
                    className='form-control form-control-white form-select-sm custom-input-height'
                    placeholder='Enter Conference Charge'
                    id='conferenceCharges'
                    name='conferenceCharges'
                    value={values.conferenceCharges}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('conferenceCharges', true)}
                  />
                  {errors.conferenceCharges && touched?.conferenceCharges && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-8 fw-semibold'>
                        <span role='alert'>{errors.conferenceCharges}</span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <span>{data.conferenceCharges}</span>
              )}
            </td>
          )}

          <td>
            {isEditing ? (
              <>
                <div className='d-flex'>
                  <div className='form-check form-switch form-check-custom form-check-success form-check-solid'>
                    <input
                      className='form-check-input h-20px w-30px'
                      name='isActive'
                      type='checkbox'
                      checked={values.isActive}
                      onChange={(e) => setFieldValue('isActive', e.target.checked)}
                      id='kt_flexSwitchCustomDefault_1_1'
                    />
                    <label className='form-check-label' htmlFor='kt_flexSwitchCustomDefault_1_1'>
                      <span
                        className={`badge ${
                          values.isActive ? 'badge-light-success' : 'badge-light-danger'
                        }`}
                      >
                        {values.isActive}
                      </span>
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='d-flex'>
                  <div className='form-check form-switch form-check-custom form-check-success form-check-solid'>
                    <label className='form-check-label' htmlFor='kt_flexSwitchCustomDefault_1_1'>
                      <span
                        className={`badge ${
                          data.isActive ? 'badge-light-success' : 'badge-light-danger'
                        }`}
                      >
                        {data.isActive ? 'Active' : 'Inactive'}
                      </span>
                    </label>
                  </div>
                </div>
              </>
            )}
          </td>
          {currentUser?.result.userType == 'SYSTEM' && (
            <td className='text-end' style={{position: 'sticky', right: 0, background: 'white'}}>
              {isEditing ? (
                <>
                  <button
                    className='btn btn-icon btn-bg-success btn-active-color-white btn-sm me-2'
                    type='submit'
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting || !isValid}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr016.svg'
                      className='svg-icon-white'
                    />
                  </button>
                  <button
                    type='reset'
                    className='btn btn-icon btn-bg-light btn-active-color-muted btn-sm me-1'
                    onClick={() => {
                      resetForm()
                      setIsEditing(false)
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr015.svg'
                      className='svg-icon-muted'
                    />
                  </button>
                </>
              ) : (
                <a
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  onClick={() => setIsEditing(true)}
                >
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-muted' />
                </a>
              )}
            </td>
          )}
        </tr>
      )}
    </Formik>
  )
}

export default TableRow
