import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Select from 'react-select'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'
/* eslint-disable jsx-a11y/anchor-is-valid */
export function QuickDialsView() {
  const languages = [
    {value: 'option 1', label: 'English-English'},
    {value: 'option 2', label: 'Achi-Achi'},
    {value: 'option 3', label: 'Acoli-Acoli'},
  ]
  const servicetype = [
    {value: 'option 1', label: 'Carrer Support'},
    {value: 'option 2', label: 'Business'},
    {value: 'option 3', label: 'class Room'},
  ]
  const [selectedPlan, setSelectedPlan] = useState(null)

  const handleRadioChange = (event: any) => {
    setSelectedPlan(event.target.value)
  }
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_quick_dials'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Edit Quick Dials</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='row g-4 g-xl-6 mb-10'>
                    <div className='col-sm-12 col-md-12 col-lg-12 d-flex flex-wrap'>
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <label className='d-flex flex-start mb-0 cursor-pointer'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              onChange={handleRadioChange}
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span className='text-gray-800 text-hover-primary fs-6'>
                              Use system quick dials (Currently Off)
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-3'>
                        <label className='d-flex flex-start mb-0 cursor-pointer'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan'
                              className='form-check-input'
                              type='radio'
                              value='2'
                              onChange={handleRadioChange}
                            />
                          </span>
                          <span className='d-flex align-items-center me-2 justify-content-end'>
                            <span className='text-gray-800 text-hover-primary fs-6'>Off</span>
                          </span>
                        </label>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-3'>
                        <label className='d-flex flex-start mb-0 cursor-pointer'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              onChange={handleRadioChange}
                            />
                          </span>
                          <span className='d-flex align-items-center me-2 justify-content-end'>
                            <span className='text-gray-800 text-hover-primary fs-6'>Custom</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {selectedPlan == 3 && (
                    <>
                      <div className='row g-4 g-xl-6 '>
                        <div className='col-sm-6 col-md-6 col-lg-6'>
                          <div className='mb-5'>
                            <label className='form-label '>Language 1</label>
                            <div>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={languages}
                                  placeholder='Select Language'
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='mb-3'>
                            <div className='mb-5'>
                              <label className='form-label '>Language 2</label>
                              <div>
                                <div className='w-100'>
                                  <Select
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={languages}
                                    placeholder='Select Language'
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        border: '1px solid #e4e6ef',
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-6 col-md-6 col-lg-6'>
                          <div className='mb-5'>
                            <label className='form-label '>Service</label>
                            <div>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={servicetype}
                                  placeholder='Select Service'
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='mb-3'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Communication Type
                            </label>
                            <div className='d-flex'>
                              <div className='form-check form-check-custom form-check-white me-4'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='flexCheckDefault'
                                />
                                <label className='form-check-label' htmlFor='flexCheckDefault'>
                                  Audio
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-white'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='flexCheckDefault'
                                />
                                <label className='form-check-label' htmlFor='flexCheckDefault'>
                                  Video
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12 col-lg-12 d-flex align-items-end justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light btn-sm'
                          data-bs-dismiss='modal'
                        >
                          Add to list
                        </button>
                      </div>
                      <div className='separator my-3'></div>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='d-flex flex-wrap'>
                          <div className='card-body bg-white p-0 ' style={{overflow: 'scroll'}}>
                            <div className='py-0 pt-3'>
                              {/* begin::Table container */}
                              <div className='table-responsive '>
                                {/* begin::Table */}
                                <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-2 table-responsive overflow-hidden'>
                                  {/* begin::Table head */}
                                  <thead>
                                    <tr className='fw-semibold text-muted text-uppercase'>
                                      <th className='min-w-50px '>Language 1</th>
                                      <th className='min-w-50px '>Language 2</th>
                                      <th className='min-w-50px'>Service Type</th>
                                      <th className='min-w-50px'>Communication Type</th>
                                      <th className='min-w-50px text-end'>Action</th>
                                    </tr>
                                  </thead>
                                  {/* end::Table head */}
                                  {/* begin::Table body */}
                                  <tbody style={{maxHeight: '50px', overflowY: 'auto'}}>
                                    <tr>
                                      <td>
                                        <a className='text-gray-800 text-hover-primary  fs-6'>
                                          English-English
                                        </a>
                                      </td>
                                      <td>
                                        <a className='text-gray-800 text-hover-primary  fs-6'>
                                          English-English
                                        </a>
                                      </td>

                                      <td className=''>
                                        <a className='text-gray-800 text-hover-primary  fs-6'>
                                          Community
                                        </a>
                                      </td>
                                      <td className=''>
                                        <a className='text-gray-800 text-hover-primary  fs-6'>
                                          Audio
                                        </a>
                                      </td>
                                      <td>
                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                          <button
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                                            type='button'
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/art/art005.svg'
                                              className='svg-icon-3'
                                            />
                                          </button>
                                          <button
                                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                            type='button'
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/general/gen027.svg'
                                              className='svg-icon-3'
                                            />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                  {/* end::Table body */}
                                </table>
                                {/* end::Table */}
                              </div>
                              {/* end::Table container */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm' data-bs-dismiss='modal'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='card-body p-0'>
        <div className='card-title d-flex align-items-center me-4 mb-3'>
          <div className='d-flex flex-column'>
            <div className='d-flex align-items-center'>
              <h5 className='text-black fs-4 fw-semibold mb-0'>Quick Dials</h5>
            </div>
            <div className='d-flex flex-wrap fs-6 '>
              <p className='text-gray-500 mb-0' style={{fontSize: '12px', maxWidth: '900px'}}>
                Adding a language/service type pairing below will create an IVR menu AND update
                dialer mobile apps for quick, one touch, selection. Note: All Quick Dials settings
                that include Video will not be included in IVR menu. Request Service type can only
                be used for IVR system.
              </p>
            </div>
          </div>
          {/* <UnderDevelopmentBadge className='' /> */}
        </div>
        <div className='row g-1 g-xl-1'>
          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex fs-3 fw-semibold mb-3'>IVR</div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_quick_dials'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>IVR Phone Number: +1 718-838-9317</strong>
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan11'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system quick dials (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan11'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan11'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Custom
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
      </div>
    </>
  )
}
