/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Linechart from './Linechart'
import { Spinner } from 'react-bootstrap'

type Props = {
  className: string
  chartColor?: string
  chartHeight?: string
  title: string
  onDemardData?: any
  label ?: string
  loading?: boolean
}

export const OverviewChart: React.FC<Props> = ({ className, title, onDemardData, label, loading }) => {
  return (
    <div className={`card card-lg-stretch mb-5 mb-xl-8 shadow-custom ${className}`}>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-6 mb-1'>{title}</span>
        </h3>
      </div>

      {loading ? (
        <div
          className='card-body pt-0 d-flex align-items-center justify-content-center'
          style={{ minHeight: '300px' }}
        >
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className='card-body pt-0'>
          <Linechart details={onDemardData} label={label} />
        </div>
      )}
    </div>
  )
}
