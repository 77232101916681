const QUERIES = {
  USERS_LIST: 'users-list',
}

const ModelQUERIES = {
  DATA_LIST: 'data-list',
}

const roleQUERIES = {
  Role_LIST: 'role-list',
  Country_LIST: 'country-list',
  Gender_LIST: 'gender-list',
  Customer_LIST: 'customer-list',
  PreCallQuestion_LIST:'precallquestion-list',
  Customer_Location_LIST: 'customer-location-list',
  requesters_LIST : 'requesters-list',
  interpreter_LIST: 'interpreter-list',
}

const getUserDetails = {
  Userdata: 'users-data',
}

export {QUERIES, ModelQUERIES, roleQUERIES, getUserDetails}
