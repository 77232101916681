import React, {useState} from 'react'
import {KTSVG} from '../_metronic/helpers'
import axios from 'axios'
import toaster from './toaster'
import {Modal} from 'bootstrap'
import {CommonLoading} from './commonLoading'
import { useParams } from 'react-router-dom'

/**
  pageName for Scheduled :-Scheduled_InPerson
  pageName for Inperson  :- Scheduled_InPerson
  PageName for Operator  :- Operator
  onDemnad is already set
*/

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  title: string
  pageName: string
  filterData: any
  searchKey: string
  callType: number
}

const DownloadModal: React.FC<Props> = ({title, pageName, filterData, searchKey, callType}) => {
  const [selectedColumns, setSelectedColumns] = useState<{header: string; value: string}[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [fileType, setFileType] = useState<string>('CSV')
  const [isLoading, setIsLoading] = useState(false)

  const OnDemand = [
    {label: 'Call Id', value: 'Code'},
    {label: 'Interpreter', value: 'Interpreter'},
    {label: 'Interpreter ID', value: 'AWSUserId_INT'},
    {label: 'Requestor', value: 'Customer'},
    {label: 'Customer', value: 'CompanyName'},
    {label: 'Date/Time', value: 'InitiationTimestamp'},
    {label: 'Downloaded Time zone', value: 'downloadedTimeZone'},
    {label: 'Language 1', value: 'language1'},
    {label: 'Language 2', value: 'Language'},
    {label: 'Service Type', value: 'serviceType'},
    {label: 'Communication Type', value: 'CallType'},
    {label: 'Duration', value: 'TotalDuration'},
    {label: 'Client Billable Duration', value: 'clientBillableDuration'},
    {label: 'Client Billable Duration', value: 'interpreterBillableDuration'},
    {label: 'Finished By', value: 'FinishedBy'},
    {label: 'Account Charge', value: 'AccountCharge'},
    {label: 'Account Minimum', value: 'AccountMinimum'},
    {label: 'Interpreter Minimum', value: 'InterpreterMinimum'},
    {label: 'Account Rounding', value: 'AccountRounding'},
    {label: 'Account Thresholds', value: 'AccountThresholds'},
    {label: 'Interpreter Thresholds', value: 'InterpreterThresholds'},
    {label: 'Account Conference Charge', value: 'AccountConferenceCharge'},
    {label: 'Account Invoice ID', value: 'AccountInvoiceID'},
    {label: 'Account Invoice Status', value: 'AccountInvoiceStatus'},
    {label: 'Interpreter Invoice ID', value: 'InterpreterInvoiceID'},
    {label: 'Interpreter Invoice Status', value: 'InterpreterInvoiceStatus'},
    {label: 'Queue Time (s)', value: 'QueueTime'},
    {label: 'Client Device Type', value: 'ClientDeviceType'},
    {label: 'From Number', value: 'FromNumber'},
    {label: 'Requestor Device Info', value: 'RequestorDeviceInfo'},
    {label: 'Interpreter Device Info', value: 'InterpreterDeviceInfo'},
    {label: 'Other Participants', value: 'OtherParticipants'},
    {label: 'Conference Duration', value: 'ConferenceDuration'},
    {label: 'Total Interpreter Pay', value: 'TotalInterpreterPay'},
    {label: 'Crowd', value: 'Crowd'},
    {label: 'Account ID', value: 'UniqueIdentifier'},
    {label: 'Call Status', value: 'CallStatus'},
    {label: 'Client Company UniqueId', value: 'ClientCompanyUniqueId'},
    {label: 'Requestor Rating', value: 'RequestorRating'},
    {label: 'Requestor Call Quality Rating', value: 'RequestorCallQualityRating'},
    {label: 'Interpreter Call Quality Rating', value: 'InterpreterCallQualityRating'},
    {label: 'Account Special Rate Amount', value: 'AccountSpecialRateAmount'},
    {label: 'Interpreter Special Rate Amount', value: 'InterpreterSpecialRateAmount'},
    {label: 'Requestor Pin', value: 'RequestorPin'},
    {label: 'Client Company UniqueId', value: 'ClientCompanyUniqueId'},
    {label: 'Name of Requestor', value: 'Customer'},
    {label: 'Client Name', value: 'CompanyName'},
    {label: 'Email Address', value: 'UserEmailAddress'},
  ]
  
  const Scheduled_InPerson= [
    {label: 'Type', value: 'CallType'},
    {label: 'Requestor', value: 'Customer'},
    {label: 'Invited Interpreters', value: 'InvitedInterpreters'},
    {label: 'Invited Interpreters ID', value: 'InvitedInterpretersID'},
    {label: 'Assigned Interpreter', value: 'Interpreter'},
    {label: 'Assigned Interpreter ID', value: 'AWSUserId_INT'},
    {label: 'Status', value: 'CallStatus'},
    {label: 'Downloaded Time zone', value: 'downloadedTimeZone'},
    {label: 'Scheduled Start Time', value: 'ScheduledStartTime'},
    {label: 'Scheduled End Time', value: 'ScheduledEndTime'},
    {label: 'Confirmed Start Time', value: 'ConfirmedStartTime'},
    {label: 'Confirmed End Time', value: 'ConfirmedEndTime'},
    {label: 'Communication Type', value: 'CommunicationType'},
    {label: 'Record ID', value: 'RecordID'},
    {label: 'Language 1', value: 'language1'},
    {label: 'Language 2', value: 'Language'},
    {label: 'Service Type', value: 'serviceType'},
    {label: 'Location', value: 'Location'},
    {label: 'Miles To Appointment', value: 'MilesToAppointment'},
    {label: 'Scheduled Duration', value: 'ScheduledDuration'},
    {label: 'Client Billable Duration', value: 'clientBillableDuration'},
    {label: 'Interpreter Billable Duration', value: 'interpreterBillableDuration'},
    {label: 'Total Account Charge', value: 'TotalAccountCharge'},
    {label: 'Account Expenses', value: 'AccountExpenses'},
    {label: 'Total Interpreter Pay', value: 'TotalInterpreterPay'},
    {label: 'Interpreter Expenses', value: 'InterpreterExpenses'},
    {label: 'Account Name', value: 'CompanyName'},
    {label: 'Account ID', value: 'CompanyId'},
    {label: 'Canceled By', value: 'CanceledBy'},
    {label: 'Total Interpretation Duration', value: 'TotalInterpretatiOnDuration'},
    {label: 'Total Call Duration', value: 'TotalDuration'},
    {label: 'Appointment Created Date', value: 'AppointmentCreatedDate'},
    {label: 'Account Invoice ID', value: 'AccountInvoiceID'},
    {label: 'Account Invoice Status', value: 'AccountInvoiceStatus'},
    {label: 'Interpreter Invoice ID', value: 'InterpreterInvoiceID'},
    {label: 'Interpreter Invoice Status', value: 'InterpreterInvoiceStatus'},
    {label: 'Requestor Joined', value: 'RequestorJoined'},
    {label: 'Interpreter Joined', value: 'InterpreterJoined'},
    {label: 'Requestor Device Info', value: 'RequestorDeviceInfo'},
    {label: 'Interpreter Device Info', value: 'InterpreterDeviceInfo'},
    {label: 'Other Participants', value: 'OtherParticipants'},
    {label: 'Conference Duration', value: 'ConferenceDuration'},
    {label: 'Account Priority Rate', value: 'AccountPriorityRate'},
    {label: 'Account Minimum', value: 'AccountMinimum'},
    {label: 'Account Rounding Rate', value: 'AccountRounding'},
    {label: 'Account Thresholds', value: 'AccountThresholds'},
    {label: 'Interpreter Minimum', value: 'InterpreterMinimum'},
    {label: 'Interpreter Rounding Rate', value: 'InterpreterRoundingRate'},
    {label: 'Interpreter Thresholds', value: 'InterpreterThresholds'},
    {label: 'Gender Preference', value: 'GenderPreference'},
    {label: 'Recurrence', value: 'Recurrence'},
    {label: 'Requestor Pin', value: 'RequestorPin'},
    {label: 'Appointment Tags', value: 'AppointmentTags'},
    {label: 'Travel Time', value: 'TravelTime'},
    {label: 'Conference Platform', value: 'ConferencePlatform'},]

  const Operator = [
    {label: 'Call Id', value: 'Code'},
    {label: 'Requestor', value: 'Customer'},
    {label: 'Date/Time', value: 'InitiationTimestamp'},
    {label: 'Downloaded Time zone', value: 'downloadedTimeZone'},
    {label: 'Language 1', value: 'language1'},
    {label: 'Language 2', value: 'Language'},
    {label: 'Service Type', value: 'serviceType'},
    {label: 'Type', value: 'CallType'},
    {label: 'Queue Time (s)', value: 'QueueTime'},
    {label: 'Operator Duration', value: 'OperatorDuration'},
    {label: 'Call Status (Operator)', value: 'CallStatusOperator'},
    {label: 'Gender', value: 'GenderPreference'},
  ]

  const handleCheckboxChange = (columnValue: {header: string; value: string}) => {
    let updatedSelectedColumns
    if (
      selectedColumns.some(
        (selectedColumn) =>
          selectedColumn.header === columnValue.header && selectedColumn.value === columnValue.value
      )
    ) {
      updatedSelectedColumns = selectedColumns.filter(
        (selectedColumn) =>
          selectedColumn.header !== columnValue.header || selectedColumn.value !== columnValue.value
      )
      setSelectAll(false)
    } else {
      updatedSelectedColumns = [...selectedColumns, columnValue]
      switch(pageName){
        case 'OnDemand':
          if (updatedSelectedColumns.length === OnDemand.length) {
            setSelectAll(true)
          }
          break;
        case 'Scheduled_InPerson':
            if (updatedSelectedColumns.length === Scheduled_InPerson.length) {
              setSelectAll(true)
            }
            break;
        case 'Operator':
          if (updatedSelectedColumns.length === Operator.length) {
            setSelectAll(true)
          }
          break;
      }
      
    }
    setSelectedColumns(updatedSelectedColumns)
  }

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setSelectAll(isChecked)

    if (isChecked) {
      switch (pageName) {
        case 'OnDemand':
          setSelectedColumns(OnDemand.map((col) => ({header: col.label, value: col.value})))
          break
        case 'Scheduled_InPerson':
          setSelectedColumns(
            Scheduled_InPerson.map((col) => ({header: col.label, value: col.value}))
          )
          break
        case 'Operator':
          setSelectedColumns(Operator.map((col) => ({header: col.label, value: col.value})))
          break
      }
    } else {
      setSelectedColumns([])
    }
  }

  const submitData = async () => {
    setIsLoading(true)
    const today = new Date()
    const oneMonthAgo = new Date(today)
    oneMonthAgo.setMonth(today.getMonth() - 1)

    const dataObject = {
      callLogExportHeaders: selectedColumns.map((col: any) => ({
        header: col.header,
        value: col.value,
      })),
      filterModel: {
        awsUserId_REQList: filterData?.awsUserId_REQList || null,
        startDate: filterData?.startDate || null,
        endDate: filterData?.endDate || null,
        languageList: filterData?.languageList || null,
        callStateList: filterData?.callStateList || null,
        callType: callType || 0,
        companyCode :filterData?.companyCode || null,
      },
      searchKey,
      isCsv: fileType === 'CSV',
    }

    try {
      const result: any = await axios.post(`${API_URL}/AWSConnect/call-log-export`, dataObject, {
        responseType: 'blob',
      })

      const fileExtension = fileType === 'CSV' ? 'csv' : 'xlsx'
      const mimeType =
        fileType === 'CSV'
          ? 'text/csv'
          : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

      const url = window.URL.createObjectURL(new Blob([result.data], {type: mimeType}))

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `call-log-export.${fileExtension}`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)

      toaster('success', 'File downloaded successfully.')
      resetSelection()
      const modal = document.getElementById('kt_download_list_modal')
      if (modal) {
        const modalInstance = Modal.getInstance(modal)
        if (modalInstance) modalInstance.hide()
      }
    } catch (error) {
      console.error('Error:', error)
      toaster('error', 'File not downloaded.')
    } finally {
      setIsLoading(false)
    }
  }

  const resetSelection = () => {
    setSelectedColumns([])
    setSelectAll(false)
    setFileType('CSV')
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_download_list_modal'>
      {isLoading && <CommonLoading />}
      <div className='modal-dialog modal-xl'>
        <div className='modal-content'>
          <div className='modal-header py-2'>
            <h4 className='modal-title'>{title}</h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={resetSelection}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>

          <div className='modal-body' style={{maxHeight: '85vh', overflowY: 'scroll'}}>
            <div>
              <div style={{display: 'flex'}}>
                <p className='col-md-3'>Select file type</p>

                <label className='col-md-3'>
                  <input
                    type='checkbox'
                    value='CSV'
                    className='form-check-input mb-2'
                    style={{marginRight: '5px'}}
                    checked={fileType === 'CSV'}
                    onChange={() => {
                      setFileType('CSV')
                    }}
                  />
                  CSV
                </label>
                <label className='col-md-3'>
                  <input
                    type='checkbox'
                    value='XLSX'
                    className='form-check-input mb-2'
                    style={{marginRight: '5px'}}
                    checked={fileType === 'XLSX'}
                    onChange={() => {
                      setFileType('XLSX')
                    }}
                  />
                  XLSX
                </label>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                  <p>Select columns to download</p>
                </div>
                <div>
                  <label className='form-label'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectAll}
                      style={{marginRight: '5px'}}
                      onChange={handleSelectAllChange}
                    />
                    Select All
                  </label>
                </div>
              </div>

              <hr className='m-0 p-2' />

              {pageName === 'OnDemand' && (
                <div className='row'>
                  {OnDemand.map((column, index) => (
                    <div key={index} className='col-md-3'>
                      <div className='form-check'>
                        <input
                          className='form-check-input mb-2'
                          type='checkbox'
                          id={column.value}
                          checked={selectedColumns.some(
                            (selectedColumn) =>
                              selectedColumn.header === column.label &&
                              selectedColumn.value === column.value
                          )}
                          onChange={() =>
                            handleCheckboxChange({header: column.label, value: column.value})
                          }
                        />
                        <label className='form-check-label' htmlFor={column.value}>
                          {column.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {pageName === 'Scheduled_InPerson' && (
                <div className='row'>
                  {Scheduled_InPerson.map((column, index) => (
                    <div key={index} className='col-md-3'>
                      <div className='form-check'>
                        <input
                          className='form-check-input mb-2'
                          type='checkbox'
                          id={column.value}
                          checked={selectedColumns.some(
                            (selectedColumn) =>
                              selectedColumn.header === column.label &&
                              selectedColumn.value === column.value
                          )}
                          onChange={() =>
                            handleCheckboxChange({header: column.label, value: column.value})
                          }
                        />
                        <label className='form-check-label' htmlFor={column.value}>
                          {column.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {pageName === 'Operator' && (
                <div className='row'>
                  {Operator.map((column, index) => (
                    <div key={index} className='col-md-3'>
                      <div className='form-check'>
                        <input
                          className='form-check-input mb-2'
                          type='checkbox'
                          id={column.value}
                          checked={selectedColumns.some(
                            (selectedColumn) =>
                              selectedColumn.header === column.label &&
                              selectedColumn.value === column.value
                          )}
                          onChange={() =>
                            handleCheckboxChange({header: column.label, value: column.value})
                          }
                        />
                        <label className='form-check-label' htmlFor={column.value}>
                          {column.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className='modal-footer py-3'>
            <button
              type='reset'
              className='btn btn-light btn-sm'
              data-bs-dismiss='modal'
              onClick={resetSelection}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary btn-sm'
              onClick={submitData}
              disabled={selectedColumns.length === 0}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DownloadModal}
