import React, {useCallback, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import './style.css'
import AppointmentOnsiteMap from './AppointmentOnsiteMap'
import {Form, Formik, FormikProps} from 'formik'
import toaster from '../../../../Utils/toaster'
import * as Yup from 'yup'
import axios from 'axios'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete'
import clsx from 'clsx'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'
import moment from 'moment'
import {useAuth} from '../../../modules/auth'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {DeleteConfirmationModal} from '../../../common/Models/confirmation-modal/DeleteConfirmationModal'
import {useQuery} from 'react-query'
import {getTimeZone} from '../../Common/components/core/_requests'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE

interface RenderFuncProps {
  getInputProps: () => React.InputHTMLAttributes<HTMLInputElement>
  getSuggestionItemProps: (suggestion: Suggestion) => React.HTMLAttributes<HTMLDivElement>
  suggestions: any
  loading?: boolean // Optional loading indicator
  errors?: any
  setFieldValue: any
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    border: '1px solid #e4e6ef',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#a4afc4',
  }),
}

const HoursOptions = Array.from({length: 24}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

const MinutesOptions = Array.from({length: 60}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

const center = {
  lat: -3.745,
  lng: -38.523,
}

const EditAppointment: React.FC<any> = () => {
  const createAppointmentSchema = Yup.object().shape({
    fK_Customer: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    fK_CommunicationType: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    fK_Requester: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    subject: Yup.string().required('Required'),
    virtualAddress: Yup.string().when(['fK_CommunicationType'], (communicationID, schema) => {
      const isVirtualAddressRequired =
        allCommunicationTypes.find((x: any) => x.key === communicationID)?.identification ===
        'THIRD_PART_PLATFORM'
      return isVirtualAddressRequired ? schema.required('Required') : schema.notRequired()
    }),
    address: Yup.string().when(['fK_CommunicationType'], (communicationID, schema) => {
      const isVirtualAddressRequired =
        allCommunicationTypes.find((x: any) => x.key === communicationID)?.identification ===
        'ON_SITE'
      return isVirtualAddressRequired ? schema.required('Required') : schema.notRequired()
    }),
    city: Yup.string().when(['fK_CommunicationType'], (communicationID, schema) => {
      const isVirtualAddressRequired =
        allCommunicationTypes.find((x: any) => x.key === communicationID)?.identification ===
        'ON_SITE'
      return isVirtualAddressRequired ? schema.required('Required') : schema.notRequired()
    }),
    state: Yup.string().when(['fK_CommunicationType'], (communicationID, schema) => {
      const isVirtualAddressRequired =
        allCommunicationTypes.find((x: any) => x.key === communicationID)?.identification ===
        'ON_SITE'
      return isVirtualAddressRequired ? schema.required('Required') : schema.notRequired()
    }),
    country: Yup.string().when(['fK_CommunicationType'], (communicationID, schema) => {
      const isVirtualAddressRequired =
        allCommunicationTypes.find((x: any) => x.key === communicationID)?.identification ===
        'ON_SITE'
      return isVirtualAddressRequired ? schema.required('Required') : schema.notRequired()
    }),
    postalCode: Yup.string().when(['fK_CommunicationType'], (communicationID, schema) => {
      const isVirtualAddressRequired =
        allCommunicationTypes.find((x: any) => x.key === communicationID)?.identification ===
        'ON_SITE'
      return isVirtualAddressRequired ? schema.required('Required') : schema.notRequired()
    }),
    pin: Yup.string(),
    callerNumber: Yup.string(),
    remarks: Yup.string(),
    fK_Gender: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    consumer: Yup.string(),
    description: Yup.string(),
    fK_LanguageFrom: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    fK_LanguageTo: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    fK_ServiceType: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    fK_PreCallId: Yup.string(),
    startTime: Yup.date().required('Required'),
    // .test('not-past', 'Start time cannot be in the past', function (value) {
    //   const {appointmentDate} = this.parent

    //   if (!appointmentDate || !value) return true // Skip validation if either field is empty

    //   // Combine appointment date with start time
    //   const combinedDateTime = moment(appointmentDate).set({
    //     hour: parseInt(value.split(':')[0]),
    //     minute: parseInt(value.split(':')[1]),
    //   })

    //   // Check if the combined date-time is in the past
    //   return combinedDateTime.isSameOrAfter(moment())
    // }),
    // endTime: Yup.date()
    //   .min(Yup.ref('startTime'), 'Start time cannot be after end time')
    //   .required('Required'),
    hours: Yup.number()
      .required('Required')
      .test('hours-and-minutes', 'Time should be at least 5 minutes', function (value) {
        const {minutes} = this.parent
        if (value === 0 && Number(minutes) < 5) {
          return false
        }
        return true
      })
      .test(
        'minutes-set-without-hours',
        'Hours is required when minutes are set',
        function (value) {
          const {minutes} = this.parent
          if (minutes && (value === undefined || value === null)) {
            return false
          }
          return true
        }
      ),
    minutes: Yup.number()
      .required('Required')
      .test('valid-minutes', 'Minutes must be 5 or greater when hours is 0', function (value) {
        if (value === undefined) {
          return true
        }
        const {hours} = this.parent
        if (hours === 0 && value < 5) {
          return false
        }
        return true
      }),
    fK_TimeZone: Yup.string().required('Required'),
    recurrence: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    accountPriority: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    interpreterPriority: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    notePM: Yup.string(),
    noteInterpreter: Yup.string(),
    recordId: Yup.string(),
    recurrenceRestriction: Yup.string().when('recurrence', {
      is: '1',
      then: Yup.string(),
      otherwise: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    }),
    byCount: Yup.number().when('recurrenceRestriction', {
      is: '1',
      then: Yup.number()
        .positive('Must be greater than 0')
        .integer('Must be an integer')
        .required('Required'),
      otherwise: Yup.number().notRequired(),
    }),
    byUntillDate: Yup.date().when('recurrenceRestriction', {
      is: '2',
      then: Yup.date().required('Required'),
      otherwise: Yup.date().notRequired(),
    }),
    isEveryWeekDay: Yup.boolean(),
    everyQuantity: Yup.number().when('isEveryWeekDay', {
      is: false,
      then: Yup.number()
        .positive('Must be greater than 0')
        .integer('Must be an integer')
        .required('Required'),
      otherwise: Yup.number(),
    }),
    everyWeeksQuantity: Yup.number().when('recurrence', {
      is: '3',
      then: Yup.number()
        .positive('Must be greater than 0')
        .integer('Must be an integer')
        .required('Required'),
      otherwise: Yup.number(),
    }),
    nameOfDays: Yup.array().when('recurrence', {
      is: '3',
      then: Yup.array().min(1, 'Please select at least one day'),
      otherwise: Yup.array(),
    }),
    isDay: Yup.boolean().when('recurrence', {
      is: '4',
      then: Yup.boolean().required('Required'),
      otherwise: Yup.boolean(),
    }),
    dayCount: Yup.number().when('recurrence', {
      is: '4',
      then: Yup.number().when('isDay', {
        is: true,
        then: Yup.number()
          .positive('Must be greater than 0')
          .integer('Must be an integer')
          .required('Required'),
        otherwise: Yup.number(),
      }),
      otherwise: Yup.number(),
    }),
    everyMonthsQuantityIsDay: Yup.number().when('recurrence', {
      is: '4',
      then: Yup.number().when('isDay', {
        is: true,
        then: Yup.number()
          .positive('Must be greater than 0')
          .integer('Must be an integer')
          .required('Required'),
        otherwise: Yup.number(),
      }),
      otherwise: Yup.number(),
    }),
    thePlaceOfDay: Yup.string().when('recurrence', {
      is: '4',
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    dayName: Yup.string().when('recurrence', {
      is: '4',
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    everyMonthsQuantityIsPlace: Yup.number().when('recurrence', {
      is: '4',
      then: Yup.number().when('isDay', {
        is: false,
        then: Yup.number()
          .positive('Must be greater than 0')
          .integer('Must be an integer')
          .required('Required'),
        otherwise: Yup.number(),
      }),
      otherwise: Yup.number(),
    }),
  })

  const prority = [
    {value: '1', label: 'Regular'},
    {value: '2', label: 'Rush'},
    {value: '3', label: 'Emergency'},
    {value: '4', label: 'Special '},
  ]
  const recurrences: any = [
    {value: '1', label: 'One Time'},
    {value: '2', label: 'Daily'},
    {value: '3', label: 'Weekly'},
    {value: '4', label: 'Monthly '},
  ]

  const [inputType, setInputType] = useState('number')

  const handleSelectChange = (event: any) => {
    if (event.target.value === '1') {
      setInputType('number')
    } else {
      setInputType('date')
    }
  }

  const [selectedOption, setSelectedOption] = useState('1')
  const [isAccRate, setIsAccRate] = useState(false)
  const [selectedOption2, setSelectedOption2] = useState('1')
  const [isInterRate, setIsInterRate] = useState(false)

  const [allAccounts, setAllAccounts] = useState<any[]>([])
  const [allCommunicationTypes, setAllCommunicationTypes] = useState<any[]>([])
  const [requesters, setRequesters] = useState<any[]>([])
  const [gpsLocation, setGpsLocation] = useState<any>({
    lat: center.lat,
    lng: center.lng,
  })
  const [genders, setGenders] = useState<any[]>([])
  const [languages, setLanguages] = useState<any[]>([])
  const [serviceTypes, setServiceTypes] = useState<any[]>([])
  const [preCallPolicies, setPreCallPolicies] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {id} = useParams()
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)

  const [initialValues, setInitialValues] = useState({
    code: '',
    fK_Customer: '0',
    fK_CommunicationType: '0',
    fK_Requester: '0',
    address: '',
    street1: '',
    street2: '',
    state: '',
    city: '',
    postalCode: '',
    country: '',
    latitude: '',
    longitude: '',
    virtualAddress: '',
    pin: '',
    callerNumber: '',
    remarks: '',
    subject: '',
    fK_Gender: '',
    consumer: '',
    description: '',
    fK_LanguageFrom: '',
    fK_LanguageTo: '',
    fK_ServiceType: '',
    fK_PreCallId: '0',
    startTime: '',
    endTime: '',
    fK_TimeZone: '',
    hours: 0,
    minutes: 0,
    recurrence: '1',
    accountPriority: '1',
    interpreterPriority: '1',
    notePM: '',
    noteInterpreter: '',
    recordId: '',
    accountRate: '0',
    interpreterPayType: '0',
    isDeleted: false, // Set to false instead of true
    recurrenceRestriction: '0',
    byCount: '',
    byUntillDate: '',
    everyQuantity: '',
    isEveryWeekDay: true,
    everyWeeksQuantity: '',
    nameOfDays: [] as string[],
    isDay: true,
    dayCount: '',
    thePlaceOfDay: '1',
    dayName: 'Monday',
    everyMonthsQuantityIsDay: '',
    everyMonthsQuantityIsPlace: '',
  })
  const urlParams = new URLSearchParams(window.location.search)
  const isBulk = String(urlParams.get('isBulk'))
  const fetchAppoinmentDetails = async () => {
    try {
      setIsLoading(true)
      let {data} = await axios.get(`${API_URL}/Appoinment/get-edit/${id}`)
      await fetchRequester(data.fK_Customer)
      await fetchPreCalls(data.fK_Customer)
      const duration = moment.duration(moment(data.endTime).diff(moment(data.startTime)))
      setInitialValues({
        ...data,
        fK_TimeZone: data.fK_TimeZone ? data.fK_TimeZone : '',
        accountRate: data.accountRate ? data.accountRate : '0',
        interpreterPayType: data.interpreterPayType ? data.interpreterPayType : '0',
        startTime: moment(data.startTime).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(data.endTime).format('YYYY-MM-DDTHH:mm'),
        duration: moment.duration(moment(data.endTime).diff(moment(data.startTime))),
        hours: Math.floor(duration.asHours()),
        minutes: duration.minutes(),
        code: data.code ? data.code : '',
        fK_Customer: data.fK_Customer ? data.fK_Customer : '0',
        fK_CommunicationType: data.fK_CommunicationType ? data.fK_CommunicationType : '0',
        fK_Requester: data.fK_Requester ? data.fK_Requester : '0',
        address: data.address ? data.address : '',
        street1: data.street1 ? data.street1 : '',
        street2: data.street2 ? data.street2 : '',
        state: data.state ? data.state : '',
        city: data.city ? data.city : '',
        postalCode: data.postalCode ? data.postalCode : '',
        country: data.country ? data.country : '',
        latitude: data.latitude ? data.latitude : '',
        longitude: data.longitude ? data.longitude : '',
        virtualAddress: data.virtualAddress ? data.virtualAddress : '',
        pin: data.pin ? data.pin : '',
        callerNumber: data.callerNumber ? data.callerNumber : '',
        remarks: data.remarks ? data.remarks : '',
        subject: data.subject ? data.subject : '',
        fK_Gender: data.fK_Gender ? data.fK_Gender : '',
        consumer: data.consumer ? data.consumer : '',
        description: data.description ? data.description : '',
        fK_LanguageFrom: data.fK_LanguageFrom ? data.fK_LanguageFrom : '',
        fK_LanguageTo: data.fK_LanguageTo ? data.fK_LanguageTo : '',
        fK_ServiceType: data.fK_ServiceType ? data.fK_ServiceType : '',
        fK_PreCallId: data.fK_PreCallId ? data.fK_PreCallId : '1',
        recurrence: data.recurrence ? data.recurrence.toString() : '1',
        accountPriority: data.accountPriority ? data.accountPriority.toString() : '1',
        interpreterPriority: data.interpreterPriority ? data.interpreterPriority.toString() : '1',
        notePM: data.notePM ? data.notePM : '',
        noteInterpreter: data.noteInterpreter ? data.noteInterpreter : '',
        recordId: data.recordId ? data.recordId : '',
        isDeleted: data.isDeleted,
        recurrenceRestriction: data.recurrenceRestriction
          ? data.recurrenceRestriction.toString()
          : '0',
        byCount: data.byCount ? data.byCount : '',
        byUntillDate: data.byUntillDate ? data.byUntillDate : '',
        everyQuantity: data.everyQuantity ? data.everyQuantity : '',
        isEveryWeekDay: data.isEveryWeekDay ? data.isEveryWeekDay : true,
        everyWeeksQuantity: data.everyWeeksQuantity ? data.everyWeeksQuantity : '',
        nameOfDays: data.nameOfDays ? data.nameOfDays : [],
        isDay: data.isDay ? data.isDay : true,
        dayCount: data.dayCount ? data.dayCount : '',
        thePlaceOfDay: data.thePlaceOfDay ? data.thePlaceOfDay.toString() : '1',
        dayName: data.dayName ? data.dayName : 'Monday',
        everyMonthsQuantityIsDay: data.everyMonthsQuantityIsDay
          ? data.everyMonthsQuantityIsDay
          : '',
        everyMonthsQuantityIsPlace: data.everyMonthsQuantityIsPlace
          ? data.everyMonthsQuantityIsPlace
          : '',
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC0Nh39yAtFIg-x83gBbRCIfOOE_N8Qdl0',
  })
  const [map, setMap] = useState<google.maps.Map | null>(null)

  const [google, setGoogle] = useState<any>(window.google ? window.google : {})

  const onLoad = useCallback(
    (map: any) => {
      setGoogle(window.google)
      setMap(map)
      // eslint-disable-next-line
    },
    [map]
  )

  const onUnmount = useCallback(() => {
    if (map) {
      setMap(null)
    }
  }, [])

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value)
    setIsAccRate(event.target.value === '3')
  }
  const handleOptionChange2 = (event: any) => {
    setSelectedOption2(event.target.value)
    setIsInterRate(event.target.value === '3')
  }

  const fetchAccounts = async () => {
    try {
      let response = await axios.get(`${API_URL}/customer/getall-shortlist/CONSUMER/0`)
      setAllAccounts(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCommunicationType = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/getall/COMMUNICATION_TYPE`)
      setAllCommunicationTypes(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchRequester = async (customerCode: number) => {
    try {
      let response = await axios.get(`${API_URL}/accounts/dd-list/CONSUMER/${customerCode}`)
      setRequesters(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchGenders = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/getall/GENDER`)
      setGenders(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchLanguages = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/languages/active-shortlist`)
      setLanguages(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllServices = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/getall/SERVICE_TYPE`)
      setServiceTypes(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchPreCalls = async (customerCode: number) => {
    try {
      let response = await axios.get(`${API_URL}/PreCalls/ddlist/${customerCode}`)
      setPreCallPolicies(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const {isFetching: isFetchingTimeZone, data: timezoneList} = useQuery(
    `Query.timezoneList`,
    () => {
      return getTimeZone()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const timezoneOptions: any = !isFetchingTimeZone
    ? timezoneList?.data?.map((d: any) => ({
        value: d.code,
        label: d.name,
      }))
    : []

  useEffect(() => {
    fetchAccounts()
    fetchCommunicationType()
    fetchGenders()
    fetchLanguages()
    fetchAllServices()
  }, [])

  useEffect(() => {
    if (id && allAccounts?.length > 0 && allCommunicationTypes?.length > 0) {
      fetchAppoinmentDetails()
    }
  }, [allAccounts, allCommunicationTypes])

  const handleSelect = async (address: string, setFieldValue: any) => {
    try {
      const results = await geocodeByAddress(address)
      const placeDetails = await getLatLng(results[0])

      // Extract detailed address components
      let street1 = ''
      let street2 = ''
      let city = ''
      let state = ''
      let country = ''
      let postalCode = ''

      results[0].address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          street1 = component.long_name
        } else if (component.types.includes('route')) {
          street2 = component.long_name
        } else if (component.types.includes('locality')) {
          city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name
        } else if (component.types.includes('country')) {
          country = component.long_name
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name
        }
      })

      setFieldValue('address', address)
      setFieldValue('street1', street1)
      setFieldValue('street2', street2)
      setFieldValue('city', city)
      setFieldValue('state', state)
      setFieldValue('country', country)
      setFieldValue('postalCode', postalCode)
      setFieldValue('latitude', placeDetails.lat)
      setFieldValue('longitude', placeDetails.lng)

      setGpsLocation({
        lat: placeDetails.lat,
        lng: placeDetails.lng,
      })
      // Select the input element with id "city"
      const cityInputElement = document.querySelector<HTMLInputElement>('#city')

      // Ensure the cityInputElement exists before focusing on it
      if (cityInputElement) {
        cityInputElement.focus() // Focuses on the input field
      }
    } catch (error) {
      console.error('Error fetching place details', error)
    }
  }

  const renderFunc: React.FC<RenderFuncProps> = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
    loading,
    errors,
    setFieldValue,
  }) => (
    <div className='autocomplete-root'>
      <input
        className={clsx('form-control form-control-sm form-control-light mb-3 custom-input-height')}
        placeholder='Address'
        {...getInputProps()}
        disabled={true}
      />
      {errors.address && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{errors.address}</span>
          </div>
        </div>
      )}
      <div className='autocomplete-dropdown-container'>
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            {...getSuggestionItemProps(suggestion)}
            key={suggestion.placeId}
            className='bg-light p-2 border border-bottom overflow-auto'
            style={{cursor: 'pointer'}}
          >
            <option>{suggestion.description}</option>
          </div>
        ))}
      </div>
    </div>
  )

  const handleCheckInterpreterAvalability = async (obj: any) => {
    try {
      let {data} = await axios.post(`${API_URL}/Appoinment/check-confirmed-interpreter`, obj)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const calculateEndTime = (startTime: string, hours: number, minutes: number): string => {
    const startDate = new Date(startTime)

    startDate.setHours(startDate.getHours() + hours)
    startDate.setMinutes(startDate.getMinutes() + minutes)

    //format the result to 'YYYY-MM-DDTHH:mm' in local time
    const year = startDate.getFullYear()
    const month = String(startDate.getMonth() + 1).padStart(2, '0')
    const day = String(startDate.getDate()).padStart(2, '0')
    const hoursFormatted = String(startDate.getHours()).padStart(2, '0')
    const minutesFormatted = String(startDate.getMinutes()).padStart(2, '0')

    return `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}`
  }

  return (
    <>
      <div className='mb-3'>
        <div className='d-flex align-items-center '>
          <a
            onClick={() => navigate(-1)}
            className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
          >
            <i className='bi bi-arrow-left-circle' style={{fontSize: '18px'}}></i>
            {/* <i className='bi bi-arrow-left-circle-fill' s></i> */}
          </a>
          <div>
            <h1 className='mb-0 fw-semibold'>Edit Appointment</h1>
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-5 shadow-sm'>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={createAppointmentSchema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true)
            const endTime = calculateEndTime(
              values.startTime,
              Number(values.hours),
              Number(values.minutes)
            )
            try {
              let response = await axios.put(`${API_URL}/Appoinment`, {
                ...values,
                isBulk: isBulk === 'true' ? true : false,
                accountRate: selectedOption,
                interpreterPayType: selectedOption2,
                startTime: values?.startTime,
                endTime,
                // endTime: values?.endTime,
                fK_TimeZone: values.fK_TimeZone !== '' ? values.fK_TimeZone : null,
                code: values.code !== '' ? values.code : null,
                fK_Customer: values.fK_Customer !== '' ? values.fK_Customer : null,
                fK_CommunicationType:
                  values.fK_CommunicationType !== '' ? values.fK_CommunicationType : null,
                fK_Requester: values.fK_Requester !== '' ? values.fK_Requester : null,
                address: values.address !== '' ? values.address : null,
                street1: values.street1 !== '' ? values.street1 : null,
                street2: values.street2 !== '' ? values.street2 : null,
                state: values.state !== '' ? values.state : null,
                city: values.city !== '' ? values.city : null,
                postalCode: values.postalCode !== '' ? values.postalCode : null,
                country: values.country !== '' ? values.country : null,
                latitude: values.latitude !== '' ? values.latitude : null,
                longitude: values.longitude !== '' ? values.longitude : null,
                virtualAddress: values.virtualAddress !== '' ? values.virtualAddress : null,
                pin: values.pin !== '' ? values.pin : null,
                callerNumber: values.callerNumber !== '' ? values.callerNumber : null,
                remarks: values.remarks !== '' ? values.remarks : null,
                subject: values.subject !== '' ? values.subject : null,
                fK_Gender: values.fK_Gender !== '' ? values.fK_Gender : null,
                consumer: values.consumer !== '' ? values.consumer : null,
                description: values.description !== '' ? values.description : null,
                fK_LanguageFrom: values.fK_LanguageFrom !== '' ? values.fK_LanguageFrom : null,
                fK_LanguageTo: values.fK_LanguageTo !== '' ? values.fK_LanguageTo : null,
                fK_ServiceType: values.fK_ServiceType !== '' ? values.fK_ServiceType : null,
                fK_PreCallId: values.fK_PreCallId !== '' ? values.fK_PreCallId : null,
                recurrence: values.recurrence !== '' ? values.recurrence : null,
                accountPriority: values.accountPriority !== '' ? values.accountPriority : null,
                interpreterPriority:
                  values.interpreterPriority !== '' ? values.interpreterPriority : null,
                notePM: values.notePM !== '' ? values.notePM : null,
                noteInterpreter: values.noteInterpreter !== '' ? values.noteInterpreter : null,
                recordId: values.recordId !== '' ? values.recordId : null,
                isDeleted: values.isDeleted,
                recurrenceRestriction:
                  values.recurrenceRestriction !== '' ? values.recurrenceRestriction : null,
                byCount: values.byCount === '' ? null : values.byCount,
                byUntillDate: values.byUntillDate === '' ? null : values.byUntillDate,
                everyQuantity: values.everyQuantity === '' ? null : values.everyQuantity,
                isEveryWeekDay: values.isEveryWeekDay,
                everyWeeksQuantity:
                  values.everyWeeksQuantity === '' ? null : values.everyWeeksQuantity,
                nameOfDays: values.nameOfDays,
                isDay: values.isDay,
                dayCount: values.dayCount === '' ? null : values.dayCount,
                thePlaceOfDay: values.thePlaceOfDay,
                dayName: values.dayName === '' ? null : values.dayName,
                everyMonthsQuantityIsDay:
                  values.everyMonthsQuantityIsDay === '' ? null : values.everyMonthsQuantityIsDay,
                everyMonthsQuantityIsPlace:
                  values.everyMonthsQuantityIsPlace === ''
                    ? null
                    : values.everyMonthsQuantityIsPlace,
              })

              if (response.data.status === 'S') {
                toaster('success', response.data.text ?? 'Successfully Updated')
                resetForm()
                //need to check current result value has some issue
                const id = Number(response.data.result)
                if (id === 0) {
                  navigate(-1)
                } else {
                  navigate(`/appointmentViewer/${id}`)
                }
              } else if (response.data.status === 'E') {
                toaster('error', response.data.text ?? 'Updated Error')
              }
            } catch (ex) {
              toaster('error', 'Server Error')
              console.error(ex)
            } finally {
              // setModalInitialValues({
              //   code: '',
              //   cT_FK_LogicTypeCode: 0,
              //   cT_FK_CalculationMesureCode: 0,
              //   thresholdStart: '',
              //   thresholdEnd: '',
              //   freeUnit: '',
              //   fK_PickLocationCode: 0,
              //   fK_DeliveryLocationCode: 0,
              //   fK_ViaLocationCode: 0,
              //   isActive: true,
              //   vehicleTypesWithAmount: [],
              // })
              setSubmitting(true)
            }
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            resetForm,
            values,
            errors,
            isValid,
            dirty,
            ...formik
          }) => (
            <Form>
              <div className='card-body pt-9 pb-0'>
                <div className='row g-6 g-xl-6'>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className=''>
                      <div className='row g-4 g-xl-4'>
                        <div className='col-sm-12 col-md-12 col-lg-12 '>
                          <div className='mb-3'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label fs-7'
                            >
                              Account
                            </label>
                            <div className='row g-4 g-xl-4'>
                              <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='w-100'>
                                  <Select
                                    {...formik.getFieldProps('fK_Customer')}
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={allAccounts.map((item: any) => ({
                                      value: item.key,
                                      label: item.value,
                                    }))}
                                    placeholder='Select Account'
                                    styles={customStyles}
                                    value={{
                                      value: values.fK_Customer,
                                      label:
                                        allAccounts.find((x: any) => x.key === values.fK_Customer)
                                          ?.value || 'Select Account',
                                    }}
                                    onChange={(selectedOption: any) => {
                                      setFieldValue('fK_Customer', selectedOption?.value)
                                      setFieldValue('fK_Requester', '0')
                                      fetchRequester(selectedOption?.value)
                                      fetchPreCalls(selectedOption?.value)
                                    }}
                                    onBlur={handleBlur}
                                    isDisabled={true}
                                  />
                                  {errors?.fK_Customer && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>
                                          {String(errors?.fK_Customer ?? '')}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* <div className='col-sm-3 col-md-3 col-lg-3 d-flex flex-end'>
                              <button type='button' className='btn btn-sm btn-primary'>
                                Invite New
                              </button>
                            </div> */}
                            </div>
                          </div>

                          <div className='mb-3'>
                            <div className='row g-4 g-xl-4'>
                              <div className='col-sm-6 '>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7'
                                >
                                  Communication Type
                                </label>
                                <div className='w-100'>
                                  <Select
                                    {...formik.getFieldProps('fK_CommunicationType')}
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={allCommunicationTypes.map((item: any) => ({
                                      value: item.key,
                                      label: item.value,
                                    }))}
                                    noOptionsMessage={() => 'No Communication Type Found'}
                                    placeholder='Select Communication Type'
                                    value={{
                                      value: values.fK_CommunicationType,
                                      label:
                                        allCommunicationTypes.find(
                                          (x: any) => x.key === values.fK_CommunicationType
                                        )?.value || 'Select Communication Type',
                                    }}
                                    onChange={(selectedOption: any) => {
                                      setFieldValue('fK_CommunicationType', selectedOption?.value)
                                      setFieldValue('virtualAddress', '')
                                      setFieldValue('pin', '')
                                      setFieldValue('callerNumber', '')
                                      setFieldValue('remarks', '')
                                      setFieldValue('address', '')
                                      setFieldValue('street1', '')
                                      setFieldValue('street2', '')
                                      setFieldValue('city', '')
                                      setFieldValue('state', '')
                                      setFieldValue('country', '')
                                      setFieldValue('postalCode', '')
                                      setFieldValue('latitude', '')
                                      setFieldValue('longitude', '')
                                      setGpsLocation({
                                        lat: center.lat,
                                        lng: center.lng,
                                      })
                                    }}
                                    onBlur={handleBlur}
                                    styles={customStyles}
                                    isDisabled={true}
                                  />
                                  {errors?.fK_CommunicationType && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>
                                          {String(errors?.fK_CommunicationType ?? '')}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='col-sm-6'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7'
                                >
                                  Requester
                                </label>
                                <div className='w-100'>
                                  <Select
                                    {...formik.getFieldProps('fK_Requester')}
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={requesters.map((item: any) => ({
                                      value: item.id,
                                      label: `${item.firstName} ${item.lastName}`,
                                    }))}
                                    noOptionsMessage={() => 'No Requester Found'}
                                    placeholder='Select Requester'
                                    value={{
                                      value: values.fK_Requester,
                                      label: requesters.find(
                                        (x: any) => x.id === values.fK_Requester
                                      )
                                        ? `${
                                            requesters.find(
                                              (x: any) => x.id === values.fK_Requester
                                            ).firstName
                                          } ${
                                            requesters.find(
                                              (x: any) => x.id === values.fK_Requester
                                            ).lastName
                                          }`
                                        : 'Select Requester',
                                    }}
                                    onChange={(selectedOption: any) => {
                                      setFieldValue('fK_Requester', selectedOption?.value)
                                    }}
                                    onBlur={handleBlur}
                                    styles={customStyles}
                                    isDisabled={true}
                                  />
                                  {errors?.fK_Requester && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>
                                          {String(errors?.fK_Requester ?? '')}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {allCommunicationTypes.find(
                                (x: any) => x.key === values.fK_CommunicationType
                              )?.identification === 'THIRD_PART_PLATFORM' && (
                                <div className='col-sm-12 '>
                                  <label
                                    htmlFor='exampleFormControlInput1'
                                    className='required form-label fs-7'
                                  >
                                    Meeting Link
                                  </label>
                                  <input
                                    {...formik.getFieldProps('virtualAddress')}
                                    type='text'
                                    className='form-control form-control-white form-select-sm custom-input-height'
                                    placeholder='Enter Meeting Link'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.virtualAddress}
                                  />
                                  {errors?.virtualAddress && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>
                                          {String(errors?.virtualAddress ?? '')}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              {allCommunicationTypes.find(
                                (x: any) => x.key === values.fK_CommunicationType
                              )?.identification === 'THIRD_PART_PLATFORM' && (
                                <div className=''>
                                  <div className='row mb-3'>
                                    <div className='col-sm-6'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='form-label fs-7'
                                      >
                                        Pin
                                      </label>
                                      <input
                                        {...formik.getFieldProps('pin')}
                                        type='text'
                                        className='form-control form-control-white form-select-sm custom-input-height'
                                        placeholder='Enter Pin'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.pin}
                                      />
                                      {errors?.pin && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{String(errors?.pin ?? '')}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='col-sm-6'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='form-label fs-7'
                                      >
                                        Meeting ID / Caller Number
                                      </label>
                                      <input
                                        {...formik.getFieldProps('callerNumber')}
                                        type='text'
                                        className='form-control form-control-white form-select-sm custom-input-height'
                                        placeholder='Enter Meeting ID / Caller Number'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.callerNumber}
                                      />
                                      {errors?.callerNumber && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>
                                              {String(errors?.callerNumber ?? '')}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className='col-sm-12'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='form-label fs-7'
                                    >
                                      Remarks
                                    </label>
                                    <input
                                      {...formik.getFieldProps('remarks')}
                                      type='text'
                                      className='form-control form-control-white form-select-sm custom-input-height'
                                      placeholder='Enter Remarks'
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.remarks}
                                    />
                                    {errors?.remarks && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span role='alert'>{String(errors?.remarks ?? '')}</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {allCommunicationTypes.find(
                                (x: any) => x.key === values.fK_CommunicationType
                              )?.identification === 'ON_SITE' && (
                                <div className='fv-row'>
                                  <div className='row gy-4'>
                                    <div className='col-sm-12 '>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7'
                                      >
                                        Address
                                      </label>
                                      {typeof window.google !== 'undefined' && (
                                        <PlacesAutocomplete
                                          onSelect={(suggestion: any) => {
                                            handleSelect(suggestion, setFieldValue)
                                          }}
                                          value={values.address}
                                          onChange={(e: any) => {
                                            if (e === '') {
                                              setFieldValue('address', '')
                                              setFieldValue('street1', '')
                                              setFieldValue('street2', '')
                                              setFieldValue('city', '')
                                              setFieldValue('state', '')
                                              setFieldValue('country', '')
                                              setFieldValue('postalCode', '')
                                              setFieldValue('latitude', center.lat)
                                              setFieldValue('longitude', center.lng)
                                              setGpsLocation({
                                                lat: center.lat,
                                                lng: center.lng,
                                              })
                                            }
                                            setFieldValue('address', e)
                                          }}
                                        >
                                          {({
                                            getInputProps,
                                            getSuggestionItemProps,
                                            suggestions,
                                            loading,
                                          }) => (
                                            <div>
                                              {renderFunc({
                                                getInputProps,
                                                getSuggestionItemProps,
                                                suggestions,
                                                loading,
                                                errors,
                                                setFieldValue,
                                              })}
                                            </div>
                                          )}
                                        </PlacesAutocomplete>
                                      )}
                                    </div>
                                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 '>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7'
                                      >
                                        City
                                      </label>
                                      <input
                                        {...formik.getFieldProps('city')}
                                        id='city'
                                        name='city'
                                        type='text'
                                        className='form-control form-control-white form-select-sm custom-input-height'
                                        placeholder='Enter City'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.city}
                                        disabled={true}
                                      />
                                      {errors?.city && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{errors?.city}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7'
                                      >
                                        State
                                      </label>
                                      <input
                                        {...formik.getFieldProps('state')}
                                        id='state'
                                        name='state'
                                        type='text'
                                        className='form-control form-control-white form-select-sm custom-input-height'
                                        placeholder='Enter State'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.state}
                                        disabled={true}
                                      />
                                      {errors.state && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{errors.state}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7'
                                      >
                                        Country
                                      </label>
                                      <input
                                        {...formik.getFieldProps('country')}
                                        id='country'
                                        name='country'
                                        type='text'
                                        className='form-control form-control-white form-select-sm custom-input-height'
                                        placeholder='Enter Country'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.country}
                                        disabled={true}
                                      />
                                      {errors.country && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{errors.country}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7'
                                      >
                                        Postal Code
                                      </label>
                                      <input
                                        {...formik.getFieldProps('postalCode')}
                                        id='postalCode'
                                        name='postalCode'
                                        type='text'
                                        className='form-control form-control-white form-select-sm custom-input-height'
                                        placeholder='Enter Postal Code'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.postalCode}
                                        disabled={true}
                                      />
                                      {errors.postalCode && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{errors.postalCode}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='col-sm-12 '>
                                      {isLoaded && (
                                        <GoogleMap
                                          mapContainerStyle={{
                                            width: 'auto',
                                            height: '400px',
                                          }}
                                          center={gpsLocation ? gpsLocation : center}
                                          zoom={10}
                                          onLoad={onLoad}
                                          onUnmount={onUnmount}
                                        >
                                          {gpsLocation && <Marker position={gpsLocation}></Marker>}
                                        </GoogleMap>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='mb-3'>
                            <div className='row g-4 g-xl-4'>
                              {/* <div className='col-sm-6 '>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7'
                                >
                                  Subject
                                </label>
                                <input
                                  {...formik.getFieldProps('subject')}
                                  id='subject'
                                  name='subject'
                                  type='text'
                                  className='form-control form-control-white form-select-sm custom-input-height'
                                  placeholder='Enter Subject'
                                  value={values?.subject}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.subject && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{errors.subject}</span>
                                    </div>
                                  </div>
                                )}
                              </div> */}
                              <div className='col-sm-6 '>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7'
                                >
                                  Gender
                                </label>
                                <div className='w-100'>
                                  <Select
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={genders.map((item: any) => ({
                                      value: item.key,
                                      label: item.value,
                                    }))}
                                    placeholder='Select Gender'
                                    styles={customStyles}
                                    value={{
                                      value: values.fK_Gender,
                                      label:
                                        genders.find((x: any) => x.key === values.fK_Gender)
                                          ?.value || 'Select Gender',
                                    }}
                                    onChange={(selectedOption: any) => {
                                      setFieldValue('fK_Gender', selectedOption?.value)
                                    }}
                                    onBlur={handleBlur}
                                    isDisabled={true}
                                  />
                                  {errors.fK_Gender && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{errors.fK_Gender}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='col-sm-6 '>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7'
                                >
                                  Service Type
                                </label>
                                <div className='w-100'>
                                  <Select
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={serviceTypes.map((item: any) => ({
                                      value: item.key,
                                      label: item.value,
                                    }))}
                                    placeholder='Select Service Type'
                                    styles={customStyles}
                                    value={{
                                      value: values.fK_ServiceType,
                                      label:
                                        serviceTypes.find(
                                          (x: any) => x.key === values.fK_ServiceType
                                        )?.value || 'Select Service Type',
                                    }}
                                    onChange={(selectedOption: any) => {
                                      setFieldValue('fK_ServiceType', selectedOption?.value)
                                    }}
                                    onBlur={handleBlur}
                                    isDisabled={true}
                                  />
                                  {errors.fK_ServiceType && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{errors.fK_ServiceType}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mb-3'>
                            <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                              Consumer
                            </label>
                            <input
                              {...formik.getFieldProps('consumer')}
                              id='consumer'
                              name='consumer'
                              type='text'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              placeholder='Enter Consumer'
                              value={values?.consumer}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.consumer && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.consumer}</span>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className='mb-3'>
                            <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                              Description
                            </label>
                            <textarea
                              {...formik.getFieldProps('description')}
                              id='description'
                              placeholder='Enter Description'
                              name='description'
                              className='form-control form-control-sm form-select-white mb-lg-0'
                              rows={2}
                              value={values?.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            ></textarea>
                            {errors.description && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.description}</span>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className='mb-3'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label fs-7'
                            >
                              Language
                            </label>
                            <div className='d-flex align-items-center flex-row justify-content-between w-100'>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={languages.map((item: any) => ({
                                    value: item.key,
                                    label: item.value,
                                  }))}
                                  placeholder='Language From'
                                  styles={customStyles}
                                  value={{
                                    value: values.fK_LanguageFrom,
                                    label:
                                      languages.find((x: any) => x.key === values.fK_LanguageFrom)
                                        ?.value || 'Language From',
                                  }}
                                  onChange={(selectedOption: any) => {
                                    setFieldValue('fK_LanguageFrom', selectedOption?.value)
                                  }}
                                  onBlur={handleBlur}
                                  isDisabled={true}
                                />
                                {errors.fK_LanguageFrom && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{errors.fK_LanguageFrom}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className='m-2'>-</div>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={languages.map((item: any) => ({
                                    value: item.key,
                                    label: item.value,
                                  }))}
                                  placeholder='Language To'
                                  styles={customStyles}
                                  value={{
                                    value: values.fK_LanguageTo,
                                    label:
                                      languages.find((x: any) => x.key === values.fK_LanguageTo)
                                        ?.value || 'Language To',
                                  }}
                                  onChange={(selectedOption: any) => {
                                    setFieldValue('fK_LanguageTo', selectedOption?.value)
                                  }}
                                  onBlur={handleBlur}
                                  isDisabled={true}
                                />
                                {errors.fK_LanguageTo && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{errors.fK_LanguageTo}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='mb-3'>
                            <div className='row g-4 g-xl-4'>
                              {/* <div className='col-sm-6 '>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='form-label fs-7'
                                >
                                  Pre-Call Policy
                                </label>
                                <div className='w-100'>
                                  <Select
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={preCallPolicies.map((item: any) => ({
                                      value: item.key,
                                      label: item.label,
                                    }))}
                                    placeholder='Select Pre Call Policy'
                                    styles={customStyles}
                                    value={{
                                      value: values.fK_PreCallId,
                                      label:
                                        preCallPolicies.find(
                                          (x: any) => x.key === values.fK_PreCallId
                                        )?.label || 'Select Pre Call Policy',
                                    }}
                                    noOptionsMessage={() => 'No Pre Call Policies Found'}
                                    onChange={(selectedOption: any) => {
                                      setFieldValue('fK_PreCallId', selectedOption?.value)
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {errors.fK_PreCallId && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{errors.fK_PreCallId}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className='mb-3'>
                            <div className='row g-4 g-xl-4'>
                              {/* <div className='col-sm-6'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className=' form-label fs-7'
                                >
                                  Duration
                                </label>
                                <div className='d-flex justify-content-amount align-items-center flex-nowrap'>
                                  <button type='button' className='btn btn-light btn-sm me-2'>
                                    -
                                  </button>
                                  <div className='d-flex flex-grow-1'>
                                    <input
                                      type='text'
                                      className='form-control form-control-sm form-control-white'
                                      pattern='[0-9]{2}:[0-9]{2}'
                                      title='Enter Duration in HH:MM format'
                                      placeholder='Duration (HH:MM)'
                                    />
                                  </div>
                                  <button type='button' className='btn btn-light btn-sm ms-2'>
                                    +
                                  </button>
                                </div>
                              </div> */}
                              <div className=''>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7'
                                >
                                  Time Zone
                                </label>

                                <div className='w-100'>
                                  <Select
                                    className='react-select-styled react-select-solid react-select-sm custom-input-height'
                                    classNamePrefix='react-select'
                                    options={timezoneOptions}
                                    placeholder='Select Time Zone'
                                    styles={customStyles}
                                    value={timezoneOptions.find(
                                      (option: any) => option.value === values.fK_TimeZone
                                    )}
                                    noOptionsMessage={() => 'No time zone found'}
                                    onChange={(selectedOption: any) => {
                                      setFieldValue('fK_TimeZone', selectedOption?.value)
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {errors.fK_TimeZone && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{errors.fK_TimeZone}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mb-3'>
                            <div className='row g-4 g-xl-4'>
                              <div className='col-sm-6'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7'
                                >
                                  Start Time
                                </label>
                                <input
                                  {...formik.getFieldProps('startTime')}
                                  type='datetime-local'
                                  id='startTime'
                                  className='form-control form-control-white form-select-sm custom-input-height'
                                  placeholder='Start Time'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.startTime}
                                />
                                {errors.startTime && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{errors.startTime}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className='col-sm-6'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7'
                                >
                                  Time Allocation
                                </label>
                                {/* <input
                                  {...formik.getFieldProps('endTime')}
                                  id='endTime'
                                  type='datetime-local'
                                  min={values.startTime}
                                  className='form-control form-control-white form-select-sm custom-input-height'
                                  placeholder='End time'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.endTime}
                                />
                                {errors.endTime && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{errors.endTime}</span>
                                    </div>
                                  </div>
                                )} */}
                                <div className='input-group input-group-sm'>
                                  <div className='d-flex w-auto' style={{flex: 1}}>
                                    <Select
                                      id='hours'
                                      className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                                      classNamePrefix='react-select'
                                      isDisabled={values.fK_Requester === '0'}
                                      options={HoursOptions}
                                      value={HoursOptions.find(
                                        (option) => option.value === values.hours
                                      )}
                                      onChange={(selectedOption: any) =>
                                        setFieldValue('hours', selectedOption?.value)
                                      }
                                      onBlur={handleBlur}
                                      placeholder='None'
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                          border: '1px solid #e4e6ef',
                                        }),
                                      }}
                                    />
                                  </div>
                                  <span className='input-group-text' style={{flex: 1}}>
                                    Hours
                                  </span>
                                  <div className='d-flex w-auto' style={{flex: 1}}>
                                    <Select
                                      id='minutes'
                                      className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                                      classNamePrefix='react-select'
                                      isDisabled={values.fK_Requester === '0'}
                                      options={MinutesOptions}
                                      placeholder='None'
                                      value={MinutesOptions.find(
                                        (option) => option.value === values.minutes
                                      )}
                                      onChange={(selectedOption: any) =>
                                        setFieldValue('minutes', selectedOption?.value)
                                      }
                                      onBlur={handleBlur}
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                          border: '1px solid #e4e6ef',
                                        }),
                                      }}
                                    />
                                  </div>
                                  <span className='input-group-text' style={{flex: 1}}>
                                    Minutes
                                  </span>
                                </div>

                                {(errors?.hours || errors?.minutes) && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{errors.hours || errors.minutes}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className=''>
                      <div className='row g-4 g-xl-4'>
                        <div className='col-sm-12 col-md-12 col-lg-12 '>
                          <div className='mb-3'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label fs-7'
                            >
                              Recurrence
                            </label>
                            <div className='row g-4 g-xl-4'>
                              <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='w-100'>
                                  <Select
                                    {...formik.getFieldProps('recurrence')}
                                    id='recurrence'
                                    className='react-select-styled react-select-solid react-select-sm'
                                    classNamePrefix='react-select'
                                    options={recurrences}
                                    placeholder='Select Recurrence'
                                    onBlur={handleBlur}
                                    noOptionsMessage={() => 'No recurrence found'}
                                    value={{
                                      value: values.recurrence,
                                      label:
                                        recurrences?.find((c: any) => c.value === values.recurrence)
                                          ?.label || 'Select Recurrence',
                                    }}
                                    onChange={(selectedOption: any) => {
                                      setFieldValue('recurrence', selectedOption?.value)
                                      if (selectedOption?.value === '1') {
                                        setFieldValue('recurrenceRestriction', '0')
                                        setFieldValue('byCount', '')
                                        setFieldValue('byDate', '')
                                      }
                                      setFieldValue('everyWeeksQuantity', '')
                                      setFieldValue('isEveryWeekDay', true)
                                      setFieldValue('nameOfDays', [])
                                      setFieldValue('isDay', true)
                                      setFieldValue('dayCount', '')
                                      setFieldValue('everyMonthsQuantityIsDay', '')
                                      setFieldValue('thePlaceOfDay', '1')
                                      setFieldValue('everyMonthsQuantityIsPlace', '')
                                    }}
                                    styles={customStyles}
                                    isDisabled={isBulk === 'true' ? false : true}
                                  />
                                  {errors.recurrence && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{errors.recurrence}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {values.recurrence !== '1' && (
                            <div className='mb-3'>
                              <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                                Recurrence Restriction
                              </label>

                              <div className='row g-4'>
                                <div className='col-sm-8'>
                                  <select
                                    className='form-select form-select-sm form-select-white'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                    value={values.recurrenceRestriction}
                                    onChange={(e: any) => {
                                      setFieldValue('recurrenceRestriction', e.target.value)
                                    }}
                                    disabled={isBulk === 'true' ? false : true}
                                  >
                                    <option value='0' hidden disabled>
                                      Select Recurrence Restriction
                                    </option>
                                    <option value='1'>By Count</option>
                                    <option value='2'>By Date Until</option>
                                  </select>
                                  {errors.recurrenceRestriction && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{errors.recurrenceRestriction}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='col-sm-4'>
                                  {values.recurrenceRestriction === '1' ? (
                                    <input
                                      type='number'
                                      className='form-control form-control-white form-select-sm'
                                      placeholder='Enter Count'
                                      id='byCount'
                                      name='byCount'
                                      value={values.byCount}
                                      onChange={(e: any) => {
                                        setFieldValue('byCount', e.target.value)
                                      }}
                                      disabled={isBulk === 'true' ? false : true}
                                    />
                                  ) : (
                                    <input
                                      type='date'
                                      className='form-control form-control-white form-select-sm'
                                      placeholder='Enter Date Until'
                                      id='byUntillDate'
                                      name='byUntillDate'
                                      value={values.byUntillDate}
                                      onChange={(e: any) => {
                                        setFieldValue('byUntillDate', e.target.value)
                                      }}
                                      min={moment().local().format('YYYY-MM-DD')}
                                      disabled={isBulk === 'true' ? false : true}
                                    />
                                  )}
                                  {errors.byCount && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{errors.byCount}</span>
                                      </div>
                                    </div>
                                  )}
                                  {errors.byUntillDate && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        <span role='alert'>{errors.byUntillDate}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}

                          {values.recurrence === '2' && (
                            <div className='mb-3'>
                              <div className='row g-4'>
                                <div className='col-sm-12'>
                                  <div className='row g-4 g-xl-4 '>
                                    <div className='pt-3 d-flex align-items-center'>
                                      <label className='d-flex flex-start mb-0 cursor-pointer'>
                                        <span className='form-check form-check-custom form-check-white me-3'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            id='isEveryWeekDay'
                                            name='isEveryWeekDay'
                                            checked={!values.isEveryWeekDay}
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                'isEveryWeekDay',
                                                !values.isEveryWeekDay
                                              )
                                            }}
                                            onBlur={handleBlur}
                                            disabled={isBulk === 'true' ? false : true}
                                          />
                                        </span>
                                        <span className='d-flex align-items-center me-2'>
                                          <span className='text-gray-800 text-hover-primary fs-6'>
                                            Every
                                          </span>
                                        </span>
                                      </label>
                                      <div className='d-flex align-items-center'>
                                        <div className='col-sm-12'>
                                          <div className='input-group input-group-sm'>
                                            {/* <span className='input-group-text'>Every</span> */}
                                            <input
                                              type='number'
                                              className='form-control form-control-white form-control-sm'
                                              placeholder='Enter Days'
                                              id='everyQuantity'
                                              name='everyQuantity'
                                              value={values.everyQuantity}
                                              disabled={
                                                values.isEveryWeekDay || isBulk === 'true'
                                                  ? false
                                                  : true
                                              }
                                              onChange={(e: any) => {
                                                setFieldValue('everyQuantity', e.target.value)
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            <span className='input-group-text'>Days</span>
                                          </div>
                                        </div>
                                      </div>
                                      {errors.everyQuantity && (
                                        <div className='fv-plugins-message-container mx-3'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{errors.everyQuantity}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className=''>
                                      <label className='d-flex flex-start mb-0 cursor-pointer'>
                                        <span className='form-check form-check-custom form-check-white me-3'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            id='isEveryWeekDay'
                                            name='isEveryWeekDay'
                                            checked={values.isEveryWeekDay}
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                'isEveryWeekDay',
                                                !values.isEveryWeekDay
                                              )
                                              setFieldValue('everyQuantity', '')
                                            }}
                                            disabled={isBulk === 'true' ? false : true}
                                            onBlur={handleBlur}
                                          />
                                        </span>
                                        <span className='d-flex align-items-center me-2 justify-content-end'>
                                          <span className='text-gray-800 text-hover-primary fs-6'>
                                            Every Weekday
                                          </span>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {values.recurrence === '3' && (
                            <div className='mb-3'>
                              <div className='row g-4'>
                                <div className='col-sm-12'>
                                  <div className='row g-4 g-xl-4 '>
                                    <div className='pt-3 d-flex align-items-center'>
                                      <label className='d-flex flex-start mb-0 cursor-pointer'>
                                        <span className='text-gray-800 text-hover-primary fs-6 me-2'>
                                          Every
                                        </span>
                                      </label>
                                      <div className='d-flex align-items-center flex-nowrap'>
                                        <div className='input-group input-group-sm'>
                                          {/* <span className='input-group-text'>Every</span> */}
                                          <input
                                            type='number'
                                            className='form-control form-control-white form-control-sm'
                                            placeholder='Enter Weeks'
                                            id='everyWeeksQuantity'
                                            name='everyWeeksQuantity'
                                            value={values.everyWeeksQuantity}
                                            onChange={(e: any) => {
                                              setFieldValue('everyWeeksQuantity', e.target.value)
                                            }}
                                            onBlur={handleBlur}
                                          />
                                          <span className='input-group-text'>week(s) on</span>
                                        </div>
                                      </div>
                                      {errors.everyWeeksQuantity && (
                                        <div className='fv-plugins-message-container mx-3'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{errors.everyWeeksQuantity}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='row g-4 g-xl-4 mt-0 '>
                                      <div className='col-sm-6'>
                                        <div className='form-check form-check-custom form-check-white me-3 p-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexCheckDefault'
                                            checked={values.nameOfDays.includes('Sunday')}
                                            onChange={(e: any) => {
                                              if (values.nameOfDays.includes('Sunday')) {
                                                setFieldValue(
                                                  'nameOfDays',
                                                  values.nameOfDays.filter(
                                                    (item: string) => item !== 'Sunday'
                                                  )
                                                )
                                              } else {
                                                setFieldValue('nameOfDays', [
                                                  ...values.nameOfDays,
                                                  'Sunday',
                                                ])
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            disabled={isBulk === 'true' ? false : true}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexCheckDefault'
                                          >
                                            Sunday
                                          </label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-white me-3 p-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexCheckDefault'
                                            checked={values.nameOfDays.includes('Monday')}
                                            onChange={(e: any) => {
                                              if (values.nameOfDays.includes('Monday')) {
                                                setFieldValue(
                                                  'nameOfDays',
                                                  values.nameOfDays.filter(
                                                    (item: string) => item !== 'Monday'
                                                  )
                                                )
                                              } else {
                                                setFieldValue('nameOfDays', [
                                                  ...values.nameOfDays,
                                                  'Monday',
                                                ])
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            disabled={isBulk === 'true' ? false : true}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexCheckDefault'
                                          >
                                            Monday
                                          </label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-white me-3 p-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexCheckDefault'
                                            checked={values.nameOfDays.includes('Tuesday')}
                                            onChange={(e: any) => {
                                              if (values.nameOfDays.includes('Tuesday')) {
                                                setFieldValue(
                                                  'nameOfDays',
                                                  values.nameOfDays.filter(
                                                    (item: string) => item !== 'Tuesday'
                                                  )
                                                )
                                              } else {
                                                setFieldValue('nameOfDays', [
                                                  ...values.nameOfDays,
                                                  'Tuesday',
                                                ])
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            disabled={isBulk === 'true' ? false : true}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexCheckDefault'
                                          >
                                            Tuesday
                                          </label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-white me-3 p-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexCheckDefault'
                                            checked={values.nameOfDays.includes('Wednesday')}
                                            onChange={(e: any) => {
                                              if (values.nameOfDays.includes('Wednesday')) {
                                                setFieldValue(
                                                  'nameOfDays',
                                                  values.nameOfDays.filter(
                                                    (item: string) => item !== 'Wednesday'
                                                  )
                                                )
                                              } else {
                                                setFieldValue('nameOfDays', [
                                                  ...values.nameOfDays,
                                                  'Wednesday',
                                                ])
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            disabled={isBulk === 'true' ? false : true}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexCheckDefault'
                                          >
                                            Wedneday
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-6'>
                                        <div className='form-check form-check-custom form-check-white me-3 p-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexCheckDefault'
                                            checked={values.nameOfDays.includes('Thursday')}
                                            onChange={(e: any) => {
                                              if (values.nameOfDays.includes('Thursday')) {
                                                setFieldValue(
                                                  'nameOfDays',
                                                  values.nameOfDays.filter(
                                                    (item: string) => item !== 'Thursday'
                                                  )
                                                )
                                              } else {
                                                setFieldValue('nameOfDays', [
                                                  ...values.nameOfDays,
                                                  'Thursday',
                                                ])
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            disabled={isBulk === 'true' ? false : true}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexCheckDefault'
                                          >
                                            Thursday
                                          </label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-white me-3 p-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexCheckDefault'
                                            checked={values.nameOfDays.includes('Friday')}
                                            onChange={(e: any) => {
                                              if (values.nameOfDays.includes('Friday')) {
                                                setFieldValue(
                                                  'nameOfDays',
                                                  values.nameOfDays.filter(
                                                    (item: string) => item !== 'Friday'
                                                  )
                                                )
                                              } else {
                                                setFieldValue('nameOfDays', [
                                                  ...values.nameOfDays,
                                                  'Friday',
                                                ])
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            disabled={isBulk === 'true' ? false : true}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexCheckDefault'
                                          >
                                            Friday
                                          </label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-white me-3 p-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexCheckDefault'
                                            checked={values.nameOfDays.includes('Saturday')}
                                            onChange={(e: any) => {
                                              if (values.nameOfDays.includes('Saturday')) {
                                                setFieldValue(
                                                  'nameOfDays',
                                                  values.nameOfDays.filter(
                                                    (item: string) => item !== 'Saturday'
                                                  )
                                                )
                                              } else {
                                                setFieldValue('nameOfDays', [
                                                  ...values.nameOfDays,
                                                  'Saturday',
                                                ])
                                              }
                                            }}
                                            onBlur={handleBlur}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexCheckDefault'
                                          >
                                            Saturday
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {errors.nameOfDays && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{errors.nameOfDays}</div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {values.recurrence === '4' && (
                            <div className='mb-3'>
                              <div className='row g-4'>
                                <div className='col-sm-12'>
                                  <div className='row g-4 g-xl-4 '>
                                    <div className='pt-3 d-flex align-items-center'>
                                      <label className='d-flex flex-start mb-0 cursor-pointer '>
                                        <span className='form-check form-check-custom form-check-white me-3'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            id='isDay'
                                            name='isDay'
                                            checked={
                                              initialValues.dayCount !== ''
                                                ? values.isDay
                                                : !values.isDay
                                            }
                                            onChange={(e: any) => {
                                              const isChecked = !(initialValues.isDay
                                                ? values.isDay
                                                : !values.isDay)
                                              setFieldValue('isDay', isChecked)

                                              // If radio button is unchecked, reset the values
                                              if (!isChecked) {
                                                setFieldValue('dayCount', '')
                                                setFieldValue('everyMonthsQuantityIsDay', '')
                                              }
                                              setFieldValue('thePlaceOfDay', '1')
                                              setFieldValue('everyMonthsQuantityIsPlace', '')
                                            }}
                                            onBlur={handleBlur}
                                            disabled={isBulk === 'true' ? false : true}
                                          />
                                        </span>
                                        <span className='d-flex align-items-center me-2'>
                                          <span className='text-gray-800 text-hover-primary fs-6'>
                                            Day
                                          </span>
                                        </span>
                                      </label>
                                      <div className='d-flex align-items-center'>
                                        <div className='input-group input-group-sm'>
                                          {/* <span className='input-group-text'>Every</span> */}
                                          <input
                                            type='number'
                                            className='form-control form-control-white form-control-sm'
                                            placeholder='None'
                                            id='dayCount'
                                            name='dayCount'
                                            value={values.dayCount}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={
                                              (isBulk === 'true' ? false : true) ||
                                              !(initialValues.dayCount !== ''
                                                ? values.isDay
                                                : !values.isDay)
                                            }
                                          />
                                          {(initialValues.dayCount !== ''
                                            ? values.isDay
                                            : !values.isDay) &&
                                            errors.dayCount && (
                                              <div className='fv-plugins-message-container mx-1'>
                                                <div className='fv-help-block'>
                                                  {errors.dayCount}
                                                </div>
                                              </div>
                                            )}
                                          <span className='input-group-text'> Of Every</span>
                                          <input
                                            type='number'
                                            className='form-control form-control-white form-control-sm'
                                            placeholder='None'
                                            id='everyMonthsQuantityIsDay'
                                            name='everyMonthsQuantityIsDay'
                                            value={values.everyMonthsQuantityIsDay}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={
                                              (isBulk === 'true' ? false : true) ||
                                              !(initialValues.dayCount !== ''
                                                ? values.isDay
                                                : !values.isDay)
                                            }
                                          />
                                          {(initialValues.dayCount !== ''
                                            ? values.isDay
                                            : !values.isDay) &&
                                            errors.everyMonthsQuantityIsDay && (
                                              <div className='fv-plugins-message-container mx-1'>
                                                <div className='fv-help-block'>
                                                  {errors.everyMonthsQuantityIsDay}
                                                </div>
                                              </div>
                                            )}
                                          <span className='input-group-text'> Month(s)</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='pt-3 d-flex align-items-center'>
                                      <label className='d-flex flex-start mb-0 cursor-pointer'>
                                        <span className='form-check form-check-custom form-check-white me-3'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            id='isDay'
                                            name='isDay'
                                            checked={
                                              !(initialValues.dayCount !== ''
                                                ? values.isDay
                                                : !values.isDay)
                                            }
                                            onChange={(e: any) => {
                                              const isChecked = !(initialValues.isDay
                                                ? values.isDay
                                                : !values.isDay)
                                              setFieldValue('isDay', isChecked)

                                              // If radio button is unchecked, reset the values
                                              if (isChecked) {
                                                setFieldValue('thePlaceOfDay', '1')
                                                setFieldValue('everyMonthsQuantityIsPlace', '')
                                              }
                                              setFieldValue('dayCount', '')
                                              setFieldValue('everyMonthsQuantityIsDay', '')
                                            }}
                                            onBlur={handleBlur}
                                            disabled={isBulk === 'true' ? false : true}
                                          />
                                        </span>
                                        <span className='d-flex align-items-center me-2'>
                                          <span className='text-gray-800 text-hover-primary fs-6'>
                                            The
                                          </span>
                                        </span>
                                      </label>
                                      <div className='d-flex align-items-center'>
                                        <div className='input-group input-group-sm'>
                                          <select
                                            className='form-select form-select-sm form-select-white'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-allow-clear='true'
                                            id='thePlaceOfDay'
                                            name='thePlaceOfDay'
                                            value={values.thePlaceOfDay}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={
                                              initialValues.dayCount !== ''
                                                ? values.isDay
                                                : !values.isDay
                                            }
                                          >
                                            <option value='1'>First</option>
                                            <option value='2'>Second</option>
                                            <option value='3'>Third</option>
                                            <option value='4'>Fourth</option>
                                            <option value='5'>Last</option>
                                          </select>
                                          <select
                                            className='form-select form-select-sm form-select-white'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-allow-clear='true'
                                            id='dayName'
                                            name='dayName'
                                            value={values.dayName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={
                                              (isBulk === 'true' ? false : true) ||
                                              (initialValues.dayCount !== ''
                                                ? values.isDay
                                                : !values.isDay)
                                            }
                                          >
                                            <option value='Monday'>Monday</option>
                                            <option value='Tuesday'>Tuesday</option>
                                            <option value='Wednsday'>Wednsday</option>
                                            <option value='Thursday'>Thursday</option>
                                            <option value='Friday'>Friday</option>
                                            <option value='Saturday'>Saturday</option>
                                            <option value='Sunday'>Sunday</option>
                                          </select>
                                          <span className='input-group-text'> Of Every</span>
                                          <input
                                            type='number'
                                            className='form-control form-control-white form-control-sm'
                                            placeholder='None'
                                            id='everyMonthsQuantityIsPlace'
                                            name='everyMonthsQuantityIsPlace'
                                            value={values.everyMonthsQuantityIsPlace}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={
                                              (isBulk === 'true' ? false : true) ||
                                              (initialValues.dayCount !== ''
                                                ? values.isDay
                                                : !values.isDay)
                                            }
                                          />
                                          <span className='input-group-text'> Month(s)</span>
                                          {!(initialValues.dayCount !== ''
                                            ? values.isDay
                                            : !values.isDay) &&
                                            errors.everyMonthsQuantityIsPlace && (
                                              <div className='fv-plugins-message-container mx-1'>
                                                <div className='fv-help-block'>
                                                  {errors.everyMonthsQuantityIsPlace}
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className='row g-4 mb-3'>
                            <div className='col-sm-6'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label fs-7'
                              >
                                Account Priority
                                <i
                                  className='bi bi-info-circle text-info  ms-2'
                                  data-bs-toggle='popover'
                                  data-bs-custom-class='popover-inverse'
                                  data-bs-placement='top'
                                  title='Based on priority rate settings, Regular priority is suggested'
                                ></i>
                              </label>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('accountPriority')}
                                  id='recurrence'
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={prority}
                                  placeholder='Select Account Priority'
                                  onBlur={handleBlur}
                                  noOptionsMessage={() => 'No account priority found'}
                                  value={{
                                    value: values.accountPriority,
                                    label:
                                      prority?.find((c: any) => c.value === values.accountPriority)
                                        ?.label || 'Select Recurrence',
                                  }}
                                  onChange={(selectedOption: any) => {
                                    setFieldValue('accountPriority', selectedOption?.value)
                                  }}
                                  styles={customStyles}
                                />
                                {errors.accountPriority && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{errors.accountPriority}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='col-sm-6'>
                              <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                                Interpreter Priority
                                <i
                                  className='bi bi-info-circle text-info ms-2'
                                  data-bs-toggle='popover'
                                  data-bs-custom-class='popover-inverse'
                                  data-bs-placement='top'
                                  title='Based on priority rate settings, Regular priority is suggested'
                                ></i>
                              </label>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('interpreterPriority')}
                                  id='interpreterPriority'
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={prority}
                                  placeholder='Select Interpreter Priority'
                                  onBlur={handleBlur}
                                  noOptionsMessage={() => 'No interpreter priority found'}
                                  value={{
                                    value: values.interpreterPriority,
                                    label:
                                      prority?.find(
                                        (c: any) => c.value === values.interpreterPriority
                                      )?.label || 'Select Interpreter Priority',
                                  }}
                                  onChange={(selectedOption: any) => {
                                    setFieldValue('interpreterPriority', selectedOption?.value)
                                  }}
                                  styles={customStyles}
                                />
                                {errors.interpreterPriority && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{errors.interpreterPriority}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className='col-sm-12 mb-3'>
                            <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                              Notes for PM
                            </label>
                            <textarea
                              {...formik.getFieldProps('notePM')}
                              id='notePM'
                              name='notePM'
                              placeholder='Enter Notes'
                              className='form-control form-control-sm form-select-white mb-lg-0'
                              value={values.notePM}
                              onChange={(e) => setFieldValue('notePM', e.target.value)}
                              onBlur={handleBlur}
                              rows={2}
                            ></textarea>
                          </div>
                          <div className='col-sm-12 mb-3'>
                            <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                              Notes for Interpreter
                            </label>
                            <textarea
                              {...formik.getFieldProps('noteInterpreter')}
                              id='noteInterpreter'
                              placeholder='Enter Notes'
                              name='noteInterpreter'
                              className='form-control form-control-sm form-select-white mb-lg-0'
                              value={values.noteInterpreter}
                              onChange={(e) => setFieldValue('noteInterpreter', e.target.value)}
                              onBlur={handleBlur}
                              rows={2}
                            ></textarea>
                          </div>
                          <div className='mb-3'>
                            <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                              Record ID
                            </label>
                            <input
                              {...formik.getFieldProps('recordId')}
                              id='recordId'
                              name='recordId'
                              type='text'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              placeholder='Enter Record ID'
                              value={values.recordId}
                              onChange={(e) => setFieldValue('recordId', e.target.value)}
                              onBlur={handleBlur}
                            />
                          </div>

                          {/* <div className='border border-dotted rounded border-gray-400 p-4 mt-5 '>
                            <div className='row '>
                              <span className='text-primary' style={{fontSize: '12px'}}>
                                Rate
                              </span>
                              <div className='separator my-5'></div>
                              <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                                Account Rates
                              </label>
                              <div className='col-sm-12 col-md-12 col-lg-12'>
                                <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                                  <span className='form-check form-check-custom form-check-white me-3'>
                                    <input
                                      name='accountPlan2'
                                      className='form-check-input'
                                      type='radio'
                                      value='1'
                                      checked={selectedOption === '1'}
                                      onChange={handleOptionChange}
                                    />
                                  </span>
                                  <span className='d-flex align-items-center me-2'>
                                    <span className='text-gray-800 text-hover-primary '>
                                      Account will be charged the standard hr/min rate
                                    </span>
                                  </span>
                                </label>
                                <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                                  <span className='form-check form-check-custom form-check-white me-3'>
                                    <input
                                      name='accountPlan2'
                                      className='form-check-input'
                                      type='radio'
                                      value='2'
                                      checked={selectedOption === '2'}
                                      onChange={handleOptionChange}
                                    />
                                  </span>
                                  <span className='d-flex align-items-center me-2'>
                                    <span className='text-gray-800 text-hover-primary '>
                                      Account will be charged the daily rate
                                    </span>
                                  </span>
                                </label>
                                <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                                  <span className='form-check form-check-custom form-check-white me-3'>
                                    <input
                                      name='accountPlan2'
                                      className='form-check-input'
                                      type='radio'
                                      value='3'
                                      checked={selectedOption === '3'}
                                      onChange={handleOptionChange}
                                    />
                                  </span>
                                  <span className='d-flex align-items-center me-2'>
                                    <span className='text-gray-800 text-hover-primary'>
                                      Override charge for this appointment...
                                    </span>
                                  </span>
                                </label>
                              </div>

                              {isAccRate && (
                                <>
                                  <div className='col-sm-12 col-md-12 col-lg-12 '>
                                    <div className='input-group input-group-sm mb-2'>
                                      <span className='input-group-text'>Rate</span>

                                      <input
                                        type='number'
                                        className='form-control form-control-white form-control-sm'
                                        placeholder='None'
                                      />

                                      <select
                                        className='form-select form-select-sm form-select-white rounded-0'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                      >
                                        <option value='1'>Min</option>
                                        <option value='2'>Hr</option>
                                        <option value='3'>Fixed</option>
                                      </select>
                                    </div>
                                    <div className='input-group input-group-sm mb-2'>
                                      <span className='input-group-text'>Rounding</span>

                                      <select
                                        className='form-select form-select-sm form-select-white rounded-0'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                      >
                                        <option value='1'>Pro-rata</option>
                                        <option value='2'>1min</option>
                                        <option value='3'>5min</option>
                                        <option value='4'>15min</option>
                                        <option value='5'>30min</option>
                                        <option value='6'>1hr</option>
                                      </select>
                                      <span className='input-group-text'>
                                        Direction{' '}
                                        <i
                                          className='bi bi-info-circle text-info  ms-2'
                                          data-bs-toggle='popover'
                                          data-bs-custom-class='popover-inverse'
                                          data-bs-placement='top'
                                          title='Rounding can be configured to round up, down or in both directions based on duration. 15 min up/down rounding follows the 7-minute rule where the cutoff point for rounding down is 7 full minutes. All other options simply round to the nearest selected interval.'
                                        ></i>
                                      </span>
                                      <select
                                        className='form-select form-select-sm form-select-white rounded-0'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                      >
                                        <option value='1'>Up/Down</option>
                                        <option value='2'>Up</option>
                                        <option value='3'>Down</option>
                                      </select>
                                    </div>
                                    <div className='input-group input-group-sm mb-2'>
                                      <span className='input-group-text'>Minimum</span>

                                      <input
                                        type='number'
                                        className='form-control form-control-white form-control-sm'
                                        placeholder='None'
                                      />

                                      <span className='input-group-text'>Minute</span>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className='mt-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='form-label fs-7'
                                >
                                  Interpreter Pay{' '}
                                  <span className='text-gray-400' style={{fontSize: '12px'}}>
                                    Only effects newly added interpreters
                                  </span>
                                </label>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                                    <span className='form-check form-check-custom form-check-white me-3'>
                                      <input
                                        name='accountPlan3'
                                        className='form-check-input'
                                        type='radio'
                                        value='1'
                                        checked={selectedOption2 === '1'}
                                        onChange={handleOptionChange2}
                                      />
                                    </span>
                                    <span className='d-flex align-items-center me-2'>
                                      <span className='text-gray-800 text-hover-primary '>
                                        Interpreter will be paid at the standard hr/min rate
                                      </span>
                                    </span>
                                  </label>
                                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                                    <span className='form-check form-check-custom form-check-white me-3'>
                                      <input
                                        name='accountPlan3'
                                        className='form-check-input'
                                        type='radio'
                                        value='2'
                                        checked={selectedOption2 === '2'}
                                        onChange={handleOptionChange2}
                                      />
                                    </span>
                                    <span className='d-flex align-items-center me-2'>
                                      <span className='text-gray-800 text-hover-primary '>
                                        Interpreter will be paid the daily rate
                                      </span>
                                    </span>
                                  </label>
                                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                                    <span className='form-check form-check-custom form-check-white me-3'>
                                      <input
                                        name='accountPlan3'
                                        className='form-check-input'
                                        type='radio'
                                        value='3'
                                        checked={selectedOption2 === '3'}
                                        onChange={handleOptionChange2}
                                      />
                                    </span>
                                    <span className='d-flex align-items-center me-2'>
                                      <span className='text-gray-800 text-hover-primary'>
                                        Override pay for this appointment...
                                      </span>
                                    </span>
                                  </label>
                                </div>

                                {isInterRate && (
                                  <>
                                    <div className='col-sm-12 col-md-12 col-lg-12 '>
                                      <div className='input-group input-group-sm mb-2'>
                                        <span className='input-group-text'>Rate</span>

                                        <input
                                          type='number'
                                          className='form-control form-control-white form-control-sm'
                                          placeholder='None'
                                        />

                                        <select
                                          className='form-select form-select-sm form-select-white rounded-0'
                                          data-kt-select2='true'
                                          data-placeholder='Select option'
                                          data-allow-clear='true'
                                        >
                                          <option value='1'>Min</option>
                                          <option value='2'>Hr</option>
                                          <option value='3'>Fixed</option>
                                        </select>
                                      </div>
                                      <div className='input-group input-group-sm mb-2'>
                                        <span className='input-group-text'>Rounding</span>

                                        <select
                                          className='form-select form-select-sm form-select-white rounded-0'
                                          data-kt-select2='true'
                                          data-placeholder='Select option'
                                          data-allow-clear='true'
                                        >
                                          <option value='1'>Pro-rata</option>
                                          <option value='2'>1min</option>
                                          <option value='3'>5min</option>
                                          <option value='4'>15min</option>
                                          <option value='5'>30min</option>
                                          <option value='6'>1hr</option>
                                        </select>
                                        <span className='input-group-text'>
                                          Direction{' '}
                                          <i
                                            className='bi bi-info-circle text-info  ms-2'
                                            data-bs-toggle='popover'
                                            data-bs-custom-class='popover-inverse'
                                            data-bs-placement='top'
                                            title='Rounding can be configured to round up, down or in both directions based on duration. 15 min up/down rounding follows the 7-minute rule where the cutoff point for rounding down is 7 full minutes. All other options simply round to the nearest selected interval.'
                                          ></i>
                                        </span>
                                        <select
                                          className='form-select form-select-sm form-select-white rounded-0'
                                          data-kt-select2='true'
                                          data-placeholder='Select option'
                                          data-allow-clear='true'
                                        >
                                          <option value='1'>Up/Down</option>
                                          <option value='2'>Up</option>
                                          <option value='3'>Down</option>
                                        </select>
                                      </div>
                                      <div className='input-group input-group-sm mb-2'>
                                        <span className='input-group-text'>Minimum</span>

                                        <input
                                          type='number'
                                          className='form-control form-control-white form-control-sm'
                                          placeholder='None'
                                        />

                                        <span className='input-group-text'>Minute</span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-footer text-end py-3 mt-2'>
                <button
                  type='reset'
                  className='btn btn-light btn-sm me-2'
                  onClick={() => {
                    resetForm()
                    navigate(-1)
                  }}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn btn-primary btn-sm'
                  disabled={isSubmitting || !isValid || !dirty}
                  onClick={async () => {
                    const isInterpreterAvalability = await handleCheckInterpreterAvalability({
                      ...values,
                      accountRate: selectedOption,
                      interpreterPayType: selectedOption2,
                      startTime: values?.startTime,
                      // endTime: values?.endTime,
                      fK_TimeZone: values?.fK_TimeZone,
                      code: values.code !== '' ? values.code : null,
                      fK_Customer: values.fK_Customer !== '' ? values.fK_Customer : null,
                      fK_CommunicationType:
                        values.fK_CommunicationType !== '' ? values.fK_CommunicationType : null,
                      fK_Requester: values.fK_Requester !== '' ? values.fK_Requester : null,
                      address: values.address !== '' ? values.address : null,
                      street1: values.street1 !== '' ? values.street1 : null,
                      street2: values.street2 !== '' ? values.street2 : null,
                      state: values.state !== '' ? values.state : null,
                      city: values.city !== '' ? values.city : null,
                      postalCode: values.postalCode !== '' ? values.postalCode : null,
                      country: values.country !== '' ? values.country : null,
                      latitude: values.latitude !== '' ? values.latitude : null,
                      longitude: values.longitude !== '' ? values.longitude : null,
                      virtualAddress: values.virtualAddress !== '' ? values.virtualAddress : null,
                      pin: values.pin !== '' ? values.pin : null,
                      callerNumber: values.callerNumber !== '' ? values.callerNumber : null,
                      remarks: values.remarks !== '' ? values.remarks : null,
                      subject: values.subject !== '' ? values.subject : null,
                      fK_Gender: values.fK_Gender !== '' ? values.fK_Gender : null,
                      consumer: values.consumer !== '' ? values.consumer : null,
                      description: values.description !== '' ? values.description : null,
                      fK_LanguageFrom:
                        values.fK_LanguageFrom !== '' ? values.fK_LanguageFrom : null,
                      fK_LanguageTo: values.fK_LanguageTo !== '' ? values.fK_LanguageTo : null,
                      fK_ServiceType: values.fK_ServiceType !== '' ? values.fK_ServiceType : null,
                      fK_PreCallId: values.fK_PreCallId !== '' ? values.fK_PreCallId : null,
                      recurrence: values.recurrence !== '' ? values.recurrence : null,
                      accountPriority:
                        values.accountPriority !== '' ? values.accountPriority : null,
                      interpreterPriority:
                        values.interpreterPriority !== '' ? values.interpreterPriority : null,
                      notePM: values.notePM !== '' ? values.notePM : null,
                      noteInterpreter:
                        values.noteInterpreter !== '' ? values.noteInterpreter : null,
                      recordId: values.recordId !== '' ? values.recordId : null,
                      isDeleted: values.isDeleted,
                      recurrenceRestriction:
                        values.recurrenceRestriction !== '' ? values.recurrenceRestriction : null,
                      byCount: values.byCount === '' ? null : values.byCount,
                      byUntillDate: values.byUntillDate === '' ? null : values.byUntillDate,
                      everyQuantity: values.everyQuantity === '' ? null : values.everyQuantity,
                      isEveryWeekDay: values.isEveryWeekDay,
                      everyWeeksQuantity:
                        values.everyWeeksQuantity === '' ? null : values.everyWeeksQuantity,
                      nameOfDays: values.nameOfDays,
                      isDay: values.isDay,
                      dayCount: values.dayCount === '' ? null : values.dayCount,
                      thePlaceOfDay: values.thePlaceOfDay,
                      dayName: values.dayName === '' ? null : values.dayName,
                      everyMonthsQuantityIsDay:
                        values.everyMonthsQuantityIsDay === ''
                          ? null
                          : values.everyMonthsQuantityIsDay,
                      everyMonthsQuantityIsPlace:
                        values.everyMonthsQuantityIsPlace === ''
                          ? null
                          : values.everyMonthsQuantityIsPlace,
                    })
                    if (isInterpreterAvalability) {
                      setIsOpenConfirmationModal(true)
                    } else {
                      handleSubmit()
                    }
                  }}
                >
                  <KTSVG path='/media/icons/duotune/files/fil008.svg' className='svg-icon-muted' />
                  Update Appointment
                </button>
              </div>
              {isOpenConfirmationModal && (
                <DeleteConfirmationModal
                  title='Confirmation'
                  content={
                    'It looks like the interpreter you are about to remove is already assigned to this appointment. Are you sure you want to proceed? Removing the interpreter will require you to edit the job assignment?'
                  }
                  onSubmit={async () => await handleSubmit()}
                  setOpen={setIsOpenConfirmationModal}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
      {isLoading && <CommonLoading />}
    </>
  )
}

export {EditAppointment}
