import React, {FC, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../members-management/users-list/core/_models'
import {useAuth} from '../../../modules/auth'
import {Form, Formik} from 'formik'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`
const GET_ALL_SERVICETYPES = `${API_URL}/master/getall/SERVICE_TYPE`
const GET_ALL_COMMUNICATIONTYPES = `${API_URL}/master/getall/COMMUNICATION_TYPE`
const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`

type Props = {
  date: any
  fetchAppoinmentDetailsUsingDate: any
}
export function CalandarFilterDropdown({fetchAppoinmentDetailsUsingDate, date}: Props) {
  // const [selectedCustomer, setSelectedCustomer] = useState<
  //   MultiValue<{value: string; label: string}>
  // >([])
  // const [selectedLanguages, setSelectedLanguages] = useState<
  //   MultiValue<{value: string; label: string}>
  // >([])
  // const [selectedServiceTypes, setSelectedServiceTypes] = useState<
  //   MultiValue<{value: string; label: string}>
  // >([])
  // const [selectedCommunicationTypes, setSelectedCommunicationTypes] = useState<
  //   MultiValue<{value: string; label: string}>
  // >([])
  const [data, setdata] = useState({
    accounts: [],
    langs: [],
    serviceTypes: [],
    communicationTypes: [],
  })
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const getCustomers = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_CUSTOMER_NAME}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getservicetypes = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_SERVICETYPES}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getcommunicationtypes = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_COMMUNICATIONTYPES}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getNativeLanguage = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    getNativeLanguage,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingServiceType, data: servicetypeList} = useQuery(
    `Query.serviceTypeList`,
    getservicetypes,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingcommunication, data: communicationTypeList} = useQuery(
    `Query.communicationTypeList`,
    getcommunicationtypes,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const languageOptions =
    nativeLanguageList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const serviceTypeOptions =
    servicetypeList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const communicationTypeOptions =
    communicationTypeList?.data
      ?.filter((item: any) => item.identification !== 'ON_DEMAND') // Filter out items with identification 'ON_DEMAND'
      .map((d: any) => ({
        value: d.key ?? '',
        label: d.value ?? '',
      })) ?? []

  const customerOptions =
    responseCustomer?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  // const handleSubmit = async (values: any) => {
  //   await fetchAppoinmentDetailsUsingDate(date, values)
  //   setShowDropdownMenu(false)
  //   setIsFilterApplied(true)
  // }

  // const handleReset = async () => {
  //   setdata({
  //     accounts: [],
  //     langs: [],
  //     serviceTypes: [],
  //     communicationTypes: [],
  //   })

  //   await fetchAppoinmentDetailsUsingDate(date, data)
  //   setShowDropdownMenu(false)
  //   setIsFilterApplied(false)
  // }
  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-350px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true)
                setdata(values)
                await fetchAppoinmentDetailsUsingDate(date, values)
                setShowDropdownMenu(false)
                setIsFilterApplied(true)
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='cardpx-4 mt-4'>
                    <div className='row flex-column'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                            Customer Name
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('accounts')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingCustomer ? customerOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    customerOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.accounts) &&
                                        (values.accounts as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'accounts',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='languages' className='form-label fs-7 mb-1'>
                            Languages
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('langs')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingNativeLanguage ? languageOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    languageOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.langs) &&
                                        (values.langs as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'langs',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                            Service Type
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('serviceTypes')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingServiceType ? serviceTypeOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    serviceTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.serviceTypes) &&
                                        (values.serviceTypes as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'serviceTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                            Communication Type
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('communicationTypes')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingcommunication ? communicationTypeOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    communicationTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.communicationTypes) &&
                                        (values.communicationTypes as string[]).includes(
                                          option.value
                                        )
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'communicationTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end pt-5'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={() => {
                        resetForm()
                        setdata({
                          accounts: [],
                          langs: [],
                          serviceTypes: [],
                          communicationTypes: [],
                        })
                        fetchAppoinmentDetailsUsingDate(date, {
                          accounts: [],
                          langs: [],
                          serviceTypes: [],
                          communicationTypes: [],
                        })
                        setShowDropdownMenu(false)
                        setIsFilterApplied(false)
                      }}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
