import React, {useState} from 'react'
import {InvoiceView} from './InvoicesView'
import {Overview} from '../../billing-management/components/Overview'
import {CurrencyView} from './CurrencyView'
import {ExpensesView} from './ExpensesView'
import {RateView} from './RateView'
import {Terms} from '../../billing-management/components/Terms'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'
import { InvoicesFormatView } from './InvoicesFormatView'

export function BillingConfig() {
  const [activeTab2, setActiveTab2] = useState(1)
  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab2(tabNumber)
  }
  return (
    <>
      <div className='mb-3 d-flex flex-column'>
        <div className='d-flex'>
          <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
            Billing Configuration
          </h1>
        </div>
        <div className='d-flex'>
          <p className='text-gray-500 fs-5 mb-2'>Manage Configuration</p>
        </div>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab2 === 1 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_config_1'
                onClick={() => handleTabClick(1)}
              >
                Invoice Format
              </a>
            </li>
            {/* <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab2 === 2 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_config_2'
                onClick={() => handleTabClick(2)}
              >
                Rates
              </a>
            </li> */}
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab2 === 3 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_config_3'
                onClick={() => handleTabClick(3)}
              >
                Currency
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab2 === 4 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_config_4'
                onClick={() => handleTabClick(4)}
              >
                Expenses
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab2 === 5 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_config_5'
                onClick={() => handleTabClick(5)}
              >
                Terms
              </a>
            </li>
          </ul>
        </div>
        <div className=''>
          <div className=''>
            <div className='tab-content' id='myTabContent1'>
              {activeTab2 === 1 && (
                <div
                  className='tab-pane fade show active'
                  id='kt_tab_pane_config_1'
                  role='tabpanel'
                >
                  <div className='card-body '>
                    {' '}
                    <InvoicesFormatView />
                  </div>
                </div>
              )}
              {/* {activeTab2 === 2 && (
                <div
                  className='tab-pane fade show active'
                  id='kt_tab_pane_config_2'
                  role='tabpanel'
                >
                  <div className='card-body '>
                    {' '}
                    <RateView />
                  </div>
                </div>
              )} */}
              {activeTab2 === 3 && (
                <div
                  className='tab-pane fade show active'
                  id='kt_tab_pane_config_3'
                  role='tabpanel'
                >
                  <div className='card-body '>
                    {' '}
                    <CurrencyView />
                  </div>
                </div>
              )}
              {activeTab2 === 4 && (
                <div
                  className='tab-pane fade show active'
                  id='kt_tab_pane_config_4'
                  role='tabpanel'
                >
                  <div className='card-body '>
                    {' '}
                    <ExpensesView />
                  </div>
                </div>
              )}
              {activeTab2 === 5 && (
                <div
                  className='tab-pane fade show active'
                  id='kt_tab_pane_config_5'
                  role='tabpanel'
                >
                  <div className='card-body '>
                    <Terms />
                  </div>
                </div>
              )}
            </div>
          </div>
          <img
            className='w-100 card-rounded-bottom'
            alt=''
            src='assetsmedia/svg/illustrations/bg-4.svg'
          />
        </div>
      </div>
    </>
  )
}
