/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { AppointmentViewTableFilterDropdown } from './AppointmentViewTableFilterDropdown '
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios from 'axios'
import { CommonPaginationModel } from '../../../../Utils/commonPagination'
import moment from 'moment'
import { CommonLoading } from '../../../../Utils/commonLoading'
import { useAuth } from '../../../modules/auth'
import { getTotalAppointments, statusMapping } from '../../../../Utils/commonData'
import toaster from '../../../../Utils/toaster'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

type Props = {
  className: string
}

const AppointmentViewTable: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  const [allAppointments, setAllAppointments] = useState<any[]>([])
  const [appointmentStatistics, setAppointmentStatistics] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const { currentUser } = useAuth()
  const [filterData, setFilterData] = useState({})
  const [isLoadingAccept, setIsLoadingAccept] = useState<boolean>(false)

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)
  const [searchQuery, setSearchQuery] = useState<any>({
    search: '',
    id: 0,
  })

  useEffect(() => {
    fetchAllAppointments(filterData, searchQuery)
  }, [currentPage, rowsPerPage, searchQuery, filterData, searchQuery])

  const fetchAllAppointments = async (filterData: any, searchQuery: any) => {
    setIsLoading(true)
    try {
      let response = await axios.post(
        `${API_URL}/Appoinment/filter/${currentUser?.result.userType}/${currentUser?.result.code}`,
        filterData,
        {
          params: {
            page: currentPage,
            items_per_page: rowsPerPage,
            ...searchQuery,
          },
        }
      )
      const { data, payload, statics } = response.data
      setAllAppointments(data)
      setAppointmentStatistics(statics)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchQuery({
        ...searchQuery,
        search: inputRef.current?.value || '',
      })
    }
  }

  const handleAcceptReject = async (status: number, id: number, userID: string) => {
    setIsLoadingAccept(true)
    try {
      let response = await axios.put(
        `${API_URL}/Appoinment/interpreter-status/${status}/${id}/${userID}`
      )
      if (response.data.status === 'S') {
        toaster('success', 'Successfuly updated')
        fetchAllAppointments(filterData, searchQuery)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingAccept(false)
    }
  }

  function getBorderColor(status: number, userType: string | undefined) {
    if (userType === 'SYSTEM' || userType === 'INTERPRETER') {
      if (status === 0) {
        return 'border-danger'
      }
      if (status === 1) {
        return 'border-primary'
      }
      if (status === 2) {
        return 'border-success'
      }
      if (status === 3) {
        return 'border-warning'
      }
      if (status === 4) {
        return 'border-info'
      }
      if (status === 5) {
        return 'border-dark'
      }
      if (status === 6) {
        return 'border-muted'
      }
    } else {
      if (userType === 'CONSUMER') {
        if (status === 0 || status === 1) {
          return 'border-danger'
        }
        if (status === 2) {
          return 'border-success'
        }
        if (status === 3) {
          return 'border-warning'
        }
        if (status === 4) {
          return 'border-info'
        }
        if (status === 5) {
          return 'border-dark'
        }
        if (status === 6) {
          return 'border-muted'
        }
      }
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header d-flex align-items-center px-0'>
        <div className='card-title'>Appointments</div>
        <div className='card-toolbar d-flex align-items-end'>
          <div className='d-flex'>
            <div className='d-flex align-items-end'>
              <div className='d-flex align-items-center position-relative'>
                <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                  <input
                    ref={inputRef}
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                    placeholder='Search'
                    onKeyDown={handleKeyDown}
                    onChange={() => {
                      if (inputRef.current?.value === '') {
                        fetchAllAppointments(filterData, searchQuery)
                      }
                    }}
                  />
                  <button
                    type='button'
                    className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                    onClick={() => {
                      setSearchQuery({
                        ...searchQuery,
                        search: inputRef.current?.value || '',
                      })
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                  </button>
                </div>
              </div>
            </div>
            <div className='d-flex text-end'>
              <AppointmentViewTableFilterDropdown
                setAllAppointments={setAllAppointments}
                setTotalPages={setTotalPages}
                setCurrentPage={setCurrentPage}
                setFilterData={setFilterData}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='mb-2 d-flex justify-content-between align-items-end flex-wrap mt-3'>
        <div className='d-flex flex-row'>
          <div className='d-flex align-items-center px-3 py-2 bg-light-dark me-2 rounded mb-1 text-dark'>
            <span className='fw-bold fs-5 me-2'>
             {/* {getTotalAppointments(currentUser?.result.userType, appointmentStatistics) ?? 0} */}
             {appointmentStatistics?.total ?? 0}
            </span>
            <span className='fw-noraml fs-7'>Total</span>
          </div>
        </div>

        <div className='d-flex flex-row flex-wrap'>
          {currentUser?.result.userType === 'CONSUMER' ? (
            <div className='d-flex align-items-center  px-3 py-2 bg-light me-2 rounded mb-1'>
              <span className='badge badge-circle badge-danger me-2 '>
                {(appointmentStatistics?.open ?? 0) +
                  (appointmentStatistics?.readyToAssign ?? 0)}
              </span>
              <span className='fw-semibolder fs-7'>Open</span>
            </div>
          ) : (
            <div className='d-flex align-items-center  px-3 py-2 bg-light me-2 rounded mb-1'>
              <span className='badge badge-circle badge-danger me-2 '>
                {appointmentStatistics?.open ?? 0}
              </span>
              <span className='fw-semibolder fs-7'>Open</span>
            </div>
          )}

          {currentUser?.result.userType === 'INTERPRETER' && (
            <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
              <span className='badge badge-circle badge-success me-2 '>
                {appointmentStatistics?.readyToAssign ?? 0}
              </span>
              <span className='fw-semibolder fs-7'>Accepted</span>
            </div>
          )}

          {currentUser?.result.userType === 'SYSTEM' && (
            <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
              <span className='badge badge-circle badge-primary me-2 '>
                {appointmentStatistics?.readyToAssign ?? 0}
              </span>
              <span className='fw-semibolder fs-7'>Ready to Assign</span>
            </div>
          )}

          <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
            <span className='badge badge-circle badge-success me-2 '>
              {appointmentStatistics?.confirmed ?? 0}
            </span>
            <span className='fw-semibolder fs-7'>Confirmed</span>
          </div>

          {(currentUser?.result.userType === 'SYSTEM' ||
            currentUser?.result.userType === 'CONSUMER') && (
              <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
                <span className='badge badge-circle badge-warning me-2 '>
                  {appointmentStatistics?.noInterpreter ?? 0}
                </span>
                <span className='fw-semibolder fs-7'>No Interpreters</span>
              </div>
            )}

          <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
            <span className='badge badge-circle badge-info me-2 '>
              {appointmentStatistics?.abandoned ?? 0}
            </span>
            <span className='fw-semibolder fs-7'>Abandoned</span>
          </div>

          <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
            <span className='badge badge-circle badge-dark me-2 '>
              {appointmentStatistics?.completed ?? 0}
            </span>
            <span className='fw-semibolder fs-7'>Complete</span>
          </div>

          <div className='d-flex align-items-center px-3 py-2 bg-light  rounded mb-1'>
            <span className='badge badge-circle badge-secondary me-2 '>
              {appointmentStatistics?.cancelled ?? 0}
            </span>
            <span className='fw-semibolder fs-7'>Cancelled</span>
          </div>
        </div>
      </div>

      {/* begin::Body */}
      <div className='py-0 pt-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-semibold text-muted text-uppercase'>
                {/* <th className='min-w-150px '>Appointment ID</th> */}
                <th className='min-w-250px '>Customer Name</th>
                <th className='min-w-150px '>Date</th>
                <th className='min-w-150px '>Time</th>
                <th className='min-w-150px '>Language</th>
                <th className='min-w-150px '>Service Type</th>
                <th className='min-w-200px'>Communication Type</th>
                {currentUser?.result?.userType === 'INTERPRETER' && (
                  <th className='min-w-100px'>Status</th>
                )}
                {currentUser?.result?.userType === 'INTERPRETER' && (
                  <th className='min-w-100px text-end'>Action</th>
                )}
                {/* <th className='min-w-100px text-end'>Action</th> */}
              </tr>
            </thead>

            <tbody>
              {allAppointments?.length > 0 ? (
                allAppointments.map((item: any, index: number) => (
                  <tr style={{ cursor: 'pointer' }}>
                    <td
                      className={`border-start border-5 ${getBorderColor(
                        item.status,
                        currentUser?.result.userType
                      )}`}
                      onClick={() => navigate(`/appointmentViewer/${item?.code}`)}
                    >
                      <span className='text-gray-800 text-hover-primary fs-6'>
                        {item?.customerName}
                      </span>
                    </td>
                    <td onClick={() => navigate(`/appointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6'>
                        {moment(item?.startTime).format('YYYY-MM-DD')}
                      </span>
                    </td>
                    <td onClick={() => navigate(`/appointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6'>
                        {moment(item.startTime).format('h:mm A')}-{' '}
                        {moment(item.endTime).format('h:mm A')}
                      </span>
                    </td>
                    <td onClick={() => navigate(`/appointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6'>
                        {item?.languageFrom ?? '-'} to {item?.languageTo ?? '-'}
                      </span>
                    </td>
                    <td onClick={() => navigate(`/appointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6'>
                        {item?.serviceType}
                      </span>
                    </td>
                    <td onClick={() => navigate(`/appointmentViewer/${item?.code}`)}>
                      <span className='text-gray-800 text-hover-primary fs-6 '>
                        {item?.communicationType}
                      </span>
                    </td>
                    {currentUser?.result?.userType === 'INTERPRETER' && (
                      <td>{statusMapping[item?.assignStatus]}</td>
                    )}
                    {currentUser?.result?.userType === 'INTERPRETER' && 
                    (item?.status === 0 || item?.status === 1 ) &&
                    item?.assignStatus === 0 && (
                      <td className='text-end'>
                        <div className='d-flex justify-content-end'>
                          <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='tooltip-filter'>Accept</Tooltip>}
                          >
                            <div className='d-flex justify-content-start flex-shrink-0 me-2'>
                              <button
                                className='btn btn-success btn-sm'
                                disabled={isLoadingAccept}
                                onClick={() =>
                                  handleAcceptReject(1, item.code, currentUser?.result?.code)
                                }
                              >
                                <i className='bi bi-check-lg'></i>
                              </button>
                            </div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='tooltip-filter'>Decline</Tooltip>}
                          >
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <button
                                className='btn btn-danger btn-sm'
                                disabled={isLoadingAccept}
                                onClick={() =>
                                  handleAcceptReject(2, item.code, currentUser?.result?.code)
                                }
                              >
                                <i className='bi bi-x-lg'></i>
                              </button>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                      <div className='text-center'>
                        <div className='symbol symbol-200px '>
                          <img src='/media/other/nodata.png' alt='' />
                        </div>
                      </div>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                        No matching records found
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CommonPaginationModel
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          total={totalItems}
          onPageChange={setCurrentPage}
          onRowsPerPageChange={(newRowsPerPage: number) => setRowsPerPage(newRowsPerPage)}
        />
      </div>
      {isLoading && <CommonLoading />}
    </div>
  )
}

export { AppointmentViewTable }
