import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {QUERIES, isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {initial, Model, ResponseObject} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {ListLoading} from '../components/loading/ListLoading'
import {createDataRecord, updateDataRecord} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useQuery} from 'react-query'
import {Value} from 'sass'
import toaster from '../../../../../Utils/toaster'
import Select from 'react-select'
const docType = [
  {value: 'option 1', label: 'T1'},
  {value: 'option 2', label: 'T2'},
]
type Props = {
  isObjLoading: boolean
  dbObj: Model
  userID: string
}

const editObjSchema = Yup.object().shape({
  name: Yup.string()
    //.matches(/^[a-zA-Z\s-]*$/, 'Invalid Currency Code.')
    .required('Required'),
  description: Yup.string()
    //.matches(/^[a-zA-Z\s-]*$/, 'Invalid Description.')
    .required('Required'),
  file: Yup.mixed().required('File is required'),
  acquisition_date: Yup.date(),
  expiration_date: Yup.date().min(
    Yup.ref('acquisition_date'),
    'expiration date cannot be before acquisition date'
  ),
})

const EditModalForm: FC<Props> = ({dbObj, isObjLoading, userID}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [result, setResult] = useState<ResponseObject>({})
  const [selectedFile, setSelectedFile] = useState()

  const [dbObjForEdit] = useState<Model>({
    ...dbObj,
    code: dbObj.code || initial.code,
    //value: dbObj.value || initial.value,
    //isDelete: (dbObj.isDelete && initial.isDelete) || (initial.isDelete && dbObj.isDelete == null),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: dbObjForEdit,
    validationSchema: editObjSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const formData = new FormData()
        formData.append('name', values?.name ?? '')
        formData.append('description', values?.description ?? '')
        formData.append('file', selectedFile ?? '')
        formData.append('DateOfAcquisition', values?.acquisition_date ?? '')
        formData.append('DateOfExpiration', values?.expiration_date ?? '')

        // formData.append('ProfileImage', values?.profileImage ?? "");
        //formData.append('lastName', values.lastName ?? "");

        //formData.append('profileImageFile', selectedFile ?? "");
        //  formData.append('ProfileImage', values?.profileImage ?? "");
        //  formData.append('code', values.code + "");

        var result2 = await createDataRecord(formData, userID)
        setResult({status: result2?.status, text: result2?.text})
        console.log(JSON.stringify(result2))
        if (result2?.status == 'S') {
          setSubmitting(true)
          toaster('success', result2?.text ?? 'Successfully Inserted')
          cancel(true)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        //setSubmitting(true)
        //cancel(true)
      }
    },
  })

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
    formik.setFieldValue('file', e.currentTarget.files[0])
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className=''>
            <div className='row g-4'>
              <div className='col-sm-12 col-md-12 col-lg-6 '>
                <div className=''>
                  <label className='form-label required fs-7'>Name</label>
                  <input
                    placeholder='Name'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx(
                      'form-control form-control-white form-select-sm mb-2 custom-input-height',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='on'
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className='mb-2'>
                  <label htmlFor='exampleFormControlInput1' className='required form-label fs-7'>
                    Document Type
                  </label>
                  <div className='w-100'>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={docType}
                      placeholder='Select Document Type'
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#a4afc4',
                        }),
                      }}
                    />
                  </div>
                </div> */}

                <div className='mb-2'>
                  <label className='form-label fs-7'>Description</label>
                  <input
                    placeholder='Description'
                    {...formik.getFieldProps('description')}
                    type='text'
                    name='description'
                    className={clsx(
                      'form-control form-control-white form-select-sm custom-input-height',
                      {'is-invalid': formik.touched.description && formik.errors.description},
                      {
                        'is-valid': formik.touched.description && !formik.errors.description,
                      }
                    )}
                    autoComplete='on'
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='mb-2'>
                  <label htmlFor='exampleFormControlInput1' className='form-label required fs-7'>
                    File Upload
                  </label>
                  <input
                    name='file'
                    className='form-control form-control-white form-select-sm custom-input-height'
                    type='file'
                    onChange={(e) => onSelectFile(e)}
                  />
                  {formik.touched.file && formik.errors.file && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.file}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-6 '>
                <div className='mb-2'>
                  <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                    Date of Acquisition
                  </label>
                  <input
                    className='form-control form-control-white form-select-sm custom-input-height'
                    type='date'
                    {...formik.getFieldProps('acquisition_date')}
                    name='acquisition_date'
                    id='acquisition_date'
                  />
                </div>
                <div className='mb-2'>
                  <label htmlFor='exampleFormControlInput1' className='form-label fs-7'>
                    Date of Expiration
                  </label>
                  <input
                    className='form-control form-control-white form-select-sm custom-input-height'
                    type='date'
                    {...formik.getFieldProps('expiration_date')}
                    name='expiration_date'
                    id='expiration_date'
                  />
                  {formik.touched.expiration_date && formik.errors.expiration_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.expiration_date}</span>
                      </div>
                    </div>
                  )}
                </div>
               
              </div>
            </div>
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {result?.status == 'E' && (
          <>
            <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>Error!</h4>
                  <div className='fs-6 text-gray-600'>{result?.text}</div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* begin::Actions */}
        <div className='text-end pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light btn-sm me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isObjLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary btn-sm'
            data-kt-users-modal-action='submit'
            disabled={isObjLoading || formik.isSubmitting || !formik.isValid}
          >
            <span className='indicator-label'>Upload Document</span>
            {(formik.isSubmitting || isObjLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isObjLoading) && <ListLoading />}
    </>
  )
}

export {EditModalForm}
