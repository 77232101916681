import {useListView} from '../../core/ListViewProvider'
import {ListToolbar} from './ListToolbar'
import {ListGrouping} from './ListGrouping'
import { ListSearchComponent } from './ListSearchComponent'
import UnderDevelopmentBadge from '../../../../../common/componenets/underDevelopment/underDevelopmentBadge'


type Props = {
  userType?: string
}

const ListHeader: React.FC<Props> = ({userType}) => {
  const {selected} = useListView()
  return (
    <>
      
      <div className='card-header align-items-center px-0'>
        <div className='card-title'>
          <div className='d-flex flex-column flex-wrap'>
            <h5 className='text-black fs-3 fw-bold mb-0'>Documents</h5>
            <p className='text-gray-500 mb-0 fs-8'>Add/Upload & Delete Documents</p>
          </div>
        </div>
        {userType == 'USER' && (
          <>
            <div className='card-toolbar'>
              <ListSearchComponent />
              {/* begin::Group actions */}
              {selected.length > 0 ? <ListGrouping /> : <ListToolbar />}
              {/* end::Group actions */}
            </div>
          </>
        )}
        {/* begin::Card toolbar */}
        {/* end::Card toolbar */}
      </div>
    </>
  )
}

export {ListHeader}
