/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {CardsWidget20} from '../../../_metronic/partials/widgets'
import {OverviewFilterDropdown} from './OverviewFilterDropdown'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import '../dashboard/Logs/style.css'
import {AvarageOverviewChart} from './OverviewCharts/AvarageOverviewChart'
import {OverviewChart} from './OverviewCharts/OverviewChart'
import axios from 'axios'
import moment from 'moment'
import {CommonLoading} from '../../../Utils/commonLoading'
import {useNavigate} from 'react-router-dom'
import {OverviewSmallCards} from './OverviewCharts/OverviewSmallCards'
import {formatDuration} from '../../../Utils/commonData'
import UnderDevelopmentBadge from '../../common/componenets/underDevelopment/underDevelopmentBadge'
import {useAuth} from '../../modules/auth'
import {OverviewSmallCardsSecond} from './OverviewCharts/OverviewSmallCardsSecond'
import toaster from '../../../Utils/toaster'
import {overViewStatus} from '../../../Utils/overViewStatus'

const API_URL = process.env.REACT_APP_API_URL

export function DashboardOverview() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingOnDemand, setIsLoadingOnDemand] = useState(false)
  const [isLoadingTotalEarnings, setIsLoadingTotalEarnings] = useState(false)
  const [isLoadingCompleteAppointments, setIsLoadingCompleteAppointments] = useState(false)
  const [requiredAction, setRequiredAction] = useState<any>({})
  const [showNewUI, setShowNewUI] = useState(true)
  const [showConfirmInterUI, setShowConfirmInterUI] = useState(false)
  const [onDemardData, setOnDemardData] = useState<any>([])
  const [aggregateRateData, setAggregateRateData] = useState<any>([])
  const [completedAppointments, setCompletedAppointments] = useState<any>([])
  const [totalEarningsData, setTotalEarningsData] = useState<any>([])
  const [filterBy, setFilterBy] = useState<any>(5)
  const [selectedlabel, setselectedlabel] = useState('current month')
  const [filterCommunicationTypes, setFilterCommunicationTypes] = useState<any>(null)
  const [lifeTimeData, setLifeTimeData] = useState<any>({})
  const {currentUser} = useAuth()
  

  function handleNewClick() {
    setShowNewUI(true)
    setShowConfirmInterUI(false)
  }

  function handleConfirmInterClick() {
    setShowNewUI(false)
    setShowConfirmInterUI(true)
  }

  useEffect(() => {
    fetchDashBoardData(filterBy, filterCommunicationTypes)
  }, [])

  const fetchDashBoardData = async (filter: number, communicationTypes: any) => {
    setIsLoading(true)
    setIsLoadingOnDemand(true)
    setIsLoadingTotalEarnings(true)
    setIsLoadingCompleteAppointments(true)
    try {
      let response1 = await axios.get(`${API_URL}/Appoinment/dashboard-overview/require-action`)
      setRequiredAction(response1.data)
      let response = await axios.post(`${API_URL}/Appoinment/dashboard-overview/on-demand`, {
        filter: filter,
        communicationTypes: communicationTypes
          ? communicationTypes?.map((x: any) => x.value)
          : null,
      })
      setLifeTimeData(response?.data)
      setIsLoading(false)

      let ondemandChart = await axios.post(`${API_URL}/Appoinment/dashboard-overview/on-demand-graph`, {
        filter: filter,
        communicationTypes: communicationTypes
          ? communicationTypes?.map((x: any) => x.value)
          : null,
      })
      setOnDemardData(ondemandChart?.data?.onDemand)
      setIsLoadingOnDemand(false)

      let completedAppointmentsChart = await axios.post(`${API_URL}/Appoinment/dashboard-overview/completed-appointments`, {
        filter: filter,
        communicationTypes: communicationTypes
          ? communicationTypes?.map((x: any) => x.value)
          : null,
      })
      setCompletedAppointments(completedAppointmentsChart?.data?.completedAppointments)
      setIsLoadingCompleteAppointments(false)

      let totalEarningsChart = await axios.post(`${API_URL}/Appoinment/dashboard-overview/total-earnings`, {
        filter: filter,
        communicationTypes: communicationTypes
          ? communicationTypes?.map((x: any) => x.value)
          : null,
      })
      setTotalEarningsData(totalEarningsChart?.data?.onDemand)
      setIsLoadingTotalEarnings(false)


      // setAggregateRateData(response?.data?.aggregateRating)
    } catch (error) {
      toaster('error', 'Loading failed!')
      console.log(error)
    } finally {
      setIsLoading(false)
      setIsLoadingOnDemand(false)
      setIsLoadingCompleteAppointments(false)
      setIsLoadingTotalEarnings(false)
    }
  }

  return (
    <>
      <div className='row g-4'>
        <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 bg-light py-3 px-4 rounded'>
          <div className='d-flex justify-content-between flex-wrap align-items-center mb-4'>
            <div className='d-flex'>
              <h4 className='mb-0 text-gray-500 fs-6 fw-semibold mt-1' style={{lineHeight: '30px'}}>
                {' '}
                Appointments that require action
              </h4>
            </div>
          </div>

          <div className='row g-0'>
            <div className='col-md-6 col-sm-12 d-flex flex-grow-1'>
              <button
                type='button'
                onClick={handleNewClick}
                className={`btn btn-outline text-muted btn-outline-dotted btn-sm me-3 text-active-white w-100 ${
                  showNewUI ? 'active fw-bold border border-2 bg-primary' : ''
                }`}
              >
                <div className='d-flex flex-column justify-content-center align-items-center pt-2'>
                  <span className='badge badge-circle badge-light'>
                    {requiredAction?.newCount ?? 0}
                  </span>
                  <div className='d-flex t1'>New Appointments</div>
                </div>
              </button>
            </div>
            {currentUser?.result?.userType !== 'CONSUMER' && (
              <div className='col-md-6 col-sm-12 d-flex flex-grow-1'>
                <button
                  type='button'
                  onClick={handleConfirmInterClick}
                  className={`btn btn-outline text-muted btn-outline-dotted btn-sm text-active-white w-100 ${
                    showConfirmInterUI ? 'active fw-bold border border-2 bg-primary' : ''
                  }`}
                >
                  <div className='d-flex flex-column justify-content-center align-items-center pt-2'>
                    <span className='badge badge-circle badge-light'>
                      {requiredAction?.confirmedCount ?? 0}
                    </span>
                    {currentUser?.result?.userType === 'INTERPRETER' ? (
                      <div className='d-flex t1'>Accepted Appointments</div>
                    ) : (
                      <div className='d-flex t1'>Confirmed Appointments</div>
                    )}
                  </div>
                </button>
              </div>
            )}
          </div>
          {showNewUI && (
            <div className='flex-column' style={{maxHeight: 'auto', overflow: 'auto'}}>
              {requiredAction?.newAppoitments?.map((item: any, index: number) => (
                <a href='#' className='light-hover'>
                  <div
                    className='d-flex justify-content-between py-3 mt-3 flex-wrap light-hover'
                    onClick={() => navigate(`/appointmentViewer/${item?.id}`)}
                  >
                    <div
                      className='d-flex flex-column text-nowrap'
                      style={{
                        textOverflow: 'ellipsis',
                        width: '10px',
                        overflow: 'hidden',
                        flexGrow: '1',
                      }}
                    >
                      <h5 className='text-gray-700 fs-7'>{item?.customer ?? '-'}</h5>
                      <span className='text-gray-400 fs-8'>
                        {moment(item.startTime).format('DD/MM/YYYY h:mm A')}
                        {' - '}
                        {moment(item.endTime).format('DD/MM/YYYY hh:mm A')}
                      </span>
                    </div>
                    {currentUser?.result?.userType === 'SYSTEM' && (
                      <div className='d-flex align-items-start'>
                        <span
                          className='badge fs-9'
                          style={{
                            backgroundColor: overViewStatus(item?.statusName).color,
                          }}
                        >
                          {overViewStatus(item?.statusName).label}
                        </span>
                      </div>
                    )}
                    {currentUser?.result?.userType === 'INTERPRETER' && (
                      <div className='d-flex align-items-start'>
                        <span
                          className='badge fs-9'
                          style={{
                            backgroundColor: overViewStatus(item?.assignStatusName).color,
                          }}
                        >
                          {overViewStatus(item?.assignStatusName).label}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className='separator separator-dashed border-gray-300 my-3'></div>
                </a>
              ))}
              {requiredAction?.newAppoitments?.length === 0 && (
                <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                  <div className='text-center'>
                    <div className='symbol symbol-200px '>
                      <img src='/media/other/nodata.png' alt='' />
                    </div>
                  </div>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                    No matching records found
                  </div>
                </div>
              )}
            </div>
          )}
          {showConfirmInterUI && (
            <div className='flex-column' style={{maxHeight: 'auto', overflow: 'auto'}}>
              {requiredAction?.confirmedAppointments?.map((item: any, index: number) => (
                <a href='#' className='light-hover bg-light'>
                  <div
                    className='d-flex justify-content-between py-3 mt-3 flex-wrap light-hover'
                    onClick={() => navigate(`/appointmentViewer/${item?.id}`)}
                  >
                    <div
                      className='d-flex flex-column text-nowrap'
                      style={{
                        textOverflow: 'ellipsis',
                        width: '10px',
                        overflow: 'hidden',
                        flexGrow: '1',
                      }}
                    >
                      <h5 className='text-gray-700 fs-7'>{item?.customer ?? '-'}</h5>
                      <span className='text-gray-400 fs-8'>
                        {moment(item.startTime).format('DD/MM/YYYY h:mm A')}
                        {' - '}
                        {moment(item.endTime).format('DD/MM/YYYY hh:mm A')}
                      </span>
                    </div>

                    {currentUser?.result?.userType === 'SYSTEM' && (
                      <div className='d-flex align-items-start'>
                        <span
                          className='badge fs-9'
                          style={{
                            backgroundColor: overViewStatus(item?.statusName).color,
                          }}
                        >
                          {overViewStatus(item?.statusName).label}
                        </span>
                      </div>
                    )}
                    {currentUser?.result?.userType === 'INTERPRETER' && (
                      <div className='d-flex align-items-start'>
                        <span
                          className='badge fs-9'
                          style={{
                            backgroundColor: overViewStatus(item?.assignStatusName).color,
                          }}
                        >
                          {overViewStatus(item?.assignStatusName).label}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className='separator separator-dashed border-gray-300 my-3'></div>
                </a>
              ))}
              {requiredAction?.confirmedAppointments?.length === 0 && (
                <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                  <div className='text-center'>
                    <div className='symbol symbol-200px '>
                      <img src='/media/other/nodata.png' alt='' />
                    </div>
                  </div>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                    No matching records found
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='col-xl-9 col-lg-8 col-md-6 col-sm-12'>
          <div className='row g-4'>
            <div className='d-flex justify-content-between flex-wrap align-items-center mb-4'>
              <div className='d-flex '>
                <h4 className='text-center text-gray-500 fs-6 fw-semibold mb-0'>Overview</h4>
              </div>
              <div className='d-flex flex-wrap'>
                <div className=''>
                  <OverviewFilterDropdown
                    setFilterBy={setFilterBy}
                    setFilterCommunicationTypes={setFilterCommunicationTypes}
                    filterBy={filterBy}
                    filterCommunicationTypes={filterCommunicationTypes}
                    fetchDashBoardData={fetchDashBoardData}
                    setselectedlabel={setselectedlabel}
                  />
                </div>
              </div>
            </div>

            <div className='col-xl-3 col-lg-6  col-md-12 col-sm-12 mt-0'>
              <OverviewSmallCardsSecond
                count={Math.ceil(lifeTimeData?.onDemandCallCount ?? 0)}
                totalMinutes={Math.ceil(Math.ceil(lifeTimeData?.onDemandCallsInSeconds ?? 0) / 60)}
                className1=''
                className2='text-gray-800'
                className3='text-dark'
                className='text-info'
                description='On-demand Calls '
                url='bi bi-telephone-fill'
              />
            </div>

            <div className='col-xl-3 col-lg-6  col-md-12 col-sm-12 mt-0'>
              <OverviewSmallCardsSecond
                count={Math.ceil(lifeTimeData?.scheduleCallCount ?? 0)}
                totalMinutes={Math.ceil(Math.ceil(lifeTimeData?.scheduledCallsInSeconds ?? 0) / 60)}
                className1=''
                className2='text-gray-800'
                className3='text-dark'
                className='text-primary'
                description='Scheduled Calls '
                url='bi bi-telephone-fill'
              />
            </div>

            <div className='col-xl-3 col-lg-6  col-md-12 col-sm-12 mt-0 position-relative'>
              <OverviewSmallCards
                className1=''
                className2='text-gray-800'
                className3='text-dark'
                number={Math.ceil(lifeTimeData?.completedAppointmentCount ?? 0)}
                subnumber={selectedlabel}
                className='text-success'
                description='Completed Appointments '
                url='bi bi-check-circle-fill'
              />
            </div>

            <div
              className='col-xl-3 col-lg-6  col-md-12 col-sm-12 mt-0 position-relative'
              onClick={() => navigate(`/appointment/3`)}
              style={{cursor: 'pointer'}}
            >
              <div style={{position: 'absolute', top: -12, right: 0, zIndex: '9'}}></div>
              <OverviewSmallCards
                className1=''
                className2='text-gray-800'
                className3='text-dark'
                number={Math.ceil(lifeTimeData?.upcomingAppointmentsCount ?? 0)}
                subnumber={selectedlabel}
                className='text-success'
                description='Upcoming Appointments '
                url='bi bi-calendar-week'
              />
            </div>

            <div className='col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-0 '>
              <OverviewChart
                className=' shadow-custom mb-4'
                chartColor='danger'
                chartHeight='100px'
                title={'On Demand Calls'}
                onDemardData={onDemardData}
                label='Calls'
                loading = {isLoadingOnDemand}
              />
            </div>

            <div className='col-sm-12 col-xl-6 mt-2'>
              <AvarageOverviewChart
                className=' shadow-custom mb-4'
                chartColor='danger'
                chartHeight='100px'
                title={'Completed Appointments'}
                aggregateRateData={completedAppointments}
                label='Appointments'
                loading = {isLoadingCompleteAppointments}
              />
            </div>

            {/* <div className='col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-0 mb-2 position-relative'>
              <div style={{position: 'absolute', top: -12, right: 0, zIndex: '9'}}>
                <UnderDevelopmentBadge className={undefined} />
              </div>
              <AvarageOverviewChart
                className='shadow-custom mb-4'
                chartColor='danger'
                chartHeight='100px'
                title={'Aggregate Rating'}
                aggregateRateData={aggregateRateData}
                label='Rating'
              />
            </div> */}
            {process.env.REACT_APP_SERVER === 'DEV' && (
              <div>
                {currentUser?.result.userType === 'CONSUMER' ? null : (
                  <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-0 mb-2 position-relative'>
                    {/* <div style={{position: 'absolute', top: -12, right: 0, zIndex: '9'}}>
                  <UnderDevelopmentBadge className={undefined} />
                </div> */}

                    <OverviewChart
                      className='shadow-custom mb-4'
                      chartColor='primary'
                      chartHeight='100px'
                      title={'Total Earnings'}
                      loading ={isLoadingTotalEarnings}
                      onDemardData={totalEarningsData}
                      label='Earnings($)'
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isLoading && <CommonLoading />}
      </div>
    </>
  )
}

// const DashboardWrapper: FC = () => {
//   const intl = useIntl()
//   return (
//     <>
//       <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
//       <DashboardOverview />
//     </>
//   )
// }
