import axios from 'axios'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {KTSVG} from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

export function ConsumerLangugeTable() {
  const [langugeData, setLangugeData] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    fetchAllLangugeData(currentPage)
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    if (searchTerm.trim() === '') {
      handleSearch()
    }
  }, [searchTerm])

  const fetchAllLangugeData = async (page: number) => {
    setIsLoading(true)
    try {
      const response = await axios.get(`${API_URL}/accounts/requester-language`, {
        params: {
          page,
          items_per_page: rowsPerPage,
          search: searchTerm,
        },
      })
      const {payload} = response.data
      setLangugeData(response.data.data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearch = () => {
    setCurrentPage(1)
    fetchAllLangugeData(1)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }
  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title d-flex align-items-center position-relative me-4'>
            <div className='d-flex flex-column me-3'>
              <div className='d-flex align-items-center position-relative '>
                <h5 className='text-black fs-3 fw-bold '>Languages</h5>
              </div>
              <div className='d-flex flex-wrap fs-6'>
                <p className='text-gray-500 mb-0' style={{fontSize: '12px'}}>
                  Qualified Languages
                </p>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                  onClick={handleSearch}
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-4 mb-4 py-5 px-8'>
          <div className='tab-content' id='myTabContent'>
            {/* begin::Body */}
            <div className='py-0 pt-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-semibold  text-muted text-uppercase'>
                      <th className='min-w-250px text-start '>From Language</th>
                      <th className='min-w-300px  text-end'>To Language</th>
                    </tr>
                  </thead>

                  <tbody>
                    {langugeData?.length > 0 ? (
                      langugeData.map((item: any, index: number) => (
                        <tr key={index}>
                          <td className='text-start'>{item.languageFromName || '-'}</td>
                          <td className='text-end'>{item.languageToName || '-'}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10}>
                          <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                            <div className='text-center'>
                              <div className='symbol symbol-200px '>
                                <img src='/media/other/nodata.png' alt='' />
                              </div>
                            </div>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                              No matching records found
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <CommonPaginationModel
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={(newRowsPerPage: number) => setRowsPerPage(newRowsPerPage)}
          />
        </div>
      </div>
      {isLoading && <CommonLoading />}
    </>
  )
}
