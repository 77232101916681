// @ts-nocheck
import {Column} from 'react-table'
import {InfoCellName, InfoCellStatus, InfoCellDateTime,InfoCellAcquisitionDateTime,InfoCellExpirationDateTime} from './InfoCell'
import {LastLoginCell} from './LastLoginCell'
import {TwoStepsCell} from './TwoStepsCell'
import {ActionsCell} from './ActionsCell'
import {SelectionCell} from './SelectionCell'
import {CustomHeader} from './CustomHeader'
import {SelectionHeader} from './SelectionHeader'
import {Model} from '../../core/_models'

const objsColumns: ReadonlyArray<Column<Model>> = [
  /*{
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <SelectionCell id={props.data[props.row.index].code} />,
  },*/
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <InfoCellName dbObj={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => <CustomHeader tableProps={props} title='Description' className='min-w-125px' />,
  //   accessor: 'value',
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader tableProps={props} title='IsDeleted' className='min-w-125px' />),
  //   id: 'isdelete',
  //   Cell: ({ ...props }) => <InfoCellStatus dbObj={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader tableProps={props} title='Modified By' className='min-w-125px' />
  //   ),
  //   accessor: 'fK_ModifiedBy',
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader tableProps={props} title='Modified Time' className='min-w-125px' />
  //   ),
  //   id: 'lastModifiedDateTime',
  //   Cell: ({ ...props }) => <InfoCellDateTime dbObj={props.data[props.row.index]} />,
  // },
  {
       Header: (props) => (
         <CustomHeader tableProps={props} title='Date of Acquisition' className='min-w-125px' />
       ),
       id: 'dateOfAcquisition',
       Cell: ({ ...props }) => <InfoCellAcquisitionDateTime dbObj={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Date of Expiration' className='min-w-125px' />
    ),
    id: 'dateOfExpiration',
    Cell: ({ ...props }) => <InfoCellExpirationDateTime dbObj={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Action' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ActionsCell
        code={props.data[props.row.index].code}
        value={props.data[props.row.index].fileName}
      />
    ),
  },
]

export {objsColumns}
