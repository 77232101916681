import React from 'react'
import {Modal, Button, Form} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import toaster from '../../../Utils/toaster'

interface ConfirmationModalProps {
  show: boolean
  permissionError?: string
  list?: any
  updateData?: any
  handleClose: () => void
  refetchCsutomer: any
}

const API_URL = process.env.REACT_APP_API_URL

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  handleClose,
  updateData,
  list,
  permissionError,
  refetchCsutomer,
}) => {
  const formik = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema: Yup.object({
      note: Yup.string().required('required'),
    }),
    onSubmit: async (values) => {
      const permissionChange = {
        ...updateData,
        reason: values.note,
      }

      try {
        const response = await axios.put(`${API_URL}/accounts/permission-update`, permissionChange)
        if (response?.data?.status === 'S') {
          toaster('success', response?.data?.text ?? 'Record Updated')
          formik.resetForm()
          handleClose()
          await cancel(true)
        } else if (response?.data?.status === 'E') {
          toaster('error', response?.data?.text ?? 'Record Not Updated')
          await cancel(true)
        }
      } catch (error) {
        console.error('Error updating permissions:', error)
      }
    },
  })

  const cancel = async (withRefresh?: boolean) => {
    if (withRefresh) {
      await refetchCsutomer()
    }
    setItemIdForUpdate(undefined)
    formik.resetForm()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Please Confirm Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId='confirmationNote'>
            <p className='text-align-justify'>{permissionError}</p>
            <p className='mb-0'>
              Permission Type(s):
              <br />
            </p>
            {list &&
              list.map((item: any, index: any) => (
                <p key={index} className='text-danger mb-0'>
                  {item}
                </p>
              ))}

            <p className='mt-2'>Please confirm to remove permission</p>
            <Form.Label>Enter reason</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              name='note'
              value={formik.values.note}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.note && formik.touched.note}
            />
            <Form.Control.Feedback type='invalid'>{formik.errors.note}</Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
function refetchCsutomer() {
  throw new Error('Function not implemented.')
}

function setItemIdForUpdate(undefined: undefined) {
  throw new Error('Function not implemented.')
}
