import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {useState} from 'react'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function InvoicesFormatView() {
  const [selectedFormat, setSelectedFormat] = useState('0')

  const handleFormatChange = (event: any) => {
    setSelectedFormat(event.target.value)
  }
  return (
    <>
      <div className='card-body p-0'>
        <div className='row g-1'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='card-title d-flex align-items-center me-4 mb-3'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <h5 className='text-black fs-4 fw-semibold mb-0'>Invoice Numbers</h5>
                </div>
                <div className='d-flex flex-wrap fs-6 '>
                  <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                    System default invoice formats
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='py-0 pt-3'>
            <div className='row g-4 '>
              <div className='col-md-6 col-sm-12'>
                <div
                  className='card shadow-sm card-md-stretch'
                  style={{borderTop: '3px solid rgb(163, 162, 66)'}}
                >
                  <div className='card-body flex-column'>
                    <div className='d-flex justify-content-between flex-wrap'>
                      <div className='flex flex-column'>
                        <div>
                          {' '}
                          <img
                            className=''
                            alt=''
                            style={{width: '100px', height: '100px'}}
                            src='../media/Avatars/social-care.gif'
                          />
                        </div>
                        <div className='d-flex'>
                          <h2>Customer</h2>
                        </div>
                      </div>

                      <div className='d-flex align-items-center'>
                        <h1 className='text-gray-800 bg-light p-5 rounded'>INV-AC-AR-####</h1>
                      </div>
                    </div>

                    <div className='d-flex justify-content-between flex-wrap'></div>
                    <div className='text-gray-600 mt-2'>
                      (<strong className='text-gray-700'>"INV"</strong> stands for "Invoice",{' '}
                      <strong className='text-gray-700'>"AC" </strong> for "Ad-Astra Connect",{' '}
                      <strong className='text-gray-700'>"AR" </strong> for "Accounts Receivable",{' '}
                      <strong className='text-gray-700'>"#" </strong> for invoice numbers)
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div
                  className='card shadow-sm card-md-stretch'
                  style={{borderTop: '3px solid rgb(163, 162, 66)'}}
                >
                  <div className='card-body flex-column'>
                    <div className='d-flex justify-content-between flex-wrap'>
                      <div className='flex flex-column'>
                        <div>
                          {' '}
                          <img
                            className=''
                            alt=''
                            style={{width: '100px', height: '100px'}}
                            src='../media/Avatars/translator.gif'
                          />
                        </div>
                        <div className='d-flex'>
                          <h2>Interpreter</h2>
                        </div>
                      </div>

                      <div className='d-flex align-items-center'>
                        <h1 className='text-gray-800 bg-light p-5 rounded'>INV-AC-AP-####</h1>
                      </div>
                    </div>

                    <div className='d-flex justify-content-between flex-wrap'></div>
                    <div className='text-gray-600 mt-2'>
                      (<strong className='text-gray-700'>"INV"</strong> stands for "Invoice",{' '}
                      <strong className='text-gray-700'>"AC" </strong> for "Ad-Astra Connect",{' '}
                      <strong className='text-gray-700'>"AP" </strong> for "Accounts Payable",{' '}
                      <strong className='text-gray-700'>"#" </strong> for invoice numbers)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
