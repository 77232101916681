import React, {useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import toaster from '../../../../Utils/toaster'

export default function ImageComponent(props) {
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    props.hiddenFileInput.current.click()
  }

  return (
    <div className='col-sm-4 col-md-4 col-lg-4'>
      <div className='d-flex flex-center flex-column'>
        <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
          {props.profileImage && props.profileImage !== '' ? (
            props.preview && props.preview !== '' ? (
              <img src={props.preview} alt='user' className='rounded-circle cvf' />
            ) : (
              <img
                src={`${process.env.REACT_APP_IMG_URL}/${props.type}/${props.profileImage}`}
                alt='user'
                className='rounded-circle bbfg'
                style={{objectFit: 'cover'}}
              />
            )
          ) : props.preview && props.preview !== '' ? (
            <img
              src={props.preview}
              alt='user'
              className='rounded-circle nnn'
              style={{objectFit: 'cover'}}
            />
          ) : (
            <img
              src={toAbsoluteUrl('../media/avatars/blank.png')}
              alt='user'
              className='rounded-circle'
              style={{objectFit: 'cover'}}
            />
          )}
        </div>
        <div className='d-flex flex-center mt-5'>
          <input
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            type='file'
            ref={props.hiddenFileInput}
            style={{display: 'none'}}
            onChange={(e) => {
              const file = e.target.files[0]
              if (
                file.type != 'image/jpg' &&
                file.type != 'image/png' &&
                file.type != 'image/jpeg'
              ) {
                toaster('error', 'Please upload jpg , jpeg or png file')
                props.hiddenFileInput.current.value = null; // Clear the file input
                return
              } else {
                props.onSelectFile(e)
              }
            }}
          />
          <div className='d-flex flex-center'>
            <a
              className='btn btn-color-primary btn-active-primary btn-sm'
              onClick={(e) => handleClick(e)}
            >
              <i className='bi bi-cloud-arrow-up-fill'></i>
              Upload
            </a>
          </div>
          {/* <button
            type='button'
            className='btn btn-outline btn-outline-dashed btn-outline-default btn-sm text-active-primary active py-1'
          >
            Upload
          </button> */}
        </div>
      </div>
    </div>
  )
}
