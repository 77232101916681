import {type} from 'os'
import {ID, Response} from '../../../../../_metronic/helpers'
import {s} from '@fullcalendar/resource/internal-common'

// export type User = {
//   code?: ID
//   firstName?: string
//   avatar?: string
//   email?: string
//   position?: string
//   role?: string
//   lastLoginDate?: string
//   two_steps?: boolean
//   join_date?: string
//   online?: boolean
//   lastName?: string
//   address?: string
//   niC_no?: string
//   lockoutEnabled?: boolean
//   designation?: string
//   phoneNumber?: string
//   accountStatus?:string
//   fK_Country?:string
//   fK_Gender?:string
//   fK_Customer?:number
//   fK_Location?:string
//   city?:string
//   isConfirmed?:boolean
//   isNewPasswordGenerate?:boolean
//   userType?:string
//   initials?: {
//     label: string
//     state: string
//   }
// }
export type User = {
  code?: ID
  avatar?: string
  lastLoginDate?: string
  accountStatus?: string
  lockoutEnabled?: boolean
  join_date?: string
  firstName?: string
  lastName?: string
  email?: string
  role?: string
  phoneNumber?: string
  password?: null
  modifiedBy?: null
  modifiedDateTime?: string
  niC_no?: null
  address?: string
  designation?: null
  isdeleted?: boolean
  edit_By?: null
  delete_By?: null
  edit_Date?: string
  delete_Date?: null
  userType?: string
  fK_Customer?: string
  fK_Gender?: string
  position?: string
  contryName?: string
  customerName?: string
  officeLocation?: string
  fK_Location?: string
  fK_PreCallPolicy?: string
  fK_ServiceType?: string
  defaultTimeZone?: string
  fK_DefaultTimeZone?: string
  fK_DefaultNativeLanguage?: string
  city?: string
  street1?: string
  street2?: string
  state?: string
  postalCode?: string
  serviceType?: string
  defaultLanguage?: string
  profileImage?: string
  profileImageFile?: File
  isNewPasswordGenerate?: boolean
  isConfirmed?: boolean
  opI_ShdTelephonic?: boolean
  opI_OndemandTelephonic?: boolean

  vrI_ShdVideoInteroreting?: boolean
  vrI_OndemandVideoInteroreting?: boolean

  osI_OnsiteConsecutive?: boolean
  osI_OnsiteSimultaneous?: boolean
  osI_OnsiteWhisper?: boolean
  osI_Onsite?: boolean
  other_3rdPartyPlatform?: boolean
  pinCode?: number | string
  pinCodeString?: string
  latitude?: string
  longitude?: string
  country?: string
  serviceTypes?: any
}
export type CustomDictionaryModel = {
  key?: string
  value?: string
}

export type UserRole = {
  id?: string
  name?: string
  normalizedName?: string
  status: string
  userId?: string
  userName?: string
}

export type Deletemodel = {
  email?: string
  code?: string
}

export type UsersQueryResponse = Response<Array<User>>
export type RolesQueryResponse = Response<Array<UserRole>>
export type DropdownResponse = Response<Array<CustomDictionaryModel>>

export const initialUser: User = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: '',
  firstName: '',
  email: '',
  lastName: '',
  isNewPasswordGenerate: true,
  pinCode: undefined,
  address: '',
  serviceTypes: [],
  fK_Customer: '',
  userType : 'CONSUMER',
}
