/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Model} from '../../core/_models'
import moment from 'moment'

type Props = {
  dbObj: Model
}

const isRecentlyUpdated = (modifiedDateTime: string | undefined) => {
  if (!modifiedDateTime) {
    return false
  }

  const today = new Date()
  const modifiedDate = new Date(modifiedDateTime)

  return (
    today.getDate() === modifiedDate.getDate() &&
    today.getMonth() === modifiedDate.getMonth() &&
    today.getFullYear() === modifiedDate.getFullYear()
  )
}

const InfoCellName: FC<Props> = ({dbObj}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a
        href={process.env.REACT_APP_IMG_URL + '/files/' + dbObj.fileName}
        target='_blank'
        className='text-gray-900 text-hover-primary mb-1 fw-semibold fs-6'
      >
        {dbObj.name}
      </a>
      <span>
        {dbObj.description}{' '}
        {isRecentlyUpdated(dbObj.modifiedDateTime) && (
          <span className='badge badge-warning fs-9 px-3 py-2'>Recently Updated</span>
        )}
        {/* {!isRecentlyUpdated(dbObj.modifiedDateTime) && (
          <span className='fs-9 text-gray-600'> - {(dbObj.modifiedDateTime + '').split('T')[0]}</span>
        )} */}
      </span>
    </div>
  </div>
)

const InfoCellStatus: FC<Props> = ({dbObj}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>
        {/* {dbObj.isDelete == false && <span className='badge badge-light-success  fs-8'>Active</span>} */}
      </span>
      <span>
        {/* {dbObj.isDelete == true && <span className='badge badge-light-danger  fs-8'>Inactive</span>} */}
      </span>
    </div>
  </div>
)

const InfoCellDateTime: FC<Props> = ({dbObj}) => {
  const formattedDateTime = (dateTimeString: string | undefined) => {
    if (!dateTimeString) {
      return ''
    }

    const dateTime = new Date(dateTimeString)
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    }

    const formatter = new Intl.DateTimeFormat('en-US', options)
    const formattedDate = formatter.format(dateTime)

    return formattedDate.replace(/,/, ' -')
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{formattedDateTime(dbObj.modifiedDateTime)}</span>
      </div>
    </div>
  )
}

const InfoCellAcquisitionDateTime: FC<Props> = ({dbObj}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>
        {dbObj.dateOfAcquisition
          ? moment(dbObj.dateOfAcquisition).format('MM/DD/YYYY - hh:mm:ss A')
          : ''}
      </span>
    </div>
  </div>
)
const InfoCellExpirationDateTime: FC<Props> = ({dbObj}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>
        {dbObj.dateOfExpiration
          ? moment(dbObj.dateOfExpiration).format('MM/DD/YYYY - hh:mm:ss A')
          : ''}
      </span>
    </div>
  </div>
)

export {
  InfoCellName,
  InfoCellStatus,
  InfoCellDateTime,
  InfoCellAcquisitionDateTime,
  InfoCellExpirationDateTime,
}
