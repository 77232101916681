import React, {useState} from 'react'
import {NotificationConfig} from './NotificationConfig'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'
import {MainConfig} from './MainConfig'

export function EmailConfig() {
  const [activeTab, setActiveTab] = useState(1)
  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber)
  }
  return (
    <>
      <div className='mb-3 d-flex flex-column'>
        <div className='d-flex'>
          <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
            Email Configuration
          </h1>
        </div>
        <div className='d-flex'>
          <p className='text-gray-500 fs-5 mb-2'>Manage Configuration</p>
        </div>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400   ${
                  activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_1'
                onClick={() => handleTabClick(1)}
              >
                Notification Configuration
              </a>
            </li>
          </ul>
        </div>

        <div className=''>
          <div className='tab-content' id='myTabContent'>
            {activeTab === 1 && (
              <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                <div className='card-body h-100vh'>
                  <NotificationConfig />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
