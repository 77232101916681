import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import LocationMap from './LocationMap'
import {useAuth} from '../../../../app/modules/auth'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'

type Props = {
  className: string
}

const CompanyView: React.FC<Props> = ({className}) => {
  const [showAdditionalUI, setShowAdditionalUI] = useState(false)
  const [showInfoUI, setShowInfoUI] = useState(true)
  const {currentUser, logout} = useAuth()

  function handleAuthenticationClick() {
    setShowAdditionalUI(true)
    setShowInfoUI(false) // Hide Info UI
  }

  function handleInfoClick() {
    setShowInfoUI(true)
    setShowAdditionalUI(false) // Hide Authentication UI
  }

  return (
    <>
      <div className='mb-3'>
        <h1 className='mb-0 fw-semibold fs-2'>Ad Astra</h1>
        <span className='text-gray-500'>Manage Company Details</span>
      </div>
      <div className='card'>
        <div className='rounded'>
          <div className='pt-5 card-body'>
            <div className={`card ${className}`}>
              <div className='card-header px-0' style={{borderBottom: 'none'}}>
                <div className='card-title d-flex align-items-center position-relative me-4 '></div>

                <div className='card-toolbar'>
                  <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                    <div className='modal-dialog modal-lg'>
                      <div className='modal-content '>
                        <div className='modal-header py-2'>
                          <h4 className='modal-title'>Complete Your Profile</h4>
                          <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </div>
                        <div className='modal-body'>
                          <div className='row g-4 g-xl-6'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className=''>
                                <div className='row g-4 g-xl-6'>
                                  <div className='col-sm-4 col-md-4 col-lg-4'>
                                    <div className='d-flex flex-center flex-column'>
                                      <div className='d-flex symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                                        <img
                                          src={toAbsoluteUrl('../media/logos/default-small.svg')}
                                          alt='Metornic'
                                          className='rounded-circle'
                                        />
                                        {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                                      </div>
                                      <div className='d-flex flex-center mt-5'>
                                        <button
                                          type='button'
                                          className='btn btn-outline btn-outline-dashed btn-outline-default btn-sm text-active-primary active py-1'
                                        >
                                          <i className='bi bi-cloud-arrow-up-fill'></i>
                                          Upload
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-sm-8 col-md-8 col-lg-8 '>
                                    <div className='mb-3'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7 mb-1 '
                                      >
                                        Company
                                      </label>
                                      <input
                                        type='text'
                                        className='form-control form-control-white form-select-sm'
                                        placeholder='Enter Title'
                                      />
                                    </div>
                                    <div className='mb-3'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7 mb-1 '
                                      >
                                        Address
                                      </label>
                                      <div className='d-flex align-items-center'>
                                        <div className='row g-3 g-xl-4'>
                                          <div className='col-sm-6 col-md-6 col-lg-6 '>
                                            <input
                                              type='text'
                                              className='form-control form-control-white form-select-sm mb-2'
                                              placeholder='Street Address'
                                            />
                                            <input
                                              type='text'
                                              className='form-control form-control-white form-select-sm mb-2'
                                              placeholder='Apt/Suite'
                                            />
                                            <input
                                              type='text'
                                              className='form-control form-control-white form-select-sm mb-2'
                                              placeholder='City'
                                            />
                                          </div>
                                          <div className='col-sm-6 col-md-6 col-lg-6 '>
                                            <input
                                              type='text'
                                              className='form-control form-control-white form-select-sm mb-2'
                                              placeholder='State/Region'
                                            />
                                            <input
                                              type='text'
                                              className='form-control form-control-white form-select-sm mb-2'
                                              placeholder='Postal Code'
                                            />
                                            <select
                                              className='form-select form-select-sm form-select-white mb-2'
                                              data-kt-select2='true'
                                              data-placeholder='Select option'
                                              data-allow-clear='true'
                                            >
                                              <option style={{color: '#ededed'}}>
                                                Select Country
                                              </option>
                                              <option value='1'>United States</option>
                                              <option value='2'>Uganda</option>
                                              <option value='2'>Zambia</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Default Service Type
                                </label>
                                <div>
                                  <select
                                    className='form-select form-select-sm form-select-white'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                  >
                                    <option style={{color: '#ededed'}}>
                                      Select Default Service Type
                                    </option>
                                    <option value='1'>Health</option>
                                    <option value='2'>Law</option>
                                    <option value='2'>Community</option>
                                  </select>
                                </div>
                              </div>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Native Language
                                </label>
                                <div>
                                  <select
                                    className='form-select form-select-sm form-select-white'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                  >
                                    <option style={{color: '#ededed'}}>
                                      Select Native Language
                                    </option>
                                    <option value='1'>English-English</option>
                                    <option value='2'>German-English</option>
                                  </select>
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Default Timezone
                                </label>
                                <div>
                                  <select
                                    className='form-select form-select-sm form-select-white'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                  >
                                    <option style={{color: '#ededed'}}>Select timezone</option>
                                    <option value='1'>
                                      {' '}
                                      (UTC-05:00) Eastern Time (US & Canada)
                                    </option>
                                    <option value='2'>
                                      {' '}
                                      (UTC-05:00) Eastern Time (US & Canada)
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Unique Identifier
                                </label>
                                <div>
                                  <input
                                    type='text'
                                    className='form-control form-control-white form-select-sm'
                                    placeholder='Enter Unique Identifier'
                                  />
                                </div>
                              </div>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  IVR
                                </label>
                                <div>
                                  <input
                                    type='text'
                                    className='form-control form-control-white form-select-sm'
                                    placeholder='IVR Number'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer py-3'>
                          <button
                            type='button'
                            className='btn btn-light btn-sm'
                            data-bs-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <Link to='#'>
                            <button
                              type='button'
                              className='btn btn-primary btn-sm'
                              data-bs-dismiss='modal'
                            >
                              Save
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
                    <div className='modal-dialog modal-lg'>
                      <div className='modal-content '>
                        <div className='modal-header py-2'>
                          <h4 className='modal-title'>Edit Quick Dials</h4>
                          <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </div>
                        <div className='modal-body'>
                          <div className='row g-4 g-xl-6'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='row g-4 g-xl-6 mb-10'>
                                <div className='col-sm-12 col-md-12 col-lg-12 d-flex flex-wrap'>
                                  <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <label className='d-flex flex-start mb-0 cursor-pointer'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          name='accountPlan'
                                          className='form-check-input'
                                          type='radio'
                                          value='1'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          Use system quick dials (currently Off)
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                  <div className='col-sm-6 col-md-6 col-lg-3'>
                                    <label className='d-flex flex-start mb-0 cursor-pointer'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          name='accountPlan'
                                          className='form-check-input'
                                          type='radio'
                                          value='2'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2 justify-content-end'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          Off
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                  <div className='col-sm-6 col-md-6 col-lg-3'>
                                    <label className='d-flex flex-start mb-0 cursor-pointer'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          name='accountPlan'
                                          className='form-check-input'
                                          type='radio'
                                          value='2'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2 justify-content-end'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          Custom
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row g-4 g-xl-6 '>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                  <div className='mb-3'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Language 1
                                    </label>
                                    <div>
                                      <select
                                        className='form-select form-select-sm form-select-white'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                      >
                                        <option style={{color: '#ededed'}}>Select Language</option>
                                        <option value='1'>English</option>
                                        <option value='2'>German</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className='mb-3'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Language 1
                                    </label>
                                    <div>
                                      <select
                                        className='form-select form-select-sm form-select-white'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                      >
                                        <option style={{color: '#ededed'}}>Select Language</option>
                                        <option value='1'>English</option>
                                        <option value='2'>German</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                  <div className='mb-3'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Service Type
                                    </label>
                                    <div>
                                      <select
                                        className='form-select form-select-sm form-select-white'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                      >
                                        <option style={{color: '#ededed'}}>
                                          Request Service Type
                                        </option>
                                        <option value='1'> Health</option>
                                        <option value='2'> law</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className='mb-3'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Communication Type
                                    </label>
                                    <div className='d-flex'>
                                      <div className='form-check form-check-custom form-check-white me-4'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          value=''
                                          id='flexCheckDefault'
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexCheckDefault'
                                        >
                                          Audio
                                        </label>
                                      </div>
                                      <div className='form-check form-check-custom form-check-white'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          value=''
                                          id='flexCheckDefault'
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexCheckDefault'
                                        >
                                          Video
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='separator my-3'></div>
                              <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='d-flex flex-wrap'>
                                  <div
                                    className='card-body bg-white p-0 '
                                    style={{overflow: 'scroll'}}
                                  >
                                    <div className='py-0 pt-3'>
                                      {/* begin::Table container */}
                                      <div className='table-responsive '>
                                        {/* begin::Table */}
                                        <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-2 table-responsive overflow-hidden'>
                                          {/* begin::Table head */}
                                          <thead>
                                            <tr className='fw-semibold text-muted text-uppercase'>
                                              <th className='min-w-50px '>Language 1</th>
                                              <th className='min-w-50px '>Language 2</th>
                                              <th className='min-w-50px'>Service Type</th>
                                              <th className='min-w-50px'>Communication Type</th>
                                              <th className='min-w-50px text-end'>Action</th>
                                            </tr>
                                          </thead>
                                          {/* end::Table head */}
                                          {/* begin::Table body */}
                                          <tbody style={{maxHeight: '50px', overflowY: 'auto'}}>
                                            <tr>
                                              <td>
                                                <a className='text-gray-800 text-hover-primary  fs-6'>
                                                  English-English
                                                </a>
                                              </td>
                                              <td>
                                                <a className='text-gray-800 text-hover-primary  fs-6'>
                                                  English-English
                                                </a>
                                              </td>

                                              <td className=''>
                                                <a className='text-gray-800 text-hover-primary  fs-6'>
                                                  Community
                                                </a>
                                              </td>
                                              <td className=''>
                                                <a className='text-gray-800 text-hover-primary  fs-6'>
                                                  Audio
                                                </a>
                                              </td>
                                              <td>
                                                <div className='d-flex justify-content-end flex-shrink-0'>
                                                  <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                                                    type='button'
                                                  >
                                                    <KTSVG
                                                      path='/media/icons/duotune/art/art005.svg'
                                                      className='svg-icon-3'
                                                    />
                                                  </button>
                                                  <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                    type='button'
                                                  >
                                                    <KTSVG
                                                      path='/media/icons/duotune/general/gen027.svg'
                                                      className='svg-icon-3'
                                                    />
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                          {/* end::Table body */}
                                        </table>
                                        {/* end::Table */}
                                      </div>
                                      {/* end::Table container */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer py-3'>
                          <button
                            type='button'
                            className='btn btn-light btn-sm'
                            data-bs-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <Link to='#'>
                            <button
                              type='button'
                              className='btn btn-primary btn-sm'
                              data-bs-dismiss='modal'
                            >
                              Save
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
                    <div className='modal-dialog'>
                      <div className='modal-content'>
                        <div className='modal-header py-2'>
                          <h4 className='modal-title'>Configure Email Authentication</h4>
                          <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </div>
                        <div className='modal-body'>
                          <div className='row g-4 g-xl-6 d-flex align-items-center'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className=''>
                                <p className='text-gray-600' style={{fontSize: '12px'}}>
                                  <i className='bi bi-info-circle-fill text-gray-600 me-2 '></i> We
                                  will send an email with your confirmation code
                                </p>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Enter Your Email Address
                                </label>
                                <div className='row g-4 g-xl-6'>
                                  <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm'
                                      placeholder='Enter Email Address'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer py-3'>
                          <button
                            type='button'
                            className='btn btn-light btn-sm'
                            data-bs-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <Link to='#'>
                            <button
                              type='button'
                              className='btn btn-primary btn-sm'
                              data-bs-dismiss='modal'
                            >
                              Send Code
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='modal fade' tabIndex={-1} id='kt_modal_5'>
                    <div className='modal-dialog'>
                      <div className='modal-content'>
                        <div className='modal-header py-2'>
                          <h4 className='modal-title'>Add Location</h4>
                          <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </div>
                        <div className='modal-body'>
                          <div className='row g-4 g-xl-6 d-flex align-items-center'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className=''>
                                <div className='row g-4 g-xl-6'>
                                  <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Name
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm'
                                      placeholder='Enter Name'
                                    />
                                  </div>
                                  <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Location
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm'
                                      placeholder='Enter Location'
                                    />
                                  </div>
                                  <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <div>
                                      <LocationMap />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer py-3'>
                          <button
                            type='button'
                            className='btn btn-light btn-sm'
                            data-bs-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <Link to='#'>
                            <button
                              type='button'
                              className='btn btn-primary btn-sm'
                              data-bs-dismiss='modal'
                            >
                              Save
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='modal fade' tabIndex={-1} id='kt_modal_6'>
                    <div className='modal-dialog'>
                      <div className='modal-content modal-lg'>
                        <div className='modal-header py-2'>
                          <h4 className='modal-title'>Create New Billable Party</h4>
                          <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </div>
                        <div className='modal-body'>
                          <div className='row g-4 g-xl-6'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Billable Name
                                </label>
                                <div>
                                  <input
                                    type='text'
                                    className='form-control form-control-white form-select-sm'
                                    placeholder='Enter Billable Name'
                                  />
                                </div>
                              </div>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Attn
                                </label>
                                <div>
                                  <input
                                    type='text'
                                    className='form-control form-control-white form-select-sm'
                                    placeholder='Enter Attn'
                                  />
                                </div>
                              </div>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Address
                                </label>

                                <div className='row g-3 g-xl-4'>
                                  <div className='col-sm-6 col-md-6 col-lg-6 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm mb-2'
                                      placeholder='Street Address'
                                    />
                                  </div>
                                  <div className='col-sm-6 col-md-6 col-lg-6 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm mb-2'
                                      placeholder='Apt/Suite'
                                    />
                                  </div>
                                </div>
                                <div className='row g-3 g-xl-4'>
                                  <div className='col-sm-6 col-md-6 col-lg-4 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm mb-2'
                                      placeholder='City'
                                    />
                                  </div>
                                  <div className='col-sm-6 col-md-6 col-lg-4 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm mb-2'
                                      placeholder='State/Region'
                                    />
                                  </div>
                                  <div className='col-sm-6 col-md-6 col-lg-4 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm mb-2'
                                      placeholder='Postal Code'
                                    />
                                  </div>
                                </div>
                                <div className='row g-3 g-xl-4'>
                                  <div className='col-sm-12 col-md-12 col-lg-12 '>
                                    <select
                                      className='form-select form-select-sm form-select-white mb-2'
                                      data-kt-select2='true'
                                      data-placeholder='Select option'
                                      data-allow-clear='true'
                                    >
                                      <option style={{color: '#ededed'}}>Select Country</option>
                                      <option value='1'>United States</option>
                                      <option value='2'>Uganda</option>
                                      <option value='2'>Zambia</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Email
                                </label>
                                <div>
                                  <input
                                    type='email'
                                    className='form-control form-control-white form-select-sm'
                                    placeholder='Enter Email'
                                  />
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Phone
                                </label>
                                <div>
                                  <input
                                    type='tel'
                                    className='form-control form-control-white form-select-sm'
                                    placeholder='Enter Mobile Number'
                                  />
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  IVR
                                </label>
                                <div>
                                  <input
                                    type='text'
                                    className='form-control form-control-white form-select-sm'
                                    placeholder='Enter Unique Identifier'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer py-3'>
                          <button
                            type='button'
                            className='btn btn-light btn-sm'
                            data-bs-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <Link to='#'>
                            <button
                              type='button'
                              className='btn btn-primary btn-sm'
                              data-bs-dismiss='modal'
                            >
                              Save
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={handleInfoClick}
                    type='button'
                    className={`btn btn-outline btn-outline-dashed btn-outline-default btn-sm text-active-primary me-3 ${
                      showInfoUI ? 'active' : ''
                    }`}
                  >
                    Info
                  </button>
                  <button
                    type='button'
                    onClick={handleAuthenticationClick}
                    className={`btn btn-outline btn-outline-dashed btn-outline-default btn-sm text-active-primary ${
                      showAdditionalUI ? 'active' : ''
                    }`}
                  >
                    Additional Details
                  </button>
                </div>
              </div>
              {showInfoUI && (
                <div>
                  <div className='card mb-5 mb-xl-5' style={{backgroundColor: '#FAF7F5'}}>
                    <div className='card-body pt-9 pb-0'>
                      <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                        <div className='me-7 mb-4'>
                          <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                            <img
                              src={toAbsoluteUrl('../media/logos/default-small.svg')}
                              alt='Metornic'
                              className='rounded-circle'
                            />
                            {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                          </div>

                          <div className='d-flex flex-center mt-2'>
                            {/* {currentUser?.result.role !== '' && <> */}
                            <button
                              type='button'
                              className='btn btn-sm btn-primary'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_1'
                            >
                              <i className='fa-solid fa-pen '></i>
                              Edit
                            </button>
                            {/* </>} */}
                          </div>
                        </div>

                        <div className='flex-grow-1'>
                          <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                              <div className='d-flex align-items-center mb-2'>
                                <a
                                  href='#'
                                  className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                                >
                                  Ad Astra Internal
                                </a>
                              </div>

                              <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 '>
                                <a
                                  href='#'
                                  className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'
                                >
                                  <i className='bi bi-geo-alt-fill me-2'></i>
                                  8701 Georgia Ave, Suite 800, Silver Spring, Maryland, 20910 , US
                                </a>
                                {/* <a
                                  href='#'
                                  className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'
                                >
                                  <i className='fa-solid fa-phone me-2'></i>
                                  +94 77 345 6789 / +94 41 123 4556
                                </a> */}
                              </div>
                            </div>
                          </div>

                          <div className='d-flex flex-wrap flex-stack'>
                            <div className='d-flex flex-column flex-grow-1 pe-8'>
                              <div className='d-flex flex-wrap'>
                                <div className='card-body p-0'>
                                  <div className='row mb-5'>
                                    <label className='col-lg-3 fw-bold text-black fs-6'>
                                      Default Service Type{' '}
                                    </label>
                                    <div className='col-lg-9'>
                                      <span className='fs-6 text-gray-900'>: Business</span>
                                    </div>
                                  </div>
                                  <div className='row mb-5'>
                                    <label className='col-lg-3 fw-bold text-black fs-6'>
                                      Native Language
                                    </label>
                                    <div className='col-lg-9 fv-row'>
                                      <span className='fs-6'>: English</span>
                                    </div>
                                  </div>
                                  <div className='row mb-5'>
                                    <label className='col-lg-3 fw-bold text-black fs-6'>
                                      Timezone
                                      {/* <i
                            className='fas fa-exclamation-circle ms-1 fs-7'
                            data-bs-toggle='tooltip'
                            title='Phone number must be active'
                          ></i> */}
                                    </label>
                                    <div className='col-lg-9 d-flex align-items-center'>
                                      <span className=' fs-6 me-2'>
                                        : (UTC-05:00) Eastern Time (US & Canada)
                                      </span>
                                      {/* <span className='badge badge-success'>Verified</span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-5 mb-xl-5' style={{backgroundColor: '#FAF7F5'}}>
                    <div className='card-body pt-9 pb-4'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center'>
                            <h5 className='text-black fs-3 fw-bold '>
                              Pre-Authenticated PIN Management
                            </h5>
                          </div>

                          <div className='d-flex flex-wrap fs-6 mb-3'>
                            <p className='text-gray-500' style={{fontSize: '12px'}}>
                              Add/Update numbers that can call directly into IVR without a PIN
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-12 col-md-5 col-lg-3 border-2 border-end border-secondary'>
                          <div className='row'>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label fs-7 mb-1 '
                              >
                                Phone Number
                              </label>
                              <input
                                type='text'
                                className='form-control form-control-white form-select-sm'
                                placeholder='Enter Phone Number'
                              />
                            </div>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label fs-7 mb-1  w-100'
                              >
                                User
                              </label>
                              <div>
                                <select
                                  className='form-select form-select-sm form-select-white'
                                  data-kt-select2='true'
                                  data-placeholder='Select option'
                                  data-allow-clear='true'
                                >
                                  <option style={{color: '#ededed'}}>Select User</option>
                                  <option value='1'>User 1</option>
                                  <option value='2'>User 2</option>
                                  <option value='2'>User 3</option>
                                </select>
                              </div>
                            </div>
                            <div className='text-end'>
                              <button type='button' className='btn btn-sm btn-primary'>
                                <i className='bi bi-plus fs-2'></i>
                                Add Phone
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className='col-sm-12 col-md-7 col-lg-9'>
                          <div className='d-flex flex-wrap'>
                            <div className='card-body p-5 bg-white'>
                              <div className='d-flex justify-content-end'>
                                <div className='d-flex align-items-center position-relative'>
                                  <span className='svg-icon svg-icon-3 position-absolute ms-3'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                      className='mh-50px'
                                    >
                                      <rect
                                        opacity='0.5'
                                        x='17.0365'
                                        y='15.1223'
                                        width='8.15546'
                                        height='2'
                                        rx='1'
                                        transform='rotate(45 17.0365 15.1223)'
                                        fill='currentColor'
                                      ></rect>
                                      <path
                                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                        fill='currentColor'
                                      ></path>
                                    </svg>
                                  </span>
                                  <input
                                    type='text'
                                    id='kt_filter_search'
                                    className='form-control form-control-white form-control-sm max-w-250px ps-9'
                                    placeholder='Search'
                                  />
                                </div>
                              </div>

                              <div className='py-0 pt-3'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                  {/* begin::Table */}
                                  <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-2 table-responsive overflow-hidden'>
                                    {/* begin::Table head */}
                                    <thead>
                                      <tr className='fw-semibold text-muted text-uppercase'>
                                        <th className='min-w-50px '>Phone</th>
                                        <th className='min-w-50px'>User</th>
                                        <th className='min-w-50px text-end'>Action</th>
                                      </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody style={{maxHeight: '50px', overflowY: 'auto'}}>
                                      <tr>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            +94113 456 789
                                          </a>
                                        </td>

                                        <td className=''>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            Johan Mirindo
                                          </a>
                                        </td>
                                        <td>
                                          <div className='d-flex justify-content-end flex-shrink-0'>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/art/art005.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            +94113 444 444
                                          </a>
                                        </td>

                                        <td className=''>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            Hirun Mirindo
                                          </a>
                                        </td>
                                        <td>
                                          <div className='d-flex justify-content-end flex-shrink-0'>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/art/art005.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                    {/* end::Table body */}
                                  </table>
                                  {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-5 mb-xl-5' style={{backgroundColor: '#FAF7F5'}}>
                    <div className='card-body pt-9 pb-4'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center'>
                            <h5 className='text-black fs-3 fw-bold '>Quick Dials</h5>
                          </div>

                          <div className='d-flex flex-wrap fs-6 mb-3'>
                            <p className='text-gray-500' style={{fontSize: '10px'}}>
                              Adding a language/service type pairing below will create an IVR menu
                              AND update dialer mobile apps for quick, one touch, selection.
                              <br></br> <strong>Note : </strong>
                              All Quick Dials settings that include Video will not be included in
                              IVR menu. Request Service type can only be used for IVR system.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-12 col-md-5 col-lg-3 border-2 border-end border-secondary'>
                          <div className='row'>
                            <div className='d-flex align-items-start mb-2 flex-column'>
                              <h5 className='text-black fs-3 fw-bold'>IVR Number/PIN</h5>
                              <h5 className='text-gray-600 text-hover-primary fw-normal me-1'>
                                +1 718-838-9317
                              </h5>
                              <div className='row g-4 g-xl-6 '>
                                <div className='pt-3'>
                                  <label className='d-flex flex-start mb-0 cursor-pointer'>
                                    <span className='form-check form-check-custom form-check-white me-3'>
                                      <input
                                        name='accountPlan'
                                        className='form-check-input'
                                        type='radio'
                                        value='1'
                                      />
                                    </span>
                                    <span className='d-flex align-items-center me-2'>
                                      <span className='text-gray-800 text-hover-primary fs-6'>
                                        Use system quick dials (currently Off)
                                      </span>
                                    </span>
                                  </label>
                                </div>
                                <div className=''>
                                  <label className='d-flex flex-start mb-0 cursor-pointer'>
                                    <span className='form-check form-check-custom form-check-white me-3'>
                                      <input
                                        name='accountPlan'
                                        className='form-check-input'
                                        type='radio'
                                        value='2'
                                      />
                                    </span>
                                    <span className='d-flex align-items-center me-2 justify-content-end'>
                                      <span className='text-gray-800 text-hover-primary fs-6'>
                                        Off
                                      </span>
                                    </span>
                                  </label>
                                </div>
                                <div className=''>
                                  <label className='d-flex flex-start mb-0 cursor-pointer'>
                                    <span className='form-check form-check-custom form-check-white me-3'>
                                      <input
                                        name='accountPlan'
                                        className='form-check-input'
                                        type='radio'
                                        value='2'
                                      />
                                    </span>
                                    <span className='d-flex align-items-center me-2 justify-content-end'>
                                      <span className='text-gray-800 text-hover-primary fs-6'>
                                        Custom
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className='text-end'>
                              {/* {currentUser?.result.role !== '' && <> */}
                              <button
                                type='button'
                                className='btn btn-sm btn-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_2'
                              >
                                <i className='fa-solid fa-pen '></i>
                                Edit
                              </button>
                              {/* </>
                              } */}
                            </div>
                          </div>
                        </div>

                        <div className='col-sm-12 col-md-7 col-lg-9'>
                          <div className='d-flex flex-wrap'>
                            <div className='card-body p-5 bg-white overflow-scroll'>
                              <div className='d-flex justify-content-end'>
                                <div className='d-flex align-items-center position-relative'>
                                  <span className='svg-icon svg-icon-3 position-absolute ms-3'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                      className='mh-50px'
                                    >
                                      <rect
                                        opacity='0.5'
                                        x='17.0365'
                                        y='15.1223'
                                        width='8.15546'
                                        height='2'
                                        rx='1'
                                        transform='rotate(45 17.0365 15.1223)'
                                        fill='currentColor'
                                      ></rect>
                                      <path
                                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                        fill='currentColor'
                                      ></path>
                                    </svg>
                                  </span>
                                  <input
                                    type='text'
                                    id='kt_filter_search'
                                    className='form-control form-control-white form-control-sm max-w-250px ps-9'
                                    placeholder='Search'
                                  />
                                </div>
                              </div>

                              <div className='py-0 pt-3'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                  {/* begin::Table */}
                                  <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-2 table-responsive overflow-hidden'>
                                    {/* begin::Table head */}
                                    <thead>
                                      <tr className='fw-semibold text-muted text-uppercase'>
                                        <th className='min-w-50px '>Key</th>
                                        <th className='min-w-50px'>Language 1</th>
                                        <th className='min-w-50px '>Language 2</th>
                                        <th className='min-w-50px'>Service Type</th>
                                        <th className='min-w-50px text-end'>Communication Type</th>
                                      </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody style={{maxHeight: '50px', overflowY: 'auto'}}>
                                      <tr>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            1
                                          </a>
                                        </td>

                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            English-English
                                          </a>
                                        </td>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            Spanish - espanol
                                          </a>
                                        </td>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            Community
                                          </a>
                                        </td>
                                        <td className=' text-end'>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            Audio
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            2
                                          </a>
                                        </td>

                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            English-English
                                          </a>
                                        </td>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            Haitian - kreyol
                                          </a>
                                        </td>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            Community
                                          </a>
                                        </td>
                                        <td className=' text-end'>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            Audio
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                    {/* end::Table body */}
                                  </table>
                                  {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showAdditionalUI && (
                <>
                  <div className='card mb-5 mb-xl-5' style={{backgroundColor: '#FAF7F5'}}>
                    <div className='card-body pt-9 pb-4'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center'>
                            <h5 className='text-black fs-3 fw-bold '>Locations</h5>
                          </div>

                          <div className='d-flex flex-wrap fs-6 mb-3'>
                            <p className='text-gray-500' style={{fontSize: '12px'}}>
                              Add/Update Locations
                            </p>
                          </div>
                        </div>
                        <div className='text-end'>
                          <button
                            type='button'
                            className='btn btn-sm btn-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_5'
                          >
                            <i className='bi bi-plus fs-2'></i>
                            Add Location
                          </button>
                        </div>
                      </div>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='d-flex flex-wrap'>
                            <div className='card-body p-5 bg-white'>
                              <div className='d-flex justify-content-end'>
                                <div className='d-flex align-items-center position-relative'>
                                  <span className='svg-icon svg-icon-3 position-absolute ms-3'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                      className='mh-50px'
                                    >
                                      <rect
                                        opacity='0.5'
                                        x='17.0365'
                                        y='15.1223'
                                        width='8.15546'
                                        height='2'
                                        rx='1'
                                        transform='rotate(45 17.0365 15.1223)'
                                        fill='currentColor'
                                      ></rect>
                                      <path
                                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                        fill='currentColor'
                                      ></path>
                                    </svg>
                                  </span>
                                  <input
                                    type='text'
                                    id='kt_filter_search'
                                    className='form-control form-control-white form-control-sm max-w-250px ps-9'
                                    placeholder='Search'
                                  />
                                </div>
                              </div>

                              <div className='py-0 pt-3'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                  {/* begin::Table */}
                                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-responsive overflow-hidden'>
                                    {/* begin::Table head */}
                                    <thead>
                                      <tr className='fw-semibold text-muted text-uppercase'>
                                        <th className='min-w-50px '>Name</th>
                                        <th className='min-w-50px'>Location</th>
                                        <th className='min-w-50px text-end'>Action</th>
                                      </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody style={{maxHeight: '50px', overflowY: 'auto'}}>
                                      <tr>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            +94113 456 789
                                          </a>
                                        </td>

                                        <td className=''>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            Johan Mirindo
                                          </a>
                                        </td>
                                        <td>
                                          <div className='d-flex justify-content-end flex-shrink-0'>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/art/art005.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                    {/* end::Table body */}
                                  </table>
                                  {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-5 mb-xl-5' style={{backgroundColor: '#FAF7F5'}}>
                    <div className='card-body pt-9 pb-4'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center'>
                            <h5 className='text-black fs-3 fw-bold '>Third Party Billing</h5>
                          </div>

                          <div className='d-flex flex-wrap fs-6 mb-3'>
                            <p className='text-gray-500' style={{fontSize: '12px'}}>
                              Billing Details
                            </p>
                          </div>
                        </div>
                        {/* <UnderDevelopmentBadge className='' /> */}
                        <div className='text-end'>
                          <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='tooltip-filter'> Add Billable Party</Tooltip>}
                          >
                            <div>
                              <button
                                type='button'
                                className='btn btn-sm btn-primary btn-icon'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_6'
                              >
                                <i className='bi bi-plus fs-2'></i>
                              </button>
                            </div>
                          </OverlayTrigger>
                          {/* <button
                            type='button'
                            className='btn btn-sm btn-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_6'
                          >
                            <i className='bi bi-plus fs-2'></i>
                            Add Billable Party
                          </button> */}
                        </div>
                      </div>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='d-flex flex-wrap'>
                            <div className='card-body p-5 bg-white'>
                              {/* <div className='d-flex justify-content-end'>
                                <div className='d-flex align-items-center position-relative'>
                                  <span className='svg-icon svg-icon-3 position-absolute ms-3'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                      className='mh-50px'
                                    >
                                      <rect
                                        opacity='0.5'
                                        x='17.0365'
                                        y='15.1223'
                                        width='8.15546'
                                        height='2'
                                        rx='1'
                                        transform='rotate(45 17.0365 15.1223)'
                                        fill='currentColor'
                                      ></rect>
                                      <path
                                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                        fill='currentColor'
                                      ></path>
                                    </svg>
                                  </span>
                                  <input
                                    type='text'
                                    id='kt_filter_search'
                                    className='form-control form-control-white form-control-sm max-w-250px ps-9'
                                    placeholder='Search'
                                  />
                                </div>
                              </div> */}

                              <div className='py-0 pt-3'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                  {/* begin::Table */}
                                  <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-2 table-responsive overflow-hidden'>
                                    {/* begin::Table head */}
                                    <thead>
                                      <tr className='fw-semibold text-muted text-uppercase'>
                                        <th className='min-w-50px'>Billiable Name</th>
                                        <th className='min-w-50px'>Email</th>
                                        <th className='min-w-50px text-end'>Action</th>
                                      </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody style={{maxHeight: '50px', overflowY: 'auto'}}>
                                      <tr>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            New Billi
                                          </a>
                                        </td>

                                        <td className=''>
                                          <a className='text-gray-800 text-hover-primary  fs-6'>
                                            bill@gmail.com
                                          </a>
                                        </td>
                                        <td>
                                          <div className='d-flex justify-content-end flex-shrink-0'>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/art/art005.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                    {/* end::Table body */}
                                  </table>
                                  {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {CompanyView}
