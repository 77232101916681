import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Select from 'react-select'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'
/* eslint-disable jsx-a11y/anchor-is-valid */
export function AppointmentView() {
  const call = [
    {value: 'option 1', label: 'Eff 1'},
    {value: 'option 2', label: 'Eff 2'},
    {value: 'option 3', label: 'Eff 3'},
  ]
  const interpreters = [
    {value: 'option 1', label: 'AASA 1'},
    {value: 'option 2', label: 'AASA 2'},
    {value: 'option 3', label: 'AASA 3'},
  ]
  const mode = [
    {value: 'option 1', label: 'Prioritize'},
    {value: 'option 2', label: 'Broadcast Only'},
  ]
  const [isRatePerMile, setIsRatePerMile] = useState(true)

  const [isChecked, setIsChecked] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)
  const [isTravelTime, setIsTravelTime] = useState(true)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2)
  }
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_req_app_create'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex flex-column mb-3'>
                <h4 className='modal-title'>Requestor Appointment Creation</h4>
                <span className='text-gray-500' style={{fontSize: '12px'}}>
                  Configure appointment creation rules for requestors.
                </span>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Appointment Creation -</strong>
                      <span>Configure appointment creation rules for requestors.</span>
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <span className='d-flex mb-2'>
                      <strong className='me-2'>Recurring Appointments -</strong>
                      {/* Allow interpreters in your pool to dial out to the external numbers and share
                    the link to join the conference */}
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan12'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan12'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan12'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <span className='d-flex mb-2'>
                      <strong className='me-2'>Appointment Approval -</strong>
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan13'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan13'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan13'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_app_auto_broadcast'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex'>
                <h4 className='modal-title'>Appointment Auto-Broadcasting</h4>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Appointment Auto-Confirmation </strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_app_auto_confirm'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex'>
                <h4 className='modal-title'>Appointment Auto-Confirmation</h4>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Appointment Auto-Confirmation </strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>
                        Auto-confirm interpreters with time conflicts
                      </strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_app_auto_location'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex'>
                <h4 className='modal-title'>Restrict Appointment Locations</h4>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Restrict Appointment Locations </strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_require_signatures'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex'>
                <h4 className='modal-title'>Require Signatures</h4>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Require Signatures</strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex flex-wrap'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On (Manual uploads only)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On (Manual OR Digital Signatures)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='4'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_edit_appointment'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex'>
                <h4 className='modal-title'>Canceling/Editing Scheduled Appointments</h4>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Restrict Interpreters -</strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan114'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan114'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan114'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Restrict Requestors - Standard Workflow -</strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan114'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_vri_change'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex'>
                <h4 className='modal-title'>Scheduled OPI/VRI Charge</h4>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Interpreter is paid based on -</strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex flex-wrap'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan112'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (currently - Actual with minimum scheduled duration)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan112'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            Scheduled duration
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan112'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Actual duration
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan112'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Actual with minimum scheduled duration
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Account is charged based on -</strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex flex-wrap'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan111'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (currently - Actual duration)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan111'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            Scheduled duration
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan111'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Actual duration
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan111'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Actual with minimum scheduled duration
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_pre_interpreter'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex'>
                <h4 className='modal-title'>Request Preferred Interpreters</h4>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='d-flex flex-column mb-2'>
                  <div className='mb-3'>
                    <span className='d-flex mb-2 d-flex flex-wrap'>
                      <strong className='me-2'>Request Preferred Interpreters -</strong>
                      {/* <span>Configure appointment creation rules for requestors.</span> */}
                    </span>
                    <div className='d-flex'>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Use system settings (Currently Off)
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='2'
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-600 text-hover-primary '
                            style={{fontSize: '12px'}}
                          >
                            On
                          </span>
                        </span>
                      </label>
                      <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan11'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span
                            className='text-gray-800 text-hover-primary'
                            style={{fontSize: '12px'}}
                          >
                            Off
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_interpreter_broadcast_list'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex flex-column'>
                <h4 className='modal-title'>Interpreter Broadcasting List</h4>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 '>
                <div className='row g-2'>
                  <div className='col-sm-2 col-md-2 col-lg-2 d-flex align-items-end'>
                    <div className='form-check form-check-custom form-check-light form-check-sm mb-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexRadioLg11'
                      />
                      <label className='form-check-label' htmlFor='flexRadioLg11'>
                        On
                      </label>
                    </div>
                  </div>
                  <div className='col-sm-10 col-md-10 col-lg-10'>
                    <div className=''>
                      <label className='form-label '>Mode</label>
                      <div>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={mode}
                            placeholder='Select Mode'
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row g-2'>
                  <div className='col-sm-10 col-md-10 col-lg-10 '>
                    <div className=''>
                      <label className='form-label '>Interpreters</label>
                      <div>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={interpreters}
                            placeholder='Select Interpreters'
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-2 col-md-2 col-lg-2 d-flex align-items-end'>
                    <div className='form-check form-check-custom form-check-light form-check-sm mb-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexRadioLg11'
                      />
                      <label className='form-check-label' htmlFor='flexRadioLg11'>
                        All
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_interpreter_donotbroadcast_list'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex flex-column'>
                <h4 className='modal-title'>Interpreter Do Not Broadcast List</h4>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 '>
                <div className='col-sm-2 col-md-2 col-lg-2 d-flex align-items-end'>
                  <div className='form-check form-check-custom form-check-light form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg11'
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg11'>
                      On
                    </label>
                  </div>
                </div>

                <div className='row g-2'>
                  <div className='col-sm-10 col-md-10 col-lg-10 '>
                    <div className=''>
                      <label className='form-label '>Interpreters</label>
                      <div>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={interpreters}
                            placeholder='Select Interpreters'
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-2 col-md-2 col-lg-2 d-flex align-items-end'>
                    <div className='form-check form-check-custom form-check-light form-check-sm mb-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexRadioLg11'
                      />
                      <label className='form-check-label' htmlFor='flexRadioLg11'>
                        All
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='card-body p-0'>
        <div className='card-title d-flex align-items-center me-4 mb-3'>
          <div className='d-flex flex-column'>
            <div className='d-flex align-items-center'>
              <h5 className='text-black fs-4 fw-semibold mb-0'>Appointment Setting</h5>
            </div>
            <div className='d-flex flex-wrap fs-6 '>
              <p className='text-gray-500 mb-0' style={{fontSize: '12px', maxWidth: '900px'}}>
                Manage Appointment Setting
              </p>
            </div>
          </div>
          {/* <UnderDevelopmentBadge className='' /> */}
        </div>

        <div className='row g-1 g-xl-1'>
          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column mb-3'>
                  <div className='d-flex fs-3 fw-semibold'>Requestor Appointment Creation</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Configure appointment creation rules for requestors.
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_req_app_create'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Appointment Creation -</strong>
                    <span>
                      Allow requestors to create appointments. If set to OFF, requestors can still
                      view appointments.
                    </span>
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan110'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan110'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan110'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Recurring Appointments -</strong>
                    <span>Allow requestors to create recurring appointments.</span>
                  </span>

                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan12'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan12'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan12'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Appointment Approval -</strong>
                    <span>
                      Require account administrators to approve appointments created by requestors
                      before broadcasting.
                    </span>
                  </span>

                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan13'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan13'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan13'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column mb-3'>
                  <div className='d-flex fs-3 fw-semibold'>Appointment Auto-Broadcasting</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Automatically broadcast and schedule appointments based on your available
                    interpreters, the requirements of your clients, or even location, or priority.
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_app_auto_broadcast'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Appointment Auto-Broadcasting -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan31'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan31'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan31'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column mb-3'>
                  <div className='d-flex fs-3 fw-semibold'>Appointment Auto-Confirmation</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Automatically confirm interpreters based on 1st interpreter to respond.
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_app_auto_confirm'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Appointment Auto-Confirmation -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan32'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan32'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan32'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Auto-confirm interpreters with time conflict -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan33'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan33'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column mb-3'>
                  <div className='d-flex fs-3 fw-semibold'>Restrict Appointment Locations</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    When enabled, requestors will not be able to enter any appointment location as a
                    free text. Only selecting from saved account locations will be available.
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_app_auto_location'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Restrict Appointment Locations -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan35'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan35'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan35'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column mb-3'>
                  <div className='d-flex fs-3 fw-semibold'>Require Signatures</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Require your accounts and interpreters sign a statement of work before an
                    appointment can be Invoiced.
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_require_signatures'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Require Signatures -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan36'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (currently - Off, Interpreters: Off, Requestors: Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan36'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On (Manual uploads only)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan36'
                          className='form-check-input'
                          type='radio'
                          value='3'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On (Manual OR Digital Signatures)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan36'
                          className='form-check-input'
                          type='radio'
                          value='4'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                  <span className='fst-italic text-gray-600' style={{fontSize: '10px'}}>
                    When enabled, signature will be required for scheduled appointments and new
                    appointments going forward.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column mb-3'>
                  <div className='d-flex fs-3 fw-semibold'>
                    Canceling/Editing Scheduled Appointments
                  </div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Allow requestors to cancel or edit scheduled appointments, and interpreters to
                    cancel their participation, only within specified time before appointment start.
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_edit_appointment'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Restrict Interpreters -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan118'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan118'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan118'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Restrict Requestors - Standard Workflow -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan11'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan11'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan11'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column mb-3'>
                  <div className='d-flex fs-3 fw-semibold'>Scheduled OPI/VRI Charge</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Choose charge/pay settings for your accounts and interpreters when participating
                    in scheduled OPI/VRI appointments.
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_vri_change'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Interpreter is paid based on -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex flex-wrap'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan112'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (currently - Actual with minimum scheduled duration)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan112'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          Scheduled duration
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan112'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Actual duration
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan112'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Actual with minimum scheduled duration
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Account is charged based on -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex flex-wrap'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan111'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (currently - Actual duration)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan111'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          Scheduled duration
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan111'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Actual duration
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan111'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Actual with minimum scheduled duration
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column mb-3'>
                  <div className='d-flex fs-3 fw-semibold'>Request Preferred Interpreters</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Allow requestors to select preferred interpreter from the list of previously met
                    interpreters.
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_pre_interpreter'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <div className='mb-3'>
                  <span className='d-flex mb-2 d-flex flex-wrap'>
                    <strong className='me-2'>Request Preferred Interpreters -</strong>
                    {/* <span>Configure appointment creation rules for requestors.</span> */}
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan119'
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Use system settings (Currently Off)
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan119'
                          className='form-check-input'
                          type='radio'
                          value='2'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan119'
                          className='form-check-input'
                          type='radio'
                          value='3'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row g-1 g-xl-4'>
          <div className='col-md-6 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column'>
                  <div className='d-flex fs-3 fw-semibold'>
                    [Disabled] Interpreter Broadcasting List{' '}
                  </div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Interpreters from this list will be prioritized first in call routing queue
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_interpreter_broadcast_list'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>
              <div className='row g-4 '>
                <div className=''>
                  <div className='table-responsive'>
                    <table className='table table-striped table-rounded table-hover table-row-gray-300 align-middle gs-0 gy-2'>
                      <thead>
                        <tr className='fw-semibold text-muted text-uppercase'>
                          <th className='min-w-100px '>Full Name</th>
                          <th className='min-w-100px text-end'>Email </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a className='text-gray-800 text-hover-primary fs-6'>
                              Marcos Wasinger - AASA
                            </a>
                          </td>
                          <td className='text-end'>
                            <a className='text-gray-800 text-hover-primary fs-6'>
                              marcoswasinger16@gmail.com
                            </a>
                          </td>

                          {/* <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_delete_confirm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-muted '
                                />
                              </a>
                            </div>
                          </td> */}
                        </tr>
                        <tr>
                          <td>
                            <a className='text-gray-800 text-hover-primary fs-6'>
                              Marcos Wasinger 2 - AASA
                            </a>
                          </td>
                          <td className='text-end'>
                            <a className='text-gray-800 text-hover-primary fs-6'>
                              123marcoswasinger16@gmail.com
                            </a>
                          </td>

                          {/* <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_delete_confirm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-muted '
                                />
                              </a>
                            </div>
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column'>
                  <div className='d-flex fs-3 fw-semibold'>
                    {' '}
                    [Disabled] Interpreter Do Not Broadcast List{' '}
                  </div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Interpreters from this list won't be available for appointments broadcasting
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_interpreter_donotbroadcast_list'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>
              <div className='row g-4 '>
                <div className=''>
                  <div className='table-responsive'>
                    <table className='table table-striped table-rounded table-hover table-row-gray-300 align-middle gs-0 gy-2'>
                      <thead>
                        <tr className='fw-semibold text-muted text-uppercase'>
                          <th className='min-w-100px '>Full Name</th>
                          <th className='min-w-100px text-end'>Email </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a className='text-gray-800 text-hover-primary fs-6'>
                              Marcos Wasinger - AASA
                            </a>
                          </td>
                          <td className='text-end'>
                            <a className='text-gray-800 text-hover-primary fs-6'>
                              marcoswasinger16@gmail.com
                            </a>
                          </td>

                          {/* <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_delete_confirm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-muted '
                                />
                              </a>
                            </div>
                          </td> */}
                        </tr>
                        <tr>
                          <td>
                            <a className='text-gray-800 text-hover-primary fs-6'>
                              Marcos Wasinger 2 - AASA
                            </a>
                          </td>
                          <td className='text-end'>
                            <a className='text-gray-800 text-hover-primary fs-6'>
                              123marcoswasinger16@gmail.com
                            </a>
                          </td>

                          {/* <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_delete_confirm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-muted '
                                />
                              </a>
                            </div>
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
