import React, {FC, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue, SingleValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../members-management/users-list/core/_models'
import {useAuth} from '../../../modules/auth'
import {Form, Formik} from 'formik'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`
const GET_ALL_SERVICETYPES = `${API_URL}/master/getall/SERVICE_TYPE`
const GET_ALL_COMMUNICATIONTYPES = `${API_URL}/master/getall/COMMUNICATION_TYPE`
const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`

type Props = {
  setAllAppointments: any
  setCurrentPage: any
  setFilterData: any
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
}
export function UpcomingAppointmentFilterDropdown({
  setAllAppointments,
  setCurrentPage,
  setFilterData,
  setTotalPages,
}: Props) {
  const {currentUser} = useAuth()

  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState<any>({
    search: '',
    id: 0,
  })
  const [currentPage, setpage] = useState(1)
  const [data, setdata] = useState({
    accounts: [],
    langs: [],
    serviceTypes: [],
    communicationTypes: [],
    UpcomingAppointmentFilter: undefined,
  })
  const getCustomers = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_CUSTOMER_NAME}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getservicetypes = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_SERVICETYPES}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getcommunicationtypes = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_COMMUNICATIONTYPES}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getNativeLanguage = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    getNativeLanguage,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingServiceType, data: servicetypeList} = useQuery(
    `Query.serviceTypeList`,
    getservicetypes,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingcommunication, data: communicationTypeList} = useQuery(
    `Query.communicationTypeList`,
    getcommunicationtypes,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const languageOptions =
    nativeLanguageList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const serviceTypeOptions =
    servicetypeList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const communicationTypeOptions =
    communicationTypeList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const customerOptions =
    responseCustomer?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleSubmit = (values: any) => {
    const SUBMIT_FILTERS_URL = `${API_URL}/Appoinment/upcoming-appointments`

    setFilterData(values)
    setCurrentPage(1)

    axios.post(SUBMIT_FILTERS_URL, values).then((response) => {
      const {data: appointments, payload} = response.data
      setAllAppointments(appointments)
      setTotalPages(payload.pagination.last_page)
      setShowDropdownMenu(false)
      setIsFilterApplied(true)
    })
  }
  const fetchAllAppointments = async () => {
    setIsLoading(true)
    try {
      let response = await axios.post(
        `${API_URL}/Appoinment/upcoming-appointments`,
        {
          accounts: [],
          langs: [],
          serviceTypes: [],
          communicationTypes: [],
          UpcomingAppointmentFilter: null,
        },
        {
          params: {
            page: currentPage,
            items_per_page: 10,
            ...searchQuery,
          },
        }
      )
      const {data, payload} = response.data
      setCurrentPage(1)
      setAllAppointments(data)
      setTotalPages(payload.pagination.last_page)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleReset = async () => {
    setCurrentPage(1)
    setdata({
      accounts: [],
      langs: [],
      serviceTypes: [],
      communicationTypes: [],
      UpcomingAppointmentFilter: undefined,
    })

    setShowDropdownMenu(false)
    await fetchAllAppointments()
    setFilterData({})
    setIsFilterApplied(false)
  }
  interface OptionType {
    value: number
    label: string
  }
  const upcomingfilter = [
    {value: 1, label: 'Today'},
    {value: 2, label: 'Tommorow'},
    {value: 3, label: 'Current Week'},
    {value: 4, label: 'Current Month'},
    {value: 5, label: 'Upcoming Month'},
    {value: 6, label: 'Current Year'},
  ]

  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-350px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true)
                setdata(values)
                handleSubmit(values)
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                            Customer Name
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('accounts')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingCustomer ? customerOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    customerOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.accounts) &&
                                        (values.accounts as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'accounts',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  onBlur={(e) => setFieldTouched('customers', true)}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='languages' className='form-label fs-7 mb-1'>
                            Languages
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingNativeLanguage ? languageOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    languageOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.langs) &&
                                        (values.langs as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'langs',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                            Service Type
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingServiceType ? serviceTypeOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    serviceTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.serviceTypes) &&
                                        (values.serviceTypes as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'serviceTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                            Communication Type
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingcommunication ? communicationTypeOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    communicationTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.communicationTypes) &&
                                        (values.communicationTypes as string[]).includes(
                                          option.value
                                        )
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'communicationTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                            Date Ranges
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('UpcomingAppointmentFilter')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={upcomingfilter}
                                  value={
                                    upcomingfilter?.find(
                                      (option: any) =>
                                        option.value === values.UpcomingAppointmentFilter
                                    ) || null
                                  }
                                  onChange={(selectedoption) =>
                                    setFieldValue(
                                      'UpcomingAppointmentFilter',
                                      selectedoption?.value || ''
                                    )
                                  }
                                  placeholder='Select'
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='fv-row'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                        Start Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder='Enter Subject'
                      />
                    </div>
                    <div className='col-sm-6'>
                      <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                        End Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder='Start Time'
                      />
                    </div>
                  </div>
                </div> */}
                    </div>
                  </div>
                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={handleReset}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
