import React, {FC, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../../UI/members-management/users-list/core/_models'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {useAuth} from '../../../modules/auth'
import {Form, Formik} from 'formik'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_REQUESTERS = `${API_URL}/accounts/ddlist-awsuserid`
const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`

type Props = {
  setcall_log: any
  setCurrentPage: any
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  setFilterData: any
  fetchcall_log: any
  searchQuery: any
  callType: number
}

export function LogsFilterDropdown({
  setcall_log,
  setCurrentPage,
  setTotalPages,
  setFilterData,
  fetchcall_log,
  searchQuery,
  callType,
}: Props) {
  // const [selectedRequestor, setSelectedRequestor] = useState<
  //   MultiValue<{value: string; label: string}>
  // >([])
  // const [selectedLanguages, setSelectedLanguages] = useState<
  //   MultiValue<{value: string; label: string}>
  // >([])
  // const [callStatus, setCallStatus] = useState<MultiValue<{value: string; label: string}>>([])
  // const [startDate, setStartDate] = useState('')
  // const [endDate, setEndDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [currentPage, setpage] = useState(1)
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const getRequesters = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_REQUESTERS}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getNativeLanguage = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseRequesters} = useQuery(
    `${roleQUERIES.requesters_LIST}`,
    getRequesters,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    getNativeLanguage,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const languageOptions =
    nativeLanguageList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const requesterOptions =
    responseRequesters?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []
    const callStatusOptions = [
      {value: 'CNT', label: 'Connected'},
      {value: 'ABD', label: 'Abandoned'},
    ];
  const [data, setdata] = useState({
    awsUserId_REQList: null,
    languageList: null,
    callStateList: null,
    startDate: undefined,
    endDate: undefined,
    callType: callType,
  })
  const handleSubmit = async (values: any) => {
    setFilterData(values)
    try {
      await fetchcall_log(values, {
        params: {
          page: 1,
          items_per_page: 10,
          ...searchQuery,
        },
      })
      setCurrentPage(1)
      setIsFilterApplied(true)
    } catch (error) {
      console.log(error)
    } finally {
      setShowDropdownMenu(false)
      setIsLoading(false)
    }
  }

  const handleReset = async () => {
    setCurrentPage(1)
    setdata({
      awsUserId_REQList: null,
      languageList: null,
      callStateList: null,
      startDate: undefined,
      endDate: undefined,
      callType: callType,
    })
    setShowDropdownMenu(false)
    setFilterData({
      awsUserId_REQList: null,
      languageList: null,
      callStateList: null,
      startDate: null,
      endDate: null,
      callType: callType,
      companyCode: null,
    })
    await fetchcall_log(
      {
        awsUserId_REQList: null,
        languageList: null,
        callStateList: null,
        startDate: null,
        endDate: null,
        callType: callType,
        companyCode: null,
      },
      {
        search: '',
      }
    )
    setIsFilterApplied(false)
  }

  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-350px' style={{minHeight: '100%'}}>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true)
                setdata(values)
                handleSubmit(values)            
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      {currentUser?.result?.userType !== 'INTERPRETER' &&
                        currentUser?.result?.role !== 'User' && (
                          <div className='col-sm-12 col-md-12'>
                            <div className='mb-3'>
                              <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                                Requester
                              </label>
                              <div className='row g-4 g-xl-4'>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                  <div className='w-100'>
                                    <Select
                                      {...formik.getFieldProps('awsUserId_REQList')}
                                      className='react-select-styled react-select-solid react-select-sm'
                                      classNamePrefix='react-select'
                                      options={!isFetchingCustomer ? requesterOptions : []}
                                      placeholder='Select : All'
                                      isMulti
                                      value={
                                        requesterOptions?.filter(
                                          (option: any) =>
                                            Array.isArray(values.awsUserId_REQList) &&
                                            (values.awsUserId_REQList as string[]).includes(
                                              option.value
                                            )
                                        ) || []
                                      }
                                      onChange={(selectedOptions) => {
                                        setFieldValue(
                                          'awsUserId_REQList',
                                          selectedOptions
                                            ? selectedOptions.map((option) => option.value)
                                            : []
                                        )
                                      }}
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                          border: '1px solid #e4e6ef',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='languages' className='form-label fs-7 mb-1'>
                            Languages
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('languageList')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingNativeLanguage ? languageOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    languageOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.languageList) &&
                                        (values.languageList as string[]).includes(option.label)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'languageList',
                                      selectedOptions
                                        ? selectedOptions.map((option) =>option.label)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                            Call Status
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('callStateList')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={callStatusOptions || []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={callStatusOptions?.filter((option: any) =>
                                    Array.isArray(values.callStateList) &&
                                    (values.callStateList as string[]).includes(option.value)
                                  ) || []}  
                                  onChange={(selectedOptions) =>
                                    setFieldValue(
                                      'callStateList',
                                      selectedOptions?.map((option) => option.value) || []
                                    )
                                  }
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='fv-row'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                              Start Date
                            </label>
                            <input
                              {...formik.getFieldProps('startDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.startDate}
                              onChange={(e) => setFieldValue('startDate', e.target.value)}
                              placeholder='Enter Subject'
                            />
                          </div>
                          <div className='col-sm-6'>
                            <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                              End Date
                            </label>
                            <input
                              {...formik.getFieldProps('endDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.endDate}
                              onChange={(e) => setFieldValue('endDate', e.target.value)}
                              placeholder='Start Time'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={handleReset}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
      {isLoading && <CommonLoading />}
    </>
  )
}
