import axios, {AxiosResponse} from 'axios'
import {ID, Response, ResponseObject} from '../../../../../_metronic/helpers'
import {
  RolesQueryResponse,
  User,
  UsersQueryResponse,
  Deletemodel,
  DropdownResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/Accounts/Insert`
const GET_USERS_URL = `${API_URL}/accounts/members/filter`
const GET_RoleS_URL = `${API_URL}/roles/getall-shortlist`
const GET_UERSBY_URL = `${API_URL}/Accounts/GetAccountDetailByID`
const Update_UERSBY_URL = `${API_URL}/accounts/Update`
const Delete_UERSBY_URL = `${API_URL}/Accounts/DeleteUserAccount`
const GET_ALL_COUNTRY = `${API_URL}/country/getall`
const GET_ALL_PreCallQuestions_BY_CUSTOMER = `${API_URL}/precalls/pre-calls-policies`
const GET_ALL_PINCODE = `${API_URL}/accounts/getpincode`
const GET_ALL_CHECK_AVAILABILITY = `${API_URL}/accounts/check-pin-availablity`

const GET_ALL_MASTER = `${API_URL}/master/getall`
const GET_ALL_CUSTOMER_SHORTLIST = `${API_URL}/customer/getall-shortlist`
const POST_INV_EMAILS = `${API_URL}/accounts/send-invitation`
const GET_ALL_CUSTOMER_LOCATION = `${API_URL}/customer`
const USER_REINVITE_URL = `${API_URL}/accounts/re-invite`
const GENERATE_PIN_CODE = `${API_URL}/accounts/pincode`
const User_Email_Check = `${API_URL}/accounts/checkUserEmail`
const sendInvitation = (user: User): Promise<ResponseObject | undefined> => {
  return axios
    .post(POST_INV_EMAILS, user)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const getUsers = (
  query: string,
  userType: string,
  customerCode?: number,
  payload?: any
): Promise<UsersQueryResponse> => {
  var dt = axios
    .post(`${GET_USERS_URL}/${userType}/${customerCode ?? '0'}/?${query}`, payload)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return dt
}

const getCountries = (): Promise<DropdownResponse> => {
  var dt = axios.get(`${GET_ALL_COUNTRY}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getAllPreCallQuestionByCustomer = async (customerId: string): Promise<DropdownResponse> => {
  var dt = await axios
    .get(`${GET_ALL_PreCallQuestions_BY_CUSTOMER}/${customerId}`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const GetPinCode = (): Promise<DropdownResponse> => {
  var dt = axios.get(`${GENERATE_PIN_CODE}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const CheckPinAvailability = (pin: string): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_CHECK_AVAILABILITY}/${pin}`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}
const getCustomerLocations = (Code: number): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_CUSTOMER_LOCATION}/${Code}}/location/getall-shortlist`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const checkUserEmail = (email: string, type: string): Promise<ResponseObject | undefined> => {
  return axios
    .get(`${User_Email_Check}/${type}/${email}`)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const getMaster = (code: string): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_MASTER}/${code}`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getUserById = (id: ID): Promise<User | undefined> => {
  var dr = axios
    .get(`${GET_UERSBY_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
  return dr
}

const getUserroles = (usertype: string): Promise<RolesQueryResponse> => {
  var dt = axios
    .get(`${GET_RoleS_URL}/${usertype}`)
    .then((d: AxiosResponse<RolesQueryResponse>) => d.data)
  return dt
}

const createUser = (user: FormData): Promise<ResponseObject | undefined> => {
  return axios
    .post(USER_URL, user, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const updateUser = (user: FormData): Promise<ResponseObject | undefined> => {
  return axios
    .post(Update_UERSBY_URL, user, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const deleteUser = (Id: ID): Promise<ID | undefined> => {
  return axios
    .post(`${Delete_UERSBY_URL}/${Id}`)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.post(`${Delete_UERSBY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const reinvite = (userID: string): Promise<ResponseObject | undefined> => {
  return axios
    .post(`${USER_REINVITE_URL}/${userID}`)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  getUserroles,
  getCountries,
  getMaster,
  getCustomerLocations,
  sendInvitation,
  reinvite,
  GetPinCode,
  CheckPinAvailability,
  checkUserEmail,
}
