/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios from 'axios'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {DownloadModal} from '../../../../Utils/DownloadModal'
import { LogsFilterDropdown } from './LogsFilterDropDown'
import { LogsViewTable } from './LogsViewTable'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

type Props = {
  callType:number
  pageTitle : string
  downloadPage : string
}

const MainLogs: React.FC<Props> = ({callType , pageTitle , downloadPage}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const tableRef = useRef<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [call_log, setcall_log] = useState([])
  const [filterData, setFilterData] = useState({
    awsUserId_REQList: null,
    languageList: null,
    callStateList: null,
    startDate: null,
    endDate: null,
    callType: callType,
    companyCode:null
  })

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)
  const [searchQuery, setSearchQuery] = useState<any>({
    search: '',
  })

  useEffect(() => {
    fetchcall_log(filterData, searchQuery)
  }, [currentPage, rowsPerPage, searchQuery])

  const fetchcall_log = async (passingData: any, searchQuery: any) => {
    setIsLoading(true)
    try {
      let response = await axios.post(`${API_URL}/AWSConnect/call-log-filter`, passingData, {
        params: {
          page: currentPage,
          items_per_page: rowsPerPage,
          ...searchQuery,
        },
      })

      const {data, payload} = response.data
      setcall_log(data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchQuery({
        ...searchQuery,
        search: inputRef.current?.value || '',
      })
    }
  }

  return (
    <>
      <div className='card-body p-0'>
        <div className='rounded'>
          <div className='mt-4 d-flex justify-content-between flex-wrap align-items-center'>
            <div className='d-flex'>
              <h4 className='text-center mb-0'>{pageTitle}</h4>
            </div>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  ref={inputRef}
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                  onKeyDown={handleKeyDown}
                  onChange={() => {
                    if (inputRef.current?.value === '') {
                      fetchcall_log(filterData, {
                        search: '',
                      })
                    }
                  }}
                />

                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                  onClick={() => {
                    setSearchQuery({
                      ...searchQuery,
                      search: inputRef.current?.value || '',
                    })
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Download CSV</Tooltip>}
              >
                <button
                  type='button'
                  className='btn btn-primary btn-sm me-3 btn-icon'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_download_list_modal'
                >
                  <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-muted' />
                </button>
              </OverlayTrigger>

              <div className=''>
                <LogsFilterDropdown
                  setcall_log={setcall_log}
                  setTotalPages={setTotalPages}
                  setCurrentPage={setCurrentPage}
                  setFilterData={setFilterData}
                  fetchcall_log={fetchcall_log}
                  searchQuery={searchQuery}
                  callType={callType}
                />
              </div>
              <DownloadModal 
               title='Select Download Options' 
               pageName={downloadPage}
               filterData = {filterData}
               searchKey = {searchQuery}
               callType = {callType}
              />
            </div>
          </div>

          <div className='table-responsive mb-5 mt-3'>
            <LogsViewTable
              className={''}
              title={pageTitle}
              isLoading={isLoading}
              call_log={call_log}
              tableRef={tableRef}
            />
          </div>
          <CommonPaginationModel
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={(newRowsPerPage: number) => setRowsPerPage(newRowsPerPage)}
          />
        </div>

        <img
          className='w-100 card-rounded-bottom'
          alt=''
          src='assetsmedia/svg/illustrations/bg-4.svg'
        />
      </div>
    </>
  )
}

export {MainLogs}
