/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'

type Props = {
  className: string
  isLoading: boolean
  data: any
  tableRef: any
}

const InterpreterVoucherTable: React.FC<Props> = ({className, isLoading, data, tableRef}) => {
  const navigate = useNavigate()

  return (
    <div className={`card ${className}`}>
      <table
        className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'
        ref={tableRef}
      >
        {/* begin::Table head */}
        <thead>
          <tr className='fw-semibold text-muted text-uppercase'>
            <th className='min-w-150px '>Interpreter</th>
            <th className='min-w-150px '>Invoice #</th>
            <th className='min-w-100px '>Status</th>
            {/* <th className='min-w-50px '> Quick Book</th>
            <th className='min-w-100px '>PO#</th> */}
            <th className='min-w-200px '>Created</th>
            <th className='min-w-150px '>Invoice Date</th>
            <th className='min-w-100px '>Due Date</th>
            <th className='min-w-100px '>Call/Appts</th>
            <th className='min-w-100px text-end'>Total</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {data?.length > 0 ? (
            data?.map((item: any, index: any) => (
              <tr>
                <td onClick={() => navigate(`/voucher/${item?.code}`)} style={{cursor: 'pointer'}}>
                  <div className='d-flex justify-content-start flex-column'>
                    <a className='text-gray-800 text-hover-primary fs-6'>
                      {item?.interpreter ?? '-'}
                    </a>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    <a className='text-gray-800 text-hover-primary fs-6'>{item?.refNo ?? '-'}</a>
                  </div>
                </td>
                <td>
                  {item?.statusIdentification === 'DR' && (
                    <span className='badge badge-light-dark px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-dark-800'>
                      {item?.status ?? '-'}
                    </span>
                  )}
                  {item?.statusIdentification === 'VO' && (
                    <span className='badge badge-light-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-success-800'>
                      {item?.status ?? '-'}
                    </span>
                  )}
                  {item?.statusIdentification === 'PD' && (
                    <span className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-gray-800'>
                      {item?.status ?? '-'}
                    </span>
                  )}
                  {item?.statusIdentification === 'DW' && (
                    <span className='badge badge-light-warning px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-warning-800'>
                      {item?.status ?? '-'}
                    </span>
                  )}
                  {item?.statusIdentification === 'AP' && (
                    <span className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center text-gray-800'>
                      {item?.status ?? '-'}
                    </span>
                  )}
                </td>

                {/* <td>
                <a className='text-gray-800 text-hover-primary fs-6'>
                </a>
              </td>
              <td>
                <a className='text-gray-800 text-hover-primary fs-6'></a>
              </td> */}

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>
                    {' '}
                    {item?.createdDate
                      ? moment(item?.createdDate).format('MM/DD/YYYY hh:mm A')
                      : '-'}
                  </a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>
                    {item?.createdDate ? moment(item?.date).format('MM/DD/YYYY') : '-'}
                  </a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>
                    {' '}
                    {item?.createdDate ? moment(item?.dueDate).format('MM/DD/YYYY') : '-'}
                  </a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>
                    {item?.callCount ?? 0} / {item?.appoinmentCount ?? 0}
                  </a>
                </td>
                <td className='text-end'>
                  <a className='text-gray-800 text-hover-primary fs-6 '>${item?.total ?? '0'}</a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                  <div className='text-center'>
                    <div className='symbol symbol-200px '>
                      <img src='/media/other/nodata.png' alt='' />
                    </div>
                  </div>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                    No matching records found
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {/* end::Table body */}
      </table>
      {isLoading && <CommonLoading />}
    </div>
  )
}

export {InterpreterVoucherTable}
