import React from 'react'
import {Bar} from 'react-chartjs-2'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import 'chart.js/auto'
import {Spinner} from 'react-bootstrap'

ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  details: {
    x: string[]
    opi: number[]
    vri: number[]
    osi: number[]
  }
  loading?: boolean
}

const TopLanguageBarChart = ({details, loading}: Props) => {
  const data = {
    labels: details?.x,
    datasets: [
      {
        label: 'OPI',
        data: details?.opi,
        backgroundColor: 'rgba(163, 162, 66, 0.6)',
        barPercentage: 0.6,
        categoryPercentage: 1,
        barThickness: 30,
        maxBarThickness: 30,
        minBarLength: 2,
      },
      {
        label: 'VRI',
        data: details?.vri,
        backgroundColor: 'rgba(227, 117, 155, 0.6)',
        barPercentage: 0.6,
        categoryPercentage: 1,
        barThickness: 30,
        maxBarThickness: 30,
        minBarLength: 2,
      },
      {
        label: 'OSI',
        data: details?.osi,
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
        barPercentage: 0.6,
        categoryPercentage: 1,
        barThickness: 30,
        maxBarThickness: 30,
        minBarLength: 2,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw} minutes`
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Languages',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'No of Minutes (OPI / VRI / OSI)',
        },
        beginAtZero: true,
      },
    },
  }

  return (
    <div className='card card-lg-stretch mb-5 mb-xl-8 shadow-custom'>
      <div className='card-header border-0'>
        <h3 className='card-title fw-semibold text-dark fs-6'>Minutes by Top Languages</h3>
      </div>
      {loading ? (
        <div
          className='card-body pt-0 d-flex align-items-center justify-content-center'
          style={{height: '500px'}}
        >
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className='card-body pt-0'>
          <Bar data={data} options={options} />
        </div>
      )}
    </div>
  )
}

export default TopLanguageBarChart
