import {useAuth} from '../app/modules/auth'

export const overViewStatus = (status: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {currentUser} = useAuth()
  let color
  let label

  if (currentUser?.result?.userType === 'INTERPRETER') {
    switch (status) {
      case 'Invited':
        label = 'Invited'
        color = 'rgba(215, 56, 60, 1)'
        break
      case 'InterpreterApproved':
        label = 'Accepted'
        color = 'rgba(96, 187, 26, 1)'
        break
      case 'Assign':
        label = 'Confirmed'
        color = 'rgba(96, 187, 26, 1)'
        break
      case 'Pending':
        label = 'Pending'
        color = 'rgba(255, 199, 0, 1)'
        break
      case 'InterpreterReject':
        label = 'Interpreter Reject'
        color = 'rgba(24, 28, 50, 1)'
        break
      case 'Reject':
        label = 'Reject'
        color = 'rgba(227, 117, 155, 1)'
        break
    }
  } else {
    switch (status) {
      case 'Open':
        label = 'Open'
        color = 'rgba(215, 56, 60, 1)'
        break
      case 'ReadyToAssign':
        label = 'Ready To Assign'
        color = 'rgba(163, 162, 66, 1)'
        break
      case 'Confirmed':
        label = 'Confirmed'
        color = 'rgba(96, 187, 26, 1)'
        break
      case 'NoInterpreter':
        label = 'No Interpreter'
        color = 'rgba(255, 199, 0, 1)'
        break
      case 'Abandoned':
        label = 'Abandoned'
        color = 'rgba(227, 117, 155, 1)'
        break
      case 'Completed':
        label = 'Completed'
        color = 'rgba(24, 28, 50, 1)'
        break
      case 'Canceled':
        label = 'Canceled'
        color = 'rgba(228, 230, 239, 1)'
        break
    }
  }
  return {label, color}
}
