import {Suspense} from 'react'
import {Helmet} from 'react-helmet'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {DataProvider} from './UI/DataContext'
import {useAuth} from '../app/modules/auth'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Provider} from 'react-redux'
import {store} from './redux/store'
import CCPContainer from './CCPContainer'

const App = () => {
  const {currentUser, auth} = useAuth();

  return (
    <Provider store={store}>
      <DataProvider>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {auth?.isAuthenticated == true && currentUser?.result.userType == 'INTERPRETER' && (process.env.REACT_APP_SERVER == 'DEV' || process.env.REACT_APP_SERVER == 'QA') &&(
          <CCPContainer />
        )}
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Helmet>
                  {auth?.isAuthenticated == true && currentUser?.result.userType == 'CONSUMER' && process.env.REACT_APP_SERVER == 'DEV' && (
                    <script type='text/javascript'>{`
                  (function(w, d, x, id){
    s=d.createElement('script');
    s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
    s.async=1;
    s.id=id;
    d.getElementsByTagName('head')[0].appendChild(s);
    w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
  })(window, document, 'amazon_connect', '2860e39c-75f2-485c-b974-2e06d172f32b');
  amazon_connect('styles', { iconType: 'CHAT_VOICE', openChat: { color: '#ffffff', backgroundColor: '#123456' }, closeChat: { color: '#ffffff', backgroundColor: '#123456'} });
  amazon_connect('snippetId', 'QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdGSzNrZThsbXh3UTV0bEdjeGJBb2R0QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNUkN1ZG5HZGtNdllkUjhzdkFnRVFnQ3QwWDE1anNHZ0RYQUtrSEFFVHExRXk1VG12NzZXZDhlb05ZTEJIWGgzeUUrbUozNFp1c29oYklYb2o6Okwrb3B6VUdUQWhQVUhqc0hlM0RwM1kzNzFsdW5ySDRNMzhBcmpYak50cWNZUXJpZFBLdGlNQzJET3dlYWdNSUxJWCtEZlYvSS9YZlNQNW41cytWNzVDYWxlSmxUUm01Wm9vZnIvMGhvUHVZdFQ5b21XQ3lRcjVFM2lENkRyS2lEQTdjR2tFd2VvVE1wd0hLNzFhVk9NSDR3OVUrVjg5ST0=');
  amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
  amazon_connect('contactAttributes', {
   test: 'Test Ad-astra'
  })
              `}</script>
                  )}
                  <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=zn3taxwqsc5wjxromdl1iw" async={true}></script>
                  <script src='https://maps.googleapis.com/maps/api/js?key=AIzaSyAwcOSQ6hnqoqiXX_1D1ykHOBAZZ2UorHE&libraries=places'></script>
                  {/* {(auth?.isAuthenticated == true && currentUser?.result.userType == "CONSUMER") &&
                  <script type='text/javascript'>{`
                    (function(w, d, x, id){
                  s=d.createElement('script');
                  s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
                  s.async=1;
                  s.id=id;
                  d.getElementsByTagName('head')[0].appendChild(s);
                  w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
                })(window, document, 'amazon_connect', '724eb804-1666-4df3-80bc-cf20f92c1687');
                amazon_connect('styles', { iconType: 'CHAT_VOICE', openChat: { color: '#ffffff', backgroundColor: '#123456' }, closeChat: { color: '#ffffff', backgroundColor: '#123456'} });
                amazon_connect('snippetId', 'QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdFaVZWRGI1bG53Zk81RGdpOGszSEI2QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNZEc1cUwvOW5CdTlERXVhTEFnRVFnQ3Z3Q1ROSWFSaEo3bWhJUUVvYW9OM2lrN3dVZ0RUMjE0U0UzTkVNaHUxSkxOMFMxVTI1VHN4OEpOVDc6OkQzUUl5TnBKcGhvNzJ2d2F2UFVvcFJHR25tTlI2T3AwT0oyWkk4UmhYemg1M1F3RjNuUUM4QkNOWmNJUFByZHg4cjZYOU16OW1UTXVEMmQydllxRVYzZ2x1Umlwc0JUdTAzeXNWdWQ2NXBOMmMzRGVTSzhVbTZIcDRwd1ZTVTgvMXJwTitaTzc1Y3luYnhwblVHMDlMUXlwRzIwcFNsYz0=');
                amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
              `}</script>} */}
                  {/* {(currentUser?.result.userType == "CONSUMER") && <script type='text/javascript'>{`awsClient('${currentUser?.result.awsConnectToken}')`}</script>}
                {(currentUser?.result.userType == "INTERPRETER") && <script type="text/javascript">awsAgent('323954a9-f992-4c30-965a-59870a604efa')</script>} */}
                </Helmet>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
      </DataProvider>
    </Provider>
  )
}

export {App}
