import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import axios from 'axios'
import moment from 'moment'
import clsx from 'clsx'
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {getFileType, statusMapping} from '../../../../Utils/commonData'
import {useAuth} from '../../../modules/auth'
import toaster from '../../../../Utils/toaster'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {DocumentViewModal} from '../../../../Utils/documentViewModal'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {DeleteConfirmationModal} from '../../../common/Models/confirmation-modal/DeleteConfirmationModal'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

const documentUploadSchemaSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  file: Yup.string().required('Required'),
})
const addnoteSchema = Yup.object().shape({
  note: Yup.string().required('Required'),
  chatwith: Yup.string().required('Required'),
})

export function UpcomingAppointmentViewer() {
  const chat = [
    {value: 1, label: 'User'},
    {value: 2, label: 'Requester Content Admin'},
    {value: 3, label: 'Interpreter'},
    {value: 4, label: 'Manager'},
    {value: 5, label: 'Admin'},
    {value: 6, label: 'All'},
  ]
  const category = [
    {value: 'option1', label: 'Fuel/Mileage'},
    {value: 'option2', label: 'Parking/Tolls'},
    {value: 'option3', label: 'Public Transportation'},
    {value: 'option4', label: 'Taxi/Uber/Back car'},
    {value: 'option5', label: 'Internet/Phone'},
    {value: 'option6', label: 'Meals/Foods'},
    {value: 'option7', label: 'Hotel/Accommodation'},
    {value: 'option8', label: 'Airfare'},
    {value: 'option9', label: 'Travel Time'},
    {value: 'option10', label: 'Other'},
  ]

  const {currentUser} = useAuth()
  const [activeTab, setActiveTab] = useState(
    currentUser?.result?.userType === 'INTERPRETER' ? 2 : 1
  )
  const initialValues = {
    name: '',
    description: '',
    file: '',
  }
  const initialValue = {
    chatwith: 0,
    note: '',
  }

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber)
  }
  const [selectedRecurrence, setSelectedRecurrence] = useState('1')

  const handleRecurrenceChange = (event: any) => {
    setSelectedRecurrence(event.target.value)
  }
  const [rows, setRows] = useState([{}])

  const addRow = () => {
    if (rows.length < 2) {
      setRows([...rows, {}])
    }
  }
  const removeRow = (index: number) => {
    const newRows = rows.filter((_, i) => i !== index)
    setRows(newRows)
  }
  const [isChecked, setIsChecked] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2)
  }
  const [isRatePerMile, setIsRatePerMile] = useState(true)

  const [isTravelTime, setIsTravelTime] = useState(true)
  const [selectedOption, setSelectedOption] = useState('')

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value)
  }

  //use states
  const [appointmentDetails, setAppointmentDetails] = useState<any>({})
  const [interpreters, setInterpreters] = useState<any>([])
  const [notMatchInterpreters, setNotMatchInterpreters] = useState<any>([])
  const [activities, setActivities] = useState<any>([])
  const [documents, setDocuments] = useState<any>([])
  const {id} = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)
  const [message, setMessage] = useState('')
  const [isBulkCancalation, setIsBulkCancalation] = useState(false)
  const [notes, setNotes] = useState<any>([])

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)

  useEffect(() => {
    if (id) {
      fetchAppoinmentDetails()
    }
  }, [currentPage, rowsPerPage])

  const fetchAppoinmentDetails = async () => {
    try {
      setIsLoading(true)
      let response = await axios.get(`${API_URL}/Appoinment/${id}`)
      setAppointmentDetails(response.data)
      if (currentUser?.result?.userType === 'SYSTEM') {
        let response2 = await axios.get(`${API_URL}/Appoinment/interpreters/${id}`)
        setInterpreters(response2.data.result)
      }
      let response3 = await axios.get(`${API_URL}/Appoinment/${id}/activities`)
      setActivities(response3.data)
      if (currentUser?.result?.userType === 'SYSTEM') {
        let response4 = await axios.get(`${API_URL}/Appoinment/interpreters-not-match/${id}`)
        setNotMatchInterpreters(response4.data)
      }
      getDocuments()
      let response5 = await axios.get(`${API_URL}/Appoinment/${id}/notes`)
      setNotes(response5.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchNotes = async () => {
    try {
      setIsLoading(true)
      let response5 = await axios.get(`${API_URL}/Appoinment/${id}/notes`)
      setNotes(response5.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  function countInterpretersByStatus(assignStatus: number): number {
    return interpreters.filter((interpreter: any) => interpreter.assignStatus === assignStatus)
      .length
  }

  function countNoMatchInterpretersByStatus(assignStatus: number): number {
    return notMatchInterpreters.filter(
      (interpreter: any) => interpreter.assignStatus === assignStatus
    ).length
  }

  const handleAssign = async (status: number, id: number, userID: any) => {
    try {
      let response = await axios.put(
        `${API_URL}/Appoinment/interpreter-status/${status}/${id}/${userID}`
      )
      if (response.data.status === 'S') {
        toaster('success', 'Successfuly updated')
        fetchAppoinmentDetails()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleInviteInterpreter = async (id: number, userID: any) => {
    try {
      let response = await axios.post(`${API_URL}/Appoinment/interpreters-invite/${id}/${userID}`)
      if (response.data.status === 'S') {
        toaster('success', 'Successfuly updated')
        fetchAppoinmentDetails()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAppoinmentCancelation = async () => {
    try {
      let response = await axios.delete(`${API_URL}/Appoinment`, {
        data: {
          isBulk: isBulkCancalation,
          appointment: id,
        },
      })
      if (response.data.status === 'S') {
        setIsOpenConfirmationModal(false)
        toaster('success', 'Successfuly cancelled')
        fetchAppoinmentDetails()
      } else {
        setIsOpenConfirmationModal(false)
        toaster('error', 'Fail Cancellation')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCheckInterpreterAvalability: any = async (id: number, isBulk: boolean) => {
    try {
      let {data} = await axios.get(
        `${API_URL}/Appoinment/check-interpreter-available/${id}/${isBulk}`
      )
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const onSelectFile = (e: any, setFieldValue: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue('file', '')
      return
    }
    const file = e.target.files[0]
    if (file.type != 'application/pdf' && file.type != 'image/png' && file.type != 'image/jpeg') {
      setFieldValue('file', '')
      toaster('error', 'Please upload pdf , jpeg or png file')
      return
    }
    setFieldValue('file', e.currentTarget.files[0])
  }

  const getDocuments = async () => {
    try {
      let response = await axios.get(`${API_URL}/Appoinment/${id}/documents`, {
        params: {
          page: currentPage,
          items_per_page: rowsPerPage,
        },
      })
      const {data, payload} = response.data
      setDocuments(data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <Formik
            initialValues={initialValue}
            validationSchema={addnoteSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                const result: any = await axios.post(`${API_URL}/Appoinment/notes`, {
                  fK_Appoinment: id,
                  note: values?.note,
                  visibilityType: values?.chatwith,
                })
                if (result?.data?.status == 'S') {
                  setSubmitting(true)
                  toaster('success', result?.text ?? 'Successfully Inserted')
                }
                resetForm()
              } catch (ex) {
                console.error(ex)
              } finally {
                fetchNotes()
                //setSubmitting(true)
                //cancel(true)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
              dirty,
              isSubmitting,
              handleSubmit,
              resetForm,
              ...formik
            }) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Appointment Note</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => resetForm()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label  fs-7 mb-1'
                          >
                            Visible To
                          </label>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={chat}
                            placeholder='Select'
                            //isMulti
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#a4afc4',
                              }),
                            }}
                            onChange={(option: any) => setFieldValue('chatwith', option?.value)}
                            value={{
                              value: values.chatwith,
                              label:
                                chat?.find((c: any) => c.value === values.chatwith)?.label ||
                                'Select ',
                            }}
                            name='chatwith'
                            id='chatwith'
                          />

                          {errors.chatwith && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.chatwith}</span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1'
                          >
                            Write a Note
                          </label>
                          <textarea
                            placeholder='write Note..'
                            name='note'
                            id='note'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.note}
                            className='form-control form-control-sm custom-input-height  custom-input-height  form-select-white mb-lg-0'
                          ></textarea>
                          {errors.note && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.note}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => resetForm()}
                    >
                      Close
                    </button>
                    <Link to='#'>
                      <button
                        type='submit'
                        className='btn btn-primary btn-sm'
                        data-bs-dismiss='modal'
                        disabled={isSubmitting || !isValid || !dirty}
                        onClick={() => handleSubmit()}
                      >
                        Save Note
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='document'>
        <div className='modal-dialog'>
          <Formik
            initialValues={initialValues}
            validationSchema={documentUploadSchemaSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                const formData = new FormData()
                formData.append('name', values?.name ?? '')
                formData.append('description', values?.description ?? '')
                formData.append('file', values?.file ?? '')
                formData.append('appoinmentId', id ?? '')

                const result: any = await axios.post(
                  `${API_URL}/Appoinment/documents/insert`,
                  formData
                )
                if (result?.data?.status == 'S') {
                  toaster('success', result?.text ?? 'Successfully Inserted')
                  getDocuments()
                  resetForm()
                }
              } catch (ex) {
                console.error(ex)
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
              dirty,
              isSubmitting,
              handleSubmit,
              resetForm,
              ...formik
            }) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Appointment File</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => resetForm()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            Name
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-white form-select-sm custom-input-height   custom-input-height'
                            placeholder='Name'
                            name='name'
                            id='name'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {errors.name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.name}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            Description
                          </label>
                          <textarea
                            placeholder='Write Description..'
                            className='form-control form-control-sm custom-input-height  custom-input-height  form-select-white mb-lg-0 '
                            id='description'
                            name='description'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            rows={3}
                          ></textarea>
                          {errors.description && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.description}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            File Upload
                          </label>
                          <input
                            type='file'
                            className='form-control form-control-white form-select-sm custom-input-height  '
                            placeholder='Select File'
                            onChange={(e) => onSelectFile(e, setFieldValue)}
                            name='file'
                            id='file'
                            onBlur={handleBlur}
                          />
                          {errors.file && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.file}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => resetForm()}
                    >
                      Close
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary btn-sm'
                      data-bs-dismiss='modal'
                      disabled={isSubmitting || !isValid || !dirty}
                      onClick={() => handleSubmit()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className='mb-3'>
        <div className='d-flex align-items-center '>
          <Link
            to='/appointment/3'
            className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-3'
          >
            <i className='bi bi-arrow-left-circle' style={{fontSize: '28px'}}></i>
            {/* <i className='bi bi-arrow-left-circle-fill' s></i> */}
          </Link>
          <div className=''>
            <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '36px'}}>
              Upcoming Appointment Viewer
            </h1>

            <p className='text-gray-500 fs-5'>See What's Coming Next</p>
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-5 shadow-sm' style={{borderTop: '3px solid #a3a242'}}>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-125px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('../media/avatars/upcoming.gif')}
                  alt='appointment'
                  className='rounded-sm'
                />
                {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
              </div>
              {/* {currentUser?.result?.userType !== 'INTERPRETER' && appointmentDetails?.status !== 6 && (
                <div className='d-flex flex-center mt-2'>
                  <Dropdown>
                    <Dropdown.Toggle variant='primary' id='dropdown-basic' className='btn btn-sm'>
                      <i className='fa-solid fa-pen me-1 fs-8'></i>
                      Edit
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => navigate(`/edit-appointment/${id}?isBulk=false`)}
                      >
                        Edit occurrence
                      </Dropdown.Item>
                      {appointmentDetails?.isRecurring && (
                        <Dropdown.Item
                          onClick={() => navigate(`/edit-appointment/${id}?isBulk=true`)}
                        >
                          Edit series
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a className='text-gray-800 fs-2 fw-bolder me-1 text-uppercase'>
                      {appointmentDetails?.customerName ?? 'N/A'}
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-semibold fs-6 mb-2 pe-2 '>
                    <a className='d-flex align-items-center text-gray-600 me-5 mb-2'>
                      <i className='bi bi-calendar3 me-2'></i>
                      {moment(appointmentDetails?.startTime).format('MM/DD/YYYY, dddd')}{' '}
                      {moment(appointmentDetails.startTime).format('h:mm A')} -{' '}
                      {moment(appointmentDetails?.endTime).format('MM/DD/YYYY, dddd')}{' '}
                      {moment(appointmentDetails.endTime).format('h:mm A')}
                    </a>
                    {/* <a className='d-flex align-items-center text-gray-600 me-5 mb-2'>
                      <i className='bi bi-building me-2'></i>
                      {appointmentDetails?.timeZoneName ?? 'N/A'}
                    </a> */}
                  </div>
                </div>

                <div className='d-flex flex-row'>
                  {currentUser?.result.userType !== 'INTERPRETER' &&
                    appointmentDetails?.status !== 6 && (
                      <div className='d-flex me-2'>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='danger'
                            id='dropdown-basic'
                            className='btn btn-sm'
                          >
                            <i className='bi bi-x-circle me-1 fs-6'></i>
                            Cancel
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={async () => {
                                const isInterpreterAvalability: any =
                                  await handleCheckInterpreterAvalability(
                                    appointmentDetails?.code,
                                    false
                                  )
                                setMessage(
                                  isInterpreterAvalability
                                    ? 'It looks like the interpreter you are about to remove is already assigned to this appointment. Are you sure you want to proceed? Removing the interpreter will require you to cancel the job assignment?'
                                    : 'Are you sure want to cancel this appoinment?'
                                )
                                setIsBulkCancalation(false)
                                setIsOpenConfirmationModal(true)
                              }}
                            >
                              Cancel occurrence
                            </Dropdown.Item>
                            {appointmentDetails?.isRecurring && (
                              <Dropdown.Item
                                onClick={async () => {
                                  const isInterpreterAvalability: any =
                                    await handleCheckInterpreterAvalability(
                                      appointmentDetails?.id,
                                      true
                                    )
                                  setMessage(
                                    isInterpreterAvalability
                                      ? 'It looks like the interpreter you are about to remove is already assigned to this appointment. Are you sure you want to proceed? Removing the interpreter will require you to cancel the job assignment series?'
                                      : 'Are you sure want to cancel this appoinment series?'
                                  )
                                  setIsBulkCancalation(true)
                                  setIsOpenConfirmationModal(true)
                                }}
                              >
                                Cancel series
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  {appointmentDetails?.status !== 6 && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={<Tooltip id='tooltip-filter'>Add Note</Tooltip>}
                    >
                      <div className='d-flex align-items-center mb-2'>
                        <button
                          type='button'
                          className='btn btn-sm btn-primary btn-icon px-4'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_2'
                          data-bs-backdrop='static'
                          data-bs-keyboard='false'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen005.svg'
                            className='svg-icon-muted'
                          />
                        </button>
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
              <div className='separator mt-0 mb-2'></div>
              <div className='d-flex flex-wrap justify-content-start'>
                <div className='d-flex flex-column flex-grow-1 pe-4 '>
                  <div className='d-flex flex-wrap'>
                    <div className='card-body p-0'>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>ID </label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>: {appointmentDetails?.code}</span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>
                          Communication Type{' '}
                        </label>
                        <div className='col-lg-8 fv-row'>
                          <span className='fs-6'>: {appointmentDetails?.communicationType}</span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Requirements</label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span className=' fs-6 me-2'>
                            : {appointmentDetails?.languageFrom ?? '-'} -{' '}
                            {appointmentDetails?.languageTo ?? '-'} Interpreter
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Recurrence</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            : {appointmentDetails?.recurrenceName ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Subject</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            : {appointmentDetails?.subject ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Gender</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            : {appointmentDetails?.gender ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Consumer</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            :{' '}
                            {appointmentDetails?.consumer === '' ||
                            appointmentDetails?.consumer === null
                              ? 'N/A'
                              : appointmentDetails?.consumer}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Description</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            :{' '}
                            {appointmentDetails?.description === '' ||
                            appointmentDetails?.description === null
                              ? 'N/A'
                              : appointmentDetails?.description}
                          </span>
                        </div>
                      </div>
                      {appointmentDetails?.communicationTypeIdentification === 'ON_SITE' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Location</label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span className=' fs-6 me-2'>
                              :{' '}
                              {appointmentDetails?.address === ''
                                ? 'N/A'
                                : appointmentDetails?.address}
                            </span>
                          </div>
                        </div>
                      )}
                      {appointmentDetails?.communicationTypeIdentification ===
                        'THIRD_PART_PLATFORM' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Meeting Link</label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span
                              className=' fs-6 me-2 text-nowrap'
                              style={{
                                textOverflow: 'ellipsis',
                                width: '200px',
                                overflow: 'hidden',
                                flexGrow: '1',
                              }}
                            >
                              :{' '}
                              {appointmentDetails?.virtualAddress === '' ? (
                                'N/A'
                              ) : (
                                <a
                                  href={appointmentDetails.virtualAddress}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{color: '#181C32', textDecoration: 'underline'}}
                                >
                                  {appointmentDetails.virtualAddress}
                                </a>
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {appointmentDetails?.communicationTypeIdentification ===
                        'THIRD_PART_PLATFORM' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Pin</label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span className=' fs-6 me-2'>
                              :{' '}
                              {appointmentDetails?.pin === '' || appointmentDetails?.pin === null
                                ? 'N/A'
                                : appointmentDetails?.pin}
                            </span>
                          </div>
                        </div>
                      )}
                      {appointmentDetails?.communicationTypeIdentification ===
                        'THIRD_PART_PLATFORM' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>
                            Meeting ID / Caller Number
                          </label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span className=' fs-6 me-2'>
                              :{' '}
                              {appointmentDetails?.callerNumber === '' ||
                              appointmentDetails?.callerNumber === null
                                ? 'N/A'
                                : appointmentDetails?.callerNumber}
                            </span>
                          </div>
                        </div>
                      )}
                      {appointmentDetails?.communicationTypeIdentification ===
                        'THIRD_PART_PLATFORM' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Remarks</label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span className=' fs-6 me-2'>
                              :{' '}
                              {appointmentDetails?.remarks === '' ||
                              appointmentDetails?.remarks === null
                                ? 'N/A'
                                : appointmentDetails?.remarks}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column flex-grow-1 pe-4 '>
                  <div className='d-flex flex-wrap'>
                    <div className='card-body p-0'>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>
                          Account Priority{' '}
                        </label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            : {appointmentDetails?.accountPriorityName ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>
                          Interpreter Priority{' '}
                        </label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            : {appointmentDetails?.interpreterPriorityName ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>
                          Service Delivery{' '}
                        </label>
                        <div className='col-lg-8 fv-row'>
                          <span className='fs-6'>: {appointmentDetails?.serviceType ?? 'N/A'}</span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Requester</label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span className=' fs-6 me-2'>
                            : {appointmentDetails?.requesterName ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div className='card-body p-0'>
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>
                            Appointed Interpreter{' '}
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <span>
                              :
                              {appointmentDetails?.confirmedInterpreterName === 'Pending' ? (
                                <label className='badge badge-warning ms-3 py-3 px-4 fs-8'>
                                  Pending
                                </label>
                              ) : (
                                appointmentDetails?.confirmedInterpreterName
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '40px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            {currentUser?.result?.userType === 'SYSTEM' && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400   ${
                    activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_1'
                  onClick={() => handleTabClick(1)}
                >
                  Overview
                </a>
              </li>
            )}
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 2 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_2'
                onClick={() => handleTabClick(2)}
              >
                Activities
              </a>
            </li>
            {/* {(currentUser?.result.userType === 'SYSTEM' ||
              currentUser?.result.userType === 'INTERPRETER') && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400 ${
                    activeTab === 3 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_3'
                  onClick={() => handleTabClick(3)}
                >
                  Billing
                </a>
              </li>
            )} */}

            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 4 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_4'
                onClick={() => handleTabClick(4)}
              >
                Documents
              </a>
            </li>
          </ul>
        </div>
        <div className='card-body pt-0'>
          <div className=''>
            <div className='tab-content' id='myTabContent'>
              {activeTab === 1 && currentUser?.result?.userType === 'SYSTEM' && (
                <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                  <div className='card py-0 pt-3 mb-xl-5 mt-3'>
                    {/* <h4 className='mb-3'>Overview</h4> */}
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-semibold text-muted text-uppercase'>
                            <th className='min-w-150px text-uppercase' style={{width: '30%'}}>
                              Service type matched Interpreters({countInterpretersByStatus(3)}/
                              {interpreters?.length})
                            </th>

                            <th className='min-w-100px '>Rate</th>

                            <th className='min-w-100px '>Status</th>
                            {appointmentDetails?.communicationTypeIdentification === 'ON_SITE' && (
                              <th className='min-w-100px '>Distance</th>
                            )}

                            <th className='min-w-100px '>Time Conflict</th>

                            <th className='min-w-50px text-end'>Assign</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {interpreters?.length > 0 ? (
                            interpreters?.map((item: any) => (
                              <tr>
                                <td>
                                  <Link to={`/profile/${item?.fK_Interpreter}`}>
                                    <a className='text-gray-800 text-hover-primary fs-6'>
                                      <div className='d-flex align-items-center'>
                                        {/* <div className='symbol symbol-45px me-5 rounded-circle'>
                                          <img
                                            src='../media/avatars/300-2.jpg'
                                            alt=''
                                            className='rounded-circle'
                                          />
                                        </div> */}
                                        {item.profileImage !== '' ? (
                                          <div className='symbol symbol-45px me-5 rounded-circle'>
                                            <img
                                              src={
                                                process.env.REACT_APP_IMG_URL +
                                                '/profile/' +
                                                item.profileImage
                                              }
                                              alt=''
                                              className='rounded-circle'
                                            />
                                          </div>
                                        ) : (
                                          <div className='symbol symbol-circle symbol-45px overflow-hidden me-3'>
                                            <div
                                              className={clsx(
                                                'symbol-label fs-4',
                                                'badge-light-success',
                                                `bg-light-success`
                                              )}
                                            >
                                              <span style={{fontSize: '20x'}}>
                                                {(item?.name ?? '').charAt(0).toUpperCase() +
                                                  (item?.name ?? '').charAt(1).toUpperCase()}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        <div className='d-flex justify-content-start '>
                                          <div className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                            {item?.name ?? 'N/A'}
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </Link>
                                </td>

                                {currentUser?.result.userType === 'SYSTEM' && (
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-6'>
                                      ${item?.rate ?? 'N/A'}
                                    </a>
                                  </td>
                                )}

                                <td>{statusMapping[item?.assignStatus]}</td>

                                {appointmentDetails?.communicationTypeIdentification ===
                                  'ON_SITE' && (
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-6'>
                                      {item?.distance ?? 'N/A'}
                                    </a>
                                  </td>
                                )}

                                {currentUser?.result.userType === 'SYSTEM' && (
                                  <td>
                                    <div className='d-flex'>
                                      <a
                                        href='#'
                                        className={`btn btn-icon btn-bg-light btn-color-${
                                          item?.isTimeConflict ? 'danger' : 'primary'
                                        } btn-sm`}
                                      >
                                        <i className='bi bi-calendar'></i>
                                      </a>
                                    </div>
                                  </td>
                                )}

                                {currentUser?.result.userType === 'SYSTEM' &&
                                  item?.assignStatus === 1 && (
                                    <td>
                                      <OverlayTrigger
                                        placement='left'
                                        overlay={<Tooltip id='tooltip-filter'>Assign</Tooltip>}
                                      >
                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                          <button
                                            className='btn btn-success btn-sm btn-icon'
                                            disabled={item?.assignStatus !== 1}
                                            onClick={() =>
                                              handleAssign(3, item.code, item?.fK_Interpreter)
                                            }
                                          >
                                            <i className='bi bi-check-lg'></i>
                                          </button>
                                        </div>
                                      </OverlayTrigger>
                                    </td>
                                  )}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                  <div className='text-center'>
                                    <div className='symbol symbol-200px '>
                                      <img src='/media/other/nodata.png' alt='' />
                                    </div>
                                  </div>
                                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                    No matching records found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                  </div>
                </div>
              )}
              {activeTab === 1 && currentUser?.result?.userType !== 'INTERPRETER' && (
                <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                  <div className='card py-0 pt-3 mb-xl-5 mt-3'>
                    {/* <h4 className='mb-3'>Overview</h4> */}
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-semibold text-muted text-uppercase'>
                            <th className='min-w-150px text-uppercase' style={{width: '30%'}}>
                              Service type not matched Interpreters(
                              {countNoMatchInterpretersByStatus(3)}/{notMatchInterpreters?.length})
                            </th>
                            {currentUser?.result.userType !== 'CONSUMER' && (
                              <th className='min-w-100px '>Rate</th>
                            )}
                            <th className='min-w-100px '>Status</th>
                            {appointmentDetails?.communicationTypeIdentification === 'ON_SITE' && (
                              <th className='min-w-100px '>Distance</th>
                            )}
                            {currentUser?.result.userType !== 'CONSUMER' && (
                              <th className='min-w-100px '>Time Conflict</th>
                            )}
                            {currentUser?.result.userType !== 'CONSUMER' && (
                              <th className='min-w-50px text-end'>Invite</th>
                            )}
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {notMatchInterpreters?.length > 0 ? (
                            notMatchInterpreters?.map((item: any) => (
                              <tr>
                                <td>
                                  <Link to={`/profile/${item?.fK_Interpreter}`}>
                                    <a className='text-gray-800 text-hover-primary fs-6'>
                                      <div className='d-flex align-items-center'>
                                        {/* <div className='symbol symbol-45px me-5 rounded-circle'>
                                          <img
                                            src='../media/avatars/300-2.jpg'
                                            alt=''
                                            className='rounded-circle'
                                          />
                                        </div> */}
                                        {item.profileImage !== '' ? (
                                          <div className='symbol symbol-45px me-5 rounded-circle'>
                                            <img
                                              src={
                                                process.env.REACT_APP_IMG_URL +
                                                '/profile/' +
                                                item.profileImage
                                              }
                                              alt=''
                                              className='rounded-circle'
                                            />
                                          </div>
                                        ) : (
                                          <div className='symbol symbol-circle symbol-45px overflow-hidden me-3'>
                                            <div
                                              className={clsx(
                                                'symbol-label fs-4',
                                                'badge-light-success',
                                                `bg-light-success`
                                              )}
                                            >
                                              <span style={{fontSize: '20x'}}>
                                                {(item?.name ?? '').charAt(0).toUpperCase() +
                                                  (item?.name ?? '').charAt(1).toUpperCase()}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        <div className='d-flex justify-content-start '>
                                          <div className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                            {item?.name ?? 'N/A'}
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </Link>
                                </td>

                                {currentUser?.result.userType !== 'CONSUMER' && (
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-6'>
                                      ${item?.rate ?? 'N/A'}
                                    </a>
                                  </td>
                                )}

                                <td>{statusMapping[item?.assignStatus]}</td>

                                {appointmentDetails?.communicationTypeIdentification ===
                                  'ON_SITE' && (
                                  <td>
                                    <a className='text-gray-800 text-hover-primary  fs-6'>
                                      {item?.distance ?? 'N/A'}
                                    </a>
                                  </td>
                                )}

                                {currentUser?.result.userType !== 'CONSUMER' && (
                                  <td>
                                    <div className='d-flex'>
                                      <a
                                        href='#'
                                        className={`btn btn-icon btn-bg-light btn-color-${
                                          item?.isTimeConflict ? 'danger' : 'primary'
                                        } btn-sm`}
                                      >
                                        <i className='bi bi-calendar'></i>
                                      </a>
                                    </div>
                                  </td>
                                )}

                                {currentUser?.result.userType !== 'CONSUMER' && (
                                  <td>
                                    <OverlayTrigger
                                      placement='left'
                                      overlay={<Tooltip id='tooltip-filter'>Invite</Tooltip>}
                                    >
                                      <div className='d-flex justify-content-end flex-shrink-0'>
                                        <button
                                          className='btn btn-success btn-sm btn-icon'
                                          onClick={() =>
                                            handleInviteInterpreter(
                                              item.fK_appoinment,
                                              item?.fK_Interpreter
                                            )
                                          }
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen016.svg'
                                            className='svg-icon-muted svg-icon-1hx'
                                          />
                                        </button>
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                  <div className='text-center'>
                                    <div className='symbol symbol-200px '>
                                      <img src='/media/other/nodata.png' alt='' />
                                    </div>
                                  </div>
                                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                    No matching records found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                  <div className='row g-4'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xl-6 pt-5'>
                      <h3 className='fs-6 fw-semibold text-gray-800 mb-5'>Appointment History</h3>
                      <div className='separator my-3'></div>
                      <div
                        className='scrollable-notes'
                        style={{maxHeight: '50vh', overflowY: 'auto'}}
                      >
                        {activities?.histories?.length > 0 ? (
                          activities?.histories?.map((item: any) => (
                            <>
                              <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                                <div className='card-body p-4'>
                                  <div className='d-flex justify-content-between flex-nowrap w-100'>
                                    <div className='d-flex align-items-center w-100'>
                                      <div className='symbol-group symbol-hover me-3'>
                                        {item.profileImages?.length > 0 ? (
                                          item.profileImages?.map((image: any) =>
                                            image ? (
                                              <div className='symbol symbol-50px symbol-circle d-flex align-items-start'>
                                                <img
                                                  src={
                                                    process.env.REACT_APP_IMG_URL +
                                                    '/profile/' +
                                                    image
                                                  }
                                                  alt=''
                                                  className=''
                                                  style={{objectFit: 'cover'}}
                                                />
                                              </div>
                                            ) : (
                                              <div className='symbol symbol-50px symbol-circle overflow-hidden '>
                                                <div
                                                  className={clsx(
                                                    'symbol-label fs-3',
                                                    'badge-light-success',
                                                    `bg-light-success`
                                                  )}
                                                >
                                                  <span style={{fontSize: '20x'}}>
                                                    {(item?.userName ?? '')
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      (item?.userName ?? '')
                                                        .charAt(1)
                                                        .toUpperCase()}
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )
                                        ) : (
                                          <div className='symbol symbol-50px symbol-circle overflow-hidden'>
                                            <div
                                              className={clsx(
                                                'symbol-label fs-3',
                                                'badge-light-success',
                                                `bg-light-success`
                                              )}
                                            >
                                              <span style={{fontSize: '20x'}}>
                                                {(item?.userName ?? '').charAt(0).toUpperCase() +
                                                  (item?.userName ?? '').charAt(1).toUpperCase()}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className='d-flex flex-column w-100'>
                                        <div className='d-flex justify-content-between w-100 flex-wrap'>
                                          <div className='d-flex flex-wrap'>
                                            <span className='fw-bold d-flex align-items-center fs-6 me-1 me-3'>
                                              {item?.name}
                                            </span>
                                            <div className='d-flex'>
                                              <span className='badge  badge-secondary px-3 fs-8'>
                                                {item?.userName ?? 'N/A'}
                                              </span>
                                            </div>
                                          </div>

                                          <div className='d-flex text-gray-800'>
                                            <span className='fs-8'>
                                              <i className='bi bi-calendar-check me-2'></i>
                                              {moment(item.insertedDateTime).format(
                                                'MM/DD/YY hh:mm A'
                                              )}
                                            </span>
                                          </div>
                                        </div>

                                        <div className='d-flex flex-column flex-1 mt-1'>
                                          <span className='fs-7'>{item?.description ?? 'N/A'}</span>
                                        </div>
                                        {/* <div className='separator my-3 border-white'></div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        ) : (
                          <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                            <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                              <div className='text-center'>
                                <div className='symbol symbol-200px '>
                                  <img src='/media/other/nodata.png' alt='' />
                                </div>
                              </div>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                No matching records found
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xl-6 pt-5'>
                      <h3 className='fs-6 fw-semibold text-gray-800 mb-5'>Notes</h3>
                      <div className='separator my-3'></div>
                      <div
                        className='scrollable-notes'
                        style={{maxHeight: '50vh', overflowY: 'auto'}}
                      >
                        {notes?.length > 0 ? (
                          notes?.map((item: any) => (
                            <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                              <div className='card-body p-4'>
                                <div className='d-flex justify-content-between flex-nowrap w-100'>
                                  <div className='d-flex align-items-center w-100'>
                                    <div className='symbol-group symbol-hover me-3'>
                                      {item.avatar ? (
                                        <div className='symbol symbol-circle symbol-50px'>
                                          <img
                                            src={
                                              process.env.REACT_APP_IMG_URL +
                                              '/profile/' +
                                              item.avatar
                                            }
                                            alt=''
                                            className='rounded-circle'
                                            style={{objectFit: 'cover'}}
                                          />
                                        </div>
                                      ) : (
                                        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                          <div
                                            className={clsx(
                                              'symbol-label fs-3',
                                              'badge-light-success',
                                              `bg-light-success`
                                            )}
                                          >
                                            <span style={{fontSize: '20px'}}>
                                              {(item?.insertedBy ?? '').charAt(0).toUpperCase() +
                                                (item?.insertedBy ?? '').charAt(1).toUpperCase()}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='d-flex flex-column w-100'>
                                      <div className='d-flex justify-content-between w-100 flex-wrap'>
                                        <div className='d-flex flex-wrap'>
                                          <span className='fw-bold d-flex align-items-center fs-6 me-1 me-3'>
                                            {item?.noteHeader}
                                          </span>
                                          <div className='d-flex'>
                                            <span className='badge  badge-secondary px-3 fs-8'>
                                              {item?.insertedByName ?? 'N/A'}
                                            </span>
                                          </div>
                                        </div>

                                        <div className='d-flex text-gray-600'>
                                          <span className='fs-8'>
                                            <i className='bi bi-calendar-check me-2'></i>
                                            {moment(item.insertedDateTime)
                                              .local()
                                              .format('MM/DD/YY hh:mm A')}
                                          </span>
                                        </div>
                                      </div>

                                      <div className='d-flex flex-column flex-1 mt-1'>
                                        <span className='fs-7'>{item?.note ?? 'N/A'}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                            <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                              <div className='text-center'>
                                <div className='symbol symbol-200px '>
                                  <img src='/media/other/nodata.png' alt='' />
                                </div>
                              </div>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                No matching records found
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className='card-body p-0'>
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Notes for PM </label>
                          <div className='col-lg-8 fv-row'>
                            <span className='fs-6'>
                              :{' '}
                              {appointmentDetails?.notePM === '' ||
                              appointmentDetails?.notePM === null
                                ? 'N/A'
                                : appointmentDetails?.notePM}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='card-body p-0'>
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>
                            Notes for Interpreter{' '}
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <span className='fs-6'>
                              :{' '}
                              {appointmentDetails?.noteInterpreter === '' ||
                              appointmentDetails?.noteInterpreter === null
                                ? 'N/A'
                                : appointmentDetails?.noteInterpreter}
                            </span>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel'>
                  <div className='row g-4'>
                    <div className='col-md-12 col-sm-12 pt-5 '>
                      {currentUser?.result?.userType !== 'INTERPRETER' && (
                        <div className='card mb-2 shadow-sm'>
                          <div className='rounded-sm '>
                            <div className='card-header' style={{minHeight: '50px'}}>
                              <div className='d-flex align-items-center'>
                                <h3 className='card-title text-dark'>Account Charges</h3>
                                {/* <UnderDevelopmentBadge className='' /> */}
                              </div>

                              <div className='card-toolbar'>
                                <div className='d-flex align-items-center me-2'>
                                  <a
                                    className='btn btn-color-primary btn-active-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_edit_rate'
                                    style={{fontSize: '12px'}}
                                  >
                                    <i
                                      className='fa-solid fa-pen me-1'
                                      style={{fontSize: '12px'}}
                                    ></i>
                                    Edit Rate
                                  </a>
                                </div>
                                <div className='d-flex align-items-center me-2'>
                                  <a
                                    className='btn btn-color-primary btn-active-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_edit_mileage_expense'
                                    style={{fontSize: '12px'}}
                                  >
                                    <i
                                      className='fa-solid fa-pen me-1'
                                      style={{fontSize: '12px'}}
                                    ></i>
                                    Edit Mileage Expenses Settings
                                  </a>
                                </div>
                                <div className='d-flex align-items-center'>
                                  <a
                                    className='btn btn-color-primary btn-active-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_edit_travel_expense'
                                    style={{fontSize: '12px'}}
                                  >
                                    <i
                                      className='fa-solid fa-pen me-1'
                                      style={{fontSize: '12px'}}
                                    ></i>
                                    Edit Travel Time Expenses Settings
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='card-body py-2'>
                              <div className='w-50 '>
                                <div className='d-flex justify-content-between flex-nowrap w-100'>
                                  <span className='fs-4 fw-bold text-gray-600'>Summary</span>
                                </div>
                                <div className='d-flex flex-column mt-3'>
                                  <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                    <div className='d-flex align-items-center '>
                                      <div className='d-flex flex-column w-auto'>
                                        <span className='fs-6'>Rate</span>
                                      </div>
                                    </div>
                                    <div className='text-dark fw-semibold d-flex'>
                                      $15.00 (20 minutes @ $0.75/min)
                                    </div>
                                  </div>
                                  <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                    <div className='d-flex align-items-center '>
                                      <div className='d-flex flex-column w-auto'>
                                        <span className='fs-6'>Expenses</span>
                                      </div>
                                    </div>
                                    <div className='text-dark fw-semibold d-flex'></div>
                                  </div>
                                  <div className='separator separator-dashed border-dark my-3'></div>
                                  <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                    <div className='d-flex align-items-center '>
                                      <div className='d-flex flex-column w-auto'>
                                        <span className='fs-5 fw-bold'>Estimated Total</span>
                                      </div>
                                    </div>
                                    <div className='text-dark fw-bold d-flex fs-5'>$15.00</div>
                                  </div>
                                  <div className='d-flex text-gray-500'>
                                    <span className='text-danger me-2 fs-4 '>*</span> Minimum and/or
                                    incremental charges may apply
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='py-2 card-footer'>
                              <div className='row g-1'>
                                <div className='d-flex justify-content-between flex-nowrap w-100'>
                                  <span className='fs-4 fw-bold text-gray-600'>
                                    Expense Details
                                  </span>
                                  <div className='d-flex align-items-center mb-2'>
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={<Tooltip id='tooltip-filter'>Add Expense</Tooltip>}
                                    >
                                      <div>
                                        <button
                                          type='button'
                                          className='btn btn-sm btn-primary btn-icon'
                                          data-bs-toggle='modal'
                                          data-bs-target='#kt_new_expense_1'
                                        >
                                          <i className='bi bi-plus fs-2'></i>
                                        </button>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                </div>

                                <div className='py-0 pt-3'>
                                  <div className='table-responsive'>
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
                                      <thead>
                                        <tr className='fw-semibold text-muted text-uppercase'>
                                          <th className='min-w-100px '>Created By</th>
                                          <th className='min-w-100px '>Created</th>
                                          <th className='min-w-100px '>Category</th>
                                          <th className='min-w-100px '>Note</th>
                                          <th className='min-w-100px '>Amount</th>
                                          <th className='min-w-100px text-end'>Action</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <tr>
                                          <td>
                                            <a className='text-gray-800 text-hover-primary fs-6'>
                                              Amila nipun Witharana
                                            </a>
                                          </td>
                                          <td>
                                            <a className='text-gray-800 text-hover-primary fs-6'>
                                              6/4/24 9:12 am
                                            </a>
                                          </td>
                                          <td>
                                            <a className='text-gray-800 text-hover-primary fs-6'>
                                              Other
                                            </a>
                                          </td>

                                          <td>
                                            <a className='text-gray-800 text-hover-primary  fs-6'>
                                              Test
                                            </a>
                                          </td>

                                          <td>
                                            <a className='text-gray-800 text-hover-primary  fs-6'>
                                              $200
                                            </a>
                                          </td>

                                          <td>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                              <a
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_new_expense_1'
                                              >
                                                <KTSVG
                                                  path='/media/icons/duotune/art/art005.svg'
                                                  className='svg-icon-muted'
                                                />
                                              </a>
                                              <a
                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_delete_confirm'
                                              >
                                                <KTSVG
                                                  path='/media/icons/duotune/general/gen027.svg'
                                                  className='svg-icon-muted '
                                                />
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {currentUser?.result?.userType !== 'CONSUMER' && (
                        <div className='card mb-2 shadow-sm'>
                          <div className='rounded-sm '>
                            <div className='card-header' style={{minHeight: '50px'}}>
                              <div className='d-flex align-items-center'>
                                <h3 className='card-title text-dark'>
                                  Interpreter Pay - Soyoung Kim
                                </h3>
                                {/* <UnderDevelopmentBadge className='' /> */}
                              </div>

                              <div className='card-toolbar'>
                                <div className='d-flex align-items-center'>
                                  <a
                                    className='btn btn-color-primary btn-active-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_edit_rate'
                                    style={{fontSize: '12px'}}
                                  >
                                    <i
                                      className='fa-solid fa-pen me-1'
                                      style={{fontSize: '12px'}}
                                    ></i>
                                    Edit Rate
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='card-body py-2'>
                              <div className='w-50 '>
                                <div className='d-flex justify-content-between flex-nowrap w-100'>
                                  <span className='fs-4 fw-bold text-gray-600'>Summary</span>
                                </div>
                                <div className='d-flex flex-column mt-3'>
                                  <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                    <div className='d-flex align-items-center '>
                                      <div className='d-flex flex-column w-auto'>
                                        <span className='fs-6'>Rate</span>
                                      </div>
                                    </div>
                                    <div className='text-dark fw-semibold d-flex'>
                                      $12.00 (20 minutes @ $0.75/min)
                                    </div>
                                  </div>
                                  <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                    <div className='d-flex align-items-center '>
                                      <div className='d-flex flex-column w-auto'>
                                        <span className='fs-6'>Expenses</span>
                                      </div>
                                    </div>
                                    <div className='text-dark fw-semibold d-flex'>$0.01</div>
                                  </div>
                                  <div className='separator separator-dashed border-dark my-3'></div>
                                  <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                    <div className='d-flex align-items-center '>
                                      <div className='d-flex flex-column w-auto'>
                                        <span className='fs-5 fw-bold'>Estimated Total</span>
                                      </div>
                                    </div>
                                    <div className='text-dark fw-bold d-flex fs-5'>$12.01</div>
                                  </div>
                                  {/* <div className='d-flex text-gray-500'>
                                  <span className='text-danger me-2 fs-4 '>*</span> Minimum and/or
                                  incremental charges may apply
                                </div> */}
                                </div>
                              </div>
                            </div>
                            <div className='py-2 card-footer'>
                              <div className='row g-1'>
                                <div className='d-flex justify-content-between flex-nowrap w-100'>
                                  <span className='fs-4 fw-bold text-gray-600'>
                                    Expense Details
                                  </span>
                                  <div className='d-flex align-items-center mb-2'>
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={<Tooltip id='tooltip-filter'>Add Expense</Tooltip>}
                                    >
                                      <div>
                                        <button
                                          type='button'
                                          className='btn btn-sm btn-primary btn-icon'
                                          data-bs-toggle='modal'
                                          data-bs-target='#kt_interpreter_expense'
                                        >
                                          <i className='bi bi-plus fs-2'></i>
                                        </button>
                                      </div>
                                    </OverlayTrigger>
                                    {/* <button
                                    type='button'
                                    className='btn btn-sm btn-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_interpreter_expense'
                                  >
                                    <i className='bi bi-plus' style={{fontSize: '12px'}}></i>
                                    Add Expense
                                  </button> */}
                                  </div>
                                </div>

                                <div className='py-0 pt-3'>
                                  <div className='table-responsive'>
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
                                      <thead>
                                        <tr className='fw-semibold text-muted text-uppercase'>
                                          <th className='min-w-100px '>Created By</th>
                                          <th className='min-w-100px '>Created</th>
                                          <th className='min-w-100px '>Category</th>
                                          <th className='min-w-100px '>Vendor</th>
                                          <th className='min-w-100px '>Note</th>
                                          <th className='min-w-100px '>Receipt</th>
                                          <th className='min-w-100px '>Amount</th>
                                          <th className='min-w-100px text-end'>Action</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <tr>
                                          <td>
                                            <a className='text-gray-800 text-hover-primary fs-6'>
                                              Amila nipun Witharana
                                            </a>
                                          </td>
                                          <td>
                                            <a className='text-gray-800 text-hover-primary fs-6'>
                                              6/4/24 9:12 am
                                            </a>
                                          </td>
                                          <td>
                                            <a className='text-gray-800 text-hover-primary fs-6'>
                                              Other
                                            </a>
                                          </td>

                                          <td>
                                            <a className='text-gray-800 text-hover-primary  fs-6'>
                                              Ven01
                                            </a>
                                          </td>
                                          <td>
                                            <a className='text-gray-800 text-hover-primary  fs-6'>
                                              Test
                                            </a>
                                          </td>

                                          <td>
                                            <a className='text-gray-800 text-hover-primary  fs-6 px-2 py-1 border border border-dashed border-primary rounded'>
                                              <i
                                                className='bi bi-paperclip text-primary '
                                                style={{fontSize: '1rem'}}
                                              ></i>
                                              <span
                                                className='text-gray-600 '
                                                style={{fontSize: '12px'}}
                                              >
                                                Name
                                              </span>
                                            </a>
                                          </td>
                                          <td>
                                            <a className='text-gray-800 text-hover-primary  fs-6'>
                                              $300
                                            </a>
                                          </td>

                                          <td>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                              <a
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_interpreter_expense'
                                              >
                                                <KTSVG
                                                  path='/media/icons/duotune/art/art005.svg'
                                                  className='svg-icon-muted'
                                                />
                                              </a>

                                              <a
                                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_delete_confirm'
                                              >
                                                <KTSVG
                                                  path='/media/icons/duotune/general/gen027.svg'
                                                  className='svg-icon-muted '
                                                />
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 4 && (
                <>
                  <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
                    <div className='card py-0 mb-xl-5 mt-3'>
                      <div className='d-flex align-items-center justify-content-end'>
                        {currentUser?.result.userType !== 'INTERPRETER' &&
                          appointmentDetails?.status !== 6 && (
                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip id='tooltip-filter'>Add Document</Tooltip>}
                            >
                              <div>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-primary btn-icon'
                                  data-bs-toggle='modal'
                                  data-bs-target='#document'
                                >
                                  <i className='bi bi-plus fs-2'></i>
                                </button>
                              </div>
                            </OverlayTrigger>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='py-0'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-semibold text-muted text-uppercase'>
                            {/* <th className='min-w-150px '>Appointment ID</th> */}
                            <th className='min-w-250px '>Name</th>
                            <th className='min-w-150px '>Description</th>
                            <th className='min-w-150px '>Date</th>
                            <th className='min-w-100px'>Type</th>
                            <th className='min-w-200px text-end'>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {documents?.length > 0 ? (
                            documents.map((item: any, index: number) => (
                              <tr style={{cursor: 'pointer'}}>
                                <td>
                                  <span className='text-gray-800 text-hover-primary fs-6'>
                                    {item?.name}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-800 text-hover-primary fs-6'>
                                    {item?.description ?? 'N/A'}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-800 text-hover-primary fs-6'>
                                    {moment(item?.createdDateTime).format('MM/DD/YYYY hh:mm A')}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-800 text-hover-primary fs-6'>
                                    {getFileType(item?.fileName)}
                                  </span>
                                </td>
                                <td className='text-end'>
                                  <DocumentViewModal
                                    documentCode={item?.code}
                                    fileName={item?.fileName}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                  <div className='text-center'>
                                    <div className='symbol symbol-200px '>
                                      <img src='/media/other/nodata.png' alt='' />
                                    </div>
                                  </div>
                                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                    No matching records found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <CommonPaginationModel
                      currentPage={currentPage}
                      totalPages={totalPages}
                      rowsPerPage={rowsPerPage}
                      total={totalItems}
                      onPageChange={setCurrentPage}
                      onRowsPerPageChange={(newRowsPerPage: number) =>
                        setRowsPerPage(newRowsPerPage)
                      }
                    />
                  </div>
                </>
              )}
            </div>
            {isOpenConfirmationModal && (
              <DeleteConfirmationModal
                title='Confirmation'
                content={message}
                onSubmit={async () => await handleAppoinmentCancelation()}
                setOpen={setIsOpenConfirmationModal}
              />
            )}
          </div>
        </div>
        {isLoading && <CommonLoading />}
      </div>
    </>
  )
}
