/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()

  if (currentUser?.result.userType == 'SYSTEM') {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/layouts/lay001.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/appointment/1'
          icon='/media/icons/duotune/files/fil002.svg'
          title='Appointment'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/billing/2'
          icon='/media/icons/duotune/finance/fin002.svg'
          title='Billing'
          fontIcon='bi-layers'
        />
        <SidebarMenuItemWithSub
          to='/insight'
          icon='/media/icons/duotune/abstract/abs030.svg'
          title='Insights'
          fontIcon='bi-insight'
        >
          <SidebarMenuItemWithSub to='/logs' title='Logs' fontIcon='bi-logs' hasBullet={true}>
            <SidebarMenuItem to='/logdata/all' title='Login Details' hasBullet={true} />
            <SidebarMenuItem to='/precalldata/all' title='Pre Call Details' hasBullet={true} />
            <SidebarMenuItem
              to='/premissionsChnage/all'
              title='Premissions Details'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </SidebarMenuItemWithSub>

        <SidebarMenuItem
          to={'/profile/' + currentUser.result.code}
          icon='/media/icons/duotune/communication/com005.svg'
          title='Profile'
          fontIcon='bi-layers'
        />
        <div className='separator my-3 border-1 border-gray-300'></div>
        <div className='menu-item'>
          <div className='menu-content mb-3 ms-1'>
            <span className='menu-section text-uppercase fs-5 fw-bold' style={{color: '#D7383C'}}>
              Administrator
            </span>
          </div>
        </div>
        <SidebarMenuItem
          to={'/customers'}
          icon='/media/icons/duotune/general/gen022.svg'
          title='Customers'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/members/1'
          icon='/media/icons/duotune/communication/com006.svg'
          title='Members'
          fontIcon='bi-layers'
        />
        <SidebarMenuItemWithSub
          to='/crafted/pages/profile'
          icon='/media/icons/duotune/coding/cod001.svg'
          title='Configuration'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/apps/language/all' title='Language' hasBullet={true} />
          <SidebarMenuItem to='/apps/service-type/all' title='Service Types' hasBullet={true} />
          {/*<SidebarMenuItem to='/apps/special-type/all' title='Specialized Types' hasBullet={true} />*/}
          {/*<SidebarMenuItem to='/apps/industry/all' title='Industries' hasBullet={true} />*/}
          <SidebarMenuItem
            to='/apps/communication/all'
            title='Communication Type'
            hasBullet={true}
          />
          <SidebarMenuItem to='/apps/gender/all' title='Gender' hasBullet={true} />
          <SidebarMenuItem to='/apps/ivr/all' title='IVR' hasBullet={true} />
          {/* <SidebarMenuItem to='/apps/time-zone/all' title='Time Zones' hasBullet={true} /> */}
          <SidebarMenuItem to='/apps/country-management/all' title='Countries' hasBullet={true} />
          <SidebarMenuItem to='/email/configuration' title='Emails' hasBullet={true} />
          {process.env.REACT_APP_SERVER === 'DEV' && (
            <SidebarMenuItem
              to='/email/configuration-duplicate'
              title='Emails Duplicate'
              hasBullet={true}
            />
          )}
          <SidebarMenuItem to='/billConfig/configuration' title='Billing' hasBullet={true} />
          <SidebarMenuItem
            to='/apps/SystemVariables/all'
            title='System Variables'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
        {currentUser?.result.role !== 'Manager' && (
          <SidebarMenuItem
            to={'/company-profile/' + currentUser?.result.fK_Customer}
            icon='/media/icons/duotune/general/gen022.svg'
            title='Company Profile'
            fontIcon='bi-layers'
          />
        )}
        {/* <SidebarMenuItem
          to='/quick-start'
          icon='/media/icons/duotune/general/gen002.svg'
          title='Quick Start'
          fontIcon='bi-layers'
        /> */}
      </>
    )
  } else if (currentUser?.result.userType == 'INTERPRETER') {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/layouts/lay001.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/appointment/1'
          icon='/media/icons/duotune/files/fil002.svg'
          title='Appointment'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/billing/1'
          icon='/media/icons/duotune/finance/fin002.svg'
          title='Billing'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to={'/profile/' + currentUser.result.code}
          icon='/media/icons/duotune/communication/com005.svg'
          title='Profile'
          fontIcon='bi-layers'
        />
      </>
    )
  } else if (currentUser?.result.userType == 'CONSUMER') {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/layouts/lay001.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/appointment/1'
          icon='/media/icons/duotune/files/fil002.svg'
          title='Appointment'
          fontIcon='bi-layers'
        />
        {currentUser?.result?.role !== 'User' && (
          <SidebarMenuItem
            to='/billing/1'
            icon='/media/icons/duotune/finance/fin002.svg'
            title='Billing'
            fontIcon='bi-layers'
          />
        )}

        <SidebarMenuItem
          to={'/profile/' + currentUser.result.code}
          icon='/media/icons/duotune/communication/com005.svg'
          title='Profile'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to={'/customers/' + currentUser?.result.fK_Customer}
          icon='/media/icons/duotune/general/gen022.svg'
          title='Company Profile'
          fontIcon='bi-layers'
        />
      </>
    )
  } else {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/layouts/lay001.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      </>
    )
  }

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/layouts/lay001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/appointment'
        icon='/media/icons/duotune/files/fil002.svg'
        title='Appointment'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/appointment/interpreter'
        icon='/media/icons/duotune/files/fil002.svg'
        title='Interpreter Appointment'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/billing'
        icon='/media/icons/duotune/finance/fin002.svg'
        title='Billing'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/members/1'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Members'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/customers'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
        title='Customers'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/configuration'
        icon='/media/icons/duotune/coding/cod001.svg'
        title='Configuration'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/company'
        icon='/media/icons/duotune/general/gen022.svg'
        title='Ad Astra'
        fontIcon='bi-layers'
      />

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/apps/user-role-management/roles'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User Role management'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
