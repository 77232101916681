/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import {Popover, OverlayTrigger, Tooltip} from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {useAuth} from '../../../modules/auth'
import {getTotalAppointments} from '../../../../Utils/commonData'
import SmallCalendarComponent from './SmallCalendarComponent'
import {CalandarFilterDropdown} from './CalandarFilterDropdown'

const API_URL = process.env.REACT_APP_API_URL

// Add some CSS to style the event content
const styles = `
.fc-timegrid-slot-label {
  font-size: 10px !important; 
}
.fc-timeline-slot-cushion{
  font-size: 10px !important; 
  color: #333333 !important;
  font-weight: 500 !important;
  }

.fc-timegrid-slot {
  height: 1rem;
  font-size: 0.95rem;
}
.event-content {
  display: flex;
  align-items: center;
  cursor:pointer;
}
.event-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
}
.event-title {
  font-size: 10px;
}
.fc-daygrid-table {
  font-size: 12px !important;
}
#small .fc-toolbar-title {
  font-size: 12px !important;
  margin-right: 10px !important;
}
#small .fc-col-header-cell .fc-col-header-cell-cushion {
  font-size: 8px !important;
}
#small .fc-daygrid-day-top {
    display: flex;
    justify-content: center;
}

.fc-daygrid-day-number {
  font-size: 8px !important;
}

 #small .fc .fc-col-header-cell-cushion {
  font-size: 8px !important;
  width: 2.3ch !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  padding: 0 !important;
}
  #small .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 0 !important;  
}
  #small .fc .fc-daygrid-day-number {
  font-size: 8px !important;
}
  #small .fc .fc-highlight {
  background-color: #a3a242 !important;
}
  #small .fc .fc-button {
  padding: 0.30rem .5rem !important;
}
  #small  .fc .fc-button-primary .fc-icon {
  font-size: 1rem !important;
}
  #small .fc .fc-highlight {
  background-color: #ebea9b !important;
}
  #small .fc-event-title-container {
  height: 1px !important;
}
  #small .fc-theme-standard td{
  cursor:pointer;
}
 #small .fc .fc-daygrid-day.fc-day-today{
  background-color: #f5f8fa !important;
 }
 #small .fc .fc-bg-event{
  opacity: 1 !important;
 }
 
`
const CustomerFullCalendarList = () => {
  const {id} = useParams()
  const calendarRef = useRef<FullCalendar>(null)
  const navigate = useNavigate()
  const [date, setDate] = useState<any>(moment().local().format('YYYY-MM-DD'))
  const [appoinments, setAppoinments] = useState<any>([])
  const [resources, setResources] = useState<any>([])
  const [events, setEvents] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [visiblePopover, setVisiblePopover] = useState<string | null>(null)
  const {currentUser} = useAuth()
  const [availableDates, setAvailableDates] = useState<any>([])
  const [appointmentStatistics, setAppointmentStatistics] = useState<any>([])

  const fetchAppoinmentDetailsUsingDate = async (date: string, payload: any) => {
    setIsLoading(true)
    try {
      let response = await axios.post(`${API_URL}/Appoinment/get-appointents/filter/${date}`, {
        ...payload,
        accounts: [id],
      })
      setAvailableDates(response?.data?.dates)
      setAppoinments(response?.data?.appointments)
      setAppointmentStatistics(response?.data?.statics ?? 0)

      setResources(
        response?.data?.appointments?.map((x: any) => ({
          id: x?.code,
          title: `${x?.code} - ${x?.languageFrom} to ${x?.languageTo}`,
        }))
      )

      const selectedDate = moment(date).startOf('day')

      setEvents(
        response?.data?.appointments.flatMap((x: any) => {
          const events = []
          const start = moment(x?.startTime)
          const end = moment(x?.endTime)
          const isEndDate = end.isSame(selectedDate, 'day')

          let color
          let textColor
          switch (x?.status) {
            case 0:
              color = 'rgba(215, 56, 60, 0.7)' 
              break
            case 1:
              color = 'rgba(163, 162, 66, 0.7)'
              break
            case 2:
              color = 'rgba(96, 187, 26, 0.7)' 
              break
            case 3:
              color = 'rgba(255, 199, 0, 0.7)' 
              break
            case 4:
              color = 'rgba(227, 117, 155, 0.7)' 
              break
            case 5:
              color = 'rgba(24, 28, 50, 0.7)' 
              break
            case 6:
              color = 'rgba(228, 230, 239, 0.7)'
              textColor = 'black'
              break
            default:
              color = ''
          }

          // If event start and end in selected date
          if (start.isSame(selectedDate, 'day') && end.isSame(selectedDate, 'day')) {
            events.push({
              title: x?.code,
              start: start.format('YYYY-MM-DDTHH:mm:ss'),
              end: end.format('YYYY-MM-DDTHH:mm:ss'),
              avatar:
                x?.requesterProfileImage === ''
                  ? `${toAbsoluteUrl('/media/avatars/blank.png')}`
                  : `${process.env.REACT_APP_IMG_URL}/profile/${x?.requesterProfileImage}`,
              resourceId: x?.code,
              color: color,
              textColor: textColor,
            })
          }
          // If event not start and end in selected date
          else {
            // if selected date between the start and end date
            if (start.isBefore(selectedDate, 'day') && !isEndDate) {
              events.push({
                title: x?.code,
                start: selectedDate.format('YYYY-MM-DDT00:00:00'),
                end: selectedDate.clone().endOf('day').format('YYYY-MM-DDT23:59:59'),
                avatar:
                  x?.requesterProfileImage === ''
                    ? `${toAbsoluteUrl('/media/avatars/blank.png')}`
                    : `${process.env.REACT_APP_IMG_URL}/profile/${x?.requesterProfileImage}`,
                resourceId: x?.code,
              color: color,
              textColor: textColor,
              })
            }

            // If event start before selected date and, end in selected date
            if (isEndDate) {
              events.push({
                title: x?.code,
                start: selectedDate.format('YYYY-MM-DDT00:00:00'),
                end: end.format('YYYY-MM-DDTHH:mm:ss'),
                avatar:
                  x?.requesterProfileImage === ''
                    ? `${toAbsoluteUrl('/media/avatars/blank.png')}`
                    : `${process.env.REACT_APP_IMG_URL}/profile/${x?.requesterProfileImage}`,
                resourceId: x?.code,
              color: color,
              textColor: textColor,
              })
            }

            // if event start selected date and end in future date
            if (start.isSame(selectedDate, 'day') && !isEndDate) {
              events.push({
                title: x?.code,
                start: start.format('YYYY-MM-DDTHH:mm:ss'),
                end: selectedDate.clone().endOf('day').format('YYYY-MM-DDT23:59:59'),
                avatar:
                  x?.requesterProfileImage === ''
                    ? `${toAbsoluteUrl('/media/avatars/blank.png')}`
                    : `${process.env.REACT_APP_IMG_URL}/profile/${x?.requesterProfileImage}`,
                resourceId: x?.code,
              color: color,
              textColor: textColor,
              })
            }
          }
          return events
        })
      )
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (date) {
      fetchAppoinmentDetailsUsingDate(date, {})
    }
  }, [date])

  const handleDateSelect = (selectInfo: any) => {
    setDate(moment(selectInfo?.startStr).local().format('YYYY-MM-DD')) // store the selected date in state
  }

  useEffect(() => {
    // Initialize FullCalendar instance and store it in ref
    if (calendarRef.current) {
      // Configure FullCalendar with plugins and initial options
      calendarRef.current.getApi().gotoDate(date) // Initial gotoDate call
    }
  }, [date])

  const renderEventContent = (eventInfo: any) => {
    const time = eventInfo.timeText
    const appoimentDetails = appoinments.find((x: any) => x?.code == eventInfo?.event?.title)

    return (
      <OverlayTrigger
        trigger={'click'}
        placement='top'
        show={visiblePopover === eventInfo?.event?.title}
        onToggle={() => setVisiblePopover(eventInfo?.event?.title)}
        overlay={
          <Popover id={`popover-${eventInfo?.event?.title}`} style={{minWidth: '300px'}}>
            <Popover.Header as='h5' className='py-2'>
              <div className='fw-bold fs-8'>{appoimentDetails?.customerName}</div>
            </Popover.Header>
            <Popover.Body className='pt-1 pb-2'>
              <div>
                <div className='d-flex flex-column my-2'>
                  <div className='d-flex text-gray-700 fs-7 mb-2'>
                    <span className='bg-light-success p-2 text-success w-100 rounded'>
                      {appoimentDetails?.communicationType}
                    </span>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <span className='d-flex text-gray-500 fs-8'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen014.svg'
                        className='svg-icon-muted me-2'
                      />
                      {moment(appoimentDetails?.startTime).format('DD/MM/YYYY')}{' '}
                    </span>
                    <span className='d-flex text-gray-400 fs-8'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen013.svg'
                        className='svg-icon-muted me-2'
                      />
                      {moment(appoimentDetails?.startTime).format('HH:mm A')}-
                      {moment(appoimentDetails?.endTime).format('HH:mm A')}{' '}
                    </span>
                  </div>
                </div>
                <div className='d-flex'>
                  <p className='text-gray-700 fs-7 mb-0 me-2'>
                    {appoimentDetails?.languageFrom} to {appoimentDetails?.languageTo}
                  </p>
                  <span className='text-gray-500 fs-8'>(Adstra Text Internal)</span>
                </div>
                <div className='d-flex mt-2'>
                  <button
                    type='button'
                    className='btn btn-sm btn-secondary w-100 py-1 px-4 fs-8 me-2'
                    onClick={() => setVisiblePopover(null)}
                  >
                    Close
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm btn-info w-100 py-1 px-4 fs-8'
                    onClick={() => navigate(`/appointmentViewer/${appoimentDetails?.code}`)}
                  >
                    Open
                  </button>
                </div>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div className='event-content' onClick={() => setVisiblePopover(eventInfo?.event?.title)}>
          <img
            src={eventInfo?.event?.extendedProps?.avatar}
            alt='Avatar'
            className='event-avatar'
          />
          <div className='event-title'>
            {eventInfo?.event?.title}
            <div className='event-time'>{time}</div>
          </div>
        </div>
      </OverlayTrigger>
    )
  }

  return (
    <>
      <div className='row g-2'>
        <div className='col-sm-12 col-md-3 col-lg-2'>
          <div className='pe-2' style={{width: '100%'}}>
            <SmallCalendarComponent date={date} setDate={setDate} availableDates={availableDates} />
          </div>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-10'>
          <div className='mb-2 d-flex justify-content-between align-items-end flex-wrap'>
            <div className='d-flex flex-row'>
              <div className='d-flex align-items-center px-3 py-2 bg-light-dark me-2 rounded mb-1 text-dark'>
                <span className='fw-bold fs-5 me-2'>
                {Math.ceil(
                    Number(
                      getTotalAppointments(currentUser?.result.userType, appointmentStatistics)
                    ) || 0
                  )}
                </span>
                <span className='fw-noraml fs-7'>Total</span>
              </div>
            </div>

            <div className='d-flex flex-row flex-wrap'>
              {currentUser?.result.userType === 'CONSUMER' ? (
                <div className='d-flex align-items-center  px-3 py-2 bg-light me-2 rounded mb-1'>
                  <span className='badge badge-circle badge-danger me-2 '>
                    {parseInt(appointmentStatistics?.open) +
                      parseInt(appointmentStatistics?.readyToAssign) ?? 0}
                  </span>
                  <span className='fw-semibolder fs-7'>Open</span>
                </div>
              ) : (
                <div className='d-flex align-items-center  px-3 py-2 bg-light me-2 rounded mb-1'>
                  <span className='badge badge-circle badge-danger me-2 '>
                    {appointmentStatistics?.open ?? 0}
                  </span>
                  <span className='fw-semibolder fs-7'>Open</span>
                </div>
              )}

              {currentUser?.result.userType === 'INTERPRETER' && (
                <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
                  <span className='badge badge-circle badge-success me-2 '>
                    {appointmentStatistics?.readyToAssign ?? 0}
                  </span>
                  <span className='fw-semibolder fs-7'>Accepted</span>
                </div>
              )}

              {currentUser?.result.userType === 'SYSTEM' && (
                <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
                  <span className='badge badge-circle badge-primary me-2 '>
                    {appointmentStatistics?.readyToAssign ?? 0}
                  </span>
                  <span className='fw-semibolder fs-7'>Ready to Assign</span>
                </div>
              )}

              <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
                <span className='badge badge-circle badge-success me-2 '>
                  {appointmentStatistics?.confirmed ?? 0}
                </span>
                <span className='fw-semibolder fs-7'>Confirmed</span>
              </div>

              {(currentUser?.result.userType === 'SYSTEM' ||
                currentUser?.result.userType === 'CONSUMER') && (
                <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
                  <span className='badge badge-circle badge-warning me-2 '>
                    {appointmentStatistics?.noInterpreter ?? 0}
                  </span>
                  <span className='fw-semibolder fs-7'>No Interpreters</span>
                </div>
              )}

              <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
                <span className='badge badge-circle badge-info me-2 '>
                  {appointmentStatistics?.abandoned ?? 0}
                </span>
                <span className='fw-semibolder fs-7'>Abandoned</span>
              </div>

              <div className='d-flex align-items-center px-3 py-2 bg-light me-2 rounded mb-1'>
                <span className='badge badge-circle badge-dark me-2 '>
                  {appointmentStatistics?.completed ?? 0}
                </span>
                <span className='fw-semibolder fs-7'>Complete</span>
              </div>

              <div className='d-flex align-items-center px-3 py-2 bg-light  rounded mb-1'>
                <span className='badge badge-circle badge-secondary me-2 '>
                  {appointmentStatistics?.cancelled ?? 0}
                </span>
                <span className='fw-semibolder fs-7'>Cancelled</span>
              </div>
            </div>
            <div className='d-flex mb-1'>
              <div className='me-3'>
                <CalandarFilterDropdown
                  fetchAppoinmentDetailsUsingDate={fetchAppoinmentDetailsUsingDate}
                  date={date}
                />
              </div>
              {/* {currentUser?.result?.userType !== 'INTERPRETER' && (
                <div>
                  <Link to='/create-appointment'>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip id='tooltip-filter'>Create Appointment</Tooltip>}
                    >
                      <div>
                        <button type='button' className='btn btn-sm btn-primary btn-icon'>
                          <i className='bi bi-plus fs-2'></i>
                        </button>
                      </div>
                    </OverlayTrigger>
                  </Link>
                </div>
              )} */}
            </div>
          </div>
          <FullCalendar
            ref={calendarRef}
            schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
            initialDate={date}
            slotLabelFormat={{
              hour: 'numeric',
            }}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
              resourceTimelinePlugin,
            ]}
            initialView='resourceTimeline'
            headerToolbar={{
              left: 'today',
              center: 'title',
              right: 'prev,next',
            }}
            datesSet={handleDateSelect}
            resourceAreaHeaderContent='Appointments'
            resources={resources}
            events={events}
            editable={false}
            selectable={false}
            eventDrop={(info) => {
              console.log('Event dropped', info)
            }}
            eventResize={(info) => {
              console.log('Event resized', info)
            }}
            eventContent={renderEventContent}
          />
        </div>
      </div>
      {isLoading && <CommonLoading />}
    </>
  )
}

const styleSheet = document.createElement('style')
styleSheet.type = 'text/css'
styleSheet.innerText = styles
document.head.appendChild(styleSheet)

export {CustomerFullCalendarList}
