import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import CompanyAccountMap from './CompanyAccountMap'
import {Customer} from './users-list/core/_models'
import {UsersViewTable} from '../customer-location-management/UsersViewTable'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import UnderDevelopmentBadge from '../../common/componenets/underDevelopment/underDevelopmentBadge'

type Props = {
  className: string
  CustomerModel?: Customer
  isLoading?: Boolean
}

const LocationInfo: React.FC<Props> = ({className, CustomerModel}) => {
  const [showAdditionalUI, setShowAdditionalUI] = useState(false)
  const [showInfoUI, setShowInfoUI] = useState(true)

  function handleAuthenticationClick() {
    setShowAdditionalUI(true)
    setShowInfoUI(false) // Hide Info UI
  }

  function handleInfoClick() {
    setShowInfoUI(true)
    setShowAdditionalUI(false) // Hide Authentication UI
  }

  return (
    <>
      <div className='card p-0'>
        <div className='rounded'>
          <div className='card-body p-0'>
            <div className={`card ${className}`}>
              <div className=' px-0' style={{borderBottom: 'none'}}>
                <div className='card-title d-flex align-items-center position-relative me-4 '></div>
                <div className='card-toolbar'>
                  <div className='modal fade' tabIndex={-1} id='kt_modal_5'>
                    <div className='modal-dialog'>
                      <div className='modal-content'>
                        <div className='modal-header py-2'>
                          <h4 className='modal-title'>Add Location</h4>
                          <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </div>
                        <div className='modal-body'>
                          <div className='row g-4 g-xl-6 d-flex align-items-center'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className=''>
                                <div className='row g-4 g-xl-6'>
                                  <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Name
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm'
                                      placeholder='Enter Name'
                                    />
                                  </div>
                                  <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Location
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm'
                                      placeholder='Enter Location'
                                    />
                                  </div>
                                  <div className='col-sm-12 col-md-12 col-lg-12'>
                                    <div>
                                      <CompanyAccountMap />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer py-3'>
                          <button
                            type='button'
                            className='btn btn-light btn-sm'
                            data-bs-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <Link to='#'>
                            <button
                              type='button'
                              className='btn btn-primary btn-sm'
                              data-bs-dismiss='modal'
                            >
                              Save
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='modal fade' tabIndex={-1} id='kt_modal_6'>
                    <div className='modal-dialog'>
                      <div className='modal-content modal-lg'>
                        <div className='modal-header py-2'>
                          <h4 className='modal-title'>Create New Billable Party</h4>
                          <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </div>
                        <div className='modal-body'>
                          <div className='row g-4 g-xl-6'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Billable Name
                                </label>
                                <div>
                                  <input
                                    type='text'
                                    className='form-control form-control-white form-select-sm custom-input-height'
                                    placeholder='Enter Billable Name'
                                  />
                                </div>
                              </div>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Attn
                                </label>
                                <div>
                                  <input
                                    type='text'
                                    className='form-control form-control-white form-select-sm custom-input-height'
                                    placeholder='Enter Attn'
                                  />
                                </div>
                              </div>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Address
                                </label>

                                <div className='row g-3 g-xl-4'>
                                  <div className='col-sm-6 col-md-6 col-lg-6 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm  custom-input-height mb-2'
                                      placeholder='Street Address'
                                    />
                                  </div>
                                  <div className='col-sm-6 col-md-6 col-lg-6 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm  custom-input-height mb-2'
                                      placeholder='Apt/Suite'
                                    />
                                  </div>
                                </div>
                                <div className='row g-3 g-xl-4'>
                                  <div className='col-sm-6 col-md-6 col-lg-4 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm  custom-input-height mb-2'
                                      placeholder='City'
                                    />
                                  </div>
                                  <div className='col-sm-6 col-md-6 col-lg-4 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm  custom-input-height mb-2'
                                      placeholder='State/Region'
                                    />
                                  </div>
                                  <div className='col-sm-6 col-md-6 col-lg-4 '>
                                    <input
                                      type='text'
                                      className='form-control form-control-white form-select-sm  custom-input-height mb-2'
                                      placeholder='Postal Code'
                                    />
                                  </div>
                                </div>
                                <div className='row g-3 g-xl-4'>
                                  <div className='col-sm-12 col-md-12 col-lg-12 '>
                                    <select
                                      className='form-select form-select-sm  custom-input-height form-select-white mb-2'
                                      data-kt-select2='true'
                                      data-placeholder='Select option'
                                      data-allow-clear='true'
                                    >
                                      <option style={{color: '#ededed'}}>Select Country</option>
                                      <option value='1'>United States</option>
                                      <option value='2'>Uganda</option>
                                      <option value='2'>Zambia</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Email
                                </label>
                                <div>
                                  <input
                                    type='email'
                                    className='form-control form-control-white form-select-sm custom-input-height'
                                    placeholder='Enter Email'
                                  />
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Phone
                                </label>
                                <div>
                                  <input
                                    type='tel'
                                    className='form-control form-control-white form-select-sm custom-input-height'
                                    placeholder='Enter Mobile Number'
                                  />
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  IVR
                                </label>
                                <div>
                                  <input
                                    type='text'
                                    className='form-control form-control-white form-select-sm custom-input-height'
                                    placeholder='Enter Unique Identifier'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer py-3'>
                          <button
                            type='button'
                            className='btn btn-light btn-sm'
                            data-bs-dismiss='modal'
                          >
                            Cancel
                          </button>
                          <Link to='#'>
                            <button
                              type='button'
                              className='btn btn-primary btn-sm'
                              data-bs-dismiss='modal'
                            >
                              Save
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <>
                <div className='mb-5 mb-xl-5'>
                  <div className='card-body p-0'>
                    <UsersViewTable
                      customerCode={Number(CustomerModel?.code)}
                      userType={''}
                      className={''}
                    />
                  </div>
                </div>
                {!CustomerModel?.isSystemCustomer && (
                  <div className='card mb-5 mb-xl-5 border-muted border-top border-2'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center'>
                            <h5 className='text-black fs-4 fw-semibold mb-0'>
                              Third Party Billing
                            </h5>
                          </div>
                          <div className='d-flex flex-wrap fs-6 '>
                            <p
                              className='text-gray-500 mb-0 fw-normal'
                              style={{fontSize: '12px', maxWidth: '900px'}}
                            >
                              Billing Details
                            </p>
                          </div>
                        </div>
                        {/* <UnderDevelopmentBadge className='' /> */}
                      </div>
                      <div className='card-toolbar'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap'>
                          <div className='text-end'>
                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip id='tooltip-filter'>Add Billable Party</Tooltip>}
                            >
                              <div>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-primary btn-icon'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_6'
                                >
                                  <i className='bi bi-plus fs-2'></i>
                                </button>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-body py-4'>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='d-flex flex-wrap'>
                            <div className='card-body p-0 bg-white'>
                              <div className='py-0'>
                                <div className='table-responsive'>
                                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-responsive overflow-hidden'>
                                    <thead>
                                      <tr className='fw-semibold text-muted text-uppercase'>
                                        <th className='min-w-50px'>Billiable Name</th>
                                        <th className='min-w-50px'>Email</th>
                                        <th className='min-w-50px text-end'>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody style={{maxHeight: '50px', overflowY: 'auto'}}>
                                      <tr>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary fs-6'>
                                            New Billi
                                          </a>
                                        </td>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary fs-6'>
                                            bill@gmail.com
                                          </a>
                                        </td>
                                        <td>
                                          <div className='d-flex justify-content-end flex-shrink-0'>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/art/art005.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                            <button
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                              type='button'
                                            >
                                              <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-3'
                                              />
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {LocationInfo}
