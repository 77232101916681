import React, {useState, useEffect, useRef} from 'react'
import {useParams} from 'react-router'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {AccountOverview} from './AccountOverview'
import {UsersViewTable} from './../members-management/UsersViewTable'
import {useQuery} from 'react-query'
import {getCustomerById, updateCustomer} from './users-list/core/_requests'
import {
  getMasterData,
  getNativeLanguage,
  getTimeZone,
  getCountries,
} from '../Common/components/core/_requests'
import {useSearchParams, Navigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {LocationInfo} from './LocationInfo'
import {useAuth} from '../../../app/modules/auth'
import ImageComponent from '../configuration-management/components/ImageComponent'
import toaster from '../../../Utils/toaster'
import {ListWrapper} from '../master-data/precall-management/precall-policy-management/list/List'
import {RoutingView} from './routing/RoutingView'
import {QuickDialsView} from './quick-dials/QuickDialsView'
import {AppointmentView} from './appointment/AppointmentView'
import {FeatureView} from './feature/FeatureView'
import Select from 'react-select'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete'
import {LogsView} from './Logs/LogsView'
import {CustomerFullCalendarList} from './calendar/CustomerFullCalendarList'
import axios from 'axios'
import {RateAccountView} from './rates/RateAccountView'
import {useNavigate} from 'react-router-dom'

const API_URL = process.env.REACT_APP_API_URL

interface RenderFuncProps {
  getInputProps: () => React.InputHTMLAttributes<HTMLInputElement>
  getSuggestionItemProps: (suggestion: Suggestion) => React.HTMLAttributes<HTMLDivElement>
  suggestions: any
  loading?: boolean // Optional loading indicator
  error: any
}

const center = {
  lat: -3.745,
  lng: -38.523,
}

interface IVROption {
  isGlobal: boolean
  value: string
}

const styles = `
.phoneNumber input{
    border: none; 
    outline: none; 

  }
`
type Props = {
  isUserLoading?: boolean
}

const CustomerProfile: React.FC<Props> = ({isUserLoading}) => {
  let {id} = useParams()
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState('')
  const [IVRData, setIVRData] = useState<string | any>(null)
  const {currentUser, logout} = useAuth()
  const hiddenFileInput = useRef<any>(null)
  //const { refetch } = useQueryResponse()
  const handleAccountTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTabAccout(tabNumber)
  }
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const {
    isLoading: isCustomerLoading,
    data: customer,
    error,
    refetch: refetchCsutomer,
  } = useQuery(
    ['customer-detail', id],
    () => {
      return getCustomerById(id)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const [activeTabAccout, setActiveTabAccout] = useState(
    currentUser?.result?.role === 'Admin' ? 2 : 1
  )

  useEffect(() => {
    const redirectTab = searchParams.get('tab')
    if (redirectTab === 'precall-policies') {
      setActiveTabAccout(4)
    }
  }, [])

  useEffect(() => {
    const fetchIVRData = async () => {
      try {
        const response = await axios.get(`${API_URL}/master/ivr-numbers`)
        setIVRData(response.data.data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchIVRData()
  }, [])

  useEffect(() => {
    if (!selectedFile) {
      setPreview('')
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const editUserSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    // ivr: Yup.string().test('is-valid-phone-number', 'Invalid IVR', (value) => {
    //   return isValidPhoneNumber(value ? value : '')
    // }),
    fK_NativeLanguage: Yup.string()
      .required('Please select a native language')
      .notOneOf([''], 'Please select a native language'),
    //fK_ServiceType: Yup.array().required('Required').min(1, 'Required'),
    fK_DefaultTimeZone: Yup.string().required('Please select a default time zone'),
    uniqueIdentifier: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    street1: Yup.string(),
    street2: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
    latitude: Yup.number(),
    longitude: Yup.number(),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      //  refetch()
    }
    formik.resetForm()
    hiddenFileInput.current.value = null; // Clear the file input
    setSelectedFile(undefined)
    //setItemIdForUpdate(undefined)
  }

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    () => {
      return getNativeLanguage()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingServiceType, data: servicetypeList} = useQuery(
    `Query.serviceTypeList`,
    () => {
      return getMasterData('SERVICE_TYPE')
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingTimeZone, data: timezoneList} = useQuery(
    `Query.timezoneList`,
    () => {
      return getTimeZone()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingCountry, data: countryList} = useQuery(
    `Query.countryList`,
    () => {
      return getCountries()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...customer,
      name: customer?.name ? customer?.name : '',
      ivr: customer?.ivr ? customer?.ivr : '',
      fK_NativeLanguage: customer?.fK_NativeLanguage ? customer?.fK_NativeLanguage : '',
      fK_ServiceType: customer?.fK_ServiceType ? customer?.fK_ServiceType : [],
      fK_DefaultTimeZone: customer?.fK_DefaultTimeZone ? customer?.fK_DefaultTimeZone : '',
      uniqueIdentifier: customer?.uniqueIdentifier ? customer?.uniqueIdentifier : '',
      address: customer?.address ? customer?.address : '',
      street1: customer?.street1 ? customer?.street1 : '',
      street2: customer?.street2 ? customer?.street2 : '',
      city: customer?.city ? customer?.city : '',
      state: customer?.state ? customer?.state : '',
      country: customer?.country ? customer?.country : '',
      postalCode: customer?.postalCode ? customer?.postalCode : '',
      latitude: customer?.latitude ? customer?.latitude : '',
      longitude: customer?.longitude ? customer?.longitude : '',
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const formData = new FormData()

        formData.append('address', values.address ?? '')
        formData.append('street1', values?.street1 ?? '')
        formData.append('street2', values?.street2 ?? '')
        formData.append('city', values?.city ?? '')
        formData.append('state', values?.state ?? '')
        formData.append('country', values?.country ?? '')
        formData.append('postalCode', values?.postalCode ?? '')
        formData.append('latitude', values?.latitude?.toString() ?? '0')
        formData.append('longitude', values?.longitude?.toString() ?? '0')

        //formData.append('fK_Contry', values.fK_Contry ?? '')
        formData.append('fK_NativeLanguage', values.fK_NativeLanguage ?? '')
        formData.append('fK_DefaultTimeZone', values.fK_DefaultTimeZone ?? '')
        // formData.append('fK_Country', values.fK_Contry ?? '0')
        formData.append('name', values.name ?? '')
        formData.append('fK_ServiceType', values.fK_ServiceType ?? '')

        formData.append('uniqueIdentifier', values.uniqueIdentifier ?? '')
        formData.append('logo', values.logo ?? '')
        formData.append('logoFile', selectedFile ?? '')
        formData.append('code', values.code + '')
        formData.append('ivr', values.ivr + '')

        if (values.isDeleted + '' === 'true' || values.isDeleted + '' == 'false') {
          formData.append('isDeleted', JSON.parse(values.isDeleted + ''))
        }

        if (
          values.isInformedViaEmail + '' === 'true' ||
          values.isInformedViaEmail + '' == 'false'
        ) {
          formData.append('isInformedViaEmail', JSON.parse(values.isInformedViaEmail + ''))
        }
        var result = await updateCustomer(formData)
        if (result?.status == 'S') {
          toaster('success', result?.text ?? 'Record Updated')
        } else if (result?.status == 'E') {
          toaster('error', result?.text ?? 'Record Not Updated')
        }
        refetchCsutomer()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        formik.setFieldValue('profileImage', reader.result)
        formik.setFieldTouched('profileImage', true, true)
      }
      reader.readAsDataURL(file)
    }

    setSelectedFile(file)
  }
  const languageOptions = nativeLanguageList?.data?.map((d) => ({
    value: d.key,
    label: d.value,
  }))
  // const serviceTypeOptions = servicetypeList?.data?.map((d) => ({
  //   value: d.key,
  //   label: d.value,
  // }))

  // const IVRTypeOptions = IVRData?.filter((d: {isDeleted: boolean}) => !d.isDeleted).map(
  //   (d: {value: any; isGlobal: boolean}) => ({
  //     value: d.value,
  //     label: d.isGlobal ? (
  //       <span
  //         className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
  //         style={{width: 'fit-content'}}
  //       >
  //         {d.value} - Global IVR
  //       </span>
  //     ) : (
  //       `${d.value}`
  //     ),
  //   })
  // )

  const timezoneOptions: any = !isFetchingTimeZone
    ? timezoneList?.data?.map((d: any) => ({
        value: d.code,
        label: d.name,
      }))
    : []

  const countryOptions =
    countryList?.data?.map((d) => ({
      value: d.key,
      label: d.value,
    })) || []

  const renderFunc: React.FC<RenderFuncProps> = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
    loading,
    error,
  }) => (
    <div className='autocomplete-root'>
      <input
        className={clsx('form-control form-control-sm form-control-light mb-3 custom-input-height')}
        disabled={formik.isSubmitting || isUserLoading}
        placeholder='Address'
        {...getInputProps()}
      />
      {error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{error}</span>
          </div>
        </div>
      )}
      <div className='autocomplete-dropdown-container'>
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            {...getSuggestionItemProps(suggestion)}
            key={suggestion.placeId}
            className='bg-light p-2 border border-bottom overflow-auto'
            style={{cursor: 'pointer'}}
          >
            <option>{suggestion.description}</option>
          </div>
        ))}
      </div>
    </div>
  )

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address)
      const placeDetails = await getLatLng(results[0])

      // Extract detailed address components
      let street1 = ''
      let street2 = ''
      let city = ''
      let state = ''
      let country = ''
      let postalCode = ''

      results[0].address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          street1 = component.long_name
        } else if (component.types.includes('route')) {
          street2 = component.long_name
        } else if (component.types.includes('locality')) {
          city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name
        } else if (component.types.includes('country')) {
          country = component.long_name
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name
        }
      })

      formik.setFieldValue('address', address)
      formik.setFieldValue('street1', street1)
      formik.setFieldValue('street2', street2)
      formik.setFieldValue('city', city)
      formik.setFieldValue('state', state)
      formik.setFieldValue('country', country)
      formik.setFieldValue('postalCode', postalCode)
      formik.setFieldValue('latitude', placeDetails.lat)
      formik.setFieldValue('longitude', placeDetails.lng)

      // Select the input element with id "city"
      const cityInputElement = document.querySelector<HTMLInputElement>('#city')

      // Ensure the cityInputElement exists before focusing on it
      if (cityInputElement) {
        cityInputElement.focus() // Focuses on the input field
      }
    } catch (error) {
      console.error('Error fetching place details', error)
    }
  }

  return (
    <>
      <div className='mb-5'>
        <div className='d-flex align-items-center '>
          <div>
            <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
              Company Profile
            </h1>
            <p className='text-gray-500 fs-5'>Manage Company Account Details</p>
          </div>
        </div>
      </div>
      <div>
        <div className='card mb-5 mb-xl-5 shadow-sm' style={{borderTop: '3px solid #a3a242'}}>
          {isCustomerLoading ? (
            <span
              className='indicator-progress p-5'
              style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <>
              <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                  <div className='me-7 mb-4'>
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border border-primary border-3 rounded-circle'>
                      {customer?.logo !== undefined &&
                        customer?.logo !== null &&
                        customer?.logo !== '' && (
                          <>
                            <>
                              <img
                                src={process.env.REACT_APP_IMG_URL + '/customer/' + customer?.logo}
                                alt='user'
                                className='rounded-circle'
                                style={{objectFit: 'cover'}}
                              />
                            </>
                          </>
                        )}
                      {(customer?.logo == undefined ||
                        customer?.logo == null ||
                        customer?.logo == '') && (
                        <>
                          {preview !== undefined && preview !== '' && (
                            <>
                              <img src={preview} alt='user' className='rounded-circle' />
                            </>
                          )}
                          {!(preview !== undefined && preview !== '') && (
                            <>
                              <img
                                src={toAbsoluteUrl('../media/avatars/blank.png')}
                                alt='user'
                                className='rounded-circle '
                                style={{objectFit: 'cover'}}
                              />
                            </>
                          )}
                        </>
                      )}
                      {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                    </div>
                    <div className='d-flex flex-center mt-2'>
                      <>
                        <a
                          className='btn btn-color-primary btn-active-primary btn-sm'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_4'
                        >
                          <i className='fa-solid fa-pen me-1 fs-8'></i>
                          Edit
                        </a>
                        {/* <button
                            type='button'
                            className='btn btn-sm btn-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_4'
                          >
                            <i className='fa-solid fa-pen '></i>
                            Edit
                          </button> */}
                      </>
                    </div>
                  </div>

                  <div className='flex-grow-1'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center mb-2'>
                          <a className='text-gray-800 fs-2 fw-bolder me-1 text-uppercase'>
                            {customer?.name}
                          </a>
                          {!customer?.isDeleted && (
                            <span className='badge badge-success fw-bolder me-auto px-4 py-3 ms-3'>
                              ACTIVE
                            </span>
                          )}

                          {customer?.isDeleted && (
                            <span className='badge btn-sm badge-danger fw-bolder me-auto px-4 py-3 ms-3'>
                              DEACTIVATED
                            </span>
                          )}
                        </div>

                        {customer?.street1 != null &&
                          customer?.street2 != null &&
                          customer?.city != null &&
                          customer?.postalCode != null &&
                          customer?.contryName != null && (
                            <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 '></div>
                          )}
                      </div>
                    </div>
                    <div className='separator border-muted border border-1 border my-3'></div>
                    <div className='d-flex flex-wrap flex-stack'>
                      <div className='d-flex flex-column flex-grow-1 pe-8'>
                        <div className='d-flex flex-wrap'>
                          <div className='card-body p-0'>
                            <div className='row mb-3'>
                              <label className='col-lg-3 fw-bold text-black fs-6'>Address </label>
                              <div className='col-lg-9'>
                                <span className='fs-6 text-gray-900'>: {customer?.address}</span>
                              </div>
                            </div>
                            {/* <div className='row mb-3'>
                              <label className='col-lg-3 fw-bold text-black fs-6'>
                                Service Type(s){' '}
                              </label>
                              <div className='col-lg-9'>
                                <span className='fs-6 text-gray-900'>
                                  :{' '}
                                  {Array.isArray(customer?.serviceType)
                                    ? customer?.serviceType.join(', ')
                                    : customer?.serviceType}
                                </span>
                              </div>
                            </div> */}
                            <div className='row mb-3'>
                              <label className='col-lg-3 fw-bold text-black fs-6'>Timezone</label>
                              <div className='col-lg-9 d-flex align-items-center'>
                                <span className=' fs-6 me-2'>: {customer?.defaultTimeZone}</span>
                              </div>
                            </div>
                            <div className='row mb-3'>
                              <label className='col-lg-3 fw-bold text-black fs-6'>
                                Office E-mail
                              </label>
                              <div className='col-lg-9 d-flex align-items-center'>
                                <span className=' fs-6 me-2'>: {customer?.customerEmail}</span>
                              </div>
                            </div>
                            <div className='row mb-3'>
                              <label className='col-lg-3 fw-bold text-black fs-6'>
                                Native language
                              </label>
                              <div className='col-lg-9 d-flex align-items-center'>
                                <span className=' fs-6 me-2'>: {customer?.nativeLanguage}</span>
                              </div>
                            </div>
                            {/* <div className='row mb-3'>
                              <label className='col-lg-3 fw-bold text-black fs-6'>IVR</label>
                              <div className='col-lg-9 d-flex align-items-center'>
                                <span className=' fs-6 me-2'>
                                  :
                                  {customer?.ivr !== null && customer?.ivr !== undefined ? (
                                    <> {customer?.ivr}</>
                                  ) : (
                                    ' +1 844-451-1200'
                                  )}
                                </span>
                              </div>
                            </div> */}
                            <div className='row mb-3'>
                              <label className='col-lg-3 fw-bold text-black fs-6'>
                                Unique Identifier
                              </label>
                              <div className='col-lg-9 fv-row'>
                                <span className='fs-6'>: {customer?.uniqueIdentifier}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {currentUser?.result?.role !== 'User' && (
          <div className='card mb-5'>
            <div
              className='card-header py-0'
              style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
            >
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                {currentUser?.result?.role !== 'Admin' && (
                  <li className='nav-item'>
                    <a
                      className={`nav-link fs-7 text-gray-400   ${
                        activeTabAccout === 1 ? 'text-active-dark fw-bold active show' : ''
                      }`}
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_1'
                      onClick={() => handleAccountTabClick(1)}
                    >
                      Overview
                    </a>
                  </li>
                )}

                <li className='nav-item'>
                  <a
                    className={`nav-link fs-7 text-gray-400 ${
                      activeTabAccout === 2 ? 'text-active-dark fw-bold active show' : ''
                    }`}
                    data-bs-toggle='tab'
                    href='#kt_tab_pane_2'
                    onClick={() => handleAccountTabClick(2)}
                  >
                    Locations
                  </a>
                </li>

                {currentUser?.result?.role !== 'Admin' && (
                  <li className='nav-item'>
                    <a
                      className={`nav-link fs-7 text-gray-400 ${
                        activeTabAccout === 3 ? 'text-active-dark fw-bold active show' : ''
                      }`}
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_3'
                      onClick={() => handleAccountTabClick(3)}
                    >
                      Users
                    </a>
                  </li>
                )}

                {currentUser?.result?.role !== 'Admin' && (
                  <>
                    <li className='nav-item'>
                      <a
                        className={`nav-link fs-7 text-gray-400 ${
                          activeTabAccout === 4 ? 'text-active-dark fw-bold active show' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_4'
                        onClick={() => handleAccountTabClick(4)}
                        style={{whiteSpace: 'nowrap'}}
                      >
                        Pre-Call Policies
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link fs-7 text-gray-400   ${
                          activeTabAccout === 5 ? 'text-active-dark fw-bold active show' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_5'
                        onClick={() => handleAccountTabClick(5)}
                      >
                        Logs
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link fs-7 text-gray-400   ${
                          activeTabAccout === 6 ? 'text-active-dark fw-bold active show' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_6'
                        onClick={() => handleAccountTabClick(6)}
                      >
                        Calendar
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link fs-7 text-gray-400   ${
                          activeTabAccout === 7 ? 'text-active-dark fw-bold active show' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_7'
                        onClick={() => handleAccountTabClick(7)}
                      >
                        Rate
                      </a>
                    </li>
                  </>
                )}
                {process.env.REACT_APP_SERVER === 'DEV' && !customer?.isSystemCustomer && (
                  <>
                    <li className='nav-item'>
                      <a
                        className={`nav-link fs-7 text-gray-400 ${
                          activeTabAccout === 8 ? 'text-active-dark fw-bold active show' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_8'
                        onClick={() => handleAccountTabClick(8)}
                      >
                        Routing
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link fs-7 text-gray-400 ${
                          activeTabAccout === 9 ? 'text-active-dark fw-bold active show' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_9'
                        onClick={() => handleAccountTabClick(9)}
                      >
                        Features
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link fs-7 text-gray-400 ${
                          activeTabAccout === 10 ? 'text-active-dark fw-bold active show' : ''
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_10'
                        onClick={() => handleAccountTabClick(10)}
                        style={{whiteSpace: 'nowrap'}}
                      >
                        Quick Dials
                      </a>
                    </li>
                  </>
                )}

                {currentUser?.result?.role !== 'Admin' && (
                  <li className='nav-item'>
                    <a
                      className={`nav-link fs-7 text-gray-400   ${
                        activeTabAccout === 11 ? 'text-active-dark fw-bold active show' : ''
                      }`}
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_11'
                      onClick={() => handleAccountTabClick(11)}
                    >
                      Appointments
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className='card-body p-0'>
              <div className=''>
                <div className='tab-content' id='myTabContent'>
                  {activeTabAccout === 1 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                      <div className='card-body pt-0'>
                        <AccountOverview CustomerModel={customer} isLoading={isCustomerLoading} />
                      </div>
                    </div>
                  )}
                  {activeTabAccout === 2 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                      <div className='card-body p-0'>
                        <LocationInfo
                          className={''}
                          CustomerModel={customer}
                          isLoading={isCustomerLoading}
                        />
                      </div>
                    </div>
                  )}
                  {activeTabAccout === 3 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel'>
                      <div className='card-body pt-0'>
                        <UsersViewTable
                          userType={'CONSUMER'}
                          className={''}
                          customerCode={Number(customer?.code?.toString())}
                        />
                      </div>
                    </div>
                  )}

                  {activeTabAccout === 4 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
                      <div className='card-body p-0'>
                        <ListWrapper />
                      </div>
                    </div>
                  )}
                  {activeTabAccout === 5 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_5' role='tabpanel'>
                      <div className='card-body pt-0'>
                        <LogsView className={''} />
                      </div>
                    </div>
                  )}
                  {activeTabAccout === 6 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_6' role='tabpanel'>
                      <div className='card-body pt-0'>
                        <div className='px-0 py-5'>
                          <CustomerFullCalendarList />
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTabAccout === 7 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_7' role='tabpanel'>
                      <div className='card-body'>
                        <RateAccountView />
                      </div>
                    </div>
                  )}
                  {activeTabAccout === 8 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_8' role='tabpanel'>
                      <div className='card-body'>
                        <RoutingView />
                      </div>
                    </div>
                  )}
                  {activeTabAccout === 9 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_9' role='tabpanel'>
                      <div className='card-body'>
                        <FeatureView />
                      </div>
                    </div>
                  )}
                  {activeTabAccout === 10 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_10' role='tabpanel'>
                      <div className='card-body'>
                        <QuickDialsView />
                      </div>
                    </div>
                  )}
                  {activeTabAccout === 11 && (
                    <div className='tab-pane fade show active' id='kt_tab_pane_11' role='tabpanel'>
                      <div className='card-body'>
                        <AppointmentView />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
          <div className='modal-dialog modal-lg'>
            <div className='modal-content '>
              <div className='modal-header py-2'>
                <h4 className='modal-title'>Complete {customer?.name}'s Profile</h4>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => cancel()}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              <div className='modal-body'>
                <div className='row g-4 g-xl-6'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className=''>
                      <div className='row g-4 g-xl-6'>
                        <ImageComponent
                          selectedFile={selectedFile}
                          onSelectFile={onSelectFile}
                          preview={preview}
                          profileImage={customer?.logo}
                          type='customer'
                          hiddenFileInput={hiddenFileInput}
                        />
                        <div className='col-sm-8 col-md-8 col-lg-8 '>
                          <div className='mb-3'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Company Name
                            </label>
                            <div className='d-flex align-items-center'>
                              <input
                                {...formik.getFieldProps('name')}
                                name='name'
                                className={clsx(
                                  'form-control form-control-white form-select-sm mb-2'
                                )}
                                type='text'
                                placeholder='Enter Company Name'
                              />
                            </div>
                            {formik.errors.name && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.name}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='mb-3'>
                            <label className='required form-label'>Address</label>
                            {typeof window.google !== 'undefined' && (
                              <PlacesAutocomplete
                                onSelect={(suggestion: any) => {
                                  handleSelect(suggestion)
                                }}
                                value={formik.values.address}
                                onChange={(e: any) => {
                                  if (e === '') {
                                    formik.setFieldValue('address', '')
                                    formik.setFieldValue('street1', '')
                                    formik.setFieldValue('street2', '')
                                    formik.setFieldValue('city', '')
                                    formik.setFieldValue('state', '')
                                    formik.setFieldValue('country', '')
                                    formik.setFieldValue('postalCode', '')
                                    formik.setFieldValue('latitude', center.lat)

                                    formik.setFieldValue('longitude', center.lng)
                                  }
                                  formik.setFieldValue('address', e)
                                }}
                              >
                                {({
                                  getInputProps,
                                  getSuggestionItemProps,
                                  suggestions,
                                  loading,
                                }) => (
                                  <div>
                                    {renderFunc({
                                      getInputProps,
                                      getSuggestionItemProps,
                                      suggestions,
                                      loading,
                                      error: formik.errors.address,
                                    })}
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            )}
                          </div>

                          <div className='row'>
                            <div className='col-lg-6 col-sm-12 mb-3'>
                              <label className='required form-label'>City</label>
                              <div>
                                <input
                                  placeholder='City'
                                  {...formik.getFieldProps('city')}
                                  type='text'
                                  name='city'
                                  id='city'
                                  className={clsx(
                                    'form-control form-control-sm form-control-light custom-input-height'
                                  )}
                                  autoComplete='off'
                                  disabled={formik.isSubmitting || isUserLoading}
                                />
                                {formik.errors.city && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{formik.errors.city}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='col-lg-6 col-sm-12 mb-3'>
                              <label className='required form-label'>State</label>
                              <div>
                                <input
                                  placeholder='State'
                                  {...formik.getFieldProps('state')}
                                  type='text'
                                  name='state'
                                  id='state'
                                  className={clsx(
                                    'form-control form-control-sm form-control-light custom-input-height'
                                  )}
                                  autoComplete='off'
                                  disabled={formik.isSubmitting || isUserLoading}
                                />
                                {formik.errors.state && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{formik.errors.state}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='col-lg-6 col-sm-12 mb-3'>
                              <label className='required form-label'>Country</label>
                              <div>
                                <input
                                  placeholder='Country'
                                  {...formik.getFieldProps('country')}
                                  type='text'
                                  name='country'
                                  id='country'
                                  className={clsx(
                                    'form-control form-control-sm form-control-light custom-input-height'
                                  )}
                                  autoComplete='off'
                                  disabled={formik.isSubmitting || isUserLoading}
                                />
                                {formik.errors.country && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{formik.errors.country}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='col-lg-6 col-sm-12 mb-3'>
                              <label className='required form-label'>Postal Code</label>
                              <div className=''>
                                <input
                                  placeholder='Postal Code'
                                  {...formik.getFieldProps('postalCode')}
                                  type='text'
                                  name='postalCode'
                                  id='postalCode'
                                  className={clsx(
                                    'form-control form-control-sm form-control-light custom-input-height'
                                  )}
                                  autoComplete='off'
                                  disabled={formik.isSubmitting || isUserLoading}
                                />
                                {formik.errors.postalCode && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert'>{formik.errors.postalCode}</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mb-3'>
                      <label htmlFor='exampleFormControlInput1' className='required form-label'>
                        Native Language
                      </label>
                      <div>
                        <div className='w-100'>
                          <Select
                            {...formik.getFieldProps('fK_NativeLanguage')}
                            name='fK_NativeLanguage'
                            isDisabled={formik.isSubmitting || isUserLoading}
                            placeholder='Select a Language'
                            value={
                              languageOptions?.find(
                                (option) => option.value === formik.values.fK_NativeLanguage
                              ) || null
                            }
                            onChange={(option) =>
                              formik.setFieldValue('fK_NativeLanguage', option?.value || '')
                            }
                            options={!isFetchingNativeLanguage ? languageOptions : []}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#a4afc4',
                              }),
                            }}
                            onBlur={() => formik.setFieldTouched('fK_NativeLanguage', true)}
                          />
                          {formik.errors.fK_NativeLanguage && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.fK_NativeLanguage}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className='mb-3'>
                      <label htmlFor='exampleFormControlInput1' className='required form-label'>
                        Service Type(s)
                      </label>
                      <div>
                        <div className='w-100'>
                          <Select
                            name='fK_ServiceType'
                            isMulti
                            isDisabled={formik.isSubmitting || isUserLoading}
                            placeholder='Select Service Types'
                            value={
                              serviceTypeOptions?.filter(
                                (option) =>
                                  Array.isArray(formik.values.fK_ServiceType) &&
                                  formik.values.fK_ServiceType.includes(option.value)
                              ) || []
                            }
                            onChange={(selectedOptions) => {
                              formik.setFieldValue(
                                'fK_ServiceType',
                                selectedOptions ? selectedOptions.map((option) => option.value) : []
                              )
                            }}
                            options={!isFetchingServiceType ? serviceTypeOptions : []}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#a4afc4',
                              }),
                            }}
                            onBlur={() => formik.setFieldTouched('fK_ServiceType', true)}
                          />
                          {formik.errors.fK_ServiceType && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{String(formik.errors.fK_ServiceType)}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div> */}
                    <div className='mb-3'>
                      <label htmlFor='exampleFormControlInput1' className='required form-label'>
                        Default Timezone
                      </label>
                      <div>
                        <div className='w-100'>
                          <Select
                            {...formik.getFieldProps('fK_DefaultTimeZone')}
                            name='fK_DefaultTimeZone'
                            isDisabled={formik.isSubmitting || isUserLoading}
                            placeholder='Select a Time Zone'
                            value={timezoneOptions.find(
                              (option: any) => option.value === formik.values.fK_DefaultTimeZone
                            )}
                            onChange={(option) =>
                              formik.setFieldValue('fK_DefaultTimeZone', option?.value || '')
                            }
                            options={timezoneOptions}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#a4afc4',
                              }),
                            }}
                            onBlur={() => formik.setFieldTouched('fK_DefaultTimeZone', true)}
                          />
                          {formik.errors.fK_DefaultTimeZone && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.fK_DefaultTimeZone}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='mb-3'>
                      <label htmlFor='exampleFormControlInput1' className='required form-label'>
                        Unique Identifier
                      </label>

                      <div className='d-flex align-items-center'>
                        <input
                          {...formik.getFieldProps('uniqueIdentifier')}
                          name='uniqueIdentifier'
                          className={clsx('form-control form-control-white form-select-sm mb-2')}
                          type='text'
                          placeholder='Unique Identifier'
                        />
                      </div>
                      {formik.errors.uniqueIdentifier && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.uniqueIdentifier}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className='mb-3'>
                      <label htmlFor='exampleFormControlInput1' className='form-label'>
                        IVR
                      </label>

                      <div className='d-flex align-items-center'>
                        <div className='w-100'>
                          <Select
                            {...formik.getFieldProps('ivr')}
                            name='ivr'
                            isDisabled={formik.isSubmitting || isUserLoading}
                            placeholder='Select an IVR Number'
                            value={
                              IVRTypeOptions?.find(
                                (option: {value: string | undefined}) =>
                                  option.value === formik.values.ivr
                              ) || null
                            }
                            onChange={(option) => formik.setFieldValue('ivr', option?.value || '')}
                            options={IVRTypeOptions || []}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#a4afc4',
                              }),
                            }}
                            onBlur={() => formik.setFieldTouched('ivr', true)}
                          />

                          {formik.errors.ivr && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.ivr}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div> */}
                    {/* <div className='mb-3'>
                      <div className='form-check-custom form-check-solid form-check-sm'>
                        <input
                          className='form-check-input'
                          checked={formik.values.isDeleted ?? false}
                          type='checkbox'
                          {...formik.getFieldProps('isDeleted')}
                          name='isDeleted'
                          id='isDeleted'
                        />
                        <label
                          className='form-check-label form-check-sm'
                          htmlFor='flexCheckDefault'
                        >
                          Deactivate Customer
                        </label>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='modal-footer py-3'>
                <button
                  type='button'
                  className='btn btn-light btn-sm'
                  data-bs-dismiss='modal'
                  onClick={() => cancel()}
                >
                  Close
                </button>
                <button
                  type='submit'
                  className='btn btn-sm btn-primary'
                  data-bs-dismiss='modal'
                  data-kt-users-modal-action='submit'
                  disabled={
                    isUserLoading || formik.isSubmitting || !formik.isValid || !formik.dirty
                  }
                >
                  <span className='indicator-label'>Update Customer</span>
                  {(formik.isSubmitting || isUserLoading) && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export {CustomerProfile}

// Insert the styles into the document head
const styleSheet = document.createElement('style')
styleSheet.innerText = styles
document.head.appendChild(styleSheet)
