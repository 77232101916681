import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser, reinvite} from '../../core/_requests'
import {Deletemodel} from '../../core/_models'
import toaster from '../../../../../../Utils/toaster'
import { DeleteConfirmationModal } from '../../../../../common/Models/confirmation-modal/DeleteConfirmationModal'

type Props = {
  code: string
  email: string
  isConfirmed?: boolean
  accountStatus: string
}

const UserActionsCell: FC<Props> = ({isConfirmed, code, email, accountStatus}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(code)
  }

  // const deleteData: Deletemodel = {
  //   email: email,
  //   code: code,
  // };

  const deleteItem = useMutation(() => deleteUser(code), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      toaster('success', 'Succesfully deactivated')
    },
  })

  const func_reinvite = (param: string) => {
    reinvite(param).then((result: any) => {
      if (result?.status === 'S') {
        toaster('success', 'Successfully reinvited')
      } else if (result?.status === 'E') {
        toaster('error', 'Reinvitation failed')
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={openEditModal}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Edit'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-muted' />
        </a>
        {!isConfirmed && (
          <a
            className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
            onClick={(r) => func_reinvite(code)}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Reinvite'
          >
            <i className='bi bi-envelope-paper-fill' style={{fontSize: '11px'}}></i>
          </a>
        )}
        {accountStatus !== 'Not Active' && (
          <a
            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
            //onClick={async () => await deleteItem.mutateAsync()}
            onClick={() => setDeleteModalOpen(true)}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Deactivate'
          >
            {/* <i className='bi bi-person-dash-fill'></i> */}
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </a>
        )}
        {isDeleteModalOpen && (
          <DeleteConfirmationModal
            title='Confirmation'
            content={'Are you sure you would like to deactivate ?'}
            onSubmit={async () => await deleteItem.mutateAsync()}
            setOpen={setDeleteModalOpen}
          />
        )}
      </div>
    </>
  )
}

export {UserActionsCell}
