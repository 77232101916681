import {Font, StyleSheet} from '@react-pdf/renderer'
import RobotoRegular from '../../../../../_metronic/assets/fonts/Roboto-Regular.ttf'
import RobotoBold from '../../../../../_metronic/assets/fonts/Roboto-Bold.ttf'

Font.register({
  family: 'Roboto',
  fonts: [{src: RobotoRegular}, {src: RobotoBold}],
})

const styles = StyleSheet.create({
  col2: { width: '16.66%' },
  col3: {width: '25%'},
  col4: {width: '33.33%'},
  col5: {width: '41.66%'},
  col6: {width: '50%'},
  col7: {width: '58.33%'},
  col8: {width: '66.66%'},
  col9: {width: '75%' },
  col10: {width: '83.33%' },
  col12: {width: '100%'},
  mt3: {marginTop: 3},
  mt5: {marginTop: 5},
  mt8: {marginTop: 8},
  mb3: {marginBottom: 3},
  mb5: {marginBottom: 5},
  mb8: {marginBottom: 8},
  mb40: {marginBottom: 40},
  mb30: {marginBottom: 30},
  uppercase: {textTransform: 'uppercase'},
  mr3: {marginRight: 10},
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  h3: {fontSize: 16, fontWeight: 700},
  h4: {fontSize: 13, fontWeight: 700},
  body1: {fontSize: 10},
  subtitle2: {fontSize: 9, fontWeight: 600},
  subtitle3: {fontSize: 9, fontWeight: 500},
  alignRight: {textAlign: 'right'},
  page: {
    padding: '24px 24px 5px 24px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer: {
    padding: '5px 24px 5px 24px',
    margin: 'auto',
    bottom: 0,
    borderColor: '#000',
  },
  gridContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  gridColumn: {flexDirection: 'column'},
  gridRow: {flexDirection: 'row'},
  table: {display: 'flex', width: 'auto'},
  tableHeader: {},
  tableBody: {},
  tableRow: {
    padding: '4px 0',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE3E8',
  },
  noBorder: {paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0},
  tableCell_1: {width: '5%'},
  tableCell_2: {width: '50%', paddingRight: 16},
  tableCell_3: {width: '15%'},
  tableCell_4: {width: '85%'},
  tableCell_5: {width: '25%'},
  tableCell_6: {width: '35%'},

  font7: {fontSize: '7px'},
  font8: {fontSize: '8px'},
  font9: {fontSize: '9px'},
  font10: {fontSize: '10px'},

  logo: {
    width: '110px',
    height: 'auto',
    alignItems: 'flex-start',
  },
  redbg: {
    backgroundColor: '#C82128',
    height: '20px',
    width: '100%',
  },
  whiteContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
    heigh: '20px',
  },
  whitebg: {
    backgroundColor: '#ffffff',
    lineHeigh: '20px',
    marginRight: '100px',
    padding: '15px',
  },
  borderstyle: {
    border: '1px solid #000',
    padding: '10px',
  },
  borderLeft: {
    borderLeft: '1px solid #000',
  },
  hr: {
    border: 'none',
    borderTop: '1px dotted #000',
    color: '#000',
    backgroundColor: '#fff',
    height: '10px',
    width: '100%',
  },
  signatureStyle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signature: {
    width: 'auto',
    height: '20px',
  },
  InnerBorderStyle: {
    border: '1px solid #000',
    padding: '0px 8px 8px 8px',
  },
  footerFixed: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  footerborderstyle: {
    border: '1px solid #000',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
})

export default styles
