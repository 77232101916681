import React, {useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../../UI/members-management/users-list/core/_models'
import moment from 'moment'
import toaster from '../../../../Utils/toaster'
import {Form, Formik} from 'formik'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`

type Props = {
  fetchAnalytics: any
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
}

export function DashboardAnalyticsFilter({
  fetchAnalytics,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: Props) {
  const [selectedCustomer, setSelectedCustomer] = useState<any>([])
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [selectedCommunicationType, setSelectedCommunicationType] = useState<string[]>([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  useEffect(() => {
    const defaultStartDate = moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD')
    const defaultEndDate = moment().endOf('day').format('YYYY-MM-DD')

    setStartDate(defaultStartDate)
    setEndDate(defaultEndDate)
  }, [])

  const getCustomers = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_CUSTOMER_NAME}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const customerOptions =
    responseCustomer?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  // Communication type options
  const CommunicationTypeOptions = [
    {value: 'Voice', label: 'OPI'},
    {value: 'Vedio', label: 'VRI'},
    {value: 'OSI', label: 'OSI'},
  ]
  const [data, setdata] = useState({
    customers: [],
    startDate: undefined,
    endDate: undefined,
    communicationTypes: [],
  })
  const handleSubmit = async (values: any) => {
    // const data = {
    //   customers:
    //     selectedCustomer.length > 0 ? selectedCustomer.map((item: any) => item.value) : undefined,
    //   startDate: startDate,
    //   endDate: endDate,
    //   communicationTypes:
    //     selectedCommunicationType.length > 0 ? selectedCommunicationType : undefined,
    // }
    await fetchAnalytics(values)
    setShowDropdownMenu(false)
    setIsFilterApplied(true)
  }

  const handleReset = async () => {
    // setSelectedCustomer([])
    // setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD'))
    // setEndDate(moment().format('YYYY-MM-DD'))
    // setSelectedCommunicationType([])
    setdata({
      startDate: undefined,
      endDate: undefined,
      communicationTypes: [],
      customers: [],
    })
    setIsFilterApplied(false)
    setShowDropdownMenu(false)
    await fetchAnalytics({})
  }

  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-350px' style={{minHeight: '100%'}}>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting}) => {
                setSubmitting(true)
                setdata(values)
                handleSubmit(values)
                
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                            Customer
                          </label>
                          <div className='w-100'>
                            <Select
                              {...formik.getFieldProps('customers')}
                              className='react-select-styled react-select-solid react-select-sm'
                              classNamePrefix='react-select'
                              options={!isFetchingCustomer ? customerOptions : []}
                              placeholder='Select Customer'
                              isMulti
                              value={
                                customerOptions?.filter(
                                  (option: any) =>
                                    Array.isArray(values.customers) &&
                                    (values.customers as string[]).includes(option.value)
                                ) || []
                              }
                              onChange={(selectedOptions) => {
                                setFieldValue(
                                  'customers',
                                  selectedOptions
                                    ? selectedOptions.map((option) => option.value)
                                    : []
                                )
                              }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                            Communication Type
                          </label>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={CommunicationTypeOptions}
                            placeholder='Select Communication Type'
                            isMulti
                            value={
                              CommunicationTypeOptions?.filter(
                                (option: any) =>
                                  Array.isArray(values.communicationTypes) &&
                                  (values.communicationTypes as string[]).includes(option.value)
                              ) || []
                            }
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                'communicationTypes',
                                selectedOptions ? selectedOptions.map((option) => option.value) : []
                              )
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className='fv-row'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                              Start Date
                            </label>
                            <input
                              {...formik.getFieldProps('startDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.startDate}
                              onChange={(e) => setFieldValue('startDate', e.target.value)}
                            />
                          </div>
                          <div className='col-sm-6'>
                            <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                              End Date
                            </label>
                            <input
                              {...formik.getFieldProps('endDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.endDate}
                              onChange={(e) => setFieldValue('endDate', e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={handleReset}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
