import React, {FC, useState} from 'react'
import {Dropdown, Form} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {useQuery} from 'react-query'
import {useAuth} from '../../../../modules/auth'
import {DropdownResponse} from '../../../members-management/users-list/core/_models'
import {KTSVG, roleQUERIES} from '../../../../../_metronic/helpers'
import {CommonLoading} from '../../../../../Utils/commonLoading'
import {Formik} from 'formik'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`
const USER_URL = `${API_URL}/accounts/dd-list-accounts/INTERPRETER`

type Props = {
  setCurrentPage: any
  setFilterData: any
  fetchcall_log: any
  searchQuery: any
  filterData: any
}

export function InterperterFilterDropdown({
  setCurrentPage,
  setFilterData,
  fetchcall_log,
  searchQuery,
  filterData,
}: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const getinterpreter = (): Promise<DropdownResponse> => {
    var dt = axios.get(`${USER_URL}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
    return dt
  }

  const {isFetching: isFetchingInterpreter, data: responseInterpreterMaster} = useQuery(
    `${roleQUERIES.interpreter_LIST}`,
    () => {
      return getinterpreter()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const getNativeLanguage = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    getNativeLanguage,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const languageOptions =
    nativeLanguageList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const requesterOptions =
    responseInterpreterMaster?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleReset = async () => {
    setCurrentPage(1)
    setShowDropdownMenu(false)
    setFilterData({
      awsUserId_INTList: null,
      languageList: null,
      startDate: null,
      endDate: null,
      companyCode: null,
      callType: 5,
    })
    await fetchcall_log(
      {
        awsUserId_INTList: null,
        languageList: null,
        startDate: null,
        endDate: null,
        companyCode: null,
        callType: 5,
      },
      {
        search: '',
      }
    )
    setIsFilterApplied(false)
  }

  /******  b2da24b8-a3f9-475d-8c0a-943c73758816  *******/
  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            // Call the fetchcall_log function with default filters
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Formik
            enableReinitialize
            initialValues={{
              ...filterData,
              awsUserId_INTList:
                requesterOptions.filter((d: any) =>
                  filterData?.awsUserId_INTList?.includes(d?.value)
                ) ?? [],
              languageList:
                languageOptions.filter((d: any) => filterData?.languageList?.includes(d?.label)) ??
                [],
            }}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              const passingData = {
                awsUserId_INTList: values?.awsUserId_INTList?.map((option: any) => option.value),
                languageList: values?.languageList?.map((option: any) => option.label),
                startDate: values.startDate || undefined,
                endDate: values.endDate || undefined,
                callType: 5,
              }
              setFilterData(passingData)
              try {
                await fetchcall_log(passingData, {
                  params: {
                    page: 1,
                    items_per_page: 10,
                    ...searchQuery,
                  },
                })
                setCurrentPage(1)
                setIsFilterApplied(true)
              } catch (error) {
                console.log(error)
              } finally {
                setShowDropdownMenu(false)
                setIsLoading(false)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
              dirty,
              isSubmitting,
              handleSubmit,
              resetForm,
              ...formik
            }) => (
              <Form>
                <Dropdown.Menu className='p-4 w-350px' style={{minHeight: '100%'}}>
                  <div className='px-4 pb-4'>
                    <div className='fs-4 text-dark fw-semibolder'>Filter</div>
                  </div>
                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      {currentUser?.result?.userType !== 'INTERPRETER' &&
                        currentUser?.result?.role !== 'User' && (
                          <div className='col-sm-12 col-md-12'>
                            <div className='mb-3'>
                              <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                                Interperter(s)
                              </label>
                              <div className='row g-4 g-xl-4'>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                  <div className='w-100'>
                                    <Select
                                      className='react-select-styled react-select-solid react-select-sm'
                                      classNamePrefix='react-select'
                                      options={!isFetchingInterpreter ? requesterOptions : []}
                                      placeholder='Select : All'
                                      isMulti
                                      value={values.awsUserId_INTList}
                                      onChange={(e: any) => setFieldValue('awsUserId_INTList', e)}
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                          border: '1px solid #e4e6ef',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='languages' className='form-label fs-7 mb-1'>
                            Language(s)
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingNativeLanguage ? languageOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={values.languageList}
                                  onChange={(e: any) => setFieldValue('languageList', e)}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='fv-row'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                              Start Date
                            </label>
                            <input
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              id='startDate'
                              name='startDate'
                              value={values.startDate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Enter Start Date'
                            />
                          </div>
                          <div className='col-sm-6'>
                            <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                              End Date
                            </label>
                            <input
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.endDate}
                              id='endDate'
                              name='endDate'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='End Date'
                              max={new Date().toISOString().split('T')[0]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={() => {
                        handleReset()
                        resetForm()
                      }}
                    >
                      Reset
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Dropdown.Menu>
              </Form>
            )}
          </Formik>
        )}
      </Dropdown>
      {isLoading && <CommonLoading />}
    </>
  )
}
