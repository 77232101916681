/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {CommonLoading} from '../../../../Utils/commonLoading'
import axios from 'axios'
import clsx from 'clsx'
import {useAuth} from '../../../modules/auth'
import {Modal} from 'bootstrap'
import toaster from '../../../../Utils/toaster'
import {Overlay, Tooltip} from 'react-bootstrap'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  className: string
  isLoading: boolean
  call_log: any
  tableRef: any
  title?: string
}

const LogsViewTable: React.FC<Props> = ({className, isLoading, call_log, tableRef, title}) => {
  const target = useRef(null)
  const {currentUser, logout} = useAuth()
  const [activeModalTab, setActiveModalTab] = useState(1)
  const [callDetails, setCallDetails] = useState<any>(null)
  const [routingDetails, setRoutingDetails] = useState<any>([])
  const [qaArray, setQaArray] = useState<any>([])
  const [note, setNote] = useState<any>('')
  const [isCallDetailsLoading, setIsCallDetailsLoading] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState(routingDetails?.data || [])

  const handleModalTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveModalTab(tabNumber)
  }

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredData(routingDetails?.data || [])
    }
  }, [searchTerm, routingDetails])

  const fetchCallInDetails = async (callId: string, initialContactId?: any) => {
    try {
      setIsCallDetailsLoading(true)
      const result = await axios.get(`${API_URL}/PreCalls/questions-transaction/${callId}`)
      setCallDetails({...result?.data?.data, callId: callId})
      setQaArray(result?.data?.data?.qa ?? [])

      const routerResult = await axios.post(
        `${API_URL}/Dashboard/routing-details/${initialContactId}`
      )
      setRoutingDetails(routerResult?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsCallDetailsLoading(false)
    }
  }

  const updateCallInDetails = async (callId: string) => {
    try {
      const result = await axios.put(`${API_URL}/PreCalls/questions-transaction`, {
        Model: qaArray.map((x: any) => {
          return {code: x?.code, answer: x?.answer}
        }),
        Note: note,
        Call_Id: callId,
      })
      if (result?.data?.status === 'S') {
        toaster('success', result?.data?.text)
        hideModal()
      } else {
        toaster('success', result?.data?.text)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const hideModal = () => {
    const modal = document.getElementById('kt_call_in_details')
    if (modal) {
      const modalInstance = Modal.getInstance(modal)
      if (modalInstance) modalInstance.hide()
    }
  }

  const copyToClipboard = (callId: string) => {
    if (callId) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(callId)
          .then(() => {
            setTooltipVisible(true)
            setTimeout(() => {
              setTooltipVisible(false)
            }, 2000)
          })
          .catch((err) => {
            console.error('Failed to copy: ', err)
          })
      } else {
        // Fallback for older browsers
        const textarea = document.createElement('textarea')
        textarea.value = callId
        document.body.appendChild(textarea)
        textarea.select()
        try {
          document.execCommand('copy')
          setTooltipVisible(true)
          setTimeout(() => {
            setTooltipVisible(false)
          }, 2000)
        } catch (err) {
          console.error('Fallback: Failed to copy: ', err)
        }
        document.body.removeChild(textarea)
      }
    }
  }

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      setFilteredData(routingDetails?.data || [])
    } else {
      const filtered = routingDetails?.data.filter((item: any) =>
        item.interpereterName.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilteredData(filtered)
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='modal fade' tabIndex={-1} id='kt_call_in_details'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Details</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {!isCallDetailsLoading && (
                <>
                  <div className='d-flex align-items-center justify-content-start'>
                    <h5 className='d-flex text-gray-600 fw-normal fs-2 mb-0'>
                      Call ID : {callDetails?.callId}
                    </h5>{' '}
                    <span
                      className='d-flex ms-3'
                      onClick={() => copyToClipboard(callDetails?.callId)}
                      style={{cursor: 'pointer'}}
                      ref={target}
                    >
                      <KTSVG
                        path='media/icons/duotune/general/gen054.svg'
                        className='svg-icon-muted svg-icon-2'
                      />
                    </span>
                    <Overlay target={target.current} show={tooltipVisible} placement='top'>
                      {(props) => (
                        <Tooltip id='overlay-tooltip' {...props}>
                          Call ID copied!
                        </Tooltip>
                      )}
                    </Overlay>
                  </div>
                  <div className='row g-4'>
                    <div className='mb-5'>
                      <div
                        className='py-3'
                        style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
                      >
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                          <li className='nav-item'>
                            <a
                              className={`nav-link fs-7 text-gray-400   ${
                                activeModalTab === 1 ? 'text-active-dark fw-bold active show' : ''
                              }`}
                              data-bs-toggle='tab'
                              href='#kt_modaltab_pane_1'
                              onClick={() => handleModalTabClick(1)}
                            >
                              Call Details
                            </a>
                          </li>
                          <li className='nav-item'>
                            <a
                              className={`nav-link fs-7 text-gray-400 ${
                                activeModalTab === 2 ? 'text-active-dark fw-bold active show' : ''
                              }`}
                              data-bs-toggle='tab'
                              href='#kt_modaltab_pane_2'
                              onClick={() => handleModalTabClick(2)}
                            >
                              Routing Details
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className=''>
                        <div className='my-3'>
                          <div className='tab-content' id='myTabContent'>
                            {activeModalTab === 1 && (
                              <div className=''>
                                <div
                                  className='tab-pane fade show active'
                                  id='kt_modaltab_pane_1'
                                  role='tabpanel'
                                >
                                  <>
                                    <div className=''>
                                      <div className='d-flex justify-content-between align-items-center py-5 flex-wrap'>
                                        <div className='d-flex flex-wrap'>
                                          <div className='d-flex align-items-center '>
                                            <div className='symbol-hover me-2'>
                                              <div className='symbol symbol-circle symbol-50px overflow-hidden'>
                                                {callDetails?.requester?.avatar ? (
                                                  <div className='symbol-label'>
                                                    <img
                                                      src={
                                                        process.env.REACT_APP_IMG_URL +
                                                        '/profile/' +
                                                        callDetails?.requester?.avatar
                                                      }
                                                      alt={(
                                                        callDetails?.requester?.nameReqester ?? ''
                                                      )
                                                        .charAt(0)
                                                        .toUpperCase()}
                                                      className='w-100'
                                                    />
                                                  </div>
                                                ) : (
                                                  <div
                                                    className={clsx(
                                                      'symbol-label fs-4',
                                                      'badge-light-success',
                                                      `bg-light-success`
                                                    )}
                                                  >
                                                    <span
                                                      style={{fontSize: '20x'}}
                                                      className='fs-7 text-gray-800'
                                                    >
                                                      {(callDetails?.requester?.nameReqester ?? '')
                                                        .charAt(0)
                                                        .toUpperCase() + ''}
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>

                                            <div className='d-flex flex-column flex-1 w-auto'>
                                              <div className='d-flex align-items-center fw-light'>
                                                <span className='fw-semibold d-flex align-items-center fs-6 me-1'>
                                                  {callDetails?.requester?.nameReqester} called
                                                </span>
                                              </div>
                                              {callDetails?.requester?.language &&
                                                callDetails.requester.language !== 'N/A' && (
                                                  <span className='fs-6 fw-normal'>
                                                    <i className='bi bi-globe me-2'></i>
                                                    {callDetails.requester.language}
                                                  </span>
                                                )}

                                              {callDetails?.requester?.serviceType &&
                                                callDetails.requester.serviceType !== 'N/A' && (
                                                  <span className='fs-6 fw-normal'>
                                                    <i className='bi bi-clipboard me-2'></i>
                                                    {callDetails.requester.serviceType}
                                                  </span>
                                                )}

                                              {callDetails?.requester?.description &&
                                                callDetails.requester.description !== 'N/A' && (
                                                  <span className='fs-6 fw-normal'>
                                                    <i className='bi bi-telephone-forward-fill me-2'></i>
                                                    {callDetails.requester.description}
                                                  </span>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        {callDetails?.interpreter && (
                                          <div className='d-flex flex-wrap'>
                                            <div className='d-flex align-items-center '>
                                              <div className='symbol-hover me-2'>
                                                <div className='symbol symbol-circle symbol-50px overflow-hidden'>
                                                  {callDetails?.interpreter?.avatar ? (
                                                    <div className='symbol-label'>
                                                      <img
                                                        src={
                                                          process.env.REACT_APP_IMG_URL +
                                                          '/profile/' +
                                                          callDetails?.interpreter?.avatar
                                                        }
                                                        alt={(
                                                          callDetails?.interpreter
                                                            ?.nameInterpreter ?? ''
                                                        )
                                                          .charAt(0)
                                                          .toUpperCase()}
                                                        className='w-100'
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className={clsx(
                                                        'symbol-label fs-4',
                                                        'badge-light-success',
                                                        `bg-light-success`
                                                      )}
                                                    >
                                                      <span
                                                        style={{fontSize: '20x'}}
                                                        className='fs-7 text-gray-800'
                                                      >
                                                        {(
                                                          callDetails?.interpreter
                                                            ?.nameInterpreter ?? ''
                                                        )
                                                          .charAt(0)
                                                          .toUpperCase() + ''}
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>

                                              <div className='d-flex flex-column flex-1 w-auto'>
                                                <div className='d-flex align-items-center fw-light'>
                                                  <span className='fw-semibold d-flex align-items-center fs-6 me-1'>
                                                    {callDetails?.interpreter?.nameInterpreter}{' '}
                                                    answered
                                                  </span>
                                                </div>
                                                {callDetails?.interpreter?.language &&
                                                  callDetails.interpreter.language !== 'N/A' && (
                                                    <span className='fs-6 fw-normal'>
                                                      <i className='bi bi-globe me-2'></i>
                                                      {callDetails.interpreter.language}
                                                    </span>
                                                  )}

                                                {callDetails?.interpreter?.serviceType &&
                                                  callDetails.interpreter.serviceType !== 'N/A' && (
                                                    <span className='fs-6 fw-normal'>
                                                      <i className='bi bi-clipboard me-2'></i>
                                                      {callDetails.interpreter.serviceType}
                                                    </span>
                                                  )}

                                                {callDetails?.interpreter?.description &&
                                                  callDetails.interpreter.description !== 'N/A' && (
                                                    <span className='fs-6 fw-normal'>
                                                      <i className='bi bi-phone-fill me-2'></i>
                                                      {callDetails.interpreter.description}
                                                    </span>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className='separator my-3'></div>

                                      <div className='row g-4 mb-3'>
                                        <div className='col-sm-12 col-md-5'>
                                          <div className='mb-3 d-flex align-items-center'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='form-label fs-7 fw-bold'
                                            >
                                              Note
                                            </label>
                                          </div>
                                        </div>
                                        <div className='col-sm-12 col-md-7'>
                                          <textarea
                                            placeholder=''
                                            name='Note'
                                            className='form-control form-select-white mb-lg-0'
                                            value={note}
                                            onChange={(e: any) => {
                                              setNote(e.target.value)
                                            }}
                                          ></textarea>
                                        </div>
                                      </div>
                                      {qaArray?.length > 0 && (
                                        <>
                                          <div className='separator my-3'></div>
                                          <div className='row'>
                                            {currentUser?.result.userType === 'SYSTEM' ? (
                                              <h6 className='fs-7 fw-normal py-5'>
                                                You can edit any field that was answered on this
                                                call. New fields added to a pre-call policy will not
                                                be included below.
                                              </h6>
                                            ) : (
                                              <h6 className='fs-7 fw-normal py-5'>
                                                Pre-call policy questions and answers
                                              </h6>
                                            )}
                                          </div>
                                          <div
                                            className='row g-4'
                                            style={{maxHeight: '400px', overflowX: 'auto'}}
                                          >
                                            {qaArray?.map((qa: any, index: number) => (
                                              <>
                                                <div className='col-sm-12 col-md-6'>
                                                  <div className='mb-3 d-flex align-items-center'>
                                                    <label
                                                      htmlFor='exampleFormControlInput1'
                                                      className='form-label fs-7 fw-bold'
                                                    >
                                                      {index + 1}. {qa?.question ?? ''}
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className='col-sm-12 col-md-1'>
                                                  <label htmlFor='exampleFormControlInput1'>
                                                    -
                                                  </label>
                                                </div>
                                                {currentUser?.result.userType === 'SYSTEM' ? (
                                                  <div className='col-sm-12 col-md-5'>
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-white form-select-sm custom-input-height'
                                                      placeholder='Enter Answer'
                                                      value={qa?.answer ?? ''}
                                                      onChange={(e: any) => {
                                                        const updatedItem = {
                                                          code: qa.code,
                                                          answer:
                                                            e.target.value !== ''
                                                              ? e.target.value
                                                              : '',
                                                          question: qa.question,
                                                        }
                                                        const index = qaArray.findIndex(
                                                          (i: any) => i.code === qa.code
                                                        )

                                                        if (index !== -1) {
                                                          // If item exists in the array
                                                          const newVehcleTypes = [...qaArray] // Copy the array
                                                          newVehcleTypes[index] = updatedItem // Replace the old item with the modified one
                                                          setQaArray(newVehcleTypes) // Update the state with the new array
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                ) : (
                                                  <div className='col-sm-12 col-md-5'>
                                                    <label htmlFor='exampleFormControlInput1'>
                                                      {qa?.answer ?? 'N/A'}
                                                    </label>
                                                  </div>
                                                )}
                                              </>
                                            ))}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    {/* qaArray?.length > 0 && */}

                                    {currentUser?.result.userType === 'SYSTEM' && (
                                      <div className='text-end pt-3 mt-3'>
                                        <button
                                          type='reset'
                                          className='btn btn-sm btn-light me-2'
                                          onClick={() => hideModal()}
                                        >
                                          Cancel
                                        </button>

                                        <button
                                          type='submit'
                                          className='btn btn-sm btn-primary'
                                          data-kt-menu-dismiss='true'
                                          // disabled={
                                          //   qaArray?.find((x: any) => x?.answer === '')
                                          //     ? true
                                          //     : false
                                          // }
                                          onClick={() => updateCallInDetails(callDetails?.callId)}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                            {activeModalTab === 2 && (
                              <div className=''>
                                <div
                                  className='tab-pane fade show active'
                                  id='kt_modaltab_pane_2'
                                  role='tabpanel'
                                >
                                  <>
                                    <div className='separator my-3'></div>
                                    <div className='row'>
                                      <h6 className='fs-6 fw-normal py-5'>
                                        The list below shows all interpreters in your roster that
                                        were dialed before this call was picked up or abandoned.
                                      </h6>
                                    </div>
                                    <div className='d-flex flex-wrap flex-end'>
                                      <div className='d-flex align-items-center me-3 flex-nowrap'>
                                        <input
                                          type='text'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search by name'
                                          value={searchTerm}
                                          onChange={(e) => setSearchTerm(e.target.value)}
                                        />

                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                          onClick={handleSearch}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div className='table-responsive'>
                                      {/* begin::Table */}
                                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-3 gy-3'>
                                        {/* begin::Table head */}
                                        <thead>
                                          <tr className='fw-semibold text-muted text-uppercase'>
                                            <th className='min-w-100px'>Interpreter Name</th>
                                            <th className='min-w-150px'>Time Dialed</th>
                                            <th className='min-w-150px'>Ring Duration</th>
                                            <th className='min-w-150px'>Device</th>
                                            <th className='min-w-150px text-end'>Call Status</th>
                                          </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                          {filteredData?.length > 0 ? (
                                            filteredData.map(
                                              (
                                                item: {
                                                  interpereterAvatar: string
                                                  interpereterName: string
                                                  routingTime: string | number | Date
                                                  ringDuration: any
                                                  device: string
                                                  isConnected: boolean
                                                },
                                                index: React.Key | null | undefined
                                              ) => (
                                                <tr key={index}>
                                                  <td>
                                                    <div className='d-flex flex-wrap align-items-center'>
                                                      <div className='symbol-hover me-3'>
                                                        <div className='symbol symbol-circle symbol-50px overflow-hidden'>
                                                          {item.interpereterAvatar ? (
                                                            <div className='symbol-label'>
                                                              <img
                                                                src={`${process.env.REACT_APP_IMG_URL}/profile/${item.interpereterAvatar}`}
                                                                alt={item.interpereterName
                                                                  .charAt(0)
                                                                  .toUpperCase()}
                                                                className='w-100'
                                                              />
                                                            </div>
                                                          ) : (
                                                            <div
                                                              className={clsx(
                                                                'symbol-label fs-4',
                                                                'badge-light-success',
                                                                'bg-light-success',
                                                              )}
                                                            >
                                                              <span style={{fontSize: '20x'}}>
                                                                {(item.interpereterName ?? '')
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                                  (item.interpereterName ?? '')
                                                                    .charAt(1)
                                                                    .toUpperCase()}
                                                              </span>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className='d-flex flex-column'>
                                                        <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                                          {item.interpereterName}
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                                      {new Date(item.routingTime).toLocaleString()}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                                      {`${item.ringDuration} sec`}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                                      {item.device}
                                                    </a>
                                                  </td>
                                                  <td className='text-end'>
                                                    <div className='d-flex flex-column align-items-end'>
                                                      {item.isConnected ? (
                                                        <span
                                                          className='badge badge-light-success px-3 py-2 fs-9 d-flex justify-content-end'
                                                          style={{width: '80px'}}
                                                        >
                                                          Connected
                                                        </span>
                                                      ) : (
                                                        <span
                                                          className='badge badge-light-danger px-3 py-2 fs-9 d-flex justify-content-end'
                                                          style={{width: '80px'}}
                                                        >
                                                          Not Connected
                                                        </span>
                                                      )}
                                                    </div>
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td colSpan={5}>
                                                <div className='py-2 d-flex flex-column align-content-center justify-content-center'>
                                                  <div className='text-center'>
                                                    <div className='symbol symbol-150px'>
                                                      <img src='/media/other/nodata.png' alt='' />
                                                    </div>
                                                  </div>
                                                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                                    No matching records found
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>

                                        {/* end::Table body */}
                                      </table>
                                      {/* end::Table */}
                                    </div>
                                  </>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* begin::Body */}
      <div className='py-0'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table
            className='table table-row-dashed table-row-gray-300 align-middle gs-3 gy-3'
            ref={tableRef}
          >
            {/* begin::Table head */}
            <thead>
              <tr className='fw-semibold text-muted text-uppercase'>
                <th className='min-w-200px '>Call ID</th>
                <th className='min-w-150px'>
                  <span className='d-flex align-items-center'>
                    Call Status{' '}
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Connected - call includes an interpretation duration. Abandoned - call does not include an operator or interpretation duration. Unfulfilled - call answered by an operator, but does not have an interpretation duration. Rollover - requestor placed VRI call, but chose to use OPI instead while waiting to be connected.'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='svg-icon-muted text-primary'
                      />
                    </a>
                  </span>
                </th>
                {title !== 'Operator Log' && <th className='min-w-150px '>Interpreter</th>}
                <th className='min-w-200px '>Date Time</th>
                <th className='min-w-150px '>Requester</th>
                {title !== 'Operator Log' && (
                  <th className='min-w-200px'>
                    <span className='d-flex align-items-center'>
                      Interpreter Rating{' '}
                      <a
                        type='button'
                        className=''
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Interpreter rating / call quality rating submitted by requestor'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen045.svg'
                          className='svg-icon-muted text-primary'
                        />
                      </a>
                    </span>
                  </th>
                )}

                <th className='min-w-200px '>
                  <span className='d-flex align-items-center'>
                    Requester Rating
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Requestor rating / call quality rating submitted by interpreter'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='svg-icon-muted text-primary'
                      />
                    </a>
                  </span>
                </th>

                <th className='min-w-150px '>Language</th>
                <th className='min-w-150px '>Type</th>
                <th className='min-w-200px '>
                  <span className='d-flex align-items-center'>
                    Queue / Hold Time
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Call Center Queue/Hold Time is added in parenthesis next and represents the time a requestor waiting on hold with a BPIN Call Center. This column is listed separately in CSV exports as Call Center Queue/Hold Time'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='svg-icon-muted text-primary'
                      />
                    </a>
                  </span>
                </th>
                <th className='min-w-150px '>Total Duration</th>
                <th className='min-w-150px '>Agent Duration</th>
                {title !== 'Operator Log' && <th className='min-w-200px '>Other Participants</th>}

                <th className='min-w-200px '>Total Conference Duration</th>
                {title !== 'Operator Log' && <th className='min-w-150px '>Account Charge</th>}

                {title !== 'Operator Log' && (
                  <th className='min-w-150px text-end'>
                    <span className=''>
                      Interpreter Pay
                      <a
                        type='button'
                        className=''
                        data-toggle='tooltip'
                        data-placement='top'
                        title="N/A amounts indicate that call was flagged as a test call and won't be included in the invoice."
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen045.svg'
                          className='svg-icon-muted text-primary'
                        />
                      </a>
                    </span>
                  </th>
                )}

                {/* <th className='min-w-150px text-end'>Action</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {!isLoading && call_log?.length > 0 ? (
                call_log?.map((item: any) => (
                  <tr>
                    <td>
                      <a
                        href='#'
                        className='text-gray-800 text-hover-primary d-block fs-6'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_call_in_details'
                        onClick={() => fetchCallInDetails(item.code, item.initialContactId)}
                      >
                        {item.code ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.callStatus ?? '-'}
                      </a>
                    </td>
                    {title !== 'Operator Log' && (
                      <td>
                        <a className='text-gray-800 text-hover-primary d-block fs-6'>
                          {item.interpreter ?? '-'}
                        </a>
                      </td>
                    )}
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.initiationTimestamp ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.customer ?? '-'}
                      </a>
                    </td>
                    {title !== 'Operator Log' && (
                      <td>
                        <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                      </td>
                    )}

                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                    </td>

                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.languages ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.channel ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.agentPauseDurationInSeconds
                          ? Math.ceil(item.agentPauseDurationInSeconds) + 's'
                          : '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.totalCallDuration ? Math.ceil(item.totalCallDuration) + 's' : '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.agentCallDuration ? Math.ceil(item.agentCallDuration) + 's' : '-'}
                      </a>
                    </td>
                    {title !== 'Operator Log' && (
                      <td>
                        <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                      </td>
                    )}
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.conferenceDuration ? Math.ceil(item.conferenceDuration) + 's' : '-'}
                      </a>
                    </td>
                    {title !== 'Operator Log' && (
                      <td>
                        <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                      </td>
                    )}
                    {title !== 'Operator Log' && (
                      <td className='text-end'>
                        <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                      </td>
                    )}
                    {/* <td>
                      <div className='d-flex flex-end'>
                        <a
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_call_in_details'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={14}>
                    <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                      <div className='text-center'>
                        <div className='symbol symbol-200px '>
                          <img src='/media/other/nodata.png' alt='' />
                        </div>
                      </div>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                        No matching records found
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      {(isLoading || isCallDetailsLoading) && <CommonLoading />}
    </div>
  )
}

export {LogsViewTable}
