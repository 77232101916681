import React, {useState} from 'react'

export function NotificationConfig() {
  return (
    <>
      <div className='card mb-5'>
        <div className='accordion' id='kt_accordion_1'>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='kt_accordion_1_header_1'>
              <button
                className='accordion-button fs-6 fw-semibold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_1_body_1'
                aria-expanded='false'
                aria-controls='kt_accordion_1_body_1'
              >
                <div className='editable-title-container'>
                  <div className='d-flex align-items-center '>
                    <div className='d-flex'>
                      {' '}
                      <h1 className='fs-6 fw-semibold me-3 mb-0'>Welcome to Ad Astra!</h1>
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id='kt_accordion_1_body_1'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_1'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <div className='row g-4 g-xl-6'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='card bg-light'>
                      <div className='card-header'>
                        <p className='card-title text-gray-500 fw-semibold fs-6'>
                          Template Preview
                        </p>
                      </div>
                      <div className='card-body'>
                        <div className='Econtainer border border-2 border-dashed p-4 rounded border-gray-600'>
                          <div className='Eheader'>
                            <h6 className='fw-semibold mb-10'>Welcome to Ad Astra!</h6>
                          </div>
                          <div className='Econtent'>
                            <p>Dear [User's Name],</p>
                            <p>
                              Welcome to the Ad Astra community! We’re excited to have you on board.
                              Your registration is now pending, and you can finish your set up and
                              start exploring our services by logging in to your account via the
                              link below:
                            </p>
                            <p>
                              {' '}
                              <strong style={{color: 'blue', textDecoration: 'underline'}}>
                                {' '}
                                Click here to log in
                              </strong>
                            </p>
                            <p>Here are your login details:</p>
                            {/* <p>
                              <strong>Username:</strong> [Username]
                            </p> */}
                            <p>
                              <strong>Email:</strong> [User's Email]
                            </p>
                            <p>
                              For your security, we recommend that you change your password the
                              first time you log in. You can do this by visiting your account
                              settings.
                            </p>
                            <p>
                              If you encounter any issues, please don’t hesitate to reach out to our
                              support team at <a href='mailto:admin@ad-astra.com'>admin@ad-astra.com</a>
                              . We’re here to help!
                            </p>
                          </div>
                          <div className='Efooter mt-10'>
                            <p>Best regards,</p>
                            <p>The Ad Astra Team.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='kt_accordion_1_header_2'>
              <button
                className='accordion-button fs-6 fw-semibold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_1_body_2'
                aria-expanded='false'
                aria-controls='kt_accordion_1_body_2'
              >
                <div className='editable-title-container'>
                  <div className='d-flex align-items-center'>
                    <h1 className='fs-6 fw-semibold me-3 mb-0'>Language Approved</h1>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id='kt_accordion_1_body_2'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_2'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <div className='row g-4 g-xl-6'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='card bg-light'>
                      <div className='card-header'>
                        <p className='card-title text-gray-500 fw-semibold fs-6'>
                          Template Preview
                        </p>
                      </div>
                      <div className='card-body'>
                        <div className='Econtainer border border-2 border-dashed p-4 rounded border-gray-600'>
                          <div className='Eheader'>
                            <h4 className='fw-semibold mb-10'>Language Approved</h4>
                          </div>
                          <div className='Econtent'>
                            <p>Dear [User's Name],</p>
                            <p>
                              We are delighted to inform you that your language with Ad-Astra is now
                              approved.
                            </p>
                          </div>
                          <div className='Efooter mt-10'>
                            <p>Best regards,</p>
                            <p>Ad-Astra Administrator.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='kt_accordion_1_header_3'>
              <button
                className='accordion-button fs-6 fw-semibold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_1_body_3'
                aria-expanded='false'
                aria-controls='kt_accordion_1_body_3'
              >
                <div className='editable-title-container'>
                  <div className='d-flex align-items-center'>
                    <h1 className='fs-6 fw-semibold me-3 mb-0'>New Language</h1>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id='kt_accordion_1_body_3'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_3'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <div className='row g-4 g-xl-6'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='card bg-light'>
                      <div className='card-header'>
                        <p className='card-title text-gray-500 fw-semibold fs-6'>
                          Template Preview
                        </p>
                      </div>
                      <div className='card-body'>
                        <div className='Econtainer border border-2 border-dashed p-4 rounded border-gray-600'>
                          <div className='Eheader'>
                            <h4 className='fw-semibold mb-10'>New Language</h4>
                          </div>
                          <div className='Econtent'>
                            <p>Hello [Recipient's Name/Team],</p>
                            <p>
                              We are delighted to inform you that new language has been added by{' '}
                              <strong>[Recipient's Name/Team]</strong>
                            </p>
                          </div>
                          <div className='Efooter mt-10'>
                            <p>Best regards,</p>
                            <p>Ad-Astra Administrator.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
